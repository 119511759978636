.purchase-wrap .content {
  padding: 16px;
  min-height: 130px;
  margin-top: 0;
  display: block;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.05);
}
.purchase-wrap .content .container {
  width: 100%;
  box-sizing: border-box;
}
.purchase-wrap .content .good-section {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}
.purchase-wrap .content .good-section .good-img {
  width: 160rpx;
  height: 160rpx;
  border-radius: 8rpx;
}
.purchase-wrap .content .good-section .right-content {
  width: 100%;
}
.purchase-wrap .content .good-section .right-content .title {
  overflow: hidden;
  max-height: 40px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.9);
  word-break: break-all;
}
.purchase-wrap .content .good-section .right-content .price {
  height: 28px;
  overflow: hidden;
  width: 100%;
}
.purchase-wrap .content .good-section .right-content .price .real-price {
  display: inline-block;
  font-weight: 500;
  font-size: 20px;
  color: #cb272d;
  line-height: 28px;
}
.purchase-wrap .content .good-section .right-content .price .real-price .no-price {
  font-size: 16px;
}
.purchase-wrap .content .good-section .right-content .price .origin-price {
  margin-left: 4px;
  font-weight: 400;
  font-size: 14px;
  text-decoration-line: line-through;
  display: inline-block;
  color: rgba(0, 0, 0, 0.6);
}
.purchase-wrap .content .good-section .right-content .price .origin-price .tip {
  color: rgba(0, 0, 0, 0.6);
}
.purchase-wrap .content .good-section .right-content .stock {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #666;
  margin-right: 10px;
}
.purchase-wrap .content .good-section .right-content .tip {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}
.purchase-wrap .content .expireDate {
  margin-top: 62rpx;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.purchase-wrap .content .expireDate .label {
  font-weight: 500;
  font-size: 28rpx;
  line-height: 44rpx;
  color: rgba(0, 0, 0, 0.9);
}
.purchase-wrap .content .expireDate .txt {
  margin-left: auto;
  font-weight: 500;
  font-size: 28rpx;
  line-height: 44rpx;
  color: rgba(0, 0, 0, 0.9);
}
.purchase-wrap .content .buy-num {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2px;
}
.purchase-wrap .content .buy-num .label {
  font-weight: 500;
  font-size: 28rpx;
  line-height: 44rpx;
  color: rgba(0, 0, 0, 0.9);
}
.purchase-wrap .content .buy-num .tip {
  font-weight: 400;
  line-height: 40rpx;
  color: rgba(0, 0, 0, 0.6);
}
.purchase-wrap .content .buy-num .number-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 116px;
  height: 36px;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  box-sizing: border-box;
  overflow: hidden;
}
.purchase-wrap .content .buy-num .number-box .input {
  width: 60px;
  height: 36px;
  text-align: center;
  color: var(--font-black-1, rgba(0, 0, 0, 0.9));
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  box-sizing: border-box;
  border: none;
  outline: none;
}
.purchase-wrap .content .buy-num .number-box input[type='number']::-webkit-inner-spin-button,
.purchase-wrap .content .buy-num .number-box input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.purchase-wrap .content .buy-num .number-box .minus,
.purchase-wrap .content .buy-num .number-box .plus {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 36px;
  background: #f5f5f5;
  font-size: 10px;
}
.purchase-wrap .content .buy-num .number-box .minus:hover,
.purchase-wrap .content .buy-num .number-box .plus:hover {
  color: #f99d33;
}
.purchase-wrap .content .buy-num .number-box .minus {
  border-right: 1px solid #dcdcdc;
}
.purchase-wrap .content .buy-num .number-box .plus {
  border-left: 1px solid #dcdcdc;
}
.purchase-wrap .content .buy-num .number-box .minus .deal_icon {
  width: 10px;
  height: 10px;
}
.purchase-wrap .content .buy-num .number-box .plus .deal_icon {
  width: 10px;
  height: 10px;
}
.purchase-wrap .content .good-total {
  display: flex;
  align-items: center;
  line-height: 16px;
  color: #666;
  font-size: 12px;
}
.purchase-wrap .content .heig_min {
  min-height: 23px;
  padding-top: 5px;
  margin-bottom: 4px;
}
.purchase-wrap .content .store-tip {
  width: 100%;
  padding: 16rpx 32rpx;
  box-sizing: border-box;
  height: 72rpx;
}
.purchase-wrap .content .store-tip .tip-txt {
  font-weight: 400;
  font-size: 12px;
  color: #666;
  line-height: 16px;
}
.purchase-wrap .content .store-tip .tip-txt .need-money {
  color: #f53f3f;
}
.purchase-wrap .content .reached-tip .tip-txt {
  color: rgba(0, 0, 0, 0.6);
}
.purchase-wrap .content .confirm-btn {
  margin-left: 8px;
}
.purchase-wrap .content .confirm-btn .btn {
  color: #fafafa;
  width: 72px;
  height: 36px;
  background: #f99d33;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-weight: 500;
}
.purchase-wrap .content .confirm-btn .btn span {
  z-index: 2;
}
.purchase-wrap .content .confirm-btn .btn .fixed-price-bg {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
}
.purchase-wrap .content .confirm-btn .btn2 {
  background: #f7ba1e;
}
.purchase-wrap .content .confirm-btn .disabledBtn {
  background: #fdda9f;
}
.purchase-wrap .content .confirm-btn .disabledBtn::after {
  border: none;
}
.purchase-wrap .gift-content {
  min-height: auto;
}
.item_drag {
  width: 100%;
  background: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
}
.dis_start_end {
  display: flex;
  color: #666;
}
.limit {
  color: #ce7720;
  padding-left: 5px;
  margin-left: 5px;
  position: relative;
}
.limit:before {
  content: '';
  height: 12px;
  width: 1px;
  background: #666;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
.special-btn {
  margin-left: 8px;
  border: 0;
  width: 72px;
  height: 36px;
  cursor: pointer;
  background: url('~@/assets/productCards/time-special-btn.png') no-repeat;
  background-size: 72px 36px;
  border-radius: 4px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  color: #fff;
  line-height: 36px;
  padding-right: 6px;
  text-align: right;
}
.special-btn.disabled {
  background: url('~@/assets/productCards/buy-btn-disabled.png') no-repeat;
  background-size: 72px 36px;
}
.estimated-price {
  align-self: center;
  border-radius: 8px;
  background: #fbebe7;
  height: 22px;
  line-height: 24px;
  padding: 0 8px;
  color: #d4380d;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 4px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}
.postage {
  display: flex;
  align-items: center;
}
.postage .postage-text {
  color: #666;
  line-height: 16px;
  font-size: 12px;
  max-width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.postage .enter-shop {
  color: rgba(0, 0, 0, 0.4);
  white-space: nowrap;
  line-height: 16px;
  font-size: 12px;
  margin-left: auto;
  transition: 0.3s ease;
}
.postage .enter-shop:hover {
  color: #e67d00;
}
