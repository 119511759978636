.v-hot-zone {
  width: 1200px;
  position: relative;
}
.v-hot-zone .img {
  width: 100%;
}
.v-hot-zone .zone {
  position: absolute;
}
