.four-product-wrap ::v-deep .product-item {
  border-left: 0.5px solid #eee;
}
.four-product-wrap ::v-deep .goods_container {
  width: 234px;
  padding-top: 24px;
  border-radius: 0px;
  border-right: 1px solid #EEEEEE;
}
.four-product-wrap ::v-deep .goods_container:first-child {
  border-radius: 8px 0 0 8px;
}
.four-product-wrap ::v-deep .goods_container:last-child {
  border-right: 0;
  border-radius: 0 8px 8px 0;
}
.four-product-wrap ::v-deep .goods_container .show_name,
.four-product-wrap ::v-deep .goods_container .show_org {
  width: 196px;
}
.four-product-wrap ::v-deep .goods_container .item_img {
  width: 146px;
  height: 146px;
}
.four-product-wrap ::v-deep .tag_ite {
  margin-left: 0 !important;
}
.five-product-wrap ::v-deep .goods_container {
  width: 188px;
  height: 242px;
  padding-top: 24px;
  border-radius: 0px;
  border-right: 1px solid #EEEEEE;
}
.five-product-wrap ::v-deep .goods_container:last-child {
  border-right: 0;
  border-radius: 0 8px 8px 0;
}
.five-product-wrap ::v-deep .goods_container .item_img {
  width: 100px;
  height: 100px;
}
.five-product-wrap ::v-deep .goods_container .purchase-wrap .content {
  width: 100%;
}
.five-product-wrap ::v-deep .tag_ite {
  margin-left: 0 !important;
}
.five-product-wrap ::v-deep .term_of_validity {
  left: unset;
  right: 0;
}
.v-category-card .five-product-wrap ::v-deep .purchase-wrap .content {
  width: 100%;
}
.v-category-card .five-product-wrap ::v-deep .purchase-wrap .content .title {
  width: 100%;
  white-space: unset;
}
.v-category-card .five-product-wrap ::v-deep .purchase-wrap .content .buy-num {
  flex-wrap: wrap;
}
.eight-product-wrap ::v-deep > *:nth-child(4n) {
  border-right: 0 !important;
}
.eight-product-wrap ::v-deep > *:nth-child(n + 5) {
  border-bottom: 0 !important;
}
.eight-product-wrap ::v-deep > *:nth-child(5n),
.eight-product-wrap ::v-deep > *:nth-child(0n+1) {
  border-left: 1px solid #EEEEEE;
}
.eight-product-wrap ::v-deep .goods_container {
  width: 234px;
  height: 270px;
  padding-top: 24px;
  border-radius: 0 !important;
  border-right: 1px solid #EEEEEE;
  border-bottom: 1px solid #EEEEEE;
}
.eight-product-wrap ::v-deep .goods_container .item_img {
  width: 100px;
  height: 100px;
}
.eight-product-wrap ::v-deep .tag_ite {
  margin-left: 0 !important;
}
.six-product-wrap ::v-deep > *:nth-child(2) {
  margin-left: 600px;
}
.six-product-wrap ::v-deep > *:nth-child(n + 5) {
  border-bottom: 0 !important;
}
.six-product-wrap ::v-deep .goods_container {
  width: 300px;
  height: 324px;
  padding-top: 24px;
  border-radius: 0 !important;
  border-right: 1px solid #EEEEEE;
  border-bottom: 1px solid #EEEEEE;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.six-product-wrap ::v-deep .goods_container .item_img {
  width: 160px;
  height: 160px;
}
.six-product-wrap ::v-deep .purchase-wrap .content {
  width: 100%;
}
.six-product-wrap ::v-deep .right-content {
  width: 100% !important;
}
.six-product-wrap ::v-deep .right-content .title {
  width: 100% !important;
}
.six-product-wrap ::v-deep .tag_ite {
  margin-left: 0 !important;
}
.six-product-wrap ::v-deep .prd_control {
  left: 0;
  margin-left: 0;
}
.six-product ::v-deep .six-img {
  height: 316px !important;
}
.v-many-product ::v-deep .goods_container {
  width: 228px;
  height: 272px;
  padding-top: 24px;
  border-radius: 8px;
  border-right: 1px solid transparent;
}
.v-many-product ::v-deep .goods_container:nth-child( 5n ) {
  margin-right: 0;
}
.v-many-product ::v-deep .goods_container .item_img {
  width: 100px;
  height: 100px;
}
.v-many-product ::v-deep .tag_ite {
  margin-left: 0 !important;
}
.v-product-card ::v-deep .goods_container,
.v-market ::v-deep .goods_container {
  width: 205px;
  height: 272px;
  padding-top: 24px;
  border-radius: 0;
  border-right: 1px solid #EEEEEE;
}
.v-product-card ::v-deep .goods_container:nth-child( 5n ),
.v-market ::v-deep .goods_container:nth-child( 5n ) {
  margin-right: 0;
}
.v-product-card ::v-deep .goods_container .item_img,
.v-market ::v-deep .goods_container .item_img {
  width: 109px;
  height: 109px;
}
.v-product-card ::v-deep .goods_container .content,
.v-market ::v-deep .goods_container .content {
  width: unset;
}
.v-product-card ::v-deep .goods_container .confirm-btn .btn,
.v-market ::v-deep .goods_container .confirm-btn .btn {
  width: 72px;
  height: 24px;
  line-height: 24px;
  font-size: 8px;
}
.v-product-card ::v-deep .goods_container .input,
.v-market ::v-deep .goods_container .input {
  width: 40px !important;
  margin: 0 4px !important;
}
.v-product-card ::v-deep .goods_container .minus,
.v-market ::v-deep .goods_container .minus,
.v-product-card ::v-deep .goods_container .plus,
.v-market ::v-deep .goods_container .plus {
  width: 14px !important;
  height: 14px !important;
}
.v-product-card ::v-deep .goods_container .title,
.v-market ::v-deep .goods_container .title {
  width: 100% !important;
}
.v-product-card ::v-deep .tag_ite,
.v-market ::v-deep .tag_ite {
  margin-left: 0 !important;
}
.v-product-card {
  width: 1200px;
}
.card-list {
  display: flex;
  flex-wrap: wrap;
}
.card-list .head {
  display: flex;
  align-items: center;
  height: 58px;
  flex-shrink: 0;
  padding: 0 16px;
}
.card-list .head .title {
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  color: #181818;
}
.card-list .head .more {
  margin-left: auto;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 26px;
  color: #181818;
  cursor: pointer;
}
.card-list .card-wrap {
  display: flex;
  height: 100%;
}
.card-list .card {
  width: 594px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  background: #fff;
}
.card-list .card:nth-child(1) {
  margin: 0 12px 0 0;
}
.card-list .card:nth-child(2) {
  margin: 0 0 0 0;
}
.card-list .card:nth-child(3) {
  margin: 12px 12px 0 0;
}
.card-list .card:nth-child(3) {
  margin: 12px 0 0 0;
}
.card-list .card:nth-child(4) {
  margin: 12px 0 0 12px;
}
.card-list .card:nth-child(1) .head {
  background: linear-gradient(90.02deg, #F5EBDB 6.18%, #EDD2A9 68.41%), #FFFFFF;
}
.card-list .card:nth-child(1) .head .title,
.card-list .card:nth-child(1) .head .more {
  color: #4D1F00;
}
.card-list .card:nth-child(2) .head {
  background: linear-gradient(90.13deg, #D6E8C9 5.2%, rgba(168, 209, 127, 0.56) 75.6%);
}
.card-list .card:nth-child(2) .head .title,
.card-list .card:nth-child(2) .head .more {
  color: #334524;
}
.card-list .card:nth-child(3) .head {
  background: linear-gradient(90.13deg, #CBE8E1 5.2%, rgba(141, 207, 203, 0.56) 75.6%);
}
.card-list .card:nth-child(3) .head .title,
.card-list .card:nth-child(3) .head .more {
  color: #083676;
}
.card-list .card:nth-child(4) .head {
  background: linear-gradient(90.23deg, #DCE9F5 6.25%, #CDDEF7 75.88%);
}
.card-list .card:nth-child(4) .head .title,
.card-list .card:nth-child(4) .head .more {
  color: #334524;
}
:deep(.card-wrap) .list-card {
  width: 198px;
  height: auto;
  padding: 16px 12px;
  border-radius: 0;
}
:deep(.card-wrap) .list-card:nth-child(2) {
  border-left: 0.5px solid #eee;
  border-right: 0.5px solid #eee;
}
:deep(.card-wrap) .cover-box,
:deep(.card-wrap) .cover-mask {
  width: 120px!important;
  height: 120px!important;
}
:deep(.card-wrap) .price-area-box {
  margin-top: 0;
}
