.list-card {
  padding: 16px;
  background: #fff;
  cursor: pointer;
  border-radius: 8px;
  width: 230px;
  height: 392px;
  position: relative;
  overflow: hidden;
  border: 1px solid transparent;
}
.list-card:not(.is-cms):hover {
  border: 1px solid #f99d33;
}
.cover-box,
.cover-mask {
  width: 198px;
  height: 198px;
  overflow: hidden;
  margin: 0 auto;
}
.card-footer {
  display: flex;
  align-items: center;
  max-width: 198px;
  overflow: hidden;
  padding-top: 8px;
}
.card-footer .shop-name {
  flex: 1;
  overflow: hidden;
  color: var(--font_black-2, rgba(0, 0, 0, 0.6));
  text-overflow: ellipsis;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  white-space: nowrap;
  transition: 0.3s ease;
}
.card-footer .shop-name:hover {
  color: #E67D00;
}
.card-footer .link-info {
  color: #A0703C;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 133.333% */
}
.control {
  height: 18px;
  background: #FFECE8;
  text-align: center;
  color: #F53F3F;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-left: -16px;
  margin-right: -16px;
}
.tag-control {
  margin-top: 5px;
}
.title {
  height: 40px;
  line-height: 20px;
  overflow: hidden;
  font-size: 0;
  width: 100%;
}
.title span {
  vertical-align: middle;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.9);
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
}
.company {
  overflow: hidden;
  color: rgba(0, 0, 0, 0.6);
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin-top: 3px;
  white-space: nowrap;
}
.date {
  overflow: hidden;
  color: var(--font_black-2, rgba(0, 0, 0, 0.6));
  text-overflow: ellipsis;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-top: 3px;
  white-space: nowrap;
}
.date .warning {
  color: #E67D00;
}
.price-area-box {
  margin-top: 8px;
  height: 30px;
}
.add-card-box {
  position: absolute;
  left: 0;
  bottom: -1000px;
  transition: 0.3s ease;
  width: 100%;
  height: fit-content;
  z-index: 3;
}
.add-card-box.show {
  bottom: 0;
}
.show-activity-pop {
  position: absolute;
  z-index: 9;
  left: 0;
  background: #fff;
  width: 100%;
  padding: 16px;
  border-top: 1px solid #e7e7e7;
  bottom: -300px;
  transition: 0.2s ease-in-out;
}
.show-activity-pop.show {
  bottom: 0;
}
.product-item:hover .show-activity-pop {
  bottom: 0;
}
.cover-mask {
  position: absolute;
  left: 0;
  right: 0;
  top: 16px;
  margin: auto;
  background: rgba(255, 255, 255, 0.5);
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cover-mask .cover-mask-tip {
  border-radius: 120px;
  padding: 8px 12px;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
:deep(.cover-box ) {
  display: flex;
}
:deep(.cover-box ) .g-img {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  max-height: 100%;
}
:deep(.cover-box ) .g-img img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}
:deep(.cover-box ) .events {
  display: flex;
  justify-content: center;
}
.platform-tag {
  height: 18px;
  width: auto;
  margin-right: 4px;
  vertical-align: middle;
  margin-top: -3px;
}
