.table .table-header {
  height: 36px;
}
.table .table-header div:first-child {
  padding-left: 16px;
}
.table .table-body {
  margin-top: 12px;
  overflow: hidden;
}
.table .table-body .table-body__title {
  height: 36px;
  padding: 0 16px;
}
.table .table-body .table-body__title .orgName {
  cursor: pointer;
}
.table .table-body .table-body__title .orgName:hover {
  color: var(--brand-7, #ce7720);
}
.table .table-body .table-body__title .orderNo {
  margin-right: 36px;
}
.table .table-body .table-body__title .orderNo:hover {
  cursor: pointer;
  color: #3853E2;
}
.table .table-column1 {
  width: 350px;
}
.table .table-column1 img {
  width: 72px;
  height: 72px;
  object-fit: contain;
  border: 1px solid #e7e7e7;
  border-radius: 4px;
  margin-right: 16px;
}
.table .table-column1 .products-thumbs {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.table .table-column1 .products-thumbs .omit {
  cursor: pointer;
  width: 20px;
  height: 20px;
  padding: 4px;
  background: url('../../../../assets/images/order/omit.png') no-repeat center;
  background-size: cover;
  border-radius: 4px;
  transition: 0.3s ease;
}
.table .table-column1 .products-thumbs .omit:hover {
  background-color: #e7e7e7;
}
.table .table-column2 {
  width: 154px;
}
.table .table-column3 {
  width: 273px;
}
.table .table-column3 > div {
  margin-top: 4px;
}
.table .table-column3 > div:first-child {
  margin-top: 0;
}
.table .table-column4 {
  width: 155px;
}
.table .table-column4 button {
  margin-top: 4px;
  width: 64px;
  height: 24px;
}
.table .table-column4 > div {
  margin-top: 4px;
}
.table .table-column4 > div:first-child {
  margin-top: 0;
}
.w-text-button-info {
  font-size: 12px;
}
