
.v-page-couplet {
  width: 1662px;
  position: absolute;
  top: 56px;
  left: 50%;
  display: flex;
  justify-content: space-between;
  z-index: 2;
  padding: 0 16px;
  pointer-events: none;
  transform: translateX(-50%);
  // margin: 0 auto;
  .img {
    width: 204px;
    pointer-events: visible;
    border-radius: 8px;
    box-shadow: 0px 16px 20px -10px rgba(0, 0, 0, 0.04), 0px 32px 48px 4px rgba(0, 0, 0, 0.02), 0px 12px 60px 10px rgba(0, 0, 0, 0.03);
    // height: 100px;
  }
}

