.four-product-wrap ::v-deep .product-item {
  border-left: 0.5px solid #eee;
}
.four-product-wrap ::v-deep .goods_container {
  width: 234px;
  padding-top: 24px;
  border-radius: 0px;
  border-right: 1px solid #EEEEEE;
}
.four-product-wrap ::v-deep .goods_container:first-child {
  border-radius: 8px 0 0 8px;
}
.four-product-wrap ::v-deep .goods_container:last-child {
  border-right: 0;
  border-radius: 0 8px 8px 0;
}
.four-product-wrap ::v-deep .goods_container .show_name,
.four-product-wrap ::v-deep .goods_container .show_org {
  width: 196px;
}
.four-product-wrap ::v-deep .goods_container .item_img {
  width: 146px;
  height: 146px;
}
.four-product-wrap ::v-deep .tag_ite {
  margin-left: 0 !important;
}
.five-product-wrap ::v-deep .goods_container {
  width: 188px;
  height: 242px;
  padding-top: 24px;
  border-radius: 0px;
  border-right: 1px solid #EEEEEE;
}
.five-product-wrap ::v-deep .goods_container:last-child {
  border-right: 0;
  border-radius: 0 8px 8px 0;
}
.five-product-wrap ::v-deep .goods_container .item_img {
  width: 100px;
  height: 100px;
}
.five-product-wrap ::v-deep .goods_container .purchase-wrap .content {
  width: 100%;
}
.five-product-wrap ::v-deep .tag_ite {
  margin-left: 0 !important;
}
.five-product-wrap ::v-deep .term_of_validity {
  left: unset;
  right: 0;
}
.v-category-card .five-product-wrap ::v-deep .purchase-wrap .content {
  width: 100%;
}
.v-category-card .five-product-wrap ::v-deep .purchase-wrap .content .title {
  width: 100%;
  white-space: unset;
}
.v-category-card .five-product-wrap ::v-deep .purchase-wrap .content .buy-num {
  flex-wrap: wrap;
}
.eight-product-wrap ::v-deep > *:nth-child(4n) {
  border-right: 0 !important;
}
.eight-product-wrap ::v-deep > *:nth-child(n + 5) {
  border-bottom: 0 !important;
}
.eight-product-wrap ::v-deep > *:nth-child(5n),
.eight-product-wrap ::v-deep > *:nth-child(0n+1) {
  border-left: 1px solid #EEEEEE;
}
.eight-product-wrap ::v-deep .goods_container {
  width: 234px;
  height: 270px;
  padding-top: 24px;
  border-radius: 0 !important;
  border-right: 1px solid #EEEEEE;
  border-bottom: 1px solid #EEEEEE;
}
.eight-product-wrap ::v-deep .goods_container .item_img {
  width: 100px;
  height: 100px;
}
.eight-product-wrap ::v-deep .tag_ite {
  margin-left: 0 !important;
}
.six-product-wrap ::v-deep > *:nth-child(2) {
  margin-left: 600px;
}
.six-product-wrap ::v-deep > *:nth-child(n + 5) {
  border-bottom: 0 !important;
}
.six-product-wrap ::v-deep .goods_container {
  width: 300px;
  height: 324px;
  padding-top: 24px;
  border-radius: 0 !important;
  border-right: 1px solid #EEEEEE;
  border-bottom: 1px solid #EEEEEE;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.six-product-wrap ::v-deep .goods_container .item_img {
  width: 160px;
  height: 160px;
}
.six-product-wrap ::v-deep .purchase-wrap .content {
  width: 100%;
}
.six-product-wrap ::v-deep .right-content {
  width: 100% !important;
}
.six-product-wrap ::v-deep .right-content .title {
  width: 100% !important;
}
.six-product-wrap ::v-deep .tag_ite {
  margin-left: 0 !important;
}
.six-product-wrap ::v-deep .prd_control {
  left: 0;
  margin-left: 0;
}
.six-product ::v-deep .six-img {
  height: 316px !important;
}
.v-many-product ::v-deep .goods_container {
  width: 228px;
  height: 272px;
  padding-top: 24px;
  border-radius: 8px;
  border-right: 1px solid transparent;
}
.v-many-product ::v-deep .goods_container:nth-child( 5n ) {
  margin-right: 0;
}
.v-many-product ::v-deep .goods_container .item_img {
  width: 100px;
  height: 100px;
}
.v-many-product ::v-deep .tag_ite {
  margin-left: 0 !important;
}
.v-product-card ::v-deep .goods_container,
.v-market ::v-deep .goods_container {
  width: 205px;
  height: 272px;
  padding-top: 24px;
  border-radius: 0;
  border-right: 1px solid #EEEEEE;
}
.v-product-card ::v-deep .goods_container:nth-child( 5n ),
.v-market ::v-deep .goods_container:nth-child( 5n ) {
  margin-right: 0;
}
.v-product-card ::v-deep .goods_container .item_img,
.v-market ::v-deep .goods_container .item_img {
  width: 109px;
  height: 109px;
}
.v-product-card ::v-deep .goods_container .content,
.v-market ::v-deep .goods_container .content {
  width: unset;
}
.v-product-card ::v-deep .goods_container .confirm-btn .btn,
.v-market ::v-deep .goods_container .confirm-btn .btn {
  width: 72px;
  height: 24px;
  line-height: 24px;
  font-size: 8px;
}
.v-product-card ::v-deep .goods_container .input,
.v-market ::v-deep .goods_container .input {
  width: 40px !important;
  margin: 0 4px !important;
}
.v-product-card ::v-deep .goods_container .minus,
.v-market ::v-deep .goods_container .minus,
.v-product-card ::v-deep .goods_container .plus,
.v-market ::v-deep .goods_container .plus {
  width: 14px !important;
  height: 14px !important;
}
.v-product-card ::v-deep .goods_container .title,
.v-market ::v-deep .goods_container .title {
  width: 100% !important;
}
.v-product-card ::v-deep .tag_ite,
.v-market ::v-deep .tag_ite {
  margin-left: 0 !important;
}
.v-category-card {
  width: 1200px;
}
.four-product-wrap {
  height: 324px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
}
.four-product-wrap /deep/ .prd_control {
  height: 56px;
}
.four-product-wrap .four-product-cover {
  width: 288px;
}
.four-product-wrap .four-product-cover .four-product {
  width: 100%;
  height: 100%;
}
.four-product-wrap .four-product-right {
  flex: 1;
}
.five-product-wrap {
  height: 320px;
  display: flex;
}
.five-product-wrap /deep/ .prd_control {
  height: 56px;
}
.five-product-wrap .five-product-cover {
  width: 210px;
}
.five-product-wrap .five-product-cover .five-product {
  width: 100%;
  height: 100%;
}
.five-product-wrap .five-product-right {
  border-radius: 8px;
  flex: 1;
}
.five-product-wrap .five-product-right .five-item {
  overflow: hidden;
  box-sizing: border-box;
  width: 200px;
}
.swiper-img {
  width: 24px;
  height: 32px;
  position: absolute;
  top: 45%;
  z-index: 999;
}
.four-img-right {
  left: 288px;
}
.four-img-left {
  right: 0;
}
.five-img-right {
  left: 210px;
}
.five-img-left {
  right: 0;
}
.eight-product-wrap {
  display: flex;
}
.eight-product-wrap .eight-img-wrap {
  display: flex;
  flex-direction: column;
}
.eight-product-wrap .eight-img-wrap .eight-img {
  width: 245px;
  height: 80px;
}
.eight-product-wrap .eight-prudoct-wrap {
  display: flex;
  flex-wrap: wrap;
}
:deep(.five-item) .list-card {
  width: 198px;
  height: 292px;
  padding: 12px;
  border-radius: 0;
}
:deep(.five-item) .cover-box,
:deep(.five-item) .cover-mask {
  width: 110px!important;
  height: 110px!important;
}
:deep(.five-item) .cover-mask {
  top: 12px;
}
:deep(.five-item) .price-area-box {
  margin-top: 0;
}
:deep(.four-product-wrap) .list-card {
  height: 324px;
  border-radius: 0;
  border-left: 0.5px solid #e7e7e7;
}
:deep(.four-product-wrap) .cover-box,
:deep(.four-product-wrap) .cover-mask {
  width: 148px;
  height: 148px;
}
