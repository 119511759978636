html {
  height: 100%;
}
body {
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  min-width: 1200px;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
.header {
  height: 60px;
}
.content {
  flex: 1;
}
.footer {
  height: 120px;
}
#nav {
  padding: 30px;
}
#nav a {
  font-weight: bold;
  color: #2c3e50;
}
#nav a.router-link-exact-active {
  color: #42b983;
}
.main-content {
  width: 100%;
  background-color: #F5F5F5;
  position: relative;
}
.loginPage {
  margin-top: -20px;
}
.loginPage .ivu-form-item {
  margin-bottom: 32px;
}
