
.root {
    width: 100%;
    margin: 0 auto 12px auto;
    .title {
        font-size: 24px;
        line-height: 32px;
        color: #000000;
        font-weight: 500;
    }
    .content-arrow-icon {
        margin-left: 8px;
        width: 23px;
        height: 19px;
    }
}
