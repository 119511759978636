.goods-change ::v-deep .vertical-center-modal .ivu-modal {
  width: 614px !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  max-height: calc(100% - 100px) !important;
}
.goods-change ::v-deep .vertical-center-modal .ivu-modal .ivu-modal-content .ivu-modal-header {
  border-bottom: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.9);
}
.goods-change ::v-deep .vertical-center-modal .ivu-modal .ivu-modal-content .ivu-modal-body {
  padding: 0px 0px 20px 24px;
  box-sizing: border-box;
}
.goods-change ::v-deep .vertical-center-modal .ivu-modal .ivu-modal-content .ivu-modal-body .modal-content {
  max-height: calc(100vh - 230px);
  padding: 0 20px 20px 0;
  overflow-y: auto;
}
.goods-change ::v-deep .vertical-center-modal .ivu-modal .ivu-modal-content .ivu-modal-body .price-change .text {
  font-size: 14px;
  line-height: 24px;
  color: #000000;
}
.goods-change ::v-deep .vertical-center-modal .ivu-modal .ivu-modal-content .ivu-modal-body .price-change-box {
  display: flex;
  flex-wrap: wrap;
  padding: 12px 0px;
}
.goods-change ::v-deep .vertical-center-modal .ivu-modal .ivu-modal-content .ivu-modal-body .price-change-box .item {
  width: 129px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 12px;
  margin-bottom: 12px;
}
.goods-change ::v-deep .vertical-center-modal .ivu-modal .ivu-modal-content .ivu-modal-body .price-change-box .item:nth-child(4n + 0) {
  margin-right: 0;
}
.goods-change ::v-deep .vertical-center-modal .ivu-modal .ivu-modal-content .ivu-modal-body .price-change-box .item .img-box {
  flex-shrink: 0;
  width: 129px;
  height: 129px;
  border-radius: 4px;
  border: 1px solid var(--gray-2, #EEE);
  background: var(--font-white-0, #FFF);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.goods-change ::v-deep .vertical-center-modal .ivu-modal .ivu-modal-content .ivu-modal-body .price-change-box .item .img-box img {
  width: 86px;
  height: 86px;
}
.goods-change ::v-deep .vertical-center-modal .ivu-modal .ivu-modal-content .ivu-modal-body .price-change-box .item .img-box .calculate {
  position: absolute;
  top: 4px;
  left: 4px;
  width: 60px;
  height: auto;
}
.goods-change ::v-deep .vertical-center-modal .ivu-modal .ivu-modal-content .ivu-modal-body .price-change-box .item .img-box .tag {
  position: absolute;
  top: 4px;
  left: 4px;
  padding: 2px 4px;
  box-sizing: border-box;
  color: #F53F3F;
  border-radius: 4px;
  font-weight: 500;
  font-size: 8px;
}
.goods-change ::v-deep .vertical-center-modal .ivu-modal .ivu-modal-content .ivu-modal-body .price-change-box .item .img-box .full-fold {
  background-color: #FEF5F5;
  border: 1px solid #F53F3F;
}
.goods-change ::v-deep .vertical-center-modal .ivu-modal .ivu-modal-content .ivu-modal-body .price-change-box .item .img-box .special {
  color: #f99d33;
  background: rgba(114, 46, 209, 0.05);
  border: 1px solid #f99d33;
}
.goods-change ::v-deep .vertical-center-modal .ivu-modal .ivu-modal-content .ivu-modal-body .price-change-box .item .img-box .replacement {
  color: #722ED1;
  background: #F8F5FD;
  border: 0.5px solid #722ED1;
}
.goods-change ::v-deep .vertical-center-modal .ivu-modal .ivu-modal-content .ivu-modal-body .price-change-box .item .img-box .lose-cause {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  font-size: 14px;
  border-radius: 50%;
}
.goods-change ::v-deep .vertical-center-modal .ivu-modal .ivu-modal-content .ivu-modal-body .price-change-box .item .img-box .lose-cause span {
  width: 45px;
  text-align: center;
}
.goods-change ::v-deep .vertical-center-modal .ivu-modal .ivu-modal-content .ivu-modal-body .price-change-box .item .img-box .number {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #CB272D;
}
.goods-change ::v-deep .vertical-center-modal .ivu-modal .ivu-modal-content .ivu-modal-body .price-change-box .item .img-box .buy-num {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 2px;
  right: 2px;
  width: 20px;
  height: 18px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 4px;
}
.goods-change ::v-deep .vertical-center-modal .ivu-modal .ivu-modal-content .ivu-modal-body .price-change-box .item .img-box .buy-num span {
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
  color: #FFFFFF;
}
.goods-change ::v-deep .vertical-center-modal .ivu-modal .ivu-modal-content .ivu-modal-body .price-change-box .item .img-box .buy-tip {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  width: 60px;
  height: 60px;
  text-align: center;
  padding: 8px;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 50%;
}
.goods-change ::v-deep .vertical-center-modal .ivu-modal .ivu-modal-content .ivu-modal-body .price-change-box .item .img-box .buy-tip span {
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #FFFFFF;
}
.goods-change ::v-deep .vertical-center-modal .ivu-modal .ivu-modal-content .ivu-modal-body .price-change-box .item .name {
  width: 100%;
  text-align: center;
  font-size: 12px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.9);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 4px;
}
.goods-change ::v-deep .vertical-center-modal .ivu-modal .ivu-modal-content .ivu-modal-body .price-change-box .item .price {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #cb272d;
  margin-top: 4px;
}
.goods-change ::v-deep .vertical-center-modal .ivu-modal .ivu-modal-content .ivu-modal-body .price-change-box .item .quantum {
  width: 100%;
  font-size: 12px;
  line-height: 20px;
  color: #cb272d;
}
.goods-change ::v-deep .vertical-center-modal .ivu-modal .ivu-modal-content .ivu-modal-body .price-change-box.combine-change-box {
  background-color: #f5f5f5;
  padding: 8px;
  border-radius: 4px;
  margin-top: 12px;
}
.goods-change ::v-deep .vertical-center-modal .ivu-modal .ivu-modal-content .ivu-modal-body .price-change-box.combine-change-box .item {
  width: 128px;
  margin-right: 8px;
}
.goods-change ::v-deep .vertical-center-modal .ivu-modal .ivu-modal-content .ivu-modal-body .price-change-box.combine-change-box .item:nth-child(4n + 0) {
  margin-right: 0;
}
.goods-change ::v-deep .vertical-center-modal .ivu-modal .ivu-modal-content .ivu-modal-body .batch-box {
  display: block;
  padding: 12px 0px;
}
.goods-change ::v-deep .vertical-center-modal .ivu-modal .ivu-modal-content .ivu-modal-body .batch-box .good-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.goods-change ::v-deep .vertical-center-modal .ivu-modal .ivu-modal-content .ivu-modal-body .modal-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  margin-right: 24px;
  cursor: pointer;
}
.goods-change ::v-deep .vertical-center-modal .ivu-modal .ivu-modal-content .ivu-modal-body .modal-footer span {
  padding: 5px 16px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 22px;
  margin-left: 8px;
}
.goods-change ::v-deep .vertical-center-modal .ivu-modal .ivu-modal-content .ivu-modal-body .modal-footer .back-car {
  color: rgba(0, 0, 0, 0.9);
  border: 1px solid #dcdcdc;
}
.goods-change ::v-deep .vertical-center-modal .ivu-modal .ivu-modal-content .ivu-modal-body .modal-footer .back-car.active {
  background-color: #f99d33;
  color: rgba(255, 255, 255, 0.9);
  border: 1px solid #f99d33;
}
.goods-change ::v-deep .vertical-center-modal .ivu-modal .ivu-modal-content .ivu-modal-body .modal-footer .continue {
  background-color: #f99d33;
  color: rgba(255, 255, 255, 0.9);
}
.change-tip {
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #f53f3f;
}
