
.purchase-wrap {
  .content {
    padding: 16px;
    min-height: 130px;
    margin-top: 0;
    display: block;
    box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.05);

    .container {
      width: 100%;
      box-sizing: border-box;
    }

    .good-section {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;

      .good-img {
        width: 160rpx;
        height: 160rpx;
        border-radius: 8rpx;
      }

      .right-content {
        width: 100%;

        .title {
          //width: 200px;
          overflow: hidden;
          // white-space: nowrap;
          // text-overflow: ellipsis;
          // -o-text-overflow:ellipsis;
          max-height: 40px;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: rgba(0, 0, 0, 0.9);
          word-break: break-all;
        }

        .price {
          height: 28px;
          overflow: hidden;
          width: 100%;

          .real-price {
            display: inline-block;
            font-weight: 500;
            font-size: 20px;
            color: #cb272d;
            line-height: 28px;

            .no-price {
              font-size: 16px;
            }
          }

          .origin-price {
            margin-left: 4px;
            font-weight: 400;
            font-size: 14px;
            text-decoration-line: line-through;
            display: inline-block;
            color: rgba(0, 0, 0, 0.6);

            .tip {
              color: rgba(0, 0, 0, 0.6);
            }
          }
        }

        .stock {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #666;
          margin-right: 10px;
        }

        .tip {
          font-weight: 400;
          font-size: 12px;
          // display: flex;
          line-height: 16px;
        }
      }
    }

    .expireDate {
      margin-top: 62rpx;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .label {
        font-weight: 500;
        font-size: 28rpx;
        line-height: 44rpx;
        color: rgba(0, 0, 0, 0.9);
      }

      .txt {
        margin-left: auto;
        font-weight: 500;
        font-size: 28rpx;
        line-height: 44rpx;
        color: rgba(0, 0, 0, 0.9);
      }
    }

    .buy-num {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 2px;

      .label {
        font-weight: 500;
        font-size: 28rpx;
        line-height: 44rpx;
        color: rgba(0, 0, 0, 0.9);
      }

      .tip {
        font-weight: 400;
        line-height: 40rpx;
        color: rgba(0, 0, 0, 0.6);
      }

      .number-box {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 116px;
        height: 36px;
        border: 1px solid #dcdcdc;
        border-radius: 4px;
        box-sizing: border-box;
        overflow: hidden;

        .input {
          width: 60px;
          height: 36px;
          text-align: center;
          color: var(--font-black-1, rgba(0, 0, 0, 0.9));
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 36px;
          box-sizing: border-box;
          border: none;
          outline: none;
        }

        // nput[type=number] {
        //     -moz-appearance: textfield;
        // }
        input[type='number']::-webkit-inner-spin-button,
        input[type='number']::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        .minus,
        .plus {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 28px;
          height: 36px;
          background: #f5f5f5;
          font-size: 10px;

          &:hover {
            color: #f99d33;
          }
        }

        .minus {
          border-right: 1px solid #dcdcdc;
        }

        .plus {
          border-left: 1px solid #dcdcdc;
        }

        .minus .deal_icon {
          width: 10px;
          height: 10px;
        }

        .plus .deal_icon {
          width: 10px;
          height: 10px;
        }
      }
    }

    .good-total {
      display: flex;
      align-items: center;
      line-height: 16px;
      color: #666;
      font-size: 12px;
    }

    .heig_min {
      min-height: 23px;
      padding-top: 5px;
      margin-bottom: 4px;
    }

    .store-tip {
      width: 100%;
      padding: 16rpx 32rpx;
      box-sizing: border-box;
      height: 72rpx;

      .tip-txt {
        font-weight: 400;
        font-size: 12px;
        color: #666;
        line-height: 16px;

        .need-money {
          color: #f53f3f;
        }
      }
    }

    .reached-tip {

      // margin-top: 5px;
      .tip-txt {
        color: rgba(0, 0, 0, 0.6);
      }
    }

    .confirm-btn {
      margin-left: 8px;

      .btn {
        color: #fafafa;
        width: 72px;
        height: 36px;
        background: #f99d33;
        border-radius: 4px;
        cursor: pointer;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        font-weight: 500;

        span {
          z-index: 2;
        }

        .fixed-price-bg {
          position: absolute;
          z-index: 1;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: 0;
        }
      }

      .btn2 {
        background: #f7ba1e;
      }

      .disabledBtn {
        background: #fdda9f;

        &::after {
          border: none;
        }
      }
    }
  }

  .gift-content {
    min-height: auto;
  }
}

.item_drag {
  width: 100%;
  // height: 177px;
  background: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  // box-shadow: 6px 6px 10px 10px #f7eeee;
}

.dis_start_end {
  display: flex;
  color: #666;
}

.limit {
  color: #ce7720;
  padding-left: 5px;
  margin-left: 5px;
  position: relative;

  &:before {
    content: '';
    height: 12px;
    width: 1px;
    background: #666;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}

.special-btn {
  margin-left: 8px;
  border: 0;
  width: 72px;
  height: 36px;
  cursor: pointer;
  background: url('~@/assets/productCards/time-special-btn.png') no-repeat;
  background-size: 72px 36px;
  border-radius: 4px;

  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  color: #fff;
  line-height: 36px;
  padding-right: 6px;
  text-align: right;

  &.disabled {
    background: url('~@/assets/productCards/buy-btn-disabled.png') no-repeat;
    background-size: 72px 36px;
  }
}

.estimated-price {
  align-self: center;
  border-radius: 8px;
  background: #fbebe7;
  height: 22px;
  line-height: 24px;
  padding: 0 8px;
  color: #d4380d;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 4px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}

.postage{
  display: flex;
  align-items: center;

  .postage-text{
    color:#666;
    line-height: 16px;
    font-size: 12px;
    max-width: 160px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .enter-shop{
    color:rgba(0,0,0,0.4);
    white-space: nowrap;
    line-height: 16px;
    font-size: 12px;
    margin-left: auto;
    transition: .3s ease;
    &:hover{
      color:#e67d00;
    }
  }
}
