
.detail {
  .item {
    display: flex;
    margin-bottom: 16px;

    &.center {
      align-items: center;
    }
    .label {
      width: 100px;
      text-align: right;
      color: rgba(0, 0, 0, 0.9);
    }
    .value {
      flex: 1;
      padding-left: 8px;
      color: rgba(0, 0, 0, 0.9);
    }
    .goods {
      display: flex;
      .goods-img {
        width: 80px;
        height: 80px;
        border: 1px solid #f1f1f1;
        border-radius: 4px;
      }
      .goods-info {
        padding-left: 10px;
        p {
          margin: 0;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.9);
          &.name {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          &.sub-name {
            font-size: 12px;
            color: rgba(0, 0, 0, 0.6);
            padding-top: 4px;
          }
        }
      }
    }
  }
  .car-text {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.9);
    padding: 12px 0;
    span {
      padding: 0 6px;
    }
    .rest {
      color: #cb272d;
    }
  }
  :deep(.ivu-input-number-input) {
    text-align: center !important;
  }
}
