
@import url('../../product.less');
.v-product-card {
  width: 1200px;
}
.card-list {
  display: flex;
  flex-wrap: wrap;
  .head {
    display: flex;
    align-items: center;
    height: 58px;
    flex-shrink: 0;
    padding: 0 16px;
    .title {
      font-weight: 600;
      font-size: 14px;
      line-height: 26px;
      color: #181818;
    }
    .more {
      margin-left: auto;
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 26px;
      color: #181818;
      cursor: pointer;
    }
  }
  .card-wrap {
    display: flex;
    height: 100%;
  }
  .card {
    width: 594px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    background: #fff;
    &:nth-child(1) {
      margin: 0 12px 0 0;
    }
    &:nth-child(2) {
      margin: 0 0 0 0;
    }
    &:nth-child(3) {
      margin: 12px 12px 0 0;
    }
     &:nth-child(3) {
      margin: 12px 0 0 0;
    }
    &:nth-child(4) {
      margin: 12px 0 0 12px;
    }
    &:nth-child(1) .head {
      background: linear-gradient(90.02deg, #F5EBDB 6.18%, #EDD2A9 68.41%), #FFFFFF;
      .title,.more{
        color:#4D1F00;
      }
    }
    &:nth-child(2) .head {
      background: linear-gradient(90.13deg, #D6E8C9 5.2%, rgba(168, 209, 127, 0.56) 75.6%);
      .title,.more{
        color:#334524;
      }
    }
    &:nth-child(3) .head {
      background: linear-gradient(90.13deg, #CBE8E1 5.2%, rgba(141, 207, 203, 0.56) 75.6%);
      .title,.more{
        color:#083676;
      }
    }
    &:nth-child(4) .head {
      background: linear-gradient(90.23deg, #DCE9F5 6.25%, #CDDEF7 75.88%);
      .title,.more{
        color:#334524;
      }
    }
  }
}

// 覆盖商品卡片样式
:deep(.card-wrap) {
  .list-card{
    width: 198px;
    height: auto;
    padding: 16px 12px;
    border-radius: 0;
    &:nth-child(2){
      border-left: 0.5px solid #eee;
      border-right: 0.5px solid #eee;
    }
  }

 .cover-box,.cover-mask{
   width: 120px!important;
   height: 120px!important;
 }
  .price-area-box{
    margin-top: 0;
  }
}
