::v-deep .ivu-modal-wrap .ivu-modal {
  top: 0;
}
::v-deep .ivu-modal-wrap .ivu-modal-content {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-shadow: none;
}
::v-deep .ivu-modal-wrap .ivu-modal-close {
  display: none;
}
::v-deep .ivu-modal-wrap .ivu-modal-header {
  width: 931px;
  height: 60px;
  border-bottom: none;
  padding: 0;
  background-color: rgba(55, 55, 55, 0.6);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-image: url('~@/assets/images/car/combination_bg.png');
  background-size: 100% 100%;
  background-position: top left;
}
::v-deep .ivu-modal-wrap .ivu-modal-header .desc {
  width: 931px;
  height: 60px;
  display: flex;
}
::v-deep .ivu-modal-wrap .ivu-modal-header .desc .title {
  display: flex;
  margin-top: 20px;
  margin-left: 140px;
  color: var(--font-black-1, rgba(0, 0, 0, 0.9));
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
}
::v-deep .ivu-modal-wrap .ivu-modal-header .desc .label {
  text-align: right;
  margin-top: 30px;
  padding-right: 32px;
  color: var(--warning-9, #774B04);
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}
::v-deep .ivu-modal-wrap .ivu-modal-body {
  padding: 24px 32px 32px;
}
::v-deep .vertical-center-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}
.items {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  max-height: 400px;
  overflow-y: scroll;
}
.items::-webkit-scrollbar {
  display: none;
}
.items .item {
  width: 204px;
  background-color: #f5f5f5;
  padding: 18px 14px 14px;
  margin-bottom: 24px;
  margin-right: 16px;
  border-radius: 7.2px;
  position: relative;
}
.items .item:nth-child(4n) {
  margin-right: 0px;
}
.items .item .must {
  position: absolute;
  top: 0;
  left: 0;
  padding: 3.6px 14.4px;
  background-color: #cc9213;
  font-size: 12.6px;
  line-height: 20px;
  color: #fff;
  border-radius: 7.2px 0px;
}
.items .item .img {
  padding: 0px 25px;
  box-sizing: border-box;
}
.items .item .img img {
  width: 126px;
  height: 126px;
}
.items .item .price {
  margin-top: 9px;
}
.items .item .price .new-price {
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #cb272d;
  margin-right: 8px;
}
.items .item .price .old-price {
  font-size: 10.8px;
  line-height: 18px;
  text-decoration-line: line-through;
  color: rgba(0, 0, 0, 0.6);
}
.items .item .name {
  font-size: 14.4px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.9);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.items .item .stock {
  height: 18px;
  font-size: 10.8px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.6);
}
.items .item .check-num {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 10.8px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.9);
}
.items .item .check-num .check {
  display: flex;
  align-items: center;
}
.items .item .check-num .check ::v-deep .ivu-checkbox-wrapper .ivu-checkbox {
  margin-right: 0px;
}
.items .item .check-num .check ::v-deep .ivu-checkbox-wrapper .ivu-checkbox-disabled.ivu-checkbox-checked .ivu-checkbox-inner {
  background-color: #A6A6A6;
  border-color: transparent;
}
.items .item .reason {
  margin-top: 4px;
  font-size: 12px;
  line-height: 12px;
  color: #F53F3F;
}
.compose-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.compose-footer .left .text {
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.9);
  margin-right: 8px;
}
.compose-footer .left .new-price {
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #cb272d;
  margin-right: 8px;
}
.compose-footer .left .old-price {
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.4);
  text-decoration: line-through;
}
.compose-footer .right ::v-deep .ivu-input-number {
  margin: 0 8px;
}
.compose-footer .right ::v-deep .ivu-input-number .ivu-input-number-input-wrap > .ivu-input-number-input {
  text-align: center;
}
.compose-footer .right .add-car {
  display: inline-block;
  width: 132px;
  height: 42px;
  text-align: center;
  line-height: 42px;
  background: #f99d33;
  border-radius: 6px;
  font-weight: 500;
  font-size: 15px;
  color: #fff;
  cursor: pointer;
  margin-left: 48px;
}
.compose-footer .right .disable-add {
  background: #E7E7E7;
}
.mask {
  position: absolute;
  z-index: 9;
  background: rgba(255, 255, 255, 0.4);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.mask .tip {
  border-radius: 50%;
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 87px;
  height: 87px;
  margin: 39px auto;
  background: rgba(0, 0, 0, 0.4);
}
