
.swiper {
width: 100%; height: 289px;border-radius: 8px;cursor: pointer;
}
.home-center {
  width: 652px;
  height: 100%;
  margin: 0 14px;
}
.banner-warp {
  // margin-top: 16px;
}
::v-deep .root {
  background: transparent;
}
.banner {
  position: relative;
  width: 652px;
  height: 288px;
}
.sub-list {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}
.sub-item {
  width: 212px;
  height: 110px;
  border-radius: 8px;
  cursor: pointer;
}
::v-deep .root {
  height: unset;
}
::v-deep .content {
  padding-top: unset !important;
}

.banner-button-prev, .banner-button-next {
  position: absolute;
  top: 50%;
  width: 32px;
  height: 32px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  background-size: 27px 44px;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.8;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background: #383838;
    border-radius: 50%;
  }
}
.banner-button-prev {
  left: 10px;
  right: auto;
  padding-right: 2px;
}
.banner-button-next {
  right: 10px;
  left: auto;
  padding-left: 2px;
}
::v-deep .swiper-pagination-bullet{
  background: rgba(255, 255, 255, 0.7);
  opacity: unset;
  // width: 6px;
  // height: 6px;
  &-active {
    border: 1px solid #FDDA9F;
  }
}
::v-deep .root {
  margin-bottom: 0 !important;
}
