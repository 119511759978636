.four-product-wrap ::v-deep .product-item {
  border-left: 0.5px solid #eee;
}
.four-product-wrap ::v-deep .goods_container {
  width: 234px;
  padding-top: 24px;
  border-radius: 0px;
  border-right: 1px solid #EEEEEE;
}
.four-product-wrap ::v-deep .goods_container:first-child {
  border-radius: 8px 0 0 8px;
}
.four-product-wrap ::v-deep .goods_container:last-child {
  border-right: 0;
  border-radius: 0 8px 8px 0;
}
.four-product-wrap ::v-deep .goods_container .show_name,
.four-product-wrap ::v-deep .goods_container .show_org {
  width: 196px;
}
.four-product-wrap ::v-deep .goods_container .item_img {
  width: 146px;
  height: 146px;
}
.four-product-wrap ::v-deep .tag_ite {
  margin-left: 0 !important;
}
.five-product-wrap ::v-deep .goods_container {
  width: 188px;
  height: 242px;
  padding-top: 24px;
  border-radius: 0px;
  border-right: 1px solid #EEEEEE;
}
.five-product-wrap ::v-deep .goods_container:last-child {
  border-right: 0;
  border-radius: 0 8px 8px 0;
}
.five-product-wrap ::v-deep .goods_container .item_img {
  width: 100px;
  height: 100px;
}
.five-product-wrap ::v-deep .goods_container .purchase-wrap .content {
  width: 100%;
}
.five-product-wrap ::v-deep .tag_ite {
  margin-left: 0 !important;
}
.five-product-wrap ::v-deep .term_of_validity {
  left: unset;
  right: 0;
}
.v-category-card .five-product-wrap ::v-deep .purchase-wrap .content {
  width: 100%;
}
.v-category-card .five-product-wrap ::v-deep .purchase-wrap .content .title {
  width: 100%;
  white-space: unset;
}
.v-category-card .five-product-wrap ::v-deep .purchase-wrap .content .buy-num {
  flex-wrap: wrap;
}
.eight-product-wrap ::v-deep > *:nth-child(4n) {
  border-right: 0 !important;
}
.eight-product-wrap ::v-deep > *:nth-child(n + 5) {
  border-bottom: 0 !important;
}
.eight-product-wrap ::v-deep > *:nth-child(5n),
.eight-product-wrap ::v-deep > *:nth-child(0n+1) {
  border-left: 1px solid #EEEEEE;
}
.eight-product-wrap ::v-deep .goods_container {
  width: 234px;
  height: 270px;
  padding-top: 24px;
  border-radius: 0 !important;
  border-right: 1px solid #EEEEEE;
  border-bottom: 1px solid #EEEEEE;
}
.eight-product-wrap ::v-deep .goods_container .item_img {
  width: 100px;
  height: 100px;
}
.eight-product-wrap ::v-deep .tag_ite {
  margin-left: 0 !important;
}
.six-product-wrap ::v-deep > *:nth-child(2) {
  margin-left: 600px;
}
.six-product-wrap ::v-deep > *:nth-child(n + 5) {
  border-bottom: 0 !important;
}
.six-product-wrap ::v-deep .goods_container {
  width: 300px;
  height: 324px;
  padding-top: 24px;
  border-radius: 0 !important;
  border-right: 1px solid #EEEEEE;
  border-bottom: 1px solid #EEEEEE;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.six-product-wrap ::v-deep .goods_container .item_img {
  width: 160px;
  height: 160px;
}
.six-product-wrap ::v-deep .purchase-wrap .content {
  width: 100%;
}
.six-product-wrap ::v-deep .right-content {
  width: 100% !important;
}
.six-product-wrap ::v-deep .right-content .title {
  width: 100% !important;
}
.six-product-wrap ::v-deep .tag_ite {
  margin-left: 0 !important;
}
.six-product-wrap ::v-deep .prd_control {
  left: 0;
  margin-left: 0;
}
.six-product ::v-deep .six-img {
  height: 316px !important;
}
.v-many-product ::v-deep .goods_container {
  width: 228px;
  height: 272px;
  padding-top: 24px;
  border-radius: 8px;
  border-right: 1px solid transparent;
}
.v-many-product ::v-deep .goods_container:nth-child( 5n ) {
  margin-right: 0;
}
.v-many-product ::v-deep .goods_container .item_img {
  width: 100px;
  height: 100px;
}
.v-many-product ::v-deep .tag_ite {
  margin-left: 0 !important;
}
.v-product-card ::v-deep .goods_container,
.v-market ::v-deep .goods_container {
  width: 205px;
  height: 272px;
  padding-top: 24px;
  border-radius: 0;
  border-right: 1px solid #EEEEEE;
}
.v-product-card ::v-deep .goods_container:nth-child( 5n ),
.v-market ::v-deep .goods_container:nth-child( 5n ) {
  margin-right: 0;
}
.v-product-card ::v-deep .goods_container .item_img,
.v-market ::v-deep .goods_container .item_img {
  width: 109px;
  height: 109px;
}
.v-product-card ::v-deep .goods_container .content,
.v-market ::v-deep .goods_container .content {
  width: unset;
}
.v-product-card ::v-deep .goods_container .confirm-btn .btn,
.v-market ::v-deep .goods_container .confirm-btn .btn {
  width: 72px;
  height: 24px;
  line-height: 24px;
  font-size: 8px;
}
.v-product-card ::v-deep .goods_container .input,
.v-market ::v-deep .goods_container .input {
  width: 40px !important;
  margin: 0 4px !important;
}
.v-product-card ::v-deep .goods_container .minus,
.v-market ::v-deep .goods_container .minus,
.v-product-card ::v-deep .goods_container .plus,
.v-market ::v-deep .goods_container .plus {
  width: 14px !important;
  height: 14px !important;
}
.v-product-card ::v-deep .goods_container .title,
.v-market ::v-deep .goods_container .title {
  width: 100% !important;
}
.v-product-card ::v-deep .tag_ite,
.v-market ::v-deep .tag_ite {
  margin-left: 0 !important;
}
.v-floor {
  width: 1200px;
}
.eight-product-wrap {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}
.eight-product-wrap .product-item {
  border-left: 0.5px solid #eee;
}
.eight-product-wrap .product-item:nth-child(1),
.eight-product-wrap .product-item:nth-child(5) {
  border: 0;
}
.eight-product-wrap .product-item:nth-child(n+4) {
  border-top: 0.5px solid #eee;
}
.eight-product {
  display: flex;
  border-radius: 8px;
  overflow: hidden;
}
.eight-product .eight-img {
  cursor: pointer;
  width: 288px;
  height: 648px;
}
.six-product-wrap {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  border-radius: 8px;
  overflow: hidden;
}
.six-product {
  position: relative;
}
.six-product .six-img {
  cursor: pointer;
  position: absolute;
  left: 300px;
  width: 600px;
}
.six-product .product-item {
  border-left: 0.5px solid #eee;
}
.six-product .product-item:nth-child(1),
.six-product .product-item:nth-child(2) {
  border: 0;
}
.six-product .product-item:nth-child(n+3) {
  border-top: 0.5px solid #eee;
}
.six-product .product-item:nth-child(3) {
  border-left: 0;
}
:deep(.six-product) .list-card {
  width: 300px;
  height: 316px;
  padding: 16px;
  border-radius: 0;
  border: 0;
}
:deep(.six-product) .list-card:nth-child(n+3) {
  border-top: 0.5px solid #eee;
  border-right: 0.5px solid #eee;
}
:deep(.six-product) .list-card:last-child {
  border-right: 0;
}
:deep(.six-product) .list-card .title {
  height: 20px;
  display: flex;
  align-items: center;
}
:deep(.six-product) .list-card .title span {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
:deep(.six-product) .cover-box,
:deep(.six-product) .cover-mask {
  width: 155px!important;
  height: 155px!important;
}
:deep(.six-product) .price-area-box {
  margin-top: 0;
}
:deep(.eight-product-wrap) .list-card {
  width: 228px;
  height: 324px;
  border-radius: 0;
  border-left: 0.5px solid #e7e7e7;
}
:deep(.eight-product-wrap) .list-card:nth-child(n+5) {
  border-top: 0.5px solid #e7e7e7;
}
:deep(.eight-product-wrap) .cover-mask,
:deep(.eight-product-wrap) .cover-box {
  width: 148px;
  height: 148px;
}
