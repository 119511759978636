
.top-menu-wrapper {
  position: sticky;
  top: 0;
  width: 100%;
  height: 36px;
  line-height: 36px;
  background: #fafafa;
  z-index: 1000;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);

  .top-menu-wrapper_content {
    display: flex;
    justify-content: space-between;
    width: 1200px;
    margin: 0 auto;

    .left {
      display: flex;
      align-items: center;

      .location {
        color: rgba(0, 0, 0, 0.6);
        margin-right: 16px;

        &-icon {
          margin-right: 4px;
        }
      }

      >span {
        line-height: 36px;
        height: 36px;
      }

      .top-shop-name {
        display: inline-block;
        color: rgba(0, 0, 0, 0.6);

        position: relative;
        max-width: 540px;
        white-space: nowrap;
        /* 禁止文本换行 */
        overflow: hidden;
        /* 隐藏超出范围的内容 */
        text-overflow: ellipsis;
        /* 使用省略号 */
      }

      .welcome {
        color: #f99d33;
        margin-right: 16px;
      }

      .quit {
        color: rgba(0, 0, 0, 0.25);

        &:hover {
          cursor: pointer;
          color: #ce7720;
        }
      }
    }

    .right {
      display: flex;
      align-items: center;

      .item {
        // display: inline-block;
        padding: 0 8px;
        display: flex;
        align-items: center;
        cursor: pointer;

        a {
          color: rgba(0, 0, 0, 0.6);
        }

        &:hover {
          color: #ce7720;
        }

        img {
          margin-right: 2px;
        }
      }
    }
  }

  .smartphone-icon, .smartphone-icon-hover {
    width: 12px;
    height: 12px;
    margin-right: 4px;
  }

  .smartphone-icon-hover {
    display: none;
  }
}
::v-deep .ivu-radio-wrapper {
  margin-right: 0;
}
.shop-name {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 999;

  &:hover {
    .list {
      display: block;
    }
  }

  .address-wrapper {
    background-color: #fff;
    box-shadow: 0 0 30px 1px rgba(0, 0, 0, 0.2);
    position: absolute;
    left: 402px;
    top: 0;
  }

  .list {
    border-radius: 8px;
    padding-bottom: 8px;
    display: block;
    position: absolute;
    width: 404px;
    background-color: #fff;
    box-shadow: 0 0 30px 1px rgba(0, 0, 0, 0.2);
    z-index: 999;
    top: 40px;
    left: 243px;

    .address-content {
      position: absolute;
      max-height: 400px;
      overflow-y: auto;
      box-shadow: 0 0 30px 1px rgba(0, 0, 0, 0.2);
      /*          left: calc(100%);*/
      top: 4px;
      z-index: 9999;
      background-color: #fff;

      .address-fixation {
        max-height: 350px;
        overflow-y: scroll;
      }

      .address-box {
        .address-box-loading {
          text-align: center;
          width: 200px;
          height: 50px;
          margin: 0 auto;
          position: relative;
          font-size: 14px;
          line-height: 22px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.9);
        }
      }

      .address-input {
        width: 364px;
        padding: 8px 24px 8px 24px;
      }

      .address-content-item {
        display: flex;
        padding: 8px 24px;
        margin-bottom: 5px;

        ::v-deep .ivu-radio {
          position: relative;
        }

        &:hover {
          background: #f5f5f5;
        }

        word-wrap: break-word;
        white-space: pre-wrap !important;

        .address {
          font-size: 14px;
          line-height: 22px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.9);
        }

        .phone {
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          display: flex;
          align-items: center;
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }

    .item-content {
      max-height: 400px;
      overflow-y: auto;

      ::v-deep .ivu-radio-group {
        width: 100%;
        word-wrap: break-word;
      }
    }

    .title {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      padding: 16px 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .ico {
        cursor: pointer;
      }
    }

    .list-item {
      padding: 8px 24px;
      display: flex;

      &:hover {
        background: #f5f5f5;
      }

      .content-box {
        flex: 1;
        word-wrap: break-word;
        max-width: calc(100% - 44px);
        white-space: pre-wrap !important;

        >div {
          max-width: 100%;
          word-break: break-word;
        }
      }

      .custome-name {
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.9);
      }

      .custome-addr {
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: rgba(0, 0, 0, 0.6);
      }

      .change-address {
        position: relative;
        cursor: pointer;
        max-height: 50px;
        overflow-y: auto;
        color: #3853e2;

        .address-content {
          position: absolute;
          top: 4px;
          z-index: 9999;
          background-color: #fff;

          .address-content-item {
            display: flex;
          }
        }
      }
    }
  }
}

.demo-spin-col .circular {
  width: 25px;
  height: 25px;
}

.demo-spin-icon-load {
  animation: ani-demo-spin 1s linear infinite;
}

@keyframes ani-demo-spin {
  from {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.demo-spin-col {
  height: 100px;
  position: relative;
  border: 1px solid #eee;
}

.menu-copy {
  margin-left: 8px;
}

::v-deep .ivu-dropdown .ivu-select-dropdown {
  width: auto;
}

::v-deep .ivu-dropdown-item {
  display: flex;
  align-items: center;
}

::v-deep .ivu-dropdown-menu {
  padding: 0 4px;

  .ivu-dropdown-item {
    &:hover {
      color: rgba(230, 125, 0, 1);
      background: rgba(255, 248, 232, 1);
    }
  }
}

::v-deep .ivu-poptip-popper {
  min-width: 116px;
  top: 28px !important;
  &.download-poptip {
    min-width: 100px;
  }
}

.service-box {
  .service-item {
    width: 106px;
    height: 32px;
    padding: 0px 12px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    position: relative;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.9);
    &:hover {
      background: rgba(255, 248, 232, 1);
      color: rgba(230, 125, 0, 1);
    }
  }
  .service-contact {
    position: absolute;
    bottom: 7px;
    left: 117px;
    height: 40px;
    padding: 0 12px;
    border-radius: 0px 4px 4px 0px;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: #ffffff;
    color: rgba(0, 0, 0, 0.9);
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
    .copy-text {
      font-size: 12px;
      color: rgba(36, 56, 188, 1);
      margin-left: 8px;
    }
  }
}

.download-box {
  .download-item {
    width: 90px;
    height: 32px;
    padding: 0px 12px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    position: relative;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.9);
    &:hover {
      background: rgba(255, 248, 232, 1);
      color: rgba(230, 125, 0, 1);
    }
  }
}
.smartphone, .smartphone-hover {
  width: 12px !important;
  height: 12px !important;
}

.smartphone-hover {
  display: none;
}
.phone-item {
  &:hover {
    color: rgba(230, 125, 0, 1);
    .smartphone {
      display: none;
    }
    .smartphone-hover {
      display: inline-block;
    }
  }
}

.code-container {
  width: 256px;
  height: 150px;
  background-color: #fff;
  z-index: 999;
  top: 80px;
  right: 8px;
  display: flex;
  padding: 12px 16px;
  border-radius: 8px;
  box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.08);
  .code-content {
    flex: 1;
    text-align: center;
    .code-title {
      color:  rgba(0, 0, 0, 0.90);
      font-family: "PingFang SC";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }
  }
  img {
    width: 100px;
    height: 100px;
  }

}

