
.act_time {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  // line-height: 18px;
  padding-top: 2px;
  color: #BE5F00;
  .time_doc{
    margin-right:5px;
    font-size: 12px;
  }
}
.time_num {
  display: flex;
  align-items: center;
  li {
    // padding: 0 2px;
    // margin: 0;
    list-style: none;
    font-weight: 400;
    font-size: 12px;
    // border:1px solid #FFBB96;
    // border-radius: 2px;
  }
  span{
    margin:0 2px;
  }
}
.count-time {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .activity-txt {
    font-weight: 400;
    font-size: 20rpx;
    line-height: 28rpx;
    color: #fa541c;
    margin-right: 8rpx;
  }
  .time-section {
    .time {
      display: flex;
      align-items: center;
      &__custom {
        padding: 5rpx 2rpx 0 2rpx;
        height: 24rpx;
        background: #ffffff;
        border: 1rpx solid #ffbb96;
        border-radius: 4rpx;
        display: flex;
        justify-content: center;
        align-items: center;
        &__item {
          font-weight: 500;
          font-size: 20rpx;
          line-height: 20rpx;
          color: #fa541c;
        }
      }
      &__doc {
        width: 10rpx;
        height: 36rpx;
        text-align: center;
        font-weight: 400;
        font-size: 20rpx;
        line-height: 36rpx;
        color: #fa541c;
      }
    }
  }
}

// 润滑算标签
.time-special{
  padding-left: 21px;
  padding-right: 3px;
  border:0.5px solid;
  height: 18px;
  border-radius: 4px;
  font-size: 12px;
  line-height: 18px;
  margin-right: 4px;
  display: inline-flex;
  &.green{
    border-color: #4DAC39;
    color:#086453;
    background:#F6FBF5 url("~@/assets/images/notic/time-special-green.png") no-repeat left center;
    background-size:auto 18px;
  }
  &.orange{
    border-color: #FC9C57;
    color:#A63D11;
    background:#FEFAF5 url("~@/assets/images/notic/time-special-orange.png") no-repeat left center;
    background-size:auto 18px;
  }
}
