
.friend-link-wrapper {
  // background-color: #E7E7E7 !important;
  height: 26px;
  // padding: 4px 0;
  padding-top: 8px;
  padding-bottom: 16px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e7e7e7;
  .title {
    height: 26px;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    color: rgba(0, 0, 0, 0.90);
    margin-right: 32px;
  }
  .item {
    height: 26px;
    line-height: 26px;
    margin-right: 16px;
    color: rgba(0, 0, 0, 0.40);
    &:hover {
      color: #CE7720;
    }
    span {
      cursor: pointer;
    }
  }
}
