
.v-hot-zone {
  width: 1200px;
  position: relative;
  .img {
    width: 100%;
  }
  .zone {
    position: absolute;
    // background-color: red;
  }
}
