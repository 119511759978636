.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pagination ::v-deep .ivu-page .ivu-page-item {
  border: 1px solid #E7E7E7;
  border-radius: 4px;
  margin: 0 4px;
}
.pagination ::v-deep .ivu-page .ivu-page-item:hover {
  background: #eee;
}
.pagination ::v-deep .ivu-page .ivu-page-item:hover a {
  color: rgba(0, 0, 0, 0.9);
}
.pagination ::v-deep .ivu-page .ivu-page-item-active {
  background: #eee;
}
.pagination ::v-deep .ivu-page .ivu-page-item-active a {
  color: rgba(0, 0, 0, 0.9);
}
.pagination ::v-deep .ivu-page .ivu-page-prev,
.pagination ::v-deep .ivu-page .ivu-page-next {
  background: transparent;
}
.pagination ::v-deep .ivu-page .ivu-page-prev:hover,
.pagination ::v-deep .ivu-page .ivu-page-next:hover {
  background: #eee;
  border: 1px solid #E7E7E7;
}
.pagination ::v-deep .ivu-page .ivu-page-prev:hover a,
.pagination ::v-deep .ivu-page .ivu-page-next:hover a {
  color: rgba(0, 0, 0, 0.9);
}
.pagination ::v-deep .ivu-page .ivu-page-options-elevator input {
  text-align: center;
}
.pagination ::v-deep .ivu-page .ivu-page-options-elevator input:focus {
  border: 1px solid #A6A6A6;
  box-shadow: 0 0px 0px #A6A6A6;
}
.pagination ::v-deep .ivu-page .ivu-page-options-elevator input:hover {
  border: 1px solid #A6A6A6;
  box-shadow: 0 0px 0px #A6A6A6;
}
.pagination ::v-deep .ivu-page .ivu-select-selection .ivu-select-selection:hover {
  border: 1px solid #A6A6A6;
  box-shadow: 0 0px 0px #A6A6A6;
  color: rgba(0, 0, 0, 0.9);
}
.pagination ::v-deep .ivu-page .ivu-icon {
  color: rgba(0, 0, 0, 0.9);
}
.pagination .theme-color {
  color: rgba(0, 0, 0, 0.9);
}
.pagination .ml8 {
  margin-left: 8px;
}
