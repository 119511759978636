.cms-config-empty {
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.cms-config-empty .cms-config-wrap {
  width: 216px;
  height: 216px;
  position: relative;
}
.cms-config-empty img {
  width: 216px;
  height: 216px;
}
.cms-config-empty p {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.6);
  position: absolute;
  bottom: -2px;
  left: 50%;
  transform: translateX(-50%);
}
.tab-box {
  padding: 20px 20px 0 20px;
}
.w-page {
  background: #fff;
  padding-bottom: 20px;
  position: relative;
  width: 1200px;
  margin: 12px auto;
}
.w-page :deep(.ivu-tabs-bar) {
  margin-bottom: 0;
}
.w-page .content-wrapper {
  padding: 24px;
}
.w-page .content-wrapper ::v-deep .ivu-select {
  margin-bottom: 10px;
  width: 75px;
}
.w-page .content-wrapper ::v-deep .ivu-select .ivu-select-selection {
  border: none;
}
.w-page .content-wrapper .items {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 16px 16px;
  justify-content: flex-start;
  grid-template-columns: auto auto;
  margin-top: 12px;
}
.w-page .content-wrapper .coupon-btns .btn {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  border-radius: 8px;
  margin-left: 20px;
}
.w-page .content-wrapper .coupon-btns button:first-child {
  margin-left: 0;
}
.w-page .content-wrapper .coupon-btns .btn-default {
  color: rgba(75, 75, 75, 0.7);
  background-color: #eee;
  border: none;
}
.w-page .content-wrapper .coupon-btns .btn-selected {
  color: #CE7720;
  background: #FFF8E8;
  border: 1.5px solid #F99D33;
  font-weight: 500;
}
.w-page .content-wrapper .coupon-btns .link-mycoupon {
  margin-left: 40px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  border: none;
  display: inline-block;
}
.w-page .content-wrapper .coupon-btns .link-mycoupon img {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
}
.w-page .content-wrapper .coupon-btns .link-mycoupon a {
  color: rgba(0, 0, 0, 0.9);
  margin-left: 4px;
  display: inline-block;
  vertical-align: middle;
}
.w-page .content-wrapper .coupon-btns .link-mycoupon a .right-arrow {
  width: 16px;
  height: 16px;
}
.content-wrapper ::v-deep .ivu-select-item {
  color: rgba(0, 0, 0, 0.4);
}
.content-wrapper ::v-deep .ivu-select-item-selected {
  color: #000;
}
.content-wrapper ::v-deep .ivu-select-selected-value {
  color: #000;
  font-weight: 500;
}
.content-wrapper ::v-deep .ivu-select-visible .ivu-select-selection {
  box-shadow: none;
}
.content-wrapper :deep(.coupon-item) {
  width: calc((100% - 32px)/3);
}
.content-wrapper .org-info {
  display: flex;
}
.content-wrapper .org-info .org-logo {
  display: block;
  width: 24px;
  height: 24px;
  border: 1px solid #e7e7e7;
  border-radius: 4px;
}
.content-wrapper .org-info .org-name {
  color: rgba(0, 0, 0, 0.9);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-left: 8px;
}
.main-index {
  background: #eee;
}
