@import '~view-design/src/styles/index.less';
@primary-color: #F99D33;

.ivu-checkbox-focus{
  box-shadow: none;
}

.ivu-input{
  border-radius: 4px;
}
.ivu-icon-md-eye,.ivu-icon-md-eye-off{
  color: #9c9c9c;
}

.ivu-tooltip-inner{
  background: rgba(0,0,0,0.9);
  font-size: 14px;
  color:#fff;
}
.ivu-tooltip-popper[x-placement^="top"] .ivu-tooltip-arrow{
  border-top-color: rgba(0,0,0,0.9);
}
.ivu-form-item-error-tip{
  font-size: 12px;
  color: #CB272D;
}
.ivu-select-dropdown{
  .ivu-select-item-selected{
    color: rgba(0, 0, 0, 0.9) !important;
  }
}
.ivu-select-visible .ivu-select-selection{
  border: 1px solid #A6A6A6;
  box-shadow: 0 0px 0px #A6A6A6;
  color: rgba(0, 0, 0, 0.9);
}
.ivu-select-selection:hover, .ivu-select-selection-focused{
  border: 1px solid #A6A6A6;
}
.ivu-breadcrumb > span:last-child {
  color: rgba(0, 0, 0, 0.9);
}