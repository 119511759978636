
.home-footer {
  background: #fff;
}
.search-bar {
  background-color: #fff;
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 3;
  // position: sticky;
  // z-index: 3;
  // top: 36px
  &.box-shadow {
    box-shadow: 4px 0px 20px 0px rgba(0, 0, 0, 0.08);
  }
}
.home-content {
  margin: 0 auto 12px;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  padding: 0 15px 0px 15px;
  overflow: hidden;
  position: relative;
  .content-container {
    display: flex;
    // align-items: center;
    // justify-content: center;
    width: 1200px;
    // background: #F1F1F1;
    border-radius: 8px;
    margin-top: 12px;
    // height: 552px;
    // padding: 16px;
  }
}
.content-right {
  width: 260px;
  height: max-content;
  // margin-top: 38px;
}
.cms-config {
  position: relative;
  overflow: hidden;
  clear: both;
  font-size: 0;
  padding: 0 calc((100% - 1920px) / 2 + 100px) 32px;
}
.cms-config-item {
  display: inline-block;
  vertical-align: middle;
  min-width: 1200px;
  margin: 12px 0 0 0;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  &:first-child {
    margin-top: 0;
  }
  &.no-top {
    margin-top: 0;
  }
  &.no-bottom {
    margin-bottom: 0;
  }
}
.cms-config-empty {
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .cms-config-wrap {
    width: 216px;
    height: 216px;
    position: relative;
  }
  img {
    width: 216px;
    height: 216px;
  }
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.6);
    position: absolute;
    bottom: -2px;
    left: 50%;
    transform: translateX(-50%);
  }
}
// .v-lift-nav-wrap {
  // position: absolute;
  // left: 0;
  // top: 0;
  // width: 100%;
  // height: 100%;
  .v-lift-nav-ref {
    //position: sticky;
    //bottom: 0;
    //right: 20px;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 20px;
    margin:auto;
    height: fit-content;
  }
.up-loading {
  width: 100%;
  .up-loading-content {
    margin: 0 auto;
    width: 100px;
    height: 200px;
    position: relative;
    text-align: center;
    font-weight: 600;
  }
}
.demo-spin-col .circular {
        width:25px;
        height:25px;
    }
    .demo-spin-icon-load{
        animation: ani-demo-spin 1s linear infinite;
    }
    @keyframes ani-demo-spin {
        from { transform: rotate(0deg);}
        50%  { transform: rotate(180deg);}
        to   { transform: rotate(360deg);}
    }
    ::v-deep .ivu-spin-fix {
      background: none;
    }
