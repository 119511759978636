
:deep(.vertical-center-modal){
  display: flex;
  align-items: center;
  justify-content: center;
  .ivu-modal{
    top: 0;
  }
}
:deep(.ivu-modal-header){
  // padding:24px;
  border:none;
  .ivu-modal-header-inner{
    color: var(--font-black-1, rgba(0, 0, 0, 0.90));
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    height:26px;
  }
}
:deep(.ivu-modal-close){
  right:24px;
  top:8px;
  .ivu-icon-ios-close{
    font-size:24px;
  }
}
:deep(.ivu-modal-body){
  padding: 0 16px;
}
:deep(.ivu-modal-footer){
  border:none;
  padding:20px 24px;
  .ivu-btn-text{
    height: 36px;
    padding: 8px 16px;
    border-radius: 4px;
    border: 1px solid var(--gray-4, #DCDCDC);
    box-sizing:border-box;
    color: var(--font-black-1, rgba(0, 0, 0, 0.90));
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }
  .ivu-btn-primary{
    height: 36px;
    padding: 8px 16px;
    border-radius: 4px;
    background: var(--brand-6, #F99D33);
    border:none;
    color: var(--font-white-0, #FFF);
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }
}
:deep(.ivu-table-header){
  border-radius: 8px 8px 0px 0px;
  border: 1px solid var(--gray-2, #EEE);
  background: var(--gray-1, #FAFAFA);
  th{
    border:none;
    background: none;
    padding: 4px;
    border-right: 1px solid #eee;
    &:last-child {
      border-right: none;
    }
    &:first-child{
      padding-left: 10px;
      .ivu-table-cell{
        text-align: center;
      }
    }
  }
  .ivu-table-cell{
    padding:0;
    color: var(--font-black-2, rgba(0, 0, 0, 0.90));
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
  }
}
:deep(table){
  width:1074px;
  border-spacing:16px 0;
  border-collapse: collapse;
}
:deep(.ivu-table-body){
  border-radius: 0px 0px 8px 8px;
  border: 1px solid var(--gray-2, #EEE);
  border-top:0;
  // min-height:558px;
  // overflow-y:scroll;
}
:deep(.ivu-table-tbody){
  tr{
    border-bottom:1px solid var(--gray-2, #EEE);
    &:last-child{
      border-bottom:none;
    }
  }
  td{
    border-bottom:none;
    padding: 4px;
    vertical-align:top;
    border-right: 1px solid #eee;
    &:last-child {
      border-right: none;
    }
    &:first-child {
      padding-left: 10px;
    }
  }
  .ivu-table-row-hover{
    td {
      background: #FFF8F0 !important;
    }
  }
  .ivu-table-cell{
    padding:0;
    color: var(--font-black-2, rgba(0, 0, 0, 0.90));
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
  }
}
:deep(.ivu-table){
  &:before{
    display:none;
  }
}
.good-info{
  p{
    width:205px;
    overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
  }
}
:deep(.ivu-tooltip){
  display:flex;
}
:deep(.ivu-table-tip){
  height:238px;
  padding:12px 16px;
  box-sizing:border-box;
  border-radius: 0px 0px 8px 8px;
  border: 1px solid var(--gray-2, #EEE);
  border-top:none;
  td{
    margin:0 auto;
    display:block;
    width: 216px !important;
    height: 216px;
    background:url(../../../../assets/center/noData.png) 0 0 no-repeat;
    background-size: 100% 100%;
    border:none;
    span{
      display:none;
    }
  }
}
.info {
  margin-bottom: 16px;
  .total {
    font-weight: 600;
    margin: 0 4px;
  }
  .fail-total {
    color: red;
    margin: 0 4px;
  }
}
.fail-modal-footer {
  display: flex;
  justify-content: flex-end;
  button {
    padding: 8px 16px;
    height: 36px;
  }
}
