
.shop-description-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: center;
  background-size: 100% 100%;
  background-color: #fff;
  border-bottom: 1px solid var(--gray-4, #E7E7E7);
  padding-top: 24px;
  padding-bottom: 24px;
  .bg-img {
    width: 1200px;
  }
  img {
    width: 1200px;
  }
}
