.searchBar {
  width: 100%;
  height: 85px;
}
.searchBar .shop-item {
  display: flex !important;
  align-items: center !important;
  padding: 0 4px !important;
}
.searchBar .shop-item span {
  padding: 6px 0 6px 3px !important;
}
.searchBar .searchBar-content {
  padding-top: 16px;
  width: 1200px;
  box-sizing: border-box;
  margin: 0 auto;
  display: flex;
}
.searchBar .searchBar-content .left,
.searchBar .searchBar-content .middle,
.searchBar .searchBar-content .right {
  display: inline-block;
}
.searchBar .searchBar-content .left {
  width: 195px;
  height: 48px;
  display: inline-block;
  margin-right: 73px;
  cursor: pointer;
}
.searchBar .searchBar-content .left img {
  width: 195px;
  height: 48px;
}
.searchBar .searchBar-content .middle {
  display: flex;
  position: relative;
}
.searchBar .searchBar-content .middle .input-search {
  position: relative;
  display: inline-block;
  width: 550px;
}
.searchBar .searchBar-content .middle .input-search .serach_key {
  letter-spacing: 1px;
  position: absolute;
  top: 7px;
  left: 10px;
  z-index: 9;
  border-radius: 4px;
  border: 1px solid #dcdcdc;
  background: #f5f5f5;
  color: #4b4b4b;
  font-size: 12px;
  line-height: 20px;
  padding: 2px 4px;
}
.searchBar .searchBar-content .middle .input-search .shop-list {
  margin: 7px 0 0 -6px;
  height: 22px;
  overflow: hidden;
}
.searchBar .searchBar-content .middle .input-search .shop-list span {
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
  padding: 0 6px;
  cursor: pointer;
}
.searchBar .searchBar-content .middle .input-search .shop-list span:hover {
  color: #ce7720;
}
.searchBar .searchBar-content .middle .input-search ::v-deep .ivu-input-group-append {
  border-radius: 8px;
}
.searchBar .searchBar-content .middle .input-search ::v-deep .ivu-input {
  border: 2px solid #f99d33 !important;
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}
.searchBar .searchBar-content .middle .input-search ::v-deep .ivu-input:focus {
  box-shadow: none !important;
}
.searchBar .searchBar-content .middle .input-search .search-relate {
  margin-top: 5px;
  position: absolute;
  width: 550px;
  min-height: 0px;
  max-height: 60vh;
  display: flex;
  flex-direction: column;
  z-index: 10;
  padding: 8px 8px;
  border-radius: 8px;
  background: #fff;
  overflow-y: hidden;
  /* shadow/3 */
  box-shadow: 0px 16px 20px -10px rgba(0, 0, 0, 0.04), 0px 32px 48px 4px rgba(0, 0, 0, 0.02), 0px 12px 60px 10px rgba(0, 0, 0, 0.03);
}
.searchBar .searchBar-content .middle .input-search .search-relate:hover {
  overflow-y: auto;
}
.searchBar .searchBar-content .middle .input-search .search-relate div,
.searchBar .searchBar-content .middle .input-search .search-relate span {
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
  padding: 6px 8px;
  cursor: pointer;
  border-radius: 4px;
}
.searchBar .searchBar-content .middle .input-search .search-relate div:hover,
.searchBar .searchBar-content .middle .input-search .search-relate span:hover {
  background-color: #f5f5f5;
}
.searchBar .searchBar-content .middle .input-search .search-relate .history_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.searchBar .searchBar-content .middle .input-search .search-relate .history_item img {
  margin-right: 2px;
}
.searchBar .searchBar-content .middle .input-search .search-relate .history_item .close {
  display: none;
  color: #a6a6a6;
}
.searchBar .searchBar-content .middle .input-search .search-relate .history_item:hover .close {
  display: block;
}
.searchBar .searchBar-content .middle .input-search .search-relate .history_item:hover .close:hover {
  color: #4b4b4b;
}
.searchBar .searchBar-content .middle .input-search .search-relate .history-title {
  display: flex;
  font-size: 12px;
  justify-content: space-between;
  color: rgba(0, 0, 0, 0.6);
}
.searchBar .searchBar-content .middle .input-search .search-relate .history-title .clear_btn {
  color: #3853e2;
}
.searchBar .searchBar-content .middle .input-search .search-relate .history-title span:hover {
  background: none;
}
.searchBar .searchBar-content .middle .input-search .search-relate .green {
  color: #4b4b4b;
  padding: 4px;
}
.searchBar .searchBar-content .middle .input-search .search-relate .green span {
  color: #ce7720;
  padding: 0;
}
.searchBar .searchBar-content .middle .input-search .search-relate .green .svg-icon {
  margin-right: 5px;
}
.searchBar .searchBar-content .middle .search-all {
  margin-left: 16px;
  border-radius: 8px;
}
.searchBar .searchBar-content .middle ::v-deep.ivu-input-search {
  background-color: #f99d33 !important;
}
.searchBar .searchBar-content .middle ::v-deep.ivu-input {
  border: 2px solid #f99d33 !important;
}
.searchBar .searchBar-content .notice-bar {
  position: absolute;
  left: 0;
  top: 0;
  width: 494px;
  height: 40px;
  pointer-events: none;
  z-index: 2;
}
.searchBar .searchBar-content .right {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
.searchBar .searchBar-content .right .card-btn {
  margin-left: 16px;
}
.searchBar .searchBar-content .right .licence-btn {
  margin-left: 16px;
}
::v-deep .ivu-input-group-large .ivu-input {
  padding-left: 8px !important;
}
::v-deep .ivu-input-group-large .ivu-input {
  font-size: 14px;
}
.below-keyword {
  position: absolute;
  top: 48px;
  color: rgba(0, 0, 0, 0.4);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: flex;
}
.below-keyword .keyword-block {
  display: flex;
  align-items: center;
}
.below-keyword .keyword-block:first-child .keyword {
  padding: 0 8px 0 0 !important;
}
.below-keyword .keyword {
  padding: 0 8px;
  cursor: pointer;
}
.below-keyword .line {
  width: 1px;
  height: 12px;
  background-color: rgba(0, 0, 0, 0.25);
}
.search-buy-btn {
  width: 108px;
  height: 40px;
  border-radius: 4px;
  background-color: #ffffff;
  position: relative;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #EEE;
  margin-left: 12px;
}
.search-buy-btn .search-buy-icon {
  width: 16px;
  height: 16px;
}
.search-buy-btn .search-buy-text {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);
  font-weight: 400;
  margin-left: 8px;
}
.search-buy-btn:hover {
  border: 1px solid #fab357;
}
.search-buy-btn:hover .search-buy-text {
  color: #e67d00;
}
.cart-num {
  position: absolute;
}
.cart-num {
  width: 28px;
  height: 15px;
  line-height: 15px;
  top: -10px;
  right: 6px;
  text-align: center;
  background-size: 100% 100%;
}
.cart-num .num {
  font-size: 12px;
  color: #ffffff;
}
.cart-num.indivdual {
  width: 16px;
  height: 16px;
  background-image: url(../assets/images/common/car-num-indivdual.png);
}
.cart-num.ten {
  background-image: url(../assets/images/common/car-num-ten.png);
  width: 24px;
  height: 16px;
}
.cart-num.hundred {
  width: 32px;
  height: 16px;
  background-image: url(../assets/images/common/car-num-hundred.png);
}
.suggest-item {
  display: flex!important;
  padding: 4px 8px!important;
  overflow: hidden;
}
.suggest-item .keyword {
  padding: 0!important;
  line-height: 22px!important;
  white-space: nowrap;
  max-width: 280px;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-shrink: 0;
  margin-right: 8px;
  flex-grow: 0;
}
.suggest-item .unit-space {
  margin-left: auto;
  padding: 0!important;
  height: 24px;
  overflow: hidden;
  flex-grow: 1;
  text-align: right;
}
.suggest-item .unit-space span {
  max-width: 98%!important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 4px;
  padding: 0 4px!important;
  color: rgba(0, 0, 0, 0.6);
  justify-content: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px!important;
  border-radius: 4px;
  border: 1px solid #eee;
  background: #fff;
}
.suggest-item .unit-space span:hover {
  background: #F99D33 !important;
  color: #fff;
}
