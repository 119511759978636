.product-item {
  border-radius: 8px;
  background: #fff;
  padding: 24px 16px 16px 16px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
}
.product-item.border {
  border: 1px solid transparent;
}
.product-item.border:hover {
  border: 1px solid var(--brand-6, #f99d33);
}
.product-item.card {
  border-radius: 0;
  border: 0;
}
.product-item .cover {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}
.product-item .cover img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}
.product-item .product-name {
  width: 100%;
  color: var(--font-black-1, rgba(0, 0, 0, 0.9));
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  height: 40px;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.product-item .product-name span {
  vertical-align: middle;
}
.product-item .product-name .history-label {
  display: inline-block;
  height: 18px;
  border-radius: 4px;
  background: #FBF5E8;
  color: #A0703C;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  padding: 0 4px;
  margin-right: 4px;
}
.product-item .cover,
.product-item .mask {
  width: 180px;
  height: 180px;
}
.product-item.normal {
  width: 228px;
  height: 372px;
}
.product-item.c120 {
  width: 198px;
  border-radius: 0;
}
.product-item.c120 .cover,
.product-item.c120 .mask {
  width: 120px;
  height: 120px;
}
.product-item.c148 {
  width: 228px;
  height: 324px;
  border-radius: 0;
}
.product-item.c148 .cover,
.product-item.c148 .mask {
  width: 148px;
  height: 148px;
}
.product-item.c149 {
  width: 234px;
  height: 324px;
  border-radius: 0;
}
.product-item.c149 .cover,
.product-item.c149 .mask {
  width: 148px;
  height: 148px;
}
.product-item.c160 {
  width: 300px;
  height: 316px;
  border-radius: 0;
}
.product-item.c160 .cover,
.product-item.c160 .mask {
  width: 160px;
  height: 160px;
}
.product-item.c160 .product-name {
  height: 20px;
  -webkit-line-clamp: 1;
  white-space: nowrap;
}
.product-item.c198x292 {
  width: 198px;
  height: 292px;
  border-radius: 0;
}
.product-item.c198x292 .cover,
.product-item.c198x292 .mask {
  width: 120px;
  height: 120px;
}
.product-item.c198x292 :deep(.price_container) .activity-price {
  line-height: 24px;
}
.product-item.c198x292 :deep(.price_container) .through-price {
  line-height: 24px;
}
.product-item.c204x292 {
  width: 204px;
  height: 292px;
}
.product-item.c204x292 .cover,
.product-item.c204x292 .mask {
  width: 120px;
  height: 120px;
}
.product-item.c204x292 :deep(.price_container) .activity-price {
  line-height: 24px;
}
.product-item.c204x292 :deep(.price_container) .through-price {
  line-height: 24px;
}
.product-item.c220x356 {
  width: 220px;
  height: 372px;
}
.product-item.market1 {
  width: 198px;
}
.product-item.market1 .cover,
.product-item.market1 .mask {
  width: 120px;
  height: 120px;
}
.product-item.market2 {
  width: 197px;
}
.product-item.market2 .cover,
.product-item.market2 .mask {
  width: 120px;
  height: 120px;
}
.expire {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  border-radius: 22px 8px 0 22px;
  background: #f5f5f5;
  padding: 0 8px;
  line-height: 24px;
}
.nearly-time-label {
  position: absolute;
  right: -1px;
  top: -1px;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #e67d00;
  border-radius: 22px 8px 0 22px;
  background: #fff8e8;
  padding: 0 8px;
  line-height: 24px;
}
.nearly-time-label .icon {
  margin-right: 2px;
  transform: rotate(180deg);
}
.product-space,
.company-name {
  color: var(--font-black-2, rgba(0, 0, 0, 0.6));
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 2px;
}
.shop-name {
  padding-left: 18px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background: url('../../assets/product/shop-bag.png') no-repeat left center;
  background-size: 14px 14px;
  margin-top: 6px;
}
.tag-box {
  height: 18px;
  margin-top: 2px;
}
.mask {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 24px;
  width: 180px;
  height: 180px;
  background: rgba(255, 255, 255, 0.4);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mask .tip-round {
  border-radius: 120px;
  padding: 8px 12px;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.mask .tip span {
  line-height: 20px;
  text-align: center;
  color: #fff;
  font-size: 14px;
}
.add-card-box {
  position: absolute;
  left: 0;
  bottom: -1000px;
  transition: 0.3s ease;
  background: pink;
  width: 100%;
  height: fit-content;
}
.add-card-box.show {
  bottom: 0;
}
.prd-control {
  position: absolute;
  bottom: -2px;
  left: 0;
  height: 52px;
  background: #fff;
  width: 100%;
  color: rgba(0, 0, 0, 0.4);
  text-align: center;
  line-height: 45px;
  box-shadow: 0 -5px 10px 0 rgba(0, 0, 0, 0.05);
  font-size: 12px;
}
.show-activity-pop {
  position: absolute;
  z-index: 9;
  left: 0;
  background: #fff;
  width: 100%;
  padding: 16px;
  border-top: 1px solid #e7e7e7;
  bottom: -300px;
  transition: 0.2s ease-in-out;
}
.product-item:hover .show-activity-pop {
  bottom: 0;
}
.label-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 16px;
  line-height: 16px;
  overflow: hidden;
  position: relative;
}
.label-box::after {
  content: '';
  display: block;
  width: 17px;
  height: 16px;
  position: absolute;
  right: 0px;
  background: linear-gradient(270deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
}
.label-box .label-item {
  color: #a0703c;
  font-size: 12px;
  font-style: normal;
  line-height: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
}
.label-box .label-item span {
  display: inline-block;
  margin: 0 3px;
  line-height: 10px;
}
:deep(.cover .g-img) {
  display: flex;
  align-items: center;
  justify-content: center;
}
:deep(.cover .g-img) img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}
.platform-tag {
  height: 18px;
  width: auto;
  margin-right: 4px;
  vertical-align: middle;
}
