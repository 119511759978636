
.home-helper-wrapper {
  background-repeat: no-repeat;
  .home-helper-wrapper-content {
    padding: 32px 0 16px 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    .guide-item {
      width: 90px;
      margin-right: 48px;
      h4 {
        margin: 0 0 16px 0
      }
      > * {
        margin-top: 12px;
      }
      span {
        cursor: pointer;
      }
    }
    .app-code {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      // padding: 16px 24px;
      gap: 10px;
      width: 160px;
      background: #FFFFFF;
      border-radius: 8px;
       img {
         width: 100%;
        //  height: 123px;
       }
      div {
        width: 100%;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        color: rgba(0, 0, 0, 0.9);
        text-align: center;
        margin-top: -18px;
      }
    }
  }
}
.title {
  color: rgba(0, 0, 0, 0.90);
}
.desc {
  color: #383838;
}
