
.list {
  padding-top: 10px;
  &-item {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    font-size: 14px;
    .label {
      width: 88px;
      display: inline-block;
      padding-right: 16px;
      color: rgba(0, 0, 0, 0.4);
    }

    .value{
      flex: 1;
    }
  }
}
.margin-bottom-20 {
  margin-bottom: 20px;
}
.padding-bottom-20 {
  padding-bottom: 20px;
}
.caption-right:hover{
  color: #3853E2;
  cursor: pointer;
}

.caption-right{
  color: var(--font_black-3, rgba(0, 0, 0, 0.40));
  /* regular/12 */
  font-family: PingFang SC;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}
.border-right-dashed {
    border-right: #dcdcdc 1px dashed;
  }
