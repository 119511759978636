.upload-content {
  display: inline-flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 10px;
}
.upload-content .upload .ivu-upload-drag {
  border: 1px solid #dcdee2 !important;
}
.upload-content .upload .upload-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 118px;
  height: 118px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
}
.upload-content .upload .upload-icon-loading {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.upload-content .upload-list {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
.upload-content .upload-list-item {
  width: 120px;
  height: 120px;
  text-align: center;
  line-height: 120px;
  border: 1px solid #dcdee2;
  padding: 6px;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  position: relative;
  box-sizing: border-box;
}
.upload-content .upload-list-item img {
  width: 100%;
  height: 100%;
}
.upload-content .upload-list-item .upload-list-cover {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  transition: opacity 0.5s ease;
}
.upload-content .upload-list-item .upload-list-cover i {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  margin: 0 2px;
}
.upload-content .upload-list-item:hover .upload-list-cover {
  opacity: 1;
  cursor: pointer;
}
