
.searchBar {
  width: 100%;
  height: 85px;
  .shop-item {
    display: flex !important;
    align-items: center !important;
    padding: 0 4px !important;
    span {
      padding: 6px 0 6px 3px !important;
    }
  }
  // background-color: #FFF;
  .searchBar-content {
    padding-top: 16px;
    width: 1200px;
    box-sizing: border-box;
    margin: 0 auto;
    display: flex;
    .left,
    .middle,
    .right {
      display: inline-block;
    }
    .left {
      // width: 206px;
      width: 195px;
      height: 48px;
      display: inline-block;
      margin-right: 73px;
      cursor: pointer;
      img {
        width: 195px;
        height: 48px;
      }
    }
    .middle {
      display: flex;
      position: relative;
      .input-search {
        position: relative;
        display: inline-block;
        width: 550px;
        .serach_key {
          letter-spacing: 1px;
          position: absolute;
          top: 7px;
          left: 10px;
          z-index: 9;
          border-radius: 4px;
          border: 1px solid #dcdcdc;
          background: #f5f5f5;
          color: #4b4b4b;
          font-size: 12px;
          line-height: 20px;
          padding: 2px 4px;
        }
        .shop-list {
          margin: 7px 0 0 -6px;
          height: 22px;
          overflow: hidden;
          span {
            font-size: 12px;
            line-height: 20px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.4);
            padding: 0 6px;
            cursor: pointer;
            &:hover {
              color: #ce7720;
            }
          }
        }
        ::v-deep .ivu-input-group-append {
          border-radius: 8px;
        }
        ::v-deep .ivu-input {
          border: 2px solid #f99d33 !important;
          border-top-left-radius: 8px !important;
          border-bottom-left-radius: 8px !important;
        }
        ::v-deep .ivu-input:focus {
          box-shadow: none !important;
        }
        .search-relate {
          margin-top: 5px;
          position: absolute;
          width: 550px;
          min-height: 0px;
          max-height: 60vh;
          display: flex;
          flex-direction: column;
          // background: #fff;
          // border: 1px solid #f5f5f5;
          border-radius: 8px;
          z-index: 10;
          padding: 8px 8px;
          border-radius: 8px;
          background: #fff;
          overflow-y: hidden;
          /* shadow/3 */
          box-shadow: 0px 16px 20px -10px rgba(0, 0, 0, 0.04), 0px 32px 48px 4px rgba(0, 0, 0, 0.02), 0px 12px 60px 10px rgba(0, 0, 0, 0.03);
          &:hover {
            overflow-y: auto;
          }

          div,
          span {
            display: inline-block;
            font-size: 14px;
            line-height: 20px;
            padding: 6px 8px;
            cursor: pointer;
            border-radius: 4px;
            &:hover {
              background-color: #f5f5f5;
            }
          }
          .history_item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            img {
              margin-right: 2px;
            }
            .close {
              display: none;
              color: #a6a6a6;
            }
            &:hover {
              .close {
                display: block;
                &:hover {
                  color: #4b4b4b;
                }
              }
            }
          }
          .history-title {
            display: flex;
            font-size: 12px;
            justify-content: space-between;
            color: rgba(0, 0, 0, 0.6);
            .clear_btn {
              color: #3853e2;
            }
            span {
              &:hover {
                background: none;
              }
            }
          }
          .green {
            color: #4b4b4b;
            padding: 4px;
            span {
              color: #ce7720;
              padding: 0;
            }
            .svg-icon {
              margin-right: 5px;
            }
          }
        }
      }
      .search-all {
        margin-left: 16px;
        border-radius: 8px;
      }
      ::v-deep.ivu-input-search {
        background-color: #f99d33 !important;
      }
      ::v-deep.ivu-input {
        border: 2px solid #f99d33 !important;
      }
    }
    .notice-bar {
      position: absolute;
      left: 0;
      top: 0;
      width: 494px;
      height: 40px;
      pointer-events: none;
      z-index: 2;
    }
    .right {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      .card-btn {
        margin-left: 16px;
      }
      .licence-btn {
        margin-left: 16px;
      }
    }
  }
}
::v-deep .ivu-input-group-large .ivu-input {
  padding-left: 8px !important;
}
::v-deep .ivu-input-group-large .ivu-input {
  font-size: 14px;
}
.below-keyword {
  position: absolute;
  top: 48px;
  color: rgba(0, 0, 0, 0.4);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  .keyword-block {
    display: flex;
    align-items: center;
    &:first-child .keyword {
      padding: 0 8px 0 0 !important;
    }
  }
  .keyword {
    padding: 0 8px;
    cursor: pointer;  
  }
  .line {
    width: 1px;
    height: 12px;
    background-color: rgba(0, 0, 0, 0.25);
  }
  display: flex;
}

.search-buy-btn {
  width: 108px;
  height: 40px;
  border-radius: 4px;
  background-color: #ffffff;
  position: relative;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #EEE;
  margin-left: 12px;
  .search-buy-icon {
    width: 16px;
    height: 16px;
  }
  .search-buy-text {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.9);
    font-weight: 400;
    margin-left: 8px;
  }

  &:hover {
    border: 1px solid rgba(250, 179, 87, 1);
    .search-buy-text {
      color: rgba(230, 125, 0, 1);
    }
  }
}

.cart-num {
  position: absolute;
}

.cart-num {
  width: 28px;
  height: 15px;
  line-height: 15px;
  top: -10px;
  right: 6px;
  text-align: center;
  background-size: 100% 100%;

  .num {
      font-size: 12px;
      color: #ffffff;
  }

  &.indivdual {
      width: 16px;
      height: 16px;
      background-image: url(../assets/images/common/car-num-indivdual.png);
  }

  &.ten {
      background-image: url(../assets/images/common/car-num-ten.png);
      width: 24px;
      height: 16px;
  }

  &.hundred {
      width: 32px;
      height: 16px;
      background-image: url(../assets/images/common/car-num-hundred.png);
  }
}
.suggest-item{
  display: flex!important;
  padding:4px 8px!important;
  overflow: hidden;
  .keyword{
    padding:0!important;
    line-height: 22px!important;
    white-space: nowrap;
    max-width: 280px;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 0;
    margin-right: 8px;
    flex-grow: 0;
  }
  .unit-space{
    margin-left: auto;
    padding:0!important;
    height: 24px;
    overflow: hidden;
    flex-grow: 1;
    text-align: right;
    span{
      max-width: 98%!important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 4px;
      padding: 0 4px!important;
      color: rgba(0, 0, 0, 0.60);
      justify-content: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px!important;
      border-radius: 4px;
      border: 1px solid #eee;
      background:#fff;
      &:hover{
        background:#F99D33 !important;
        color: #fff;
      }
    }
  }
}
