
.enterprise {
  min-height: 100vh;
  .business-description {
    line-height: 24px;
    padding-top: 20px;
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
  }
  .border-bottom-dashed {
    border-bottom: #dcdcdc 1px dashed;
  }
  .border-right-dashed {
    border-right: #dcdcdc 1px dashed;
  }
}
