.good-content-box ::v-deep span.ivu-radio + * {
  width: 414px;
  display: block;
  word-break: break-all !important;
  line-height: auto !important;
  white-space: wrap !important;
}
.good-content-box ::v-deep .ivu-radio-group-vertical .ivu-radio-wrapper {
  display: flex;
  height: auto !important;
  line-height: auto !important;
}
.good-content-box ::v-deep .ivu-radio-inner {
  margin-top: 7px;
}
.good-content-box ::v-deep .ivu-poptip-body {
  max-height: 300px;
  overflow-y: scroll;
}
.good-content-box ::v-deep .ivu-poptip-body::-webkit-scrollbar {
  display: none;
}
.good-content-box .good-content-right {
  width: 735px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
::v-deep .ivu-input-number-controls-outside {
  width: 112px;
  height: 24px;
  padding: 0 24px;
}
::v-deep .ivu-input-number-controls-outside .ivu-input-number-controls-outside-btn {
  width: 24px;
  height: 24px;
  line-height: 22px;
}
::v-deep .ivu-input-number-controls-outside .ivu-input-number-input-wrap {
  height: 24px;
  line-height: 24px;
}
::v-deep .ivu-input-number-controls-outside .ivu-input-number-input-wrap .ivu-input-number-input {
  height: 24px;
}
::v-deep .ivu-checkbox-wrapper {
  margin-right: 0;
}
::v-deep .ivu-checkbox-wrapper .ivu-checkbox {
  margin-right: 16px;
}
::v-deep .ivu-checkbox-wrapper .ivu-checkbox-checked .ivu-checkbox-inner {
  background-color: #f99d33;
}
.header-all {
  display: flex;
  font-size: 12px;
  line-height: 40px;
  color: rgba(0, 0, 0, 0.6);
  padding: 0 32px 0 16px;
  box-sizing: border-box;
  background-color: #fafafa;
  border-radius: 8px;
  height: 40px;
}
.header-all ::v-deep .ivu-checkbox-wrapper {
  font-size: 12px;
  line-height: 40px;
}
.header-all .handle-all-other {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.header-all .handle-all-other .good-info {
  width: 360px;
  text-align: center;
}
.header-all .handle-all-other .header-all-content {
  width: calc(100% - 360px);
  display: flex;
  justify-content: space-between;
}
.campany {
  margin-top: 16px;
}
.campany .header {
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgba(0, 0, 0, 0.9);
  background-color: #fafafa;
  padding: 0 16px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position: relative;
}
.campany .header .coupon-modal {
  position: absolute;
  top: 42px;
  left: 512px;
  width: 432px;
  max-height: 394px;
  overflow-y: scroll;
  padding: 24px;
  border-radius: 8px;
  border: 0.5px solid var(--error-6, #F53F3F);
  background: #FFF;
  box-shadow: 0px 16px 20px -10px rgba(0, 0, 0, 0.04), 0px 32px 48px 4px rgba(0, 0, 0, 0.02), 0px 12px 60px 10px rgba(0, 0, 0, 0.03);
  box-sizing: border-box;
  z-index: 999;
}
.campany .header .coupon-modal::-webkit-scrollbar {
  display: none;
}
.campany .header .coupon-modal .coupon-item {
  margin-top: 16px;
}
.campany .header .coupon-modal .coupon-item:first-child {
  margin-top: 0;
}
.campany .header .last-coupon-modal {
  top: auto;
  bottom: 42px;
}
.campany .header .ivu-checkbox-wrapper {
  font-size: 12px;
  line-height: 20px;
}
.campany .header .left-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.campany .header .left-info ::v-deep .ivu-checkbox-wrapper {
  align-items: center;
}
.campany .header .left-info ::v-deep .ivu-tooltip {
  display: flex;
  align-items: center;
}
.campany .header .left-info .store-name {
  width: 200px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
  margin-right: 32px;
}
.campany .header .left-info .store-name:hover {
  color: var(--brand-7, #CE7720);
}
.campany .header .left-info .header-coupon-box {
  display: -webkit-inline-box;
  max-width: 320px;
  overflow: hidden;
}
.campany .header .left-info .header-coupon-box .header-coupon-item {
  display: flex;
  padding: 1px 8px;
  align-items: center;
  color: var(--error-7, #CB272D);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  background-color: #FEECEC;
  margin-right: 4px;
  border-radius: 4px;
}
.campany .header .left-info .header-coupon-box .header-coupon-item.display-none {
  display: none;
}
.campany .header .left-info .header-coupon-more {
  margin-left: 8px;
}
.campany .header .left-info .header-coupon-more span {
  color: rgba(0, 0, 0, 0.9);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}
.campany .header .left-info .header-coupon-more .ivu-icon {
  transform: rotate(90deg);
}
.campany .header .right-info {
  display: flex;
  align-items: center;
}
.campany .header .right-info .get-coupon {
  width: 66px;
  height: 22px;
  padding: 0 8px;
  box-sizing: border-box;
  background-image: url("~@/assets/images/car/coupon_bg.png");
  background-size: 100% 100%;
  margin-right: 32px;
}
.campany .header .right-info .get-coupon .wrap {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 1px 0 5px;
  box-sizing: border-box;
  border-left: 0.5px dashed #CB272D;
  border-right: 0.5px dashed #CB272D;
}
.campany .header .right-info .get-coupon .txt {
  margin-right: 2px;
  color: var(--error-7, #CB272D);
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
}
.campany .header .right-info .get-coupon .arrow {
  width: 16px;
  height: 16px;
}
.campany .header .right-info span {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 20px;
}
.campany .header .right-info .tips-text {
  margin-right: 32px;
}
.campany .header .right-info .open-close {
  color: rgba(0, 0, 0, 0.6);
}
.campany .header .right-info .open-close:hover {
  color: #3853E2;
}
.campany .header .right-info .cursor {
  cursor: pointer;
}
.campany .header .right-info .tips-wrap {
  display: flex;
  align-items: center;
  margin-right: 6px;
}
.campany .header .right-info .tips-wrap .tips-text {
  margin-right: 0;
}
.campany .header .right-info .tips-wrap .poor-amount {
  margin-left: 8px;
  margin-right: 8px;
}
.campany .header .right-info .tips-wrap .poor-amount .money {
  color: var(--error-6, #F53F3F);
}
.campany .goods {
  background-color: #fff;
}
.campany .goods .item {
  padding: 0px 16px;
  box-sizing: border-box;
  border-bottom: 1px dashed #eeeeee;
}
.campany .goods .item .rule-info {
  display: flex;
  align-items: flex-start;
  padding-left: 38px;
  padding-top: 12px;
}
.campany .goods .item .rule-info .tag {
  margin-top: 4px;
  padding: 1px 4px 0;
  box-sizing: border-box;
  border: 0.5px solid var(--error-6, #F53F3F);
  color: #F53F3F;
  font-weight: 500;
  background: #FEF5F5;
  border-radius: 4px;
  font-size: 8.5px;
  line-height: 16px;
  margin-right: 8px;
}
.campany .goods .item .rule-info .text {
  max-width: 1020px;
  font-size: 14px;
  line-height: 26px;
  color: rgba(0, 0, 0, 0.9);
  margin-right: 8px;
}
.campany .goods .item .rule-info .collect-money {
  margin-top: 2px;
  font-size: 14px;
  line-height: 22px;
  color: #d4380d;
  cursor: pointer;
}
.campany .goods .item .good-content .good-content-detail,
.campany .goods .item .replacement .good-content-detail {
  display: flex;
  align-items: center;
  padding: 21px 0 12px 0px;
}
.campany .goods .item .good-content .good-content-detail .good-content-box,
.campany .goods .item .replacement .good-content-detail .good-content-box {
  flex: 1;
  display: flex;
  justify-content: space-between;
  padding-right: 16px;
  align-items: flex-start;
}
.campany .goods .item .good-content .good-content-detail .good-content-box .good-img-text,
.campany .goods .item .replacement .good-content-detail .good-content-box .good-img-text {
  width: 384px;
  display: flex;
  justify-content: flex-start;
}
.campany .goods .item .good-content .good-content-detail .good-content-box .good-img-text .good-img-box,
.campany .goods .item .replacement .good-content-detail .good-content-box .good-img-text .good-img-box {
  width: 80px;
  height: 80px;
  box-sizing: border-box;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  position: relative;
}
.campany .goods .item .good-content .good-content-detail .good-content-box .good-img-text .good-img-box .img-box,
.campany .goods .item .replacement .good-content-detail .good-content-box .good-img-text .good-img-box .img-box {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.campany .goods .item .good-content .good-content-detail .good-content-box .good-img-text .good-img-box .exchange-tip,
.campany .goods .item .replacement .good-content-detail .good-content-box .good-img-text .good-img-box .exchange-tip {
  position: absolute;
  top: 4px;
  left: 4px;
  padding: 0 4px;
  box-sizing: border-box;
  color: var(--purple-6, #722ED1);
  text-align: center;
  font-size: 8.5px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  background: #F8F5FD;
  border: 0.5px solid #722ED1;
  border-radius: 4.57px;
}
.campany .goods .item .good-content .good-content-detail .good-content-box .good-img-text .good-img-box .nearterm-tip,
.campany .goods .item .replacement .good-content-detail .good-content-box .good-img-text .good-img-box .nearterm-tip {
  position: absolute;
  top: 4px;
  left: 4px;
  padding: 1px 6px 0;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 8.5px;
  line-height: 16px;
  color: #F53F3F;
  background: #FEF5F5;
  border: 0.5px solid #F53F3F;
  border-radius: 4px;
}
.campany .goods .item .good-content .good-content-detail .good-content-box .good-img-text .good-img-box img,
.campany .goods .item .replacement .good-content-detail .good-content-box .good-img-text .good-img-box img {
  width: 64px;
  height: 64px;
}
.campany .goods .item .good-content .good-content-detail .good-content-box .good-img-text .good-img-box .img-box,
.campany .goods .item .replacement .good-content-detail .good-content-box .good-img-text .good-img-box .img-box {
  position: relative;
  cursor: pointer;
}
.campany .goods .item .good-content .good-content-detail .good-content-box .good-img-text .good-img-box .img-box .prescription-img,
.campany .goods .item .replacement .good-content-detail .good-content-box .good-img-text .good-img-box .img-box .prescription-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 64px;
  height: 64px;
}
.campany .goods .item .good-content .good-content-detail .good-content-box .good-img-text .text-info,
.campany .goods .item .replacement .good-content-detail .good-content-box .good-img-text .text-info {
  margin-left: 16px;
  width: 280px;
  display: flex;
  flex-direction: column;
}
.campany .goods .item .good-content .good-content-detail .good-content-box .good-img-text .text-info ::v-deep .ivu-tooltip-rel,
.campany .goods .item .replacement .good-content-detail .good-content-box .good-img-text .text-info ::v-deep .ivu-tooltip-rel {
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}
.campany .goods .item .good-content .good-content-detail .good-content-box .good-img-text .text-info .text-productName-wrap,
.campany .goods .item .replacement .good-content-detail .good-content-box .good-img-text .text-info .text-productName-wrap {
  width: 100%;
}
.campany .goods .item .good-content .good-content-detail .good-content-box .good-img-text .text-info .date,
.campany .goods .item .replacement .good-content-detail .good-content-box .good-img-text .text-info .date {
  font-size: 12px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.6);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.campany .goods .item .good-content .good-content-detail .good-content-box .good-img-text .text-info .date.active,
.campany .goods .item .replacement .good-content-detail .good-content-box .good-img-text .text-info .date.active {
  color: #e67d00;
}
.campany .goods .item .good-content .good-content-detail .good-content-box .good-img-text .text-info .date .active,
.campany .goods .item .replacement .good-content-detail .good-content-box .good-img-text .text-info .date .active {
  color: #e67d00;
}
.campany .goods .item .good-content .good-content-detail .good-content-box .good-img-text .text-info .date .alert-circle,
.campany .goods .item .replacement .good-content-detail .good-content-box .good-img-text .text-info .date .alert-circle {
  width: 16px;
  height: 16px;
  margin-top: -2px;
}
.campany .goods .item .good-content .good-content-detail .good-content-box .good-img-text .text-info .good-name,
.campany .goods .item .replacement .good-content-detail .good-content-box .good-img-text .text-info .good-name {
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  color: rgba(0, 0, 0, 0.9);
  font-weight: 500;
}
.campany .goods .item .good-content .good-content-detail .good-content-box .good-img-text .text-info .good-name:hover,
.campany .goods .item .replacement .good-content-detail .good-content-box .good-img-text .text-info .good-name:hover {
  color: #CE7720;
}
.campany .goods .item .good-content .good-content-detail .good-content-box .exchange-good-text,
.campany .goods .item .replacement .good-content-detail .good-content-box .exchange-good-text {
  width: 336px;
}
.campany .goods .item .good-content .good-content-detail .good-content-box .exchange-good-text .good-img-box,
.campany .goods .item .replacement .good-content-detail .good-content-box .exchange-good-text .good-img-box {
  width: 74px;
  height: 74px;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 3.2px;
  border: 1px solid var(--gray-2, #EEE);
  background: var(--font-white-0, #FFF);
  cursor: pointer;
}
.campany .goods .item .good-content .good-content-detail .good-content-box .exchange-good-text .good-img-box img,
.campany .goods .item .replacement .good-content-detail .good-content-box .exchange-good-text .good-img-box img {
  width: 56px;
  height: 56px;
}
.campany .goods .item .good-content .good-content-detail .good-content-box .exchange-good-text .good-img-box .img-box,
.campany .goods .item .replacement .good-content-detail .good-content-box .exchange-good-text .good-img-box .img-box {
  position: relative;
  cursor: pointer;
}
.campany .goods .item .good-content .good-content-detail .good-content-box .exchange-good-text .good-img-box .img-box .prescription-img,
.campany .goods .item .replacement .good-content-detail .good-content-box .exchange-good-text .good-img-box .img-box .prescription-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 56px;
  height: 56px;
}
.campany .goods .item .good-content .good-content-detail .good-content-box .goods-pack,
.campany .goods .item .replacement .good-content-detail .good-content-box .goods-pack {
  width: 100px;
  font-size: 12px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.9);
  text-align: center;
  display: flex;
  flex-direction: column;
}
.campany .goods .item .good-content .good-content-detail .good-content-box .price,
.campany .goods .item .replacement .good-content-detail .good-content-box .price {
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}
.campany .goods .item .good-content .good-content-detail .good-content-box .price .activty-img,
.campany .goods .item .replacement .good-content-detail .good-content-box .price .activty-img {
  width: 100%;
  text-align: right;
}
.campany .goods .item .good-content .good-content-detail .good-content-box .price .activty-img img,
.campany .goods .item .replacement .good-content-detail .good-content-box .price .activty-img img {
  width: 38px;
  height: 21px;
}
.campany .goods .item .good-content .good-content-detail .good-content-box .price .show-price,
.campany .goods .item .replacement .good-content-detail .good-content-box .price .show-price {
  font-size: 12px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.9);
}
.campany .goods .item .good-content .good-content-detail .good-content-box .price .lineation-price,
.campany .goods .item .replacement .good-content-detail .good-content-box .price .lineation-price {
  font-size: 12px;
  line-height: 20px;
  text-decoration-line: line-through;
  color: rgba(0, 0, 0, 0.4);
  margin-top: 4px;
}
.campany .goods .item .good-content .good-content-detail .good-content-box .price .estimate-price,
.campany .goods .item .replacement .good-content-detail .good-content-box .price .estimate-price {
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: #CB272D;
  margin-top: 4px;
}
.campany .goods .item .good-content .good-content-detail .good-content-box .price .estimate-price .received-price-label,
.campany .goods .item .replacement .good-content-detail .good-content-box .price .estimate-price .received-price-label {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.campany .goods .item .good-content .good-content-detail .good-content-box .price ::v-deep .ivu-poptip .ivu-poptip-rel,
.campany .goods .item .replacement .good-content-detail .good-content-box .price ::v-deep .ivu-poptip .ivu-poptip-rel {
  cursor: pointer;
  font-size: 12px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.6);
}
.campany .goods .item .good-content .good-content-detail .good-content-box .price ::v-deep .ivu-poptip .ivu-poptip-rel:hover,
.campany .goods .item .replacement .good-content-detail .good-content-box .price ::v-deep .ivu-poptip .ivu-poptip-rel:hover {
  color: #3853E2;
}
.campany .goods .item .good-content .good-content-detail .good-content-box .price .seat,
.campany .goods .item .replacement .good-content-detail .good-content-box .price .seat {
  height: 24px;
}
.campany .goods .item .good-content .good-content-detail .good-content-box .stepper-box,
.campany .goods .item .replacement .good-content-detail .good-content-box .stepper-box {
  width: 112px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.campany .goods .item .good-content .good-content-detail .good-content-box .stepper-box .input-wrap,
.campany .goods .item .replacement .good-content-detail .good-content-box .stepper-box .input-wrap {
  position: relative;
}
.campany .goods .item .good-content .good-content-detail .good-content-box .stepper-box ::v-deep .ivu-input-number .ivu-input-number-input-wrap > .ivu-input-number-input,
.campany .goods .item .replacement .good-content-detail .good-content-box .stepper-box ::v-deep .ivu-input-number .ivu-input-number-input-wrap > .ivu-input-number-input {
  padding: 0;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
}
.campany .goods .item .good-content .good-content-detail .good-content-box .stepper-box span,
.campany .goods .item .replacement .good-content-detail .good-content-box .stepper-box span {
  display: inline-block;
  height: 20px;
  font-size: 12px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.9);
}
.campany .goods .item .good-content .good-content-detail .good-content-box .stepper-box .num,
.campany .goods .item .replacement .good-content-detail .good-content-box .stepper-box .num {
  color: var(--error-7, #CB272D);
  font-size: 12px;
  line-height: 20px;
  margin-top: 4px;
}
.campany .goods .item .good-content .good-content-detail .good-content-box .amount,
.campany .goods .item .replacement .good-content-detail .good-content-box .amount {
  width: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}
.campany .goods .item .good-content .good-content-detail .good-content-box .amount span,
.campany .goods .item .replacement .good-content-detail .good-content-box .amount span {
  display: inline-block;
  height: 24px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
}
.campany .goods .item .good-content .good-content-detail .good-content-box .handle,
.campany .goods .item .replacement .good-content-detail .good-content-box .handle {
  width: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}
.campany .goods .item .good-content .good-content-detail .good-content-box .handle ::v-deep .ivu-poptip .ivu-poptip-rel,
.campany .goods .item .replacement .good-content-detail .good-content-box .handle ::v-deep .ivu-poptip .ivu-poptip-rel {
  font-size: 12px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.9);
  cursor: pointer;
}
.campany .goods .item .good-content .good-content-detail .good-content-box .handle ::v-deep .ivu-poptip .ivu-poptip-rel:hover,
.campany .goods .item .replacement .good-content-detail .good-content-box .handle ::v-deep .ivu-poptip .ivu-poptip-rel:hover {
  color: #3853E2;
}
.campany .goods .item .good-content .good-content-detail .good-content-box .handle ::v-deep .ivu-poptip .ivu-poptip-popper > .ivu-poptip-content > .ivu-poptip-inner > .ivu-poptip-body > .ivu-poptip-body-content,
.campany .goods .item .replacement .good-content-detail .good-content-box .handle ::v-deep .ivu-poptip .ivu-poptip-popper > .ivu-poptip-content > .ivu-poptip-inner > .ivu-poptip-body > .ivu-poptip-body-content {
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.9);
}
.campany .goods .item .good-content .good-content-detail .good-content-box .handle ::v-deep .ivu-poptip .ivu-poptip-popper > .ivu-poptip-content > .ivu-poptip-inner > .ivu-poptip-body > .ivu-poptip-body-content .del-good,
.campany .goods .item .replacement .good-content-detail .good-content-box .handle ::v-deep .ivu-poptip .ivu-poptip-popper > .ivu-poptip-content > .ivu-poptip-inner > .ivu-poptip-body > .ivu-poptip-body-content .del-good {
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
}
.campany .goods .item .good-content .good-content-detail .good-content-box .handle ::v-deep .ivu-poptip .ivu-poptip-popper > .ivu-poptip-content > .ivu-poptip-inner > .ivu-poptip-body > .ivu-poptip-body-content .del-good:hover,
.campany .goods .item .replacement .good-content-detail .good-content-box .handle ::v-deep .ivu-poptip .ivu-poptip-popper > .ivu-poptip-content > .ivu-poptip-inner > .ivu-poptip-body > .ivu-poptip-body-content .del-good:hover {
  color: #3853e2;
}
.campany .goods .item .good-content .good-content-detail .good-content-box .handle span,
.campany .goods .item .replacement .good-content-detail .good-content-box .handle span {
  display: inline-block;
  height: 24px;
  margin-top: 4px;
}
.campany .goods .item .good-content .good-content-detail .good-content-box .handle span:first-child,
.campany .goods .item .replacement .good-content-detail .good-content-box .handle span:first-child {
  cursor: pointer;
}
.campany .goods .item .good-content .good-content-detail .good-content-box .handle span:first-child :hover,
.campany .goods .item .replacement .good-content-detail .good-content-box .handle span:first-child :hover {
  color: #d4380d;
}
.campany .goods .item .good-content .good-content-detail .gift-content-box,
.campany .goods .item .replacement .good-content-detail .gift-content-box {
  padding-left: 64px;
  justify-content: flex-start;
}
.campany .goods .item .good-content .good-content-detail .gift-content-box .good-img-text,
.campany .goods .item .replacement .good-content-detail .gift-content-box .good-img-text {
  justify-content: flex-start;
  align-items: flex-start;
}
.campany .goods .item .good-content .good-content-detail .gift-content-box .good-img-text .text-info,
.campany .goods .item .replacement .good-content-detail .gift-content-box .good-img-text .text-info {
  margin-left: 16px;
}
.campany .goods .item .good-content .good-content-detail .gift-content-box .stepper-box,
.campany .goods .item .replacement .good-content-detail .gift-content-box .stepper-box {
  margin-left: 180px;
}
.campany .goods .item .good-content .about-end .act_time ::v-deep .time_num li,
.campany .goods .item .replacement .about-end .act_time ::v-deep .time_num li {
  border: none;
  height: auto;
}
.campany .goods .item .replacement {
  padding-left: 32px;
  padding-bottom: 14px;
}
.campany .goods .item .replacement .good-content-detail {
  background-color: #f1f5fe;
  padding-left: 16px;
}
.campany .goods .item .replacement .good-content-detail .good-content-box > .price span {
  margin-top: 4px;
}
.campany .goods .item .replacement .good-content-detail .good-content-box > .price span:first-child {
  margin-top: 0px;
}
.campany .goods .item .good-content-make {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 12px 0px;
}
.campany .goods .item .good-content-make .good-make-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 12px 16px 12px 16px;
  box-sizing: border-box;
  background-color: #f1f5fe;
  border-radius: 4px;
}
.campany .goods .item .good-content-make .good-make-box .goods-make {
  width: 366px;
  display: flex;
}
.campany .goods .item .good-content-make .good-make-box .goods-make .goods-item {
  display: flex;
  flex-direction: column;
  margin-right: 8px;
}
.campany .goods .item .good-content-make .good-make-box .goods-make .goods-item .new-price {
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.9);
  margin-top: 4px;
}
.campany .goods .item .good-content-make .good-make-box .goods-make .goods-item .new-price.active {
  color: #cb272d;
}
.campany .goods .item .good-content-make .good-make-box .goods-make .goods-item .old-price {
  font-size: 12px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.4);
  text-decoration-line: line-through;
}
.campany .goods .item .good-content-make .good-make-box .goods-make .goods-item .actual-price {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: #cb272d;
}
.campany .goods .item .good-content-make .good-make-box .goods-make .goods-item .actual-price .actual-price-label {
  color: #cb272d;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.campany .goods .item .good-content-make .good-make-box .goods-make .goods-item .goods-item-img {
  width: 82px;
  height: 82px;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid var(--gray-2, #EEE);
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.campany .goods .item .good-content-make .good-make-box .goods-make .goods-item .goods-item-img img {
  width: 64px;
  height: 64px;
}
.campany .goods .item .good-content-make .good-make-box .goods-make .goods-item .goods-item-img .goods-img-box {
  position: relative;
}
.campany .goods .item .good-content-make .good-make-box .goods-make .goods-item .goods-item-img .goods-img-box .prescription-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 64px;
  height: 64px;
}
.campany .goods .item .good-content-make .good-make-box .goods-make .goods-item .goods-item-img .img-box {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.campany .goods .item .good-content-make .good-make-box .goods-make .goods-item .goods-item-img .img-box:hover {
  background: #E7E7E7;
  border-radius: 4px;
}
.campany .goods .item .good-content-make .good-make-box .goods-make .goods-item .goods-item-img .more-make {
  width: 12px;
  height: 12px;
}
.campany .goods .item .good-content-make .good-make-box .goods-make .goods-item .goods-item-img .num {
  position: absolute;
  bottom: 1px;
  right: 1px;
  padding: 0px 4px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 4px;
  font-size: 10px;
  line-height: 18px;
  color: #fff;
}
.campany .goods .item .good-content-make .good-make-box .goods-make .more-img .goods-item-img {
  border: none;
}
.campany .goods .item .good-content-make .good-make-box .price-make {
  width: 128px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}
.campany .goods .item .good-content-make .good-make-box .price-make span {
  display: inline-block;
  height: 24px;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.9);
  margin-top: 4px;
}
.campany .goods .item .good-content-make .good-make-box .step-box-make {
  width: 112px;
  padding-top: 4px;
}
.campany .goods .item .good-content-make .good-make-box .step-box-make .input-wrap {
  position: relative;
}
.campany .goods .item .good-content-make .good-make-box .step-box-make ::v-deep .ivu-input-number .ivu-input-number-input-wrap > .ivu-input-number-input {
  font-size: 12px;
  line-height: 24px;
  text-align: center;
}
.campany .goods .item .good-content-make .good-make-box .step-box-make span {
  display: inline-block;
  height: 24px;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.9);
  margin-top: 4px;
}
.campany .goods .item .good-content-make .good-make-box .amount-make {
  width: 60px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.campany .goods .item .good-content-make .good-make-box .amount-make .new-price {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.9);
}
.campany .goods .item .good-content-make .good-make-box .amount-make .old-price {
  font-size: 12px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.6);
  text-decoration-line: line-through;
  margin-top: 4px;
}
.campany .goods .item .good-content-make .good-make-box .amount-make span {
  display: inline-block;
  height: 20px;
  font-size: 12px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.9);
}
.campany .goods .item .good-content-make .good-make-box .handle-make {
  width: 80px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
}
.campany .goods .item .good-content-make .good-make-box .handle-make ::v-deep .ivu-poptip .ivu-poptip-rel {
  font-size: 12px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.9);
  cursor: pointer;
}
.campany .goods .item .good-content-make .good-make-box .handle-make ::v-deep .ivu-poptip .ivu-poptip-rel:hover {
  color: #d4380d;
}
.campany .goods .item .good-content-make .good-make-box .handle-make ::v-deep .ivu-poptip .ivu-poptip-popper > .ivu-poptip-content > .ivu-poptip-inner > .ivu-poptip-body > .ivu-poptip-body-content {
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.9);
}
.campany .goods .item .good-content-make .good-make-box .handle-make ::v-deep .ivu-poptip .ivu-poptip-popper > .ivu-poptip-content > .ivu-poptip-inner > .ivu-poptip-body > .ivu-poptip-body-content .del-good {
  color: #3853e2;
  cursor: pointer;
}
.campany .goods .item .good-content-make .good-make-box .handle-make span {
  display: inline-block;
  height: 24px;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.9);
  margin-top: 4px;
}
.campany .goods .item .good-content-gift {
  display: flex;
  flex-wrap: wrap;
  background-color: #f8faff;
}
.campany .goods .item .good-content-gift .good-content-container {
  width: 100%;
  background-color: #ffffff;
}
.campany .goods .item .good-content-gift .good-content-detail {
  display: flex;
  align-items: center;
  padding: 21px 0 12px 0px;
}
.campany .goods .item .good-content-gift .good-content-detail .good-content-box {
  flex: 1;
  display: flex;
  justify-content: space-between;
  padding-right: 16px;
  align-items: flex-start;
}
.campany .goods .item .good-content-gift .good-content-detail .good-content-box .good-img-text {
  width: 384px;
  display: flex;
  justify-content: flex-start;
}
.campany .goods .item .good-content-gift .good-content-detail .good-content-box .good-img-text .good-img-box {
  width: 80px;
  height: 80px;
  box-sizing: border-box;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  position: relative;
  background-color: #fff;
}
.campany .goods .item .good-content-gift .good-content-detail .good-content-box .good-img-text .good-img-box .img-box {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.campany .goods .item .good-content-gift .good-content-detail .good-content-box .good-img-text .good-img-box .exchange-tip {
  position: absolute;
  top: 2px;
  left: 2px;
  padding: 0 4px;
  box-sizing: border-box;
  color: var(--purple-6, #722ED1);
  text-align: center;
  font-size: 8.5px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  background: #F8F5FD;
  border: 0.5px solid #722ED1;
  border-radius: 4px;
}
.campany .goods .item .good-content-gift .good-content-detail .good-content-box .good-img-text .good-img-box .nearterm-tip {
  position: absolute;
  top: 4px;
  left: 4px;
  padding: 1px 6px 0;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 8.5px;
  line-height: 16px;
  color: #F53F3F;
  background: #FEF5F5;
  border: 0.5px solid #F53F3F;
  border-radius: 4px;
}
.campany .goods .item .good-content-gift .good-content-detail .good-content-box .good-img-text .good-img-box img {
  width: 64px;
  height: 64px;
}
.campany .goods .item .good-content-gift .good-content-detail .good-content-box .good-img-text .good-img-box .img-box {
  cursor: pointer;
  position: relative;
}
.campany .goods .item .good-content-gift .good-content-detail .good-content-box .good-img-text .good-img-box .img-box .prescription-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 64px;
  height: 64px;
}
.campany .goods .item .good-content-gift .good-content-detail .good-content-box .good-img-text .text-info {
  margin-left: 16px;
  width: 280px;
  display: flex;
  flex-direction: column;
}
.campany .goods .item .good-content-gift .good-content-detail .good-content-box .good-img-text .text-info ::v-deep .ivu-tooltip-rel {
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}
.campany .goods .item .good-content-gift .good-content-detail .good-content-box .good-img-text .text-info .text-productName-wrap {
  width: 100%;
}
.campany .goods .item .good-content-gift .good-content-detail .good-content-box .good-img-text .text-info .date {
  font-size: 12px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.6);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.campany .goods .item .good-content-gift .good-content-detail .good-content-box .good-img-text .text-info .date.active {
  color: #e67d00;
}
.campany .goods .item .good-content-gift .good-content-detail .good-content-box .good-img-text .text-info .date .active {
  color: #e67d00;
}
.campany .goods .item .good-content-gift .good-content-detail .good-content-box .good-img-text .text-info .date .alert-circle {
  width: 16px;
  height: 16px;
  margin-top: -2px;
}
.campany .goods .item .good-content-gift .good-content-detail .good-content-box .good-img-text .text-info .good-name {
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  color: rgba(0, 0, 0, 0.9);
  font-weight: 500;
}
.campany .goods .item .good-content-gift .good-content-detail .good-content-box .good-img-text .text-info .good-name:hover {
  color: #CE7720;
}
.campany .goods .item .good-content-gift .good-content-detail .good-content-box .exchange-good-text {
  width: 336px;
}
.campany .goods .item .good-content-gift .good-content-detail .good-content-box .exchange-good-text .good-img-box {
  width: 74px;
  height: 74px;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 3.2px;
  border: 1px solid var(--gray-2, #EEE);
  background: var(--font-white-0, #FFF);
  cursor: pointer;
}
.campany .goods .item .good-content-gift .good-content-detail .good-content-box .exchange-good-text .good-img-box img {
  width: 56px;
  height: 56px;
}
.campany .goods .item .good-content-gift .good-content-detail .good-content-box .exchange-good-text .good-img-box .img-box {
  cursor: pointer;
  position: relative;
}
.campany .goods .item .good-content-gift .good-content-detail .good-content-box .exchange-good-text .good-img-box .img-box .prescription-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 56px;
  height: 56px;
}
.campany .goods .item .good-content-gift .good-content-detail .good-content-box .goods-pack {
  width: 100px;
  font-size: 12px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.9);
  text-align: center;
  display: flex;
  flex-direction: column;
}
.campany .goods .item .good-content-gift .good-content-detail .good-content-box .price {
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}
.campany .goods .item .good-content-gift .good-content-detail .good-content-box .price .activty-img {
  width: 100%;
  text-align: right;
}
.campany .goods .item .good-content-gift .good-content-detail .good-content-box .price .activty-img img {
  width: 38px;
  height: 21px;
}
.campany .goods .item .good-content-gift .good-content-detail .good-content-box .price .show-price {
  font-size: 12px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.9);
}
.campany .goods .item .good-content-gift .good-content-detail .good-content-box .price .lineation-price {
  font-size: 12px;
  line-height: 20px;
  text-decoration-line: line-through;
  color: rgba(0, 0, 0, 0.4);
  margin-top: 4px;
}
.campany .goods .item .good-content-gift .good-content-detail .good-content-box .price .estimate-price {
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: #CB272D;
  margin-top: 4px;
}
.campany .goods .item .good-content-gift .good-content-detail .good-content-box .price .estimate-price .received-price-label {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.campany .goods .item .good-content-gift .good-content-detail .good-content-box .price ::v-deep .ivu-poptip .ivu-poptip-rel {
  cursor: pointer;
  font-size: 12px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.6);
}
.campany .goods .item .good-content-gift .good-content-detail .good-content-box .price ::v-deep .ivu-poptip .ivu-poptip-rel:hover {
  color: #3853E2;
}
.campany .goods .item .good-content-gift .good-content-detail .good-content-box .price .seat {
  height: 24px;
}
.campany .goods .item .good-content-gift .good-content-detail .good-content-box .stepper-box {
  width: 112px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.campany .goods .item .good-content-gift .good-content-detail .good-content-box .stepper-box .input-wrap {
  position: relative;
}
.campany .goods .item .good-content-gift .good-content-detail .good-content-box .stepper-box ::v-deep .ivu-input-number .ivu-input-number-input-wrap > .ivu-input-number-input {
  padding: 0;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
}
.campany .goods .item .good-content-gift .good-content-detail .good-content-box .stepper-box span {
  display: inline-block;
  height: 20px;
  font-size: 12px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.9);
}
.campany .goods .item .good-content-gift .good-content-detail .good-content-box .stepper-box .num {
  color: var(--error-7, #CB272D);
  font-size: 12px;
  line-height: 20px;
  margin-top: 4px;
}
.campany .goods .item .good-content-gift .good-content-detail .good-content-box .amount {
  width: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}
.campany .goods .item .good-content-gift .good-content-detail .good-content-box .amount span {
  display: inline-block;
  height: 24px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
}
.campany .goods .item .good-content-gift .good-content-detail .good-content-box .handle {
  width: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}
.campany .goods .item .good-content-gift .good-content-detail .good-content-box .handle ::v-deep .ivu-poptip .ivu-poptip-rel {
  font-size: 12px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.9);
  cursor: pointer;
}
.campany .goods .item .good-content-gift .good-content-detail .good-content-box .handle ::v-deep .ivu-poptip .ivu-poptip-rel:hover {
  color: #3853E2;
}
.campany .goods .item .good-content-gift .good-content-detail .good-content-box .handle ::v-deep .ivu-poptip .ivu-poptip-popper > .ivu-poptip-content > .ivu-poptip-inner > .ivu-poptip-body > .ivu-poptip-body-content {
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.9);
}
.campany .goods .item .good-content-gift .good-content-detail .good-content-box .handle ::v-deep .ivu-poptip .ivu-poptip-popper > .ivu-poptip-content > .ivu-poptip-inner > .ivu-poptip-body > .ivu-poptip-body-content .del-good {
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
}
.campany .goods .item .good-content-gift .good-content-detail .good-content-box .handle ::v-deep .ivu-poptip .ivu-poptip-popper > .ivu-poptip-content > .ivu-poptip-inner > .ivu-poptip-body > .ivu-poptip-body-content .del-good:hover {
  color: #3853e2;
}
.campany .goods .item .good-content-gift .good-content-detail .good-content-box .handle span {
  display: inline-block;
  height: 24px;
  margin-top: 4px;
}
.campany .goods .item .good-content-gift .good-content-detail .good-content-box .handle span:first-child {
  cursor: pointer;
}
.campany .goods .item .good-content-gift .good-content-detail .good-content-box .handle span:first-child :hover {
  color: #d4380d;
}
.campany .goods .item .good-content-gift .good-content-detail .gift-content-box {
  padding-left: 8px;
  padding-right: 0;
  justify-content: flex-start;
}
.campany .goods .item .good-content-gift .good-content-detail .gift-content-box .good-img-text {
  justify-content: flex-start;
  align-items: flex-start;
}
.campany .goods .item .good-content-gift .good-content-detail .gift-content-box .good-img-text .text-info {
  margin-left: 16px;
}
.campany .goods .item .good-content-gift .good-content-detail .gift-content-box .stepper-box {
  margin-left: 180px;
}
.campany .goods .item .good-content-gift .good-content-detail .gift-content-box .gift-good-text {
  width: 80px;
  flex-wrap: wrap;
}
.campany .goods .item .good-content-gift .container-gift {
  margin-left: 0;
}
.campany .goods .item .good-content-gift .container-gift:first-child {
  margin-left: 40px;
}
.campany .goods .item .good-content-gift .container-gift .good-content-detail {
  padding: 12px 0;
}
.campany .goods .item .good-content-gift .container-gift .gift-good-num {
  width: 18px;
  height: 18px;
  padding: 0px 4px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.6);
  font-size: 10px;
  font-weight: 400;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  position: absolute;
  right: 0;
  bottom: 0;
}
.campany .goods .item .good-content-gift .container-gift .gift-good-name {
  height: 22px;
  margin-top: 4px;
  overflow: hidden;
}
.campany .subtotal {
  width: 100%;
  background-color: #fff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 12px 16px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
}
.campany .subtotal .sub-num {
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.6);
  margin-right: 24px;
}
.campany .subtotal .sub-num span {
  color: #ce7720;
  margin: 0 4px;
}
.campany .subtotal .good-amount {
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.6);
  margin-right: 24px;
}
.campany .subtotal .good-amount span {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.9);
  margin-left: 8px;
}
.campany .subtotal .sub-amount {
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.9);
}
.campany .subtotal .sub-amount span {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #cb272d;
  margin-left: 8px;
}
.lose-good {
  margin-top: 16px;
  width: 100%;
}
.lose-good .header {
  height: auto !important;
  padding: 8px 28px 8px 50px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fafafa;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-size: 14px;
}
.lose-good .header .num {
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.9);
}
.lose-good .header .clear {
  font-size: 12px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
}
.lose-good .header .clear:hover {
  color: #3853E2;
}
.lose-good .goods {
  padding: 12px 32px 12px 50px;
  box-sizing: border-box;
  background-color: #fff;
  border-bottom: 1px dashed #eeeeee;
}
.lose-good .goods:last-child {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.lose-good .goods .good-content-box {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.lose-good .goods .good-content-box .good-img-text {
  width: 396px;
  display: flex;
  justify-content: space-between;
}
.lose-good .goods .good-content-box .good-img-text .good-img-box {
  width: 82px;
  height: 82px;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid var(--gray-2, #EEE);
  border-radius: 4px;
  position: relative;
  cursor: pointer;
}
.lose-good .goods .good-content-box .good-img-text .good-img-box .lose-cause {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 62px;
  height: 62px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  font-size: 14px;
  border-radius: 50%;
  padding: 0 10px;
}
.lose-good .goods .good-content-box .good-img-text .good-img-box img {
  width: 64px;
  height: 64px;
}
.lose-good .goods .good-content-box .good-img-text .good-img-box .img-box {
  position: relative;
  cursor: pointer;
  opacity: 0.5;
}
.lose-good .goods .good-content-box .good-img-text .good-img-box .img-box .prescription-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 64px;
  height: 64px;
}
.lose-good .goods .good-content-box .good-img-text .text-info {
  width: 280px;
  display: flex;
  flex-direction: column;
}
.lose-good .goods .good-content-box .good-img-text .text-info ::v-deep .ivu-tooltip {
  width: 100%;
  height: 22px;
}
.lose-good .goods .good-content-box .good-img-text .text-info ::v-deep .ivu-tooltip .ivu-tooltip-rel {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.4);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}
.lose-good .goods .good-content-box .good-img-text .text-info .date {
  font-size: 12px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.4);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.lose-good .goods .good-content-box .good-img-text .text-info .lose-state {
  font-size: 12px;
  line-height: 20px;
  color: #CB272D;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.lose-good .goods .good-content-box .handle {
  width: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  margin-top: 10px;
}
.lose-good .goods .good-content-box .handle ::v-deep .ivu-poptip .ivu-poptip-rel {
  font-size: 12px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.9);
  cursor: pointer;
}
.lose-good .goods .good-content-box .handle ::v-deep .ivu-poptip .ivu-poptip-rel:hover {
  color: #d4380d;
}
.lose-good .goods .good-content-box .handle ::v-deep .ivu-poptip .ivu-poptip-popper > .ivu-poptip-content > .ivu-poptip-inner > .ivu-poptip-body > .ivu-poptip-body-content {
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.9);
}
.lose-good .goods .good-content-box .handle ::v-deep .ivu-poptip .ivu-poptip-popper > .ivu-poptip-content > .ivu-poptip-inner > .ivu-poptip-body > .ivu-poptip-body-content .del-good {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.6);
}
.lose-good .goods .good-content-box .handle ::v-deep .ivu-poptip .ivu-poptip-popper > .ivu-poptip-content > .ivu-poptip-inner > .ivu-poptip-body > .ivu-poptip-body-content .del-good:hover {
  color: #3853E2;
}
.lose-good .goods .good-content-box .handle span {
  display: inline-block;
  height: 24px;
  margin-top: 4px;
}
.lose-good .goods .good-content-box .handle span:first-child {
  cursor: pointer;
}
.lose-good .goods .good-content-box .handle span:first-child :hover {
  color: #d4380d;
}
.good-tag {
  border: 1px solid red;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  position: relative;
}
.tag-item {
  display: inline-block;
  font-size: 12px;
  color: #F53F3F;
  border-radius: 4px;
  padding: 0 3.5px;
  border: 0.5px solid #F53F3F;
  margin-right: 4px;
  line-height: 18px;
  height: 18px;
  box-sizing: border-box;
}
::v-deep .ivu-poptip .ivu-poptip-body {
  width: 406px;
  padding: 16px;
  max-height: 392px;
  overflow-y: scroll;
  box-sizing: border-box;
}
::v-deep .ivu-poptip .ivu-poptip-body::-webkit-scrollbar {
  display: none;
}
.cart-coupon-title {
  height: 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.4);
}
.cart-coupon-title img {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
.cart-coupon-item {
  width: 374px;
  margin: 0 auto;
  padding: 12px 0;
}
.cart-coupon-item .coupon-item-left .coupon-item-type {
  width: 38px;
  height: 17px;
  padding: 2px 4px;
  border-radius: 4px;
  background-color: #FFF4F1;
  margin-right: 4px;
  margin-top: 2px;
}
.cart-coupon-item .coupon-item-left .coupon-item-type span {
  font-size: 10px !important;
  font-weight: 400 !important;
  color: #F53F3F !important;
}
.cart-coupon-item .coupon-item-left .coupon-item-content {
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.9);
}
.cart-coupon-item .coupon-item-left .coupon-item-content .coupon-item-money {
  position: relative;
  margin-left: 0;
  padding-left: 0;
}
.cart-coupon-item .coupon-item-left .coupon-item-content .coupon-item-money.red {
  color: #f53f3f;
}
.cart-coupon-item .coupon-item-left .coupon-item-content.active {
  display: flex;
}
.cart-coupon-item .coupon-item-left .coupon-item-content.active .coupon-item-money {
  margin-left: 4px;
  padding-left: 5px;
}
.cart-coupon-item .coupon-item-left .coupon-item-content.active .coupon-item-money::before {
  content: "";
  width: 1px;
  height: 12px;
  background-color: #eeeeee;
  position: absolute;
  top: 5px;
  left: 0;
}
.cart-coupon-item .coupon-item-left .coupon-item-content.active .coupon-item-money.red {
  color: #f53f3f;
}
.cart-coupon-item .coupon-item-left .coupon-item-content.active .coupon-item-money.red::before {
  content: "";
  width: 1px;
  height: 12px;
  background-color: #fdcdc5;
  position: absolute;
  top: 5px;
  left: 0;
}
.cart-coupon-item .coupon-item-right {
  display: flex;
  width: 52px;
  height: 22px;
  padding: 0 8px;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  font-size: 12px;
  font-weight: 400;
  background-color: #F53F3F;
  color: #ffffff;
}
.cart-coupon-item .cart-coupon-swiper {
  width: 100%;
  height: 56px;
  margin-top: 12px;
}
.label-box {
  overflow: hidden;
  position: relative;
  white-space: nowrap;
}
.label-box::after {
  content: "";
  display: block;
  width: 17px;
  height: 18px;
  position: absolute;
  top: 2px;
  right: 0;
  background: linear-gradient(270deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
}
.label-box .activity-time {
  display: inline-block;
}
.label-box .label-item {
  display: inline-block;
  height: 18px;
  padding: 0 4px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 0.5px solid rgba(160, 112, 60, 0.8);
  margin-right: 4px;
  color: #A0703C;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
.exchange-good-text .label-box::after {
  content: "";
  display: none;
}
:deep(.get-coupon) {
  cursor: pointer;
}
:deep(.get-coupon) .coupon-item {
  margin-top: 10px;
  margin-right: 0;
}
:deep(.get-coupon) .coupon-item:first-child {
  margin-top: 0;
}
:deep(.get-coupon) .ivu-poptip-body {
  max-height: 502px;
  overflow-y: auto;
}
:deep(.get-coupon) .ivu-poptip-body-content {
  /* 为滚动条滑块设置样式 */
}
:deep(.get-coupon) .ivu-poptip-body-content::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 8px;
}
:deep(.get-coupon) .ivu-poptip-body-content::-webkit-scrollbar-thumb {
  background-color: #E7E7E7;
  border-radius: 8px;
}
:deep(.get-coupon) .coupon-wrap {
  max-height: 470px;
  overflow-y: auto;
  width: 100%;
  /* 或者设置滚动条的样式 */
}
:deep(.get-coupon) .coupon-wrap::-webkit-scrollbar {
  display: none;
}
.swiper-slide-cart {
  border: 1px solid #eeeeee;
  border-radius: 4px;
  width: 56px;
  height: 56px;
  position: relative;
}
.swiper-slide-cart .swiper-slide-img {
  width: 45px;
  height: 45px;
}
.swiper-slide-cart .swiper-slide-select {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 0;
  right: 0;
}
.swiper-slide-cart .swiper-slide-select img {
  width: 100%;
  height: 100%;
}
.swiper-slide-cart .swiper-slide-num {
  min-width: 24px;
  height: 18px;
  padding: 0px 4px;
  border-radius: 4px;
  font-size: 10px;
  font-weight: 400;
  text-align: left;
  color: #ffffff;
  position: absolute;
  bottom: 2px;
  left: 2px;
  background-color: rgba(0, 0, 0, 0.6);
}
.swiper-slide-cart .swiper-slide-num span {
  color: #ffffff !important;
}
.banner-button-prev,
.banner-button-next {
  position: absolute;
  top: 50%;
  width: 20px;
  height: 20px;
  z-index: 10;
  cursor: pointer;
  background-size: 27px 44px;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 50%;
  transform: translateY(-50%);
}
.banner-button-prev.swiper-button-disabled,
.banner-button-next.swiper-button-disabled {
  display: none;
}
.banner-button-prev {
  left: 0px;
  right: auto;
  padding-right: 2px;
}
.banner-button-next {
  right: 0px;
  left: auto;
  padding-left: 2px;
}
