::v-deep .com-modal {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .com-modal .ivu-modal {
  top: 0;
}
::v-deep .com-modal .ivu-modal .ivu-modal-content .ivu-modal-body {
  display: flex;
  padding: 20px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
}
::v-deep .com-modal .ivu-modal .ivu-modal-content .ivu-modal-body .title-content {
  text-align: center;
  display: flex;
  align-items: center;
  width: 100%;
}
::v-deep .com-modal .ivu-modal .ivu-modal-content .ivu-modal-body .title-content .title {
  color: var(--fontcolor-black-1, rgba(0, 0, 0, 0.9));
  /* medium/16 */
  font-family: 'PingFang SC';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  flex: 1;
  text-align: left;
}
::v-deep .com-modal .ivu-modal .ivu-modal-content .ivu-modal-body .title-content .close-icon {
  cursor: pointer;
}
::v-deep .com-modal .ivu-modal .ivu-modal-content .ivu-modal-body .footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  height: 32px;
}
:deep(.tag) {
  padding: 0px 4px;
  align-items: center;
  gap: 4px;
  justify-content: center;
}
:deep(.refuse_auth) {
  border-radius: 4px;
  border: 1px solid var(--error-7, #cb272d);
  background: rgba(203, 39, 45, 0.08);
  color: var(--error-7, #cb272d);
  text-align: right;
  font-family: 'PingFang SC';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 166.667% */
}
:deep(.un_auth) {
  border-radius: 4px;
  border: 1px solid var(--hbrand-6, #3853e2);
  background: rgba(56, 83, 226, 0.08);
  color: var(--hbrand-7, #2438bc);
  font-family: 'PingFang SC';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 133.333% */
}
:deep(.auth) {
  border-radius: 4px;
  border: 1px solid var(--success-7, #349425);
  background: rgba(52, 148, 37, 0.08);
  color: var(--success-8, #1f7c15);
  font-family: 'PingFang SC';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 133.333% */
}
