.friend-link-wrapper {
  height: 26px;
  padding-top: 8px;
  padding-bottom: 16px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e7e7e7;
}
.friend-link-wrapper .title {
  height: 26px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  color: rgba(0, 0, 0, 0.9);
  margin-right: 32px;
}
.friend-link-wrapper .item {
  height: 26px;
  line-height: 26px;
  margin-right: 16px;
  color: rgba(0, 0, 0, 0.4);
}
.friend-link-wrapper .item:hover {
  color: #CE7720;
}
.friend-link-wrapper .item span {
  cursor: pointer;
}
