::v-deep .frozen-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}
::v-deep .frozen-modal .ivu-modal {
  top: 0;
}
::v-deep .frozen-modal .ivu-modal .ivu-modal-content .ivu-modal-body .frozen-content {
  text-align: center;
}
::v-deep .frozen-modal .ivu-modal .ivu-modal-content .ivu-modal-body .frozen-content .title {
  font-size: 16px;
  font-weight: 500;
  color: #000;
}
::v-deep .frozen-modal .ivu-modal .ivu-modal-content .ivu-modal-body .frozen-content .content {
  font-size: 16px;
  margin-top: 10px;
}
::v-deep .frozen-modal .ivu-modal .ivu-modal-content .ivu-modal-footer {
  text-align: center;
  font-size: 16px;
  color: #ce7720;
  cursor: pointer;
}
::v-deep .frozen-modal .ivu-modal .ivu-modal-content .ivu-modal-footer span {
  display: inline-block;
}
