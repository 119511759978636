.price-outer .show-price {
  height: 28px;
  overflow: hidden;
}
.price-outer .show-price .activity-price {
  vertical-align: middle;
  display: inline-block;
  height: 28px;
  white-space: nowrap;
  overflow: hidden;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #cb272d;
  margin-right: 8px;
  max-width: 100%;
}
.price-outer .show-price .through-price {
  display: inline-block;
  vertical-align: middle;
  height: 28px;
  text-decoration-line: line-through;
  text-decoration-color: #999;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 7px;
}
.error-state {
  color: var(--error-7, #cb272d);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.estimated-price {
  border-radius: 8px;
  background: #fbebe7;
  line-height: 24px;
  padding: 0 8px;
  color: #d4380d;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  display: inline-block;
  height: 22px;
}
.activity-price span {
  font-size: 12px;
  line-height: 28px;
  vertical-align: middle;
}
em {
  font-style: normal;
}
.prefix {
  color: var(--error-7, #cb272d);
  margin-right: 4px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.flet {
  color: var(--error-7, #cb272d);
  font-family: 'PingFang SC';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 128.571% */
  padding: 0 3px;
}
.cny {
  color: var(--error-7, #cb272d);
  font-family: 'PingFang SC';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 128.571% */
}
.int {
  color: var(--error-7, #cb272d);
  font-family: 'Arial';
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.float {
  font-family: 'Arial';
  color: #cb272d;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}
.activity-price .no-price {
  font-size: 16px;
}
