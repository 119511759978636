
.v-together-group {
  width: 1200px;
  height: 374px;
  background-image: url('~@/assets/images/home/pc-together-group.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 0 42px;
  padding-bottom: 12px;

  .ttitle {
    color: #FFF;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: block;
    margin: 12px auto;
    text-align: center;
  }

  .list {
    display: flex;
    margin-top: 18px;
  }

  .item {
    width: 204px;
    height: 284px;
    border-radius: 8px;
    border: 2px solid #F76560;
    background: #FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-left: 24px;

    &:first-child {
      margin-left: 0;
    }

    .img {
      width: 140px;
      height: 140px;
      margin-top: 8px;
      margin-bottom: 18px;
    }

    .pieces-num {
      background-image: url('~@/assets/images/home/pc-pieces-num.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 152px;
      height: 45px;
      color: #FFEFB7;
      font-size: 16px;
      font-weight: 500;
      // padding-left: 36px;
      text-align: center;
      padding-top: 15px;
      position: absolute;
      left: 18px;
      top: 125px;
    }

    .activity-price {
      color: #CB272D;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px;
    }

    .activity-unit {
      color: rgba(0, 0, 0, 0.60);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin-right: 2px;
    }

    .sell-price {
      color: rgba(0, 0, 0, 0.60);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      text-decoration: line-through;
    }

    .product-name {
      color: rgba(0, 0, 0, 0.90);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      height: 40px;
    }

    .btn {
      width: 98px;
      height: 36px;
      color: #FFF;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      border-radius: 8px;
      border: 1px solid #CB272D;
      background: #F53F3F;
      margin-top: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 2px;
      cursor: pointer;

      &:hover {
        color: #FFF59E;
      }

      .icon {
        margin-left: 4px;
      }
    }

    .content {
      width: 172px;
    }
  }

  .more {
    border-radius: 4px;
    background: #FA3343;
    width: 48px;
    height: 24px;
    position: absolute;
    right: 10px;
    top: 20px;
    color: #FFF;
    color: #FFF;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}

::v-deep .root {
  margin-top: 0;
}
.platform-tag{
  height: 18px;
  width: auto;
  margin-right: 4px;
  vertical-align: middle;
  margin-top: -3px;
}
