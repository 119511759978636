
.cust-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;

  
  .title {
    display: flex;
    padding: 0px 8px;
    align-items: center;
    gap: 16px;

    .left {
      color: var(--font_black-1, rgba(0, 0, 0, 0.9));
      /* medium/16 */
      font-family: 'PingFang SC';
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
    }

    .right {
      cursor: pointer;
      display: flex;
      align-items: center;
      img {
        width: 16px;
        height: 16px;
      }

      span {
        color: var(--font_black-1, rgba(0, 0, 0, 0.9));

        /* regular/14 */
        font-family: 'PingFang SC';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
        padding-left: 4px;
      }
    }
  }
}

.ivu-radio-group {
  margin-bottom: -20px;
}

.ivu-radio-group-item {
  display: flex;
  padding: 12px 24px;
  align-items: center;
  align-self: stretch;
  margin-left: -24px;
  margin-right: -24px;
  width: 520px;
}

.ivu-radio-group-item:last-child {
  border-radius: 0px 0px 8px 8px;
}

.ivu-radio-wrapper-checked {
  background: var(--gray-15, #f5f5f5);
}

.search-input{
  width: 322px;
}
