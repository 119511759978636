
.car {
  background-color: #f5f5f5;

  .car-content {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;

    .car-box {
      width: 1200px;
    }
  }
}

.total-address {
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  .total {
    font-weight: 500;
    color: rgba(0, 0, 0, 0.9);
    display: flex;
    align-items: flex-end;
    margin-right: 9px;

    .total-big {
      font-size: 16px;
      line-height: 24px;
    }

    .total-num {
      font-size: 18px;
      line-height: 26px;
    }
  }

  .address {
    display: flex;
    align-items: center;

    img {
      width: 13px;
      height: 15px;
      margin-right: 10px;
    }

    span {
      font-size: 14px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.9);
    }
  }
}

::v-deep .auth-modal {
  display: flex;
  align-items: center;
  justify-content: center;

  .ivu-modal {
    top: 0;

    .ivu-modal-content {
      .ivu-modal-body {
        .tip-content {
          text-align: center;

          .title {
            font-size: 16px;
            font-weight: 500;
            color: #000;
          }

          .content {
            font-size: 16px;
            margin-top: 10px;
          }
        }
      }

      .ivu-modal-footer {
        font-size: 16px;
        cursor: pointer;
        text-align: center;

        .left-btn {
          border: none;
        }

        .right-btn {
          border: none;
          background: #f99d33;
          color: #FFFFFF;
        }

        .single {
          .right-btn {
            width: 260px;
          }
        }
      }
    }
  }
}
