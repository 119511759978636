
.root {
  width: 100%;
  background-color: #fff;
  margin-bottom: 16px;
  .content {
    width: 1200px;
    padding: 12px 0;
    height: 100%;
    margin: 0 auto;
    position: relative;
    .tabs {
      width: auto;
      height: 22px;
      display: flex;
      .tabs-list {
        cursor: pointer;
        display: inline-block;
        // border-right: 2px #EEEEEE solid;
        height: 22px;
        position: relative;
        // align-items: center;
        display: flex;
        .tabs-item {
          font-size: 16px;
          color: rgba(0, 0, 0, 0.9);
          margin-right: 40px;
          align-items: center;
        }
        .tabs-item:hover {
          color: #ce7720;
          font-weight: bold;
        }
        // .tabs-speed {
        //   font-weight: 600 !important;
        //   background-image: linear-gradient(180deg, #FF9900 0%, #FF4004 100%);
        //   color: transparent;
        //   -webkit-background-clip: text;
        //   -webkit-text-fill-color: transparent;
        // }
        .icon-direction {
          transform: rotate(90deg);
          margin: 0 8px 0 -4px;
        }
        img {
          max-width: 100%;
          max-height: 24px;
          margin: 0 8px !important;
          // display: inline-block;
          padding: 0 !important;
        }
      }
      // .tabs-list:last-child {
      //     border: none !important;
      // }
      .selectde-tab {
        color: #ce7720 !important;
        font-weight: bold;
      }
    }
  }
}
