.coupon {
  height: 100%;
}
.coupon .info_item {
  display: flex;
  align-items: center;
  padding: 16px 0;
  border-style: solid;
  border-color: #E7E7E7;
  border-width: 0 0 1px 0;
  cursor: pointer;
}
.coupon .info_item:last-child {
  border-bottom-width: 0;
  padding-bottom: 0;
}
.coupon .info_item:first-child {
  padding-top: 0;
}
.coupon .info_item .title {
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.9);
}
.coupon .info_item .content {
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.9);
  flex: 1;
}
.coupon .info_item .time {
  font-size: 12px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.4);
  margin-left: auto;
}
.search-warp {
  width: 1200px;
  margin: 40px auto 0;
  display: flex;
  justify-content: flex-end;
}
.search {
  margin-left: auto;
}
