
@import url('../../product.less');
.v-market {
  width: 1200px;
}
.card-list {
  display: flex;
  //gap: 12px;
}
.card {
  flex: 1;
}
.card-wrap {
  display: flex;
  border-radius: 0 0 8px 8px;
  overflow: hidden;
}

.head {
  display: flex;
  align-items: center;
  height: 58px;
  padding: 0 24px;
  border-radius: 8px 8px 0px 0px;
  border-bottom: 1px solid #EEE;
  .icon-title{
    height: 32px;
  }
  .title {
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;
    color: #181818;
  }
  .more {
    margin-left: auto;
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 26px;
    color: rgba(0, 0, 0, 0.60);
    cursor: pointer;
  }
}
::v-deep .term_of_validity {
  left: 50% !important;
  transform: translateX(-50%);
}

:deep(.card-wrap) {
  .list-card{
    width: 198px;
    height: auto;
    padding: 16px 12px;
    border-radius: 0;
    &:nth-child(2){
      border-left: 0.5px solid #eee;
      border-right: 0.5px solid #eee!important;
    }
  }
  .cover-box,.cover-mask{
    width: 120px!important;
    height: 120px!important;
  }
  .price-area-box{
    margin-top: 0;
  }
}
