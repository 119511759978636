.four-product-wrap ::v-deep {
  .product-item{
    border-left:0.5px solid #eee;
    &:nth-of-type(1){
      //border-left:0;
    }
  }
  .goods_container {
    // padding: ;
    width: 234px;
    padding-top: 24px;
    border-radius: 0px;
    border-right: 1px solid #EEEEEE;
    &:first-child {
      border-radius: 8px 0 0 8px;
    }
    &:last-child {
      border-right: 0;
      border-radius: 0 8px 8px 0;
    }
    .show_name , .show_org{
      width: 196px;
    }
    .item_img {
      width: 146px;
      height: 146px;
    }
  }
  .tag_ite {
    margin-left: 0 !important;
  }
}
.five-product-wrap ::v-deep {
  //&> *:first-child {
  //  border-radius: 8px;
  //}
  //&> *:nth-child(2) {
  //  border-radius: 8px 0 0 8px !important;
  //}
  .goods_container {
    width: 188px;
    height: 242px;
    padding-top: 24px;
    border-radius: 0px;
    border-right: 1px solid #EEEEEE;

    &:last-child {
      border-right: 0;
      border-radius: 0 8px 8px 0;
    }
    .show_name , .show_org{
      // width: 156px;
    }
    .item_img {
      width: 100px;
      height: 100px;
    }
    .purchase-wrap .content {
      width: 100%;
    }
  }
  .tag_ite {
    margin-left: 0 !important;
  }
  .term_of_validity {
    left: unset;
    right: 0;
  }
}
.v-category-card {
  .five-product-wrap ::v-deep {
    .purchase-wrap .content {
      width: 100%;
      .title {
        width: 100%;
        white-space: unset;
      }
      .buy-num {
        flex-wrap: wrap;
      }
    }
  }
}
.eight-product-wrap ::v-deep {
  &> *:nth-child(4n) {
    border-right: 0 !important;
  }
  &> *:nth-child(n + 5) {
    border-bottom: 0 !important;
  }
  &> *:nth-child(5n), &> *:nth-child(0n+1) {
    border-left: 1px solid #EEEEEE;
  }
  .goods_container {
    width: 234px;
    height: 270px;
    padding-top: 24px;
    border-radius: 0 !important;
    border-right: 1px solid #EEEEEE;
    border-bottom: 1px solid #EEEEEE;
    .show_name , .show_org{
      // width: 156px;
    }
    .item_img {
      width: 100px;
      height: 100px;
    }
  }
  .tag_ite {
    margin-left: 0 !important;
  }
}
.six-product-wrap ::v-deep {
  &> *:nth-child(2) {
    margin-left: 600px;
  }
  &> *:nth-child(n + 5) {
    border-bottom: 0 !important;
  }
  .goods_container {
    width: 300px;
    height: 324px;
    padding-top: 24px;
    border-radius: 0 !important;
    border-right: 1px solid #EEEEEE;
    border-bottom: 1px solid #EEEEEE;
    display: flex;
    flex-direction: column;
    align-items: center;
    .show_name , .show_org{
      //text-align: center;
    }
    .item_img {
      width: 160px;
      height: 160px;
    }
  }
  .purchase-wrap .content {
    width: 100%;
  }
  .right-content {
    width: 100% !important;
    .title {
      width: 100% !important;
    }
  }
  .tag_ite {
    margin-left: 0 !important;
  }
  .six-img {
    // height: 324px;
  }
  .prd_control {
    left: 0;
    margin-left: 0;
  }
}
.six-product ::v-deep {
  .six-img {
    height: 316px !important;
  }
}
.v-many-product ::v-deep {
  .goods_container {
    width: 228px;
    height: 272px;
    padding-top: 24px;
    border-radius: 8px;
    border-right: 1px solid transparent;

    &:nth-child( 5n ) {
      margin-right: 0;
    }
    .show_name , .show_org{
      // width: 156px;
    }
    .item_img {
      width: 100px;
      height: 100px;
    }
  }
  .tag_ite {
    margin-left: 0 !important;
  }
}
.v-product-card ::v-deep, .v-market ::v-deep {
  .goods_container {
    width: 205px;
    height: 272px;
    padding-top: 24px;
    border-radius: 0;
    border-right: 1px solid #EEEEEE;
    &:nth-child( 5n ) {
      margin-right: 0;
    }
    .show_name , .show_org{
      // width: 156px;
    }
    .item_img {
      width: 109px;
      height: 109px;
    }
    .content {
      width: unset;
    }
    .confirm-btn .btn {
      width: 72px;
      height: 24px;
      line-height: 24px;
      font-size: 8px;
    }
    .input {
      width: 40px !important;
      margin: 0 4px !important;
    }
    .minus, .plus {
      width: 14px !important;
      height: 14px !important;
    }
    .title {
      width: 100% !important;
    }
  }
  .tag_ite {
    margin-left: 0 !important;
  }
}

//.five-product-wrap,.v-product-card{
// :deep(.product-item){
//    .confirm-btn .btn {
//      width: 72px;
//      height: 24px;
//      line-height: 24px;
//      font-size: 8px;
//    }
//    .input {
//      width: 40px !important;
//      margin: 0 4px !important;
//    }
//    .minus, .plus {
//      width: 14px !important;
//      height: 14px !important;
//    }
//    .title {
//      width: 100% !important;
//    }
//  }
//}
