
.cart-btn {
    width: 126px;
    height: 40px;
    border-radius: 4px;
    background-color: #ffffff;
    position: relative;
    padding: 0 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border: 1px solid #EEE;

    .icon-direction,
    .cart-num {
        position: absolute;
    }

    .cart-num {
        width: 28px;
        height: 15px;
        line-height: 15px;
        top: -10px;
        right: 6px;
        text-align: center;
        background-size: 100% 100%;

        .num {
            font-size: 12px;
            color: #ffffff;
        }

        &.indivdual {
            width: 16px;
            height: 16px;
            background-image: url(../assets/images/common/car-num-indivdual.png);
        }

        &.ten {
            background-image: url(../assets/images/common/car-num-ten.png);
            width: 24px;
            height: 16px;
        }

        &.hundred {
            width: 32px;
            height: 16px;
            background-image: url(../assets/images/common/car-num-hundred.png);
        }
    }

    .cart-icon {
        width: 16px;
        height: 16px;
    }
    &:hover {
        border: 1px solid rgba(250, 179, 87, 1);
    }
    .cart-text {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.9);
        font-weight: 400;
        margin-left: 8px;

        &:hover {
            color: rgba(230, 125, 0, 1);
        }
    }

    .icon-direction {
        top: 15px;
        right: 23px;
    }
}

::v-deep .ivu-poptip-body {
    padding: 8px;
}

.search-cart-box {
    width: 363px;
    min-height: 260px;
    max-height: 350px;
    overflow-y: auto;

    .search-cart-list {
        width: 100%;

        .search-cart-item {
            width: 100%;
            height: 110px;
            display: flex;
            position: relative;
            cursor: pointer;
            padding: 12px;

            .search-cart-img {
                width: 80px;
                height: 80px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 8px;

                img {
                    width: 64px;
                    height: 64px;
                }
            }

            .search-cart-con {
                width: 208px;
            }

            .search-goods-name {
                width: 208px;
                height: 22px;
                font-size: 14px;
                line-height: 22px;
                color: rgba(0, 0, 0, 0.9);
                font-weight: 500;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: normal;
            }

            .search-goods-date {
                width: 208px;
                height: 20px;
                font-size: 12px;
                line-height: 20px;
                color: rgba(0, 0, 0, 0.6);
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            &.lose {
                .search-cart-img {
                    opacity: 0.5;
                }
                .search-goods-name,
                .search-goods-date {
                    color: rgba(0, 0, 0, 0.4);
                }
            }

            .search-goods-price {
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                color: #CB272D;

                span {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 22px;
                    color: rgba(0, 0, 0, 0.9);

                }
            }

            .lose-state {
                font-size: 12px;
                line-height: 20px;
                color: #CB272D;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .searct-cart-del {
                width: 16px;
                height: 16px;
                position: absolute;
                top: 15px;
                right: 12px;
                background: url('../assets/images/search/delete.png') no-repeat center;
                background-size: 100%;
                &:hover {
                    background: url('../assets/images/search/delete-hover.png') no-repeat center;
                    background-size: 100%;
                }
            }
        }
    }

    .search-cart-bot {
        width: 100%;
        border-radius: 0px 0px 8px 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        bottom: 6px;
        left: 0;
        padding: 12px;
        box-shadow: 0px -16px 20px 0px rgba(0, 0, 0, 0.04);
        background-color: #ffffff;

        .search-cart-num {
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;

            span {
                color: #ce7720;
                font-weight: 500;
            }
        }

        .search-cart-btn {
            width: 92px;
            height: 36px;
            border-radius: 4px;
            background-color: #F99D33;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            color: #ffffff;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}


.demo-spin-icon-load {
    animation: ani-demo-spin 1s linear infinite;
}

@keyframes ani-demo-spin {
    from {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(180deg);
    }

    to {
        transform: rotate(360deg);
    }
}
