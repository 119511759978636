
::v-deep .exhange-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  .ivu-modal {
    top: 0;
    .ivu-modal-content {
      .ivu-modal-body {
        padding: 0 !important;
        .exhange-modal-wrap {
          padding-top: 20px;

          .padding-lr{
            padding-left: 24px;
            padding-right: 24px;
          }
          .title-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
            .title-text {
              font-size: 16px;
              font-weight: 500;
              color: rgba(0, 0, 0, 0.9);
            }
          }
          .org-name {
            font-size: 18px;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.9);
            margin-bottom: 16px;
          }
          .label {
            font-size: 14px;
            color: rgba(0, 0, 0, 0.6);
          }
          .pics-box {
            margin-top: 8px;
            display: flex;
            // justify-content: space-between;
            flex-wrap: wrap;
            gap: 16px;
            max-height: 402px;
            overflow: auto;
            margin-bottom: 24px;
            .pic-item {
              display: flex;
              flex-direction: column;
              align-items: center;
              text-align: center;
              width: 160px;
              padding: 20px 8px 16px 8px;
              border-radius: 4px;
              border: 1px solid #e7e7e7;
              // margin-bottom: 16px;
              img {
                width: 120px;
                height: 120px;
                margin-bottom: 8px;
                background-color: #eee;
              }
            }
            .swiper-container {
              width: 100%;
            }
          }
          .platform-wrap{
            display: flex;
            align-items: center;
            margin: 16px 0;
            .ivu-radio-wrapper{
              display: inline-block !important;
              margin-right: 16px;
              .ivu-radio{
                margin-right: 0;
              }
            }
          }
          .btn-wrap{
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #FAFAFA;
            padding: 12px 24px;
            border-radius: 0px 0px 8px 8px;
          }
          .agree {
            color: rgba(0, 0, 0, 0.4);
            font-size: 12px;

            .ivu-checkbox-inner {
              border-radius: 4px;
            }

            .ivu-checkbox-wrapper {
              font-size: inherit;

              .ivu-checkbox {
                margin-right: 4px;
              }
            }

            .agree-content {
              color: #ce7720;
              line-height: 20px;
              cursor: pointer;
            }
          }
          .btn-box{
            display: flex;
            justify-content: flex-end;
            .agree-btn{
              margin-left: 12px;
            }
            .agree-btn-disabled{
              color: #fff;
              background-color: #FDDA9F;
            }
          }
        }
      }
      .ivu-modal-footer {
        text-align: center;
        font-size: 16px;
        color: #ce7720;
        cursor: pointer;
        span {
          display: inline-block;
        }
      }
    }
  }
}
