
.tips {
  display: flex;
  height: 40px;
  padding: 9px 16px;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  background: var(--brand-1, #FFF8E8);

  overflow: hidden;
  color: var(--font_black-1, rgba(0, 0, 0, 0.90));
  text-overflow: ellipsis;
  /* regular/14 */
  font-family: "PingFang SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */

  img{
    margin-right: 8px;
  }

  strong {
    overflow: hidden;
    -webkit-box-orient: vertical;
    color: var(--brand-7, #E67D00);
    text-overflow: ellipsis;

    /* regular/14 */
    font-family: "PingFang SC";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin: 0px 2px;
  }

  .right{
    flex: 1;
    text-align: right;
  }
}
.change-company{
  display: flex;
  align-items: center;
  gap: 4px;
  align-self: stretch;

  .lable{
    color: var(--font_black-1, rgba(0, 0, 0, 0.90));
    font-family: "PingFang SC";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    white-space: nowrap; /* 禁止文本换行 */
    overflow: hidden; /* 隐藏超出范围的内容 */
    text-overflow: ellipsis; /* 使用省略号 */
  }

  .select-company {
    flex: 1;
    .ivu-select-arrow{
      right: inherit;
      color: var(--font_black-1, rgba(0, 0, 0, 0.90)) !important;
    }
    .ivu-select-selection{
      display: flex;
      align-items: center;
      border: none;
    }

    .ivu-select-prefix{
      margin-top: 0px !important;
    }
  }
  .ivu-select-selected-value{
    margin-right: 8px;
    font-size: 14px;
    height: 20px !important;
    line-height: 20px !important;
    margin-top: 1px;

    color: var(--font_black-1, rgba(0, 0, 0, 0.90)) !important;
  }
  .company-status {
    display: inline-block;
    margin-left: 5px;
    padding: 2px 4px;
    color: #a35511;
    font-size: 10px;
    background-color: #fdda9f;
    border-radius: 4px;
  }
}
.addr-content{
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;

  .addr{
    color: var(--font_black-2, rgba(0, 0, 0, 0.60));
    /* regular/14 */
    font-family: "PingFang SC";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */


    display: -webkit-box; /* 设置为WebKit内核的弹性盒子模型 */
    -webkit-box-orient: vertical; /* 垂直排列 */
    -webkit-line-clamp: 2; /* 限制显示两行 */
    overflow: hidden; /* 隐藏超出范围的内容 */
    text-overflow: ellipsis; /* 使用省略号 */
  }

  .oper{
    color: var(--brand-7, #E67D00);
    /* regular/14 */
    font-family: "PingFang SC";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */

    cursor: pointer;
  }
}
.relevancy {
  display: flex;
  padding: 5px 12px;
  width: 100px;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  background: var(--3, linear-gradient(332deg, #FF5C17 17.15%, #FFAE35 82.37%));

  color: var(--font_white-0, #FFF);

  /* regular/14 */
  font-family: "PingFang SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}

.relevancy:hover {
  cursor: pointer;
  opacity: 0.6;
}

.btn-oper{
  display: flex;
  align-items: center;
  gap: 8px;
}

.unbind-enterprise{
  display: flex;
  padding: 5px 12px;
  width: 102px;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  border: 1px solid var(--brand-6, #F99D33);

  color: var(--brand-7, #E67D00);

  /* regular/14 */
  font-family: "PingFang SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 157.143% */
}

.unbind-enterprise:hover{
  cursor: pointer;
  opacity: 0.6;
}

.ivu-select-prefix {
    float: left;
    margin-top: 5px;
  }
.fejs {
  display: block;
  font-size: 10px;
  padding: 0 4px;
  border: 1px solid #349425;
  border-radius: 4px;
  background-color: rgba(52, 148, 37, 0.08);
  color: #1F7C15;
  margin-right: 8px;
}
.auth_style {
  background: rgba(77, 172, 57, 0.1);
  color: #034D00;
}
.unauth_style {
  background: rgba(249, 157, 51, 0.15);
  color: #A35511
}
.mi_auth_style {
  background: rgba(56, 83, 226, 0.1);
  color: #2438BC;
  border: 1px solid #3853E2;
}
.caption-right:hover{
  color: #3853E2 !important;
  cursor: pointer;
}
.ivu-select-single .ivu-select-selection .ivu-select-head-with-prefix.ivu-select-selected-value {
  float: left;
  margin-left: 8px;
  padding-right: 0px;
}

.local_icon {
  width: 14px;
}

.rd-title{
  margin-top: -3px;
  color: var(--font_black-1, rgba(0, 0, 0, 0.90));
  /* regular/14 */
  font-family: "PingFang SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  white-space:normal
}
.rd-addr{
  color: var(--font_black-2, rgba(0, 0, 0, 0.60));
  /* regular/12 */
  font-family: "PingFang SC";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */

  white-space:normal
}

.ivu-radio-inner{
  margin-right: 6px !important;
}

.change-cust{
  display: flex !important;
  flex-direction: column;
  max-height: 600px;
  overflow-y: auto;
  gap: 16px;

}

.ivu-radio-wrapper{
  display: flex !important;
}
