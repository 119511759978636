.pop-wrap {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(55, 55, 55, 0.6);
  height: 100%;
  z-index: 1200;
}
.img-wrap {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100vh;
  justify-content: center;
}
.img-wrap .content-zone {
  position: relative;
}
.zone {
  position: absolute;
}
.close {
  width: 48px;
  height: 48px;
  position: absolute;
  right: -64px;
  top: -46px;
}
