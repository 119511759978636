.coupon {
  width: 100%;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  position: relative;
}
.coupon.bg {
  background-size: 100% 100%;
}
.coupon .left {
  flex: 1;
  max-width: 136px;
}
.coupon .org-name {
  color: rgba(0, 0, 0, 0.9);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
.coupon .usable-range {
  color: #F53F3F;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12.583px;
  margin-top: 4px;
  margin-bottom: 2px;
}
.coupon .join-show {
  color: #F53F3F;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.coupon .coupon-type {
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  max-width: 210px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.coupon .condition {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 4px;
}
.coupon .rule {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 8px;
}
.coupon .rule img {
  width: 12px;
  height: 12px;
  margin-right: 4px;
}
.coupon .right {
  text-align: center;
  color: #f53f3f;
  font-size: 24px;
  font-weight: 500;
  padding-top: 6px;
}
.coupon .right .full-num {
  font-weight: 400;
  font-size: 12px;
}
.coupon .unit {
  font-size: 14px;
}
.coupon-box {
  border-radius: 10px 8px 8px 10px;
  background: linear-gradient(15.93deg, #FC3844 6.6%, #FF7463 95.67%);
}
.coupon-box .coupon-left {
  z-index: 1;
  border-radius: 8px;
  background-color: #fff5f5;
  flex: 1;
  height: 100%;
  padding: 6px 16px 6px 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #F53F3F;
}
.coupon-box .coupon-left .num-pic {
  font-family: PingFang SC;
  font-size: 0;
  text-align: left;
}
.coupon-box .coupon-left .money-text {
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
}
.coupon-box .coupon-left .unit {
  font-weight: 500;
  font-size: 14px;
}
.coupon-box .coupon-left .full-num {
  font-size: 12px;
  font-weight: 400;
}
.coupon-box .coupon-left .org-name {
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}
.coupon-box .coupon-right {
  flex-basis: 36px;
  height: 76px;
  padding-top: 4px;
  border-radius: 0 8px 8px 0;
  background: linear-gradient(16deg, #FC3844 6.6%, #FF7463 95.67%);
  font-family: PingFang SC;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  color: #FFFDF9;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  cursor: pointer;
}
.coupon-box .coupon-right span {
  font-weight: 500;
  writing-mode: vertical-lr;
  letter-spacing: 4px;
}
.coupon-box .coupon-right::after {
  content: '';
  background: #ff5252;
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 12px ;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: -6px;
  z-index: 1;
  transform: translateY(-50%);
}
