.footer-wrapper {
  text-align: center;
  font-size: 18px;
  line-height: 26px;
  color: rgba(255, 255, 255, 0.4);
  background-color: #F5F5F5;
}
.footer-wrapper .link-item {
  padding: 0 8px;
}
