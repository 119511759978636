
:deep(.ivu-table-header) {
  border-radius: 8px 8px 0px 0px;
  border: 1px solid var(--gray-2, #eee);
  background: var(--gray-1, #fafafa);
  .ivu-table-cell {
    padding: 0;
    span {
      color: var(--font-black-2, rgba(0, 0, 0, 0.9));
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
    }
  }
  th {
    background: none;
    border-bottom: none;
    border-right: 1px solid #eee;
    padding: 0 8px;
    &:last-child {
      border-right: none;
    }
  }
}
:deep(.ivu-table-tbody) {
  .ivu-table-cell {
    padding: 0;
  }
  tr {
    border-bottom: 1px solid var(--gray-2, #eee);
    &:last-child {
      border-bottom: none;
    }
  }
  td {
    font-size: 12px;
    border-bottom: none;
    vertical-align: top;
    background: none;
    border-right: 1px solid #eee;
    padding: 4px 8px;
    height: 36px;
    vertical-align: middle;
    color: rgba(0, 0, 0, 0.9);
    &:last-child {
      border-right: none;
    }
  }
  .ivu-table-row-hover {
    td {
      background: #fff8f0 !important;
    }
  }
}
:deep(table) {
  border-spacing: 16px 0;
  border-collapse: collapse;
  width: 100% !important;
  height: 36px;
}
:deep(.ivu-table) {
  border-radius: 8px;
  &:before {
    display: none;
  }
}
:deep(.ivu-table-body) {
  border: 1px solid var(--gray-2, #eee);
  border-top: none;
}
:deep(.ivu-modal-header) {
  border-bottom: none;
}
:deep(.ivu-modal-footer) {
  border-top: none;
}

.bread-crumb {
  line-height: 22px;
  font-size: 14px;
  padding: 0px 24px 24px 0px;
  width: 1200px;
  margin: auto;
}
.codemapping-content {
  width: 1200px;
  background: #fff;
  padding: 24px;
  height: calc(100vh - 215px);
  margin: auto;
  .search-detail {
    padding-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .search {
      display: flex;
      align-items: center;
      h4 {
        margin-right: 16px;
        color: #000;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
      }
      img {
        width: 18px;
        height: 18px;
        margin-right: 4px;
      }
    }
    .operate {
      display: flex;
      align-items: center;
      gap: 12px;
    }
  }
  .list-wrap {
    .ivu-table-wrapper {
      min-height: calc(100vh - 336px);
      overflow-y: auto;
    }
  }
  :deep(.action) {
    color: var(--hbrand-6, #3853e2);
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    cursor: pointer;
    &:not(:first-child) {
      margin-left: 8px;
    }
  }
}
