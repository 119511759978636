
.v-poster {
  width: 1200px;
  display: flex !important;
  flex-direction: row;
  // height: 272px;
  // margin: 0 auto;
  & > div {
    flex: 1;
    margin-left: 16px;
    &:first-child {
      margin-left: 0;
    }
  }
  .img {
    width: 100%;
    border-radius: 8px;
  }
}
