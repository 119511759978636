
.matching-shipping {
  ::v-deep .vertical-center-modal {
    .ivu-modal {
      width: 688px !important;
      .ivu-modal-content {
        .ivu-modal-header {
          border-bottom: none;
          font-weight: 600;
          font-size: 18px;
          line-height: 24px;
          color: rgba(0, 0, 0, 0.9);
          padding:16px 32px 4px;
        }
        .ivu-modal-close{
          top:16px;
          right:32px;
        }
        .ivu-modal-body {
          padding: 8px 32px 16px;
          .not-full {
            .text {
              font-size: 16px;
              line-height: 24px;
              color: #000;
              margin-bottom: 12px;
            }
            .content-item{
              max-height:300px;
              overflow-y:scroll;
              &::-webkit-scrollbar {
                display: none;
              }
            }
            .business-box {
              margin-bottom: 16px;
              &:last-child{
                margin-bottom: 0;
              }
              .name {
                font-size: 18px;
                line-height: 26px;
                color: rgba(0, 0, 0, 0.9);
                padding: 16px 26px 18px 26px;
                box-sizing: border-box;
                background-color: #f5f5f5;
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
              }
              .info {
                padding: 10px 26px 20px 26px;
                box-sizing: border-box;
                border: 1px solid #eee;
                border-top: none;
                border-radius: 0px 0px 8px 8px;
              }
              .info-content {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .left {
                  font-size: 16px;
                  line-height: 24px;
                  color: rgba(0, 0, 0, 0.6);
                  span {
                    color: #cb272d;
                  }
                }
                .right {
                  font-size: 16px;
                  line-height: 24px;
                  color: #CB272D;
                  cursor: pointer;
                }
              }
              .tag {
                display:flex;
                align-items:center;
                .typeOne {
                  border: 0.5px solid #3853E2;
                  background-color: #F1F5FE;
                  color: #3853E2;
                  font-size: 14px;
                  line-height: 22px;
                  border-radius: 4px;
                  padding: 2px 4px;
                  margin-right: 10px;
                  margin-top:10px;
                }
                .typeTwo {
                  background-color: #eeeeee;
                  color: rgba(0, 0, 0, 0.4);
                  font-size: 14px;
                  line-height: 22px;
                  border-radius: 4px;
                  padding: 2px 4px;
                  margin-top:10px;
                }
              }
              .freight_voucher{
                margin-top:12px;
                display:flex;
                align-items: flex-start;
                .freight_mark{
                  padding:0 6px;
                  margin-right:4px;
                  display:flex;
                  align-items: center;
                  justify-content: center;
                  background: #FFEDF1;
                  border: 0.5px solid #FF4D77;
                  border-radius: 4px;
                  .freight_txt{
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 20px;
                    color: #E72A57;
                  }
                }
                .freight_wrap{
                  display: flex;
                  flex: 1;
                  p{
                    width:536px;
                    overflow:hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    -o-text-overflow:ellipsis;
                    .freight_tip{
                      font-weight: 400;
                      font-size: 14px;
                      line-height: 22px;
                      color: rgba(0, 0, 0, 0.6);
                    }
                  }
                }
              }
            }
          }
          .modal-footer {
            display: flex;
            justify-content: flex-end;
            padding-top: 24px;
            span {
              padding: 5px 16px;
              border-radius: 3px;
              font-size: 14px;
              line-height: 22px;
              margin-left: 8px;
              cursor: pointer;
            }
            .back-car {
              background-color: #e7e7e7;
              color: rgba(0, 0, 0, 0.9);
            }
            .continue {
              background-color: #f99d33;
              color: rgba(255, 255, 255, 0.9);
            }
          }
        }
      }
    }
  }
}
