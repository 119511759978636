.ivu-modal {
  top: 40%;
}
.near-date {
  text-align: center;
}
.near-date-empty {
  text-align: left;
}
.expiration-warn {
  color: #E67D00;
}
