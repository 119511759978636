.search-bar {
  background-color: #fff;
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 3;
}
.search-bar.box-shadow {
  box-shadow: 4px 0px 20px 0px rgba(0, 0, 0, 0.08);
}
.notice-detail {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.breadcrumb {
  background: #eee;
  padding: 4px 0;
}
.breadcrumb .breadcrumb-warp {
  width: 1200px;
  margin: 0px auto 0;
  display: flex;
}
.breadcrumb .text {
  margin-right: 32px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
}
.notice-detail-content {
  width: 1200px;
  flex: 1;
  margin: 0 auto;
  margin-top: 16px;
  margin-bottom: 32px;
  background: #FFFFFF;
  border-radius: 8px;
  padding: 24px;
}
.notice-detail-content .title {
  margin-bottom: 16px;
  font-size: 18px;
  line-height: 26px;
  color: rgba(0, 0, 0, 0.9);
  font-weight: 500;
}
.notice-detail-content .notict-content {
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.6);
}
