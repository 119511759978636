.home-notice-wrap {
  background: #ffffff;
  overflow: hidden;
  margin-top: 8px;
}
.home-notice-wrap ::v-deep .ivu-tabs-bar {
  margin-bottom: 0px;
}
.home-notice-wrap ::v-deep .ivu-tabs-nav .ivu-tabs-tab {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  padding: 2px 0;
  color: rgba(0, 0, 0, 0.9);
}
.home-notice-wrap ::v-deep .ivu-tabs-nav .ivu-tabs-tab:hover {
  color: #e67d00;
}
.home-notice-wrap ::v-deep .ivu-tabs-nav .ivu-tabs-tab-active {
  color: #e67d00;
  font-weight: 500;
}
.home-notice-wrap .notic-icon {
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.6);
}
.home-notice-wrap .notic-icon:hover {
  color: #e67d00;
}
.home-notice-wrap .notic-icon:hover .ivu-icon {
  color: #e67d00 !important;
}
.home-notice-wrap .ivu-icon {
  margin-top: -2px;
  color: rgba(0, 0, 0, 0.6);
}
.home-notice-wrap .list {
  height: 96px;
  padding-top: 6px;
}
.home-notice-wrap .list .item {
  margin: 2px 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.9);
  cursor: pointer;
}
.home-notice-wrap .list .item:hover {
  color: #e67d00;
}
.home-notice-wrap .list .empty-notice {
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
  line-height: 20px;
  text-align: center;
}
