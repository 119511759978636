.customer-steps {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 400px;
  padding-top: 15px;
}
.customer-steps-item {
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  line-height: 20px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
}
.customer-steps-item::after {
  content: '';
  position: absolute;
  left: 5px;
  top: 25px;
  width: 1px;
  height: 85%;
  border-right: 1px dashed #ececec;
}
.customer-steps-item::before {
  content: '';
  position: absolute;
  left: 4px;
  top: 5px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  border: #ececec 1px solid;
  background-color: #ffffff;
}
.customer-steps-item:last-child::after {
  width: 0;
  border: none;
}
.customer-steps-item.success::after {
  background-color: #349425;
}
.customer-steps-item.success::before {
  border-color: #349425;
  background-color: #349425;
}
.customer-steps-item.waiting::before {
  border-color: #dcdcdc;
  background-color: #fff;
}
.customer-steps-item.error::after {
  background-color: #cb272d;
}
.customer-steps-item.error::before {
  border-color: #cb272d;
  background-color: #cb272d;
}
.customer-steps-item-name {
  display: flex;
  gap: 5px;
  align-items: center;
  line-height: 22px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);
}
.customer-steps-item-name .dot {
  display: inline-block;
  width: 3px;
  height: 3px;
  background: rgba(0, 0, 0, 0.9);
  border-radius: 50%;
}
.customer-steps-item .remark {
  color: var(--font_black-2, rgba(0, 0, 0, 0.6));
  font-family: 'PingFang SC';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 166.667% */
  white-space: normal;
  word-break: break-word;
}
