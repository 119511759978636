
.default{
  width: 100%;
  text-align: center;
  border: none;
  img{
    width: 216px;
    height: 216px;
    object-fit: contain;
  }
}
.product-list {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  &>div{
    border: 1px solid #EEE;
    border-radius: 8px;
  }
}
.list{
  position: relative;
  .buy-count{
    position: absolute;
    left:0;
    top:180px;
    width:100%;
    height:24px;
    text-align: center;
    line-height: 24px;
    background: rgba(238, 238, 238, 0.80);
    .text{
      color:#F76560;
    }
  }
}

.collection-price {
  margin-top: 6px;
  font-size: 12px;
  line-height: 20px;
  height: 20px;
  transform-origin: left top;
  .text {
    margin-right: 4px;
  }
  .price {
    color: #349425;
  }
  .price-no-change {
    font-weight: 500;
    color: rgba(0, 0, 0, 0.4);
  }
}

:deep(.product-list){
  .list-card{
    width: 223px;
  }
  .cover-max,.cover-box{
    width: 194px;
    height: 194px;
  }
}
