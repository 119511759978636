:deep(.ivu-modal-header) {
  border-bottom: none;
  padding: 20px 16px 0;
}
:deep(.ivu-modal-footer) {
  border-top: none;
}
:deep(.ivu-modal-footer) .tip {
  position: absolute;
  left: 16px;
  bottom: 16px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
:deep(.ivu-modal-footer) .tip .download-icon {
  width: 20px;
  height: 20px;
}
:deep(.ivu-modal-footer) .tip .down-load {
  color: var(--hbrand-6, #3853e2);
}
:deep(.ivu-modal-footer) .tip .down-load:hover {
  cursor: pointer;
}
:deep(.ivu-modal-footer) .tip .export {
  margin-left: 10px;
}
:deep(.ivu-modal-footer) .ivu-btn-primary[disabled] {
  background: var(--brand-3, #fdda9f);
  color: #fff;
  border-color: #fdda9f;
}
.upload-section .fail-content {
  height: 32px;
  background: #ffece8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
  border-radius: 4px;
}
.upload-section .fail-content svg {
  width: 16px;
  height: 16px;
}
.upload-section .fail-content a {
  color: #3853e2;
}
.upload-section .fail-content .export {
  color: #cb272d;
}
.upload-section .ivu-upload {
  margin-top: 16px;
  background: #fafafa !important;
}
.upload-section .upload-btn {
  padding: 32px;
  height: 160px;
}
.upload-section .upload-btn:hover {
  cursor: pointer;
}
.upload-section .upload-btn .upload-icon {
  width: 40px;
  height: 40px;
}
.upload-section .upload-btn .upload-text {
  color: var(--font-black-1, rgba(0, 0, 0, 0.6));
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  display: block;
}
.upload-section .upload-list .file-item {
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.upload-section .upload-list .file-item .file-name {
  color: var(--font-black-2, rgba(0, 0, 0, 0.6));
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}
.upload-section .upload-list .file-item .del-icon {
  width: 14px;
  height: 14px;
}
.upload-section .upload-list .file-item:hover {
  background: var(--gray-15, #f5f5f5);
}
