
.v-price-down {
  width: 1200px;
  height: 421px;
  background-image: url('~@/assets/images/home/pc-price-down.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 0 42px;
  padding-bottom: 12px;

  .ttitle {
    color: #FFF;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: block;
    text-align: center;
    width: 228px;
    left: 486px;
    position: absolute;
    top: 10px;
    height: 56px;
  }

  .list {
    position: absolute;
    left: 58px;
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1088px;
    margin-top: 86px;
  }

  .more {
    border-radius: 4px;
    background: #F7BA1E;
    width: 48px;
    height: 24px;
    position: absolute;
    right: 16px;
    top: 20px;
    color: #FFF;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .swiper-img {
    width: 24px;
    height: 32px;
    position: absolute;
    top: 52%;
    z-index: 999;
  }
  .img-right {
    left: 36px;
  }
  .img-left {
    right: 36px;
  }
}

::v-deep .root {
  margin-top: 0;
}
