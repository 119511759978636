
.table {
  .table-header {
    height: 36px;

    & div:first-child {
      padding-left: 16px;
    }
  }

  .table-body {
    margin-top: 12px;
    overflow: hidden;

    .table-body__title {
      height: 36px;
      padding: 0 16px;

      .orgName {
        cursor: pointer;

        &:hover {
          color: var(--brand-7, #ce7720);
        }
      }

      .orderNo {
        margin-right: 36px;

        &:hover {
          cursor: pointer;
          color: #3853E2;
        }
      }

      .time {
        // margin-right: 16px;
      }
    }
  }

  .table-column1 {
    width: 350px;

    img {
      width: 72px;
      height: 72px;
      object-fit: contain;
      border: 1px solid #e7e7e7;
      border-radius: 4px;
      margin-right: 16px;
    }

    .products-thumbs {
      display: flex;
      align-items: center;
      margin-bottom: 16px;

      .omit {
        cursor: pointer;
        width: 20px;
        height: 20px;
        padding: 4px;
        background: url('../../../../assets/images/order/omit.png') no-repeat center;
        background-size: cover;
        border-radius: 4px;
        transition: 0.3s ease;

        &:hover {
          background-color: #e7e7e7;
        }
      }
    }
  }

  .table-column2 {
    width: 154px;
  }

  .table-column3 {
    width: 273px;

    &>div {
      margin-top: 4px;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  .table-column4 {
    width: 155px;

    button {
      margin-top: 4px;
      width: 64px;
      height: 24px;
    }

    &>div {
      margin-top: 4px;

      &:first-child {
        margin-top: 0;
      }
    }
  }
}
.w-text-button-info {
  font-size: 12px;
}
