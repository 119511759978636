
.wrap{
  width:100%;
  height:calc(100vh);
  background-color: rgba(255, 255, 255, 0);
  position: fixed;
  z-index: 9999;
}
.add-wrap{
  width:24px;
  height:24px;
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  left:88px;
  top:5px;
}
.plus-wrap{
  width:24px;
  height:24px;
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  left:0;
  top:5px;
}
.deal-wrap{
  width:64px;
  height:24px;
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  left:24px;
  top:5px;
}
.demo-spin-icon-load{
  animation: ani-demo-spin 1s linear infinite;
}
@keyframes ani-demo-spin {
  from { transform: rotate(0deg);}
  50%  { transform: rotate(180deg);}
  to   { transform: rotate(360deg);}
}
