
.bottom-bar {
  width: 100%;
  display: flex;
  justify-content: center;
  position: static;
  bottom: 0;
  left: 0;
  z-index: 100;

  .bottom-bar-content {
    margin: 0 auto;
    width: 1200px;
    height: 68px;
    padding: 16px 0px 16px 16px;
    box-sizing: border-box;
    // border-radius: 8px;
    background: var(--font-white-0, #FFF);
    display: flex;
    align-items: center;
    justify-content: space-between;
    // border: 1px solid #ccc;
    box-shadow: 0px -16px 20px -10px rgba(0, 0, 0, 0.04);

    .select-del {
      ::v-deep .ivu-checkbox-wrapper {
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.9);

        .ivu-checkbox {
          margin-right: 8px;
        }

      }

      .del {
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.9);
        margin-left: 16px;
        cursor: pointer;
      }

      .del:hover {
        color: #F76560;
      }

      .del:active {
        color: #CB272D;
      }
    }

    .right {
      display: flex;
      align-items: center;

      .sub-total {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .num {
          display: flex;
          align-items: center;

          .num-wrap {
            font-size: 14px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.6);
            font-weight: 400 !important;

            i {
              font-style: normal;
              color: #ce7720;
              font-weight: 500 !important;
            }

          }

          .price-wrap {
            margin-left: 11px;

            .num-price,
            .num-price>span {
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 22px;
              color: rgba(0, 0, 0, 0.9);
              font-family: "PingFang SC";
            }

          }

          .mr-left {
            margin-left: 5px;
          }

          .mr-lf-8 {
            margin-left: 8px;
          }

          .font-style-r {
            font-weight: 600 !important;
          }
        }

        .sum {
          font-size: 12px;
          line-height: 20px;
          color: rgba(0, 0, 0, 0.6);
          margin-top: 2px;

          span {
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.9);
            font-weight: 500;
            margin-left: 8px;
            margin-right: 8px;

            &:last-child {
              margin-right: 0px;
            }
          }
        }
      }

      .gray-line {
        width: 1px;
        height: 52px;
        background-color: #e7e7e7;
        margin: 0 24px;
      }

      .total {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        // margin-left: 36px;
        .amount-price {
          display: flex;
          align-items: center;

          .amount {
            font-size: 14px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.9);
            font-weight: 400;
          }

          .price {
            font-weight: 500;
            font-size: 24px;
            line-height: 32px;
            color: #cb272d;
          }
        }

        .detailed {
          .activity-discountAmount {
            font-family: "PingFang SC";
            font-size: 12px;
            line-height: 20px;
            color: rgba(0, 0, 0, 0.9);
            font-weight: 400 !important;
            font-style: normal;
            margin-right: 16px;

            .activity-num {
              color: var(--font_black-1, rgba(0, 0, 0, 0.90));
              font-weight: 600 !important;
              font-style: normal;
            }
          }

          .detail-text {
            cursor: pointer;
            font-size: 12px;
            line-height: 20px;
            color: rgba(0, 0, 0, 0.9);
            margin-right: 2px;
          }

          ::v-deep .ivu-poptip {
            .ivu-poptip-body {
              width: 528px;
              padding: 16px;
              max-height: 392px;
              overflow-y: scroll;
              box-sizing: border-box;

              &::-webkit-scrollbar {
                display: none;
              }

              .ivu-poptip-body-content {
                .detail-content {
                  .item {
                    margin-bottom: 16px;

                    &:last-child {
                      margin-bottom: 0;
                    }

                    .company-name {
                      padding: 8px 26px;
                      box-sizing: border-box;
                      font-size: 14px;
                      line-height: 22px;
                      color: rgba(0, 0, 0, 0.9);
                      background: #f5f5f5;
                      border-top-left-radius: 8px;
                      border-top-right-radius: 8px;
                    }

                    .info {
                      border: 1px solid #f5f5f5;
                      border-top: none;
                      border-bottom-left-radius: 8px;
                      border-bottom-right-radius: 8px;
                      padding: 0px 20px 12px;

                      .row {
                        font-size: 14px;
                        line-height: 22px;
                        color: rgba(0, 0, 0, 0.6);
                        display: flex;
                        justify-content: space-between;
                        margin-top: 4px;

                        &:first-child {
                          padding-top: 12px;
                          margin-top: 0;
                        }

                        .detail-price {
                          font-weight: 500;
                          color: rgba(0, 0, 0, 0.9);
                        }

                        .info-icon {
                          transition: all .35s ease-in-out;
                        }

                        &.active {
                          .info-icon {
                            transform: rotate(-180deg);
                          }
                        }
                      }

                      .info-box {
                        height: 0;
                        background-color: rgba(250, 250, 250, 1);
                        padding: 0px 20px;
                        margin: 3px 0;
                        border-radius: 4px;
                        overflow: hidden;

                        .info-box-row {
                          font-size: 12px;
                          line-height: 22px;
                          color: rgba(0, 0, 0, 0.9);
                          display: flex;
                          justify-content: space-between;

                          .info-box-coupon {
                            color: rgba(0, 0, 0, 0.6);
                            display: inline-block;
                          }

                          .info-box-goods {
                            width: 320px;
                            overflow: hidden;
                            margin-left: 3px;
                          }
                        }

                        &.active {
                          height: auto;
                          padding: 5px 20px;
                          overflow: auto;
                        }
                      }

                      .detail-amount {
                        display: flex;
                        justify-content: space-between;
                        margin-top: 4px;

                        .left {
                          font-size: 14px;
                          line-height: 22px;
                          color: rgba(0, 0, 0, 0.6);

                          span {
                            color: #ce7720;
                            margin: 0 4px;
                          }
                        }

                        .right {
                          font-size: 14px;
                          line-height: 22px;
                          color: rgba(0, 0, 0, 0.9);

                          span {
                            color: #d4380d;
                            font-weight: 500;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .foot {
        // padding: 12px 40px;
        // line-height: 42px;
        background-color: #f99d33;
        color: var(--font-white-0, #FFF);
        font-family: PingFang SC;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        // line-height: 26px;
        // border-radius: 76px;
        cursor: pointer;
        margin-left: 24px;
        width: 136px;
        line-height: 68px;
        text-align: center;
        // padding: 8px 32px;

      }

      .foot:hover {
        background-color: #FAB357;
      }

      .foot:active {
        background-color: #E67D00;
      }

    }
  }

}

.noData {
  position: fixed !important;
}

.bottom-btn-disabled {
  background-color: #fdda9f !important;
}

.disabledBtn {
  background-color: #fdda9f !important;

  &::after {
    border: none;
  }
}

::v-deep .ivu-poptip-inner {
  border-radius: 8px;
}

// ::v-deep .ivu-checkbox:hover {
//   border-color: red;
// }

::v-deep .ivu-checkbox:hover .ivu-checkbox-inner {
  /* 在这里设置你想要的悬停颜色 */
  border-color: #F99D33;
}
