.act_time {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  padding-top: 2px;
  color: #BE5F00;
}
.act_time .time_doc {
  margin-right: 5px;
  font-size: 12px;
}
.time_num {
  display: flex;
  align-items: center;
}
.time_num li {
  list-style: none;
  font-weight: 400;
  font-size: 12px;
}
.time_num span {
  margin: 0 2px;
}
.count-time {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.count-time .activity-txt {
  font-weight: 400;
  font-size: 20rpx;
  line-height: 28rpx;
  color: #fa541c;
  margin-right: 8rpx;
}
.count-time .time-section .time {
  display: flex;
  align-items: center;
}
.count-time .time-section .time__custom {
  padding: 5rpx 2rpx 0 2rpx;
  height: 24rpx;
  background: #ffffff;
  border: 1rpx solid #ffbb96;
  border-radius: 4rpx;
  display: flex;
  justify-content: center;
  align-items: center;
}
.count-time .time-section .time__custom__item {
  font-weight: 500;
  font-size: 20rpx;
  line-height: 20rpx;
  color: #fa541c;
}
.count-time .time-section .time__doc {
  width: 10rpx;
  height: 36rpx;
  text-align: center;
  font-weight: 400;
  font-size: 20rpx;
  line-height: 36rpx;
  color: #fa541c;
}
.time-special {
  padding-left: 21px;
  padding-right: 3px;
  border: 0.5px solid;
  height: 18px;
  border-radius: 4px;
  font-size: 12px;
  line-height: 18px;
  margin-right: 4px;
  display: inline-flex;
}
.time-special.green {
  border-color: #4DAC39;
  color: #086453;
  background: #F6FBF5 url("~@/assets/images/notic/time-special-green.png") no-repeat left center;
  background-size: auto 18px;
}
.time-special.orange {
  border-color: #FC9C57;
  color: #A63D11;
  background: #FEFAF5 url("~@/assets/images/notic/time-special-orange.png") no-repeat left center;
  background-size: auto 18px;
}
