
.contact-modal{
      min-height: 80px;
      display: flex;
      flex-direction: column;
      justify-content: left;
      align-items: flex-start;
  }
  .contact-item{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 12px;
      &-title, &-content{
          color:rgba(0, 0, 0, 0.9);
          font-size: 14px;
      }
      &-content{
          padding: 0 8px;
      }
      &-btn{
          color: rgba(56, 83, 226, 1);
          font-size: 14px;
          cursor: pointer;
      }
  }
