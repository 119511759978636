.sign-content {
  width: 1200px;
  margin: auto;
}
.main {
  padding: 24px;
  width: 1200px;
  margin: 16px auto 76px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 8px;
}
.main .sign-num {
  color: rgba(0, 0, 0, 0.9);
  /* medium/18 */
  font-family: 'PingFang SC';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  /* 144.444% */
  padding-bottom: 16px;
  border-bottom: 1px solid var(--gray-3, #eee);
  margin-bottom: 16px;
}
.main .sign-list {
  display: flex;
}
.main .sign-list .sign-list-tabs {
  width: 80px;
  margin-right: 16px;
}
.main .sign-list .sign-list-tabs .sign-list-tab {
  display: flex;
  width: 80px;
  height: 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: rgba(0, 0, 0, 0.9);
  /* medium/14 */
  font-family: 'PingFang SC';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 157.143% */
  margin-bottom: 12px;
  cursor: pointer;
}
.main .sign-list .sign-list-tabs .sign-list-tab.active {
  border-radius: 4px;
  border: 1px solid var(--brand-6, #f99d33);
  background: var(--brand-1, #fff8e8);
  color: var(--brand-7, #e67d00);
}
.main .sign-list .sign-list-content {
  width: 100%;
}
.main .sign-list .sign-list-content .company-title {
  color: rgba(0, 0, 0, 0.9);
  /* medium/16 */
  font-family: 'PingFang SC';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  margin-bottom: 4px;
}
.main .sign-list .sign-list-content .company-total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}
.main .sign-list .sign-list-content .company-total .c-t-l {
  display: flex;
  align-items: center;
}
.main .sign-list .sign-list-content .company-total .c-t-l span {
  margin-right: 8px;
  color: rgba(0, 0, 0, 0.9);
  /* regular/14 */
  font-family: 'PingFang SC';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
.main .sign-list .sign-list-content .company-total .c-t-l .red {
  color: var(--error-7, #cb272d);
  /* medium/18 */
  font-family: 'PingFang SC';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  /* 144.444% */
}
.main .sign-list .sign-list-content .company-total .c-t-r {
  color: rgba(0, 0, 0, 0.6);
  /* regular/14 */
  font-family: 'PingFang SC';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
}
.main .sign-list .sign-list-content .company-total .c-t-r span {
  cursor: pointer;
  margin-left: 8px;
}
.main .sign-list .sign-list-content .sign-list-content-bottom {
  display: flex;
}
.main .sign-list .sign-list-content .sign-list-content-bottom .s-l-c-b-l {
  position: relative;
  width: 360px;
  height: 510px;
  border: 1px solid var(--gray-3, #eee);
  margin-right: 24px;
}
.main .sign-list .sign-list-content .sign-list-content-bottom .s-l-c-b-l .full-screen {
  position: absolute;
  right: 16px;
  top: 70px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.6);
  width: 82px;
  height: 28px;
  display: flex;
  padding: 0px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  cursor: pointer;
}
.main .sign-list .sign-list-content .sign-list-content-bottom .s-l-c-b-l .full-screen span {
  color: var(--fontcolor-white-0, #fff);
  font-family: 'PingFang SC';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  /* 116.667% */
  white-space: nowrap;
}
.main .sign-list .sign-list-content .sign-list-content-bottom .s-l-c-b-l .full-screen img {
  width: 16px;
  height: 16px;
}
.main .sign-list .sign-list-content .sign-list-content-bottom .s-l-c-b-r {
  flex: 1;
}
.main .sign-list .sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal {
  margin-bottom: 24px;
}
.main .sign-list .sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .title {
  color: rgba(0, 0, 0, 0.9);
  /* medium/16 */
  font-family: 'PingFang SC';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  margin-bottom: 4px;
}
.main .sign-list .sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .tip {
  color: rgba(0, 0, 0, 0.6);
  /* regular/12 */
  font-family: 'PingFang SC';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 166.667% */
  margin-bottom: 16px;
}
.main .sign-list .sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods {
  margin-top: 16px;
}
.main .sign-list .sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-header {
  display: flex;
}
.main .sign-list .sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-header .col {
  display: flex;
  height: 40px;
  padding: 0px 8px;
  align-items: center;
  background: var(--gray-1, #fafafa);
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.9);
  /* regular/12 */
  font-family: 'PingFang SC';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 166.667% */
}
.main .sign-list .sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-header .col span.red {
  color: #cb272d;
}
.main .sign-list .sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-header .col.col-1 {
  width: 268px;
}
.main .sign-list .sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-header .col.col-2 {
  width: 112px;
}
.main .sign-list .sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-header .col.col-3 {
  width: 112px;
}
.main .sign-list .sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-header .col.col-4 {
  flex: 1;
}
.main .sign-list .sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-header .col.col-5 {
  width: 48px;
}
.main .sign-list .sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-body {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid var(--gray-3, #eee);
}
.main .sign-list .sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-body .col {
  height: 80px;
  padding: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.main .sign-list .sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-body .col .abnormal-num :deep(.ivu-select-selected-value),
.main .sign-list .sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-body .col .abnormal-num :deep(.ivu-input-number-input),
.main .sign-list .sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-body .col .abnormal-num :deep(.ivu-input) {
  padding: 8px;
  color: rgba(0, 0, 0, 0.9);
  /* regular/12 */
  font-family: 'PingFang SC';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
}
.main .sign-list .sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-body .col .abnormal-num :deep(.ivu-select-selected-value) {
  padding: 8px 20px 8px 8px;
}
.main .sign-list .sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-body .col .abnormal-num :deep(.ivu-select-placeholder),
.main .sign-list .sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-body .col .abnormal-num :deep(.ivu-input-number-input::placeholder),
.main .sign-list .sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-body .col .abnormal-num :deep(.ivu-input::placeholder) {
  color: rgba(0, 0, 0, 0.4);
  font-family: 'PingFang SC';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}
.main .sign-list .sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-body .col .abnormal-num :deep(.ivu-select-dropdown) {
  padding: 0px;
  width: 270px;
  max-height: 344px;
}
.main .sign-list .sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-body .col .abnormal-num :deep(.ivu-select-dropdown-list) {
  padding: 4px;
}
.main .sign-list .sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-body .col .abnormal-num :deep(.ivu-select-item),
.main .sign-list .sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-body .col .abnormal-num :deep(.ivu-select-item-focus) {
  border-radius: 4px;
  display: flex;
  height: 28px;
  padding: 0px 12px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  /* regular/12 */
  font-family: 'PingFang SC';
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 166.667% */
}
.main .sign-list .sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-body .col .abnormal-num :deep(.ivu-select-item) {
  color: rgba(0, 0, 0, 0.9);
}
.main .sign-list .sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-body .col .abnormal-num :deep(.ivu-select-item-focus) {
  color: var(--brand-7, #e67d00) !important;
  background: var(--brand-1, #fff8e8);
}
.main .sign-list .sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-body .col .abnormal-num :deep(.ivu-select-selection:hover),
.main .sign-list .sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-body .col .abnormal-num :deep(.ivu-select-selection-focused) {
  border-color: #fab15c;
}
.main .sign-list .sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-body .col.col-1 {
  width: 268px;
}
.main .sign-list .sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-body .col.col-1 div {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.main .sign-list .sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-body .col.col-1 div.good-name {
  color: rgba(0, 0, 0, 0.9);
  font-family: 'PingFang SC';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 166.667% */
}
.main .sign-list .sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-body .col.col-1 div.good-company,
.main .sign-list .sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-body .col.col-1 div.good-code {
  color: rgba(0, 0, 0, 0.6);
  /* regular/12 */
  font-family: 'PingFang SC';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}
.main .sign-list .sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-body .col.col-2 {
  width: 112px;
}
.main .sign-list .sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-body .col.col-2 .abnormal-num {
  width: 96px;
}
.main .sign-list .sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-body .col.col-3 {
  width: 112px;
}
.main .sign-list .sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-body .col.col-3 .abnormal-num {
  width: 76px;
}
.main .sign-list .sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-body .col.col-3 .unit {
  color: rgba(0, 0, 0, 0.9);
  /* regular/12 */
  font-family: 'PingFang SC';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 166.667% */
  margin-left: 8px;
}
.main .sign-list .sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-body .col.col-4 {
  flex: 1;
}
.main .sign-list .sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-body .col.col-4 .abnormal-num {
  width: 100%;
}
.main .sign-list .sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-body .col.col-5 {
  width: 48px;
}
.main .sign-list .sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-body .col.col-5 .del-btn {
  color: var(--hblue-6, #3853e2);
  /* regular/12 */
  font-family: 'PingFang SC';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 166.667% */
  cursor: pointer;
}
.main .sign-list .sign-list-content .sign-list-content-bottom .s-l-c-b-r .certificate .title {
  color: rgba(0, 0, 0, 0.9);
  /* medium/16 */
  font-family: 'PingFang SC';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  margin-bottom: 4px;
}
.btns {
  box-shadow: 4px 0px 20px 0px rgba(0, 0, 0, 0.08);
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background: #fff;
  padding: 16px 24px;
  width: 1200px;
  height: 60px;
  border-radius: 8px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btns .btns-l .icon {
  color: #f99d33;
  font-size: 16px;
  font-weight: 600;
  margin-right: 8px;
}
.btns .btns-l .tip {
  color: rgba(0, 0, 0, 0.9);
  /* regular/14 */
  font-family: 'PingFang SC';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
}
.btns .btns-r {
  display: flex;
  align-items: center;
}
.btns .btns-r .user {
  color: rgba(0, 0, 0, 0.9);
  /* regular/14 */
  font-family: 'PingFang SC';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
  margin-right: 24px;
}
.btns .btns-r .btn {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 92px;
  height: 36px;
  border-radius: 4px;
  background: var(--brand-6, #f99d33);
  color: var(--font_white-0, #fff);
  /* regular/14 */
  font-family: 'PingFang SC';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
}
:deep(.sign-tip-modal) .ivu-modal-content {
  width: 480px;
  padding: 20px 24px;
  border-radius: 8px;
}
:deep(.sign-tip-modal) .ivu-modal-content .ivu-modal-header {
  padding: 0;
  border: none;
}
:deep(.sign-tip-modal) .ivu-modal-content .ivu-modal-header .ivu-modal-header-inner {
  color: rgba(0, 0, 0, 0.9);
  /* medium/16 */
  font-family: 'PingFang SC';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
}
:deep(.sign-tip-modal) .ivu-modal-content .ivu-modal-body {
  padding: 16px 0;
  color: rgba(0, 0, 0, 0.9);
  /* regular/14 */
  font-family: 'PingFang SC';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
}
:deep(.sign-tip-modal) .ivu-modal-content .ivu-modal-footer {
  padding: 0;
  border: none;
}
:deep(.sign-tip-modal) .ivu-modal-content .ivu-modal-footer .cancel-btn {
  color: rgba(0, 0, 0, 0.9);
  /* regular/14 */
  font-family: 'PingFang SC';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
  padding: 0 12px;
  border-radius: 4px;
  border: 1px solid var(--gray-5, #dcdcdc);
}
:deep(.sign-tip-modal) .ivu-modal-content .ivu-modal-footer .confirm-btn {
  color: var(--fontcolor-white-0, #fff);
  /* regular/14 */
  font-family: 'PingFang SC';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
  padding: 0 12px;
  border-radius: 4px;
  background: var(--brand-6, #f99d33);
  margin-left: 12px;
}
:deep(.sign-tip-modal) .ivu-modal-content .ivu-modal-close {
  font-size: 14px;
  right: 18px;
  top: 14px;
}
.sign-success {
  width: 1200px;
  margin: 16px auto 16px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 8px;
  height: calc(100vh - 175px);
  overflow-y: hidden;
  padding: 24px;
}
.sign-success .pic-placeholder {
  margin: 40px auto 24px;
  text-align: center;
  width: 120px;
  height: 96px;
}
.sign-success .pic-placeholder img {
  width: 100%;
  height: 100%;
}
.sign-success .tip1 {
  color: rgba(0, 0, 0, 0.9);
  /* medium/16 */
  font-family: 'PingFang SC';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  text-align: center;
}
.sign-success .tip2 {
  color: rgba(0, 0, 0, 0.6);
  /* regular/14 */
  font-family: 'PingFang SC';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
  text-align: center;
  margin: 8px 0 4px;
}
.sign-success .tip3 {
  color: var(--hbrand-6, #3853e2);
  /* regular/14 */
  font-family: 'PingFang SC';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sign-success .tip3 span {
  margin-right: 4px;
}
