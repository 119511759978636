
.cms-dialog {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 100000;

	._shade {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background: #000;
		opacity: .6;
		z-index: 110000;
	}

	.box {
		width: 548px;
		background-size: 100% auto;
		height: 358px;
		background-color: #fff;
		border-radius: 8px;
		position: absolute;
		background-repeat: no-repeat;
		z-index: 110001;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		padding: 24px;
		box-sizing: border-box;
		.header {
			height: 22px;
			display: flex;
			.title {
				font-size: 18px;
				font-weight: 500;
				color: rgba(0, 0, 0, 0.9);
				text-align: center;
			}
			.close {
				margin: 0 0 0 auto;
				width: 20px;
				height: 20px;
				cursor: pointer;
			}
		}
		.region {
			box-sizing: border-box;
			display: flex;
			height: 284px;
			flex-direction: column;
			.content {
				font-size: 14px;
				line-height: 48px;
				color: rgba(0, 0, 0, 0.6);
				// margin-top: 24px;
				flex: 1;
				white-space:pre-wrap;
				word-break: break-all;
				overflow: hidden auto;
			}
		}

		.btns {
			width: 100%;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			height: 36px;
			// padding: 48px 40px;
			box-sizing: border-box;

			.btn {
				// flex: 1;
				line-height: 36px;
				text-align: center;
				border-radius: 4px;
				font-size: 14px;
				width: 92px;
				// width: 30%;
				// height: 36px;
				cursor: pointer;
				&:nth-child(1) {
					color: rgb(255, 255, 255);
    				background-color: rgb(249, 157, 51);
				}
				&:nth-child(2) {
					color: rgba(0, 0, 0, 0.4);
    				background-color: rgb(238, 238, 238);
					margin-left: 16px;
				}
			}
		}
		.zone {
			position: absolute;
		}
	}
}
