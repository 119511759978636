
.search-bar {
  background-color: #fff;
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 3;

  // position: sticky;
  // z-index: 3;
  // top: 36px
  &.box-shadow {
    box-shadow: 4px 0px 20px 0px rgba(0, 0, 0, 0.08);
  }
}

.lock_record {
  width: 216px;
  height: 238px;
  margin: 10% auto;

  img {
    width: 100%;
    height: 100%;
  }
}

.list {
  width: 1200px;
  margin: 0 auto;

  .item {
    background: #FFF;
    border-radius: 8px;
    margin-bottom: 16px;
    height: 225px;
    display: flex;

    .shop-info {
      padding: 16px;
      box-sizing: border-box;
      width: 288px;
      height: 100%;

      .left {
        cursor: pointer;
      }

      .org-abbr {
        color: rgba(0, 0, 0, 0.90);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        margin-left: 8px;
        cursor: pointer;
        // display: -webkit-box;
        // -webkit-line-clamp: 2;
        // -webkit-box-orient: vertical;
        // overflow: hidden;
        // text-overflow: ellipsis;
      }

      .middle {
        color: rgba(0, 0, 0, 0.60);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin-top: 8px;
      }
    }

    .saved_img {
      width: 75px !important;
      margin-right: 8px;
    }

    .follow-container {
      margin-right: 8px;
      height: 30px;

      :hover {
        background-color: #E7E7E7;
        border-radius: 20px;
      }
    }

    .follow {
      width: 64px;
      height: 30px;
      fill: none;
    }

    .btns {
      display: flex;
      height: 30px;
      margin: 8px 0 12px 0;

      .contact {
        border-radius: 24px;
        border: 0.5px solid #DCDCDC;
        padding: 0 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .contact-org {
          margin-left: 6px;
          color: #3853E2;
        }
      }
    }

    .icon {
      width: 16px;
      height: 16px;
      margin-left: 4px;
    }

    .go-shop {
      border-color: #DCDCDC;
      color: rgba(0, 0, 0, 0.90);
      font-size: 14px;
      display: flex;
      align-items: center;
      gap: 8px;
      border-radius: 8px;
    }
  }

  .product {
    width: 228px;
    border-left: 0.5px solid #E7E7E7;
    padding: 12px 16px 16px;
    box-sizing: border-box;

    .img {
      width: 120px;
      height: 120px;
      margin: 0 auto;
    }

    .price {
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px;
      color: #CB272D;
      margin: 10px 0 2px;
    }

    .name {
      color: rgba(0, 0, 0, 0.90);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .product-preparing-good {
    display: flex;
    justify-content: center;

    .preparing-good {
      height: 120px;
      height: 120px;
      margin-top: 40px;
    }
  }
}
