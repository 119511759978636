
.coupon {
  width: 100%;
  border-radius: 8px;
  // background: rgba(245, 63, 63, 0.05);
  // padding: 8px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // gap: 8px;
  box-sizing: border-box;
  position: relative;
  &.bg {
    // background: url(~@/assets/home/coupon-bg.png)  no-repeat;
    background-size: 100% 100%;
  }
  .max-full {
    // position: absolute;
    // top: 0;
    // right: 0;
    // font-size: 12px;
    // color: rgba(245, 63, 63, 1);
    // background-color: rgba(255, 236, 232, 1);
    // padding: 0 8px;
    // border-radius: 0 8px;
  }
  .left{
    flex: 1;
    max-width: 136px;
  }
  .org-name {
    color: rgba(0, 0, 0, 0.90);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
  .usable-range {
    color: #F53F3F;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12.583px;
    margin-top: 4px;
    margin-bottom: 2px;
  }
  .join-show {
    color: #F53F3F;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  .coupon-type{
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.9);
    max-width: 210px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .condition{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    margin-top: 4px;
  }
  .rule{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    margin-top: 8px;
    img{
      width: 12px;
      height: 12px;
      margin-right: 4px;
    }
  }
  .right{
    text-align: center;
    color: rgba(245, 63, 63, 1);
    font-size: 24px;
    font-weight: 500;
    padding-top: 6px;
    .full-num{
      font-weight: 400;
      font-size: 12px;
    }
  }
  .unit {
    font-size: 14px;
  }

}
.coupon-box{
  border-radius: 10px 8px 8px 10px;
  // height: 100%;
  background: linear-gradient(15.93deg, #FC3844 6.6%, #FF7463 95.67%);
  .coupon-left{
    z-index: 1;
    border-radius: 8px;
    background-color: rgba(255, 245, 245, 1);
    flex: 1;
    height: 100%;
    // max-width: 136px;
    padding: 6px 16px 6px 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // align-content: space-between;
    color:#F53F3F;
    .num-pic{
      font-family: PingFang SC;
      font-size: 0;
      text-align: left;
    }
    .money-text{
      font-size: 24px;
      font-weight: 600;
      line-height: 24px;
    }
    .unit{
      font-weight: 500;
      font-size: 14px;
    }
    .full-num{
      font-size: 12px;
      font-weight: 400;
    }
    .org-name{
      color: rgba(0, 0, 0, 0.60);
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;

    }

  }
  .coupon-right{
    flex-basis: 36px;
    height: 76px;
    padding-top: 4px;
    border-radius: 0 8px 8px 0;
    // background: linear-gradient(15.93deg, #FC3844 6.6%, #FF7463 95.67%);
    background: linear-gradient(16deg, #FC3844 6.6%, #FF7463 95.67%);

    //styleName: medium/12;
    font-family: PingFang SC;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: #FFFDF9;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    cursor: pointer;
    span{
      font-weight: 500;
      writing-mode: vertical-lr;
      letter-spacing: 4px;
    }
    &::after{
      content: '';
      background: rgb(255, 82, 82);
      display: block;
      width: 12px;
      height: 12px;
      border-radius: 12px ;
      overflow: hidden;
      position: absolute;
      top: 50%;
      left: -6px;
      z-index: 1;
      transform: translateY(-50%);
    }

  }
}
