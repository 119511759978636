
:deep(.match-result) {
  width: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  padding: 0px 4px;
  border-radius: 4px;
  border: 1px solid var(--success-7, #349425);
  background: rgba(52, 148, 37, 0.08);
  color: var(--success-8, #1F7C15);
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}

:deep(.match-fail) {
  border-color: var(--error-7, #CB272D);
  background: rgba(203, 39, 45, 0.08);
  color: var(--error-7, #CB272D);
}

:deep(.good-info) {
  p {
    width: 205px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

:deep(.ivu-tooltip) {
  display: flex;
}

:deep(.ivu-table-header) {
  // padding: 8px 16px;
  border-radius: 8px 8px 0px 0px;
  border: 1px solid var(--gray-2, #EEE);
  background: var(--gray-1, #FAFAFA);

  .ivu-table-cell {
    padding: 0;

    span {
      color: var(--font-black-2, rgba(0, 0, 0, 0.90));
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
    }
  }

  th {
    background: none;
    border-bottom: none;
    border-right: 1px solid #eee;

    &:last-child {
      border-right: none;
    }
  }
}

:deep(.ivu-table-tbody) {
  .ivu-table-cell {
    padding: 0;
  }

  tr {
    border-bottom: 1px solid var(--gray-2, #EEE);

    &:last-child {
      border-bottom: none;
    }
  }

  td {
    font-size: 12px;
    padding: 12px 0;
    border-bottom: none;
    vertical-align: top;
    background: none;
    border-right: 1px solid #eee;

    &:last-child {
      border-right: none;
    }
  }

  .ivu-table-row-hover {
    td {
      background: #FFF8F0 !important;
    }
  }
}

:deep(table) {
  border-spacing: 16px 0;
  border-collapse: collapse;
  width: 1135px !important;
  height: 36px;
}

:deep(.ivu-table) {
  border-radius: 8px;

  &:before {
    display: none;
  }
}

:deep(.ivu-table-body) {
  // padding: 0px 16px;
  // border-radius: 0px 0px 8px 8px;
  border: 1px solid var(--gray-2, #EEE);
  border-top: none;
}

:deep(.ivu-table-tip) {
  height: 238px;
  padding: 12px 16px;
  box-sizing: border-box;
  border-radius: 0px 0px 8px 8px;
  border: 1px solid var(--gray-2, #EEE);
  border-top: none;

  td {
    border: none;
    margin: 0 auto;
    display: block;
    width: 216px !important;
    height: 216px;
    background: url(../../../assets/center/noData.png) 0 0 no-repeat;
    background-size: 100% 100%;

    span {
      display: none;
    }
  }
}

:deep(.ivu-input-number-controls-outside-btn) {
  height: 24px;
  line-height: 22px;
  width: 28px !important;
}

:deep(.ivu-input-number-input-wrap) {
  height: 24px;
  line-height: 22px;
}

:deep(.ivu-input-number-input) {
  height: 24px;
  line-height: 22px;
  text-align: center;
}

:deep(.stock) {
  color: var(--font-black-2, rgba(0, 0, 0, 0.60));
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}

:deep(.tip) {
  margin-top: 4px;
  width: 112px;
  text-align: center;
  color: var(--font_black-2, rgba(0, 0, 0, 0.60));
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}

:deep(.price) {
  color: var(--font-black-2, rgba(0, 0, 0, 0.90));
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}

:deep(.action) {
  color: var(--hbrand-6, #3853E2);
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;

  &:hover {
    cursor: pointer;
  }
}

:deep(.undelete) {
  opacity: 0.6;
}

:deep(.select) {
  margin-right: 16px;
}

:deep(.ivu-btn:focus) {
  box-shadow: none;
}

:deep(.ivu-input) {
  padding: 4px 12px;
  border-radius: 4px;
  border: 1px solid var(--gray-2, #EEE);

  &:focus {
    box-shadow: none;
  }
}

:deep(.ivu-modal-header) {
  border-bottom: none;
  padding: 20px 16px 0;
}

:deep(.ivu-modal-footer) {
  border-top: none;
}

.batch-order-wrap1 {
  background: #fff;
  padding: 24px;
  height: calc(100vh - 266px);

  // overflow-y: auto;
  .batch-order-content1 {

    .search-detail {
      padding: 0 0 12px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      // border-bottom: 1px solid var(--gray-2, #EEE);
      .search {
        display: flex;
        align-items: center;

        h4 {
          margin-right: 16px;
          color: #000;
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
        }

        img {
          width: 18px;
          height: 18px;
          margin-right: 4px;
        }
      }

      .operate {
        display: flex;
        align-items: center;
        gap: 12px;
        .expand {
          line-height: normal;
        }
        .operation-instructions {
          color: var(--font-black-1, rgba(0, 0, 0, 0.60));

          i {
            margin-left: 4px;
            font-weight: 600;
          }

          &:hover {
            color: rgba(230, 125, 0, 1);
            cursor: pointer;
          }
        }
      }
    }

    .shop-name {
      padding: 12px 0;
      color: var(--font-black-2, rgba(0, 0, 0, 0.60));
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
    }

    .add-btn {
      margin-top: -6px;
      display: flex;
      height: 42px;
      padding: 14px 16px 8px;
      justify-content: center;
      align-items: center;
      background: var(--gray-1, #FAFAFA);
      border-radius: 0px 0px 8px 8px;
      border-right: 1px solid var(--gray-2, #EEE);
      border-bottom: 1px solid var(--gray-2, #EEE);
      border-left: 1px solid var(--gray-2, #EEE);
    }

    .unable {
      background: var(--brand-3, #FDDA9F) !important;
    }
  }
}

.operation {
  margin-top: 16px;
  width: 1200px;
  position: fixed;
  bottom: 0;
  display: flex;
  height: 68px;
  padding: 16px 24px;
  justify-content: space-between;
  align-items: center;
  background: var(--font-white-0, #FFF);

  .operation-check {
    display: flex;
    align-items: center;
  }

  .message {
    display: flex;
    align-items: center;
    color: var(--font-black-1, rgba(0, 0, 0, 0.90));
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
  }

  .detail {
    display: flex;
    align-items: center;

    .total {
      margin-right: 32px;

      .num {
        color: var(--font-black-2, rgba(0, 0, 0, 0.60));
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;

        .count {
          color: var(--brand-7, #CE7720);
        }
      }

      .total-price {
        text-align: right;
        margin-top: 6px;
        color: var(--font-black-1, rgba(0, 0, 0, 0.90));
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;

        .price {
          color: var(--error-7, #CB272D);
          font-size: 18px;
          font-weight: 500;
          line-height: 20px;
        }
      }
    }

    .operation-btn {
      .back-btn {
        margin-right: 12px;
        height: 36px;
        padding: 8px 16px;
        border-radius: 4px;
        border: 1px solid var(--gray-4, #DCDCDC);
        color: var(--font-black-1, rgba(0, 0, 0, 0.90));
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
      }

      .submit-btn {
        margin-left: 12px;
        height: 36px;
        padding: 8px 16px;
        border-radius: 4px;
        background: var(--brand-6, #F99D33);
        border: none;
        color: var(--font-white-0, #FFF);
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
      }

      .unable {
        background: var(--brand-3, #FDDA9F) !important;
      }

      .create-plan-btn {
        height: 36px;
        // padding: 8px 16px;
        border-radius: 4px;
        background: var(--error-6, #FFF);
        color: rgba(0, 0, 0, 0.4);
        font-size: 14px;
        font-weight: 400;
        border-color: #DCDCDC;
      }

      .uncheckAll {
        color: rgba(0, 0, 0, 0.9);
      }
    }
  }

  .check-all {
    color: var(--font-black-1, rgba(0, 0, 0, 0.90));
    font-size: 14px;
    font-weight: 400;
    line-height: 1;

    :deep(.ivu-checkbox) {
      margin-right: 16px;
    }
  }

  .delete-btn {
    margin-left: 16px;

    .dele {
      height: 36px;
      // padding: 8px 16px;
      border-radius: 4px;
      background: var(--error-6, #FFF);
      color: rgba(0, 0, 0, 0.4);
      font-size: 14px;
      font-weight: 400;
      border-color: #DCDCDC;
    }

    .uncheckAll {
      color: rgba(0, 0, 0, 0.9);
    }
  }
}

.isDel {
  :deep(.ivu-table-header) {
    th {
      padding: 0 8px;
    }
  }

  :deep(.ivu-table-tbody) {
    td {
      padding: 4px 8px;
      height: 36px;
      vertical-align: middle;
      color: rgba(0, 0, 0, 0.9);
    }
  }
}

.instruct-modal {
  z-index: 999;
  width: 487px;
  // height: 432px;
  position: absolute;
  right: 20px;
  top: 72px;
  background-color: #fff;
  border-radius: 8px;
  // padding: 12px 16px;
  box-shadow: 0px 16px 20px -10px rgba(0, 0, 0, 0.04);
  box-shadow: 0px 32px 48px 4px rgba(0, 0, 0, 0.02);
  box-shadow: 0px 12px 60px 10px rgba(0, 0, 0, 0.03);

  img {
    width: 100%;
  }
}

:deep(.product-a) {
  &:hover {
    color: #3853E2 !important;
  }

}

.front-display-label-box {
    overflow: hidden;
    white-space: nowrap;
}
.front-display-label {
  color: rgba(160, 112, 60, 1);
}
.front-display-label-split {
    margin: 0 4px;
    font-size: 10px;
    color: rgba(160, 112, 60, 1);
    border-right: 1px solid rgba(160, 112, 60, 0.3);
}

:deep(.product-count-input) {
  border-radius: 0;
  text-align: center;
  width: 56px;
  height: 36px;
  border-left: none;
  border-right: none;

  .ivu-input-number {

    &:focus {
      border-top-color: #dcdee2;
      border-bottom-color: #dcdee2;
    }
  }

  .ivu-input-number-input {
    padding: 0;
    height: 36px;
    line-height: 36px;
  }

  .ivu-input-number-handler-wrap {
    display: none;
  }

}

:deep(.product-count-btn) {
  width: 28px;
  height: 36px;
  background: #f8f8f9;
  color: rgba(75, 75, 75, 1);
  border: 1px solid #dcdee2;
  font-size: 20px;

  &:hover {
    color: rgba(249, 157, 51, 1);
    cursor: pointer;
  }
}

:deep(.product-count-hover-btn) {
  width: 28px;
  height: 36px;
  background: #f8f8f9;
  color: rgba(230, 125, 0, 1);
  border: 1px solid #dcdee2;

  &:hover {
    cursor: pointer;
  }
}

:deep(.text-class) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  box-orient: vertical;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  max-height: 42px
}
.near-date{
  text-align: center;
}
