:deep(.ivu-modal-header) {
  border-bottom: none;
  padding: 20px 16px 0;
}
:deep(.ivu-modal-body) {
  padding: 24px;
}
:deep(.ivu-modal-footer) {
  border-top: none;
}
:deep(.ivu-modal-footer) .tip {
  position: absolute;
  left: 16px;
  bottom: 16px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
:deep(.ivu-modal-footer) .tip .download-icon {
  width: 20px;
  height: 20px;
}
:deep(.ivu-modal-footer) .tip .down-load {
  color: var(--hbrand-6, #3853E2);
}
:deep(.ivu-modal-footer) .tip .down-load:hover {
  cursor: pointer;
}
:deep(.ivu-modal-footer) .tip .export {
  margin-left: 10px;
}
:deep(.ivu-modal-footer) .ivu-btn-primary[disabled] {
  background: var(--brand-3, #FDDA9F);
  color: #fff;
  border-color: #fdda9f;
}
