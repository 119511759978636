::v-deep .ivu-modal-content {
  display: flex;
  border-radius: 8px;
  background: var(--font_white-0, #FFF);
}
::v-deep .ivu-modal-header {
  height: 0px;
  padding: 0px;
  margin: 0px;
}
::v-deep .ivu-modal-footer {
  height: 0px;
  padding: 0px;
  margin: 0px;
}
::v-deep .ivu-modal-body {
  display: flex;
  padding: 20px 24px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  width: 100%;
  gap: 16px;
}
::v-deep .ivu-modal-body .title {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
}
::v-deep .ivu-modal-body .title img {
  width: 16px;
  height: 16px;
}
::v-deep .ivu-modal-body .title span {
  color: var(--fontcolor-black-1, rgba(0, 0, 0, 0.9));
  /* medium/16 */
  font-family: "PingFang SC";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
}
::v-deep .ivu-modal-body .title .close-icon {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
::v-deep .ivu-modal-body .title .close-icon .img {
  width: 16px;
  height: 16px;
  cursor: pointer;
}
::v-deep .ivu-modal-body .content {
  color: var(--font_black-1, rgba(0, 0, 0, 0.9));
  /* regular/14 */
  font-family: "PingFang SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
  margin-left: 23px;
}
::v-deep .ivu-modal-body .bottom {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 12px;
}
