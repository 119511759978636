
html {
  height: 100%;
}

body {
  height: 100%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
  min-width: 1200px;

  display: flex;
  flex-direction: column;
  min-height: 100%;
  // background: #F5F5F5;
}

.header {
  height: 60px;
}

.content {
  flex: 1;
}

.footer {
  height: 120px;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.main-content {
  width: 100%;
  background-color: #F5F5F5;
  position: relative;
}

.loginPage {
  margin-top: -20px;

  .ivu-form-item {
    margin-bottom: 32px;
  }
}
