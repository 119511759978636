.licence-btn {
  width: 168px;
  height: 40px;
  background: #FFFFFF;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 14px;
  box-sizing: border-box;
  cursor: pointer;
  user-select: none;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);
  border: 1px solid #EEE;
}
.licence-icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}
