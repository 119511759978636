::v-deep .exhange-popup {
  top: 91px;
  right: -801px;
}
::v-deep .exhange-popup .ivu-modal .ivu-modal-content .ivu-modal-body {
  padding: 20px 24px;
}
::v-deep .exhange-popup .ivu-modal .ivu-modal-content .ivu-modal-body .exhange-popup-wrap .title-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
::v-deep .exhange-popup .ivu-modal .ivu-modal-content .ivu-modal-body .exhange-popup-wrap .title-box .title-text {
  margin-left: 8px;
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
}
::v-deep .exhange-popup .ivu-modal .ivu-modal-content .ivu-modal-body .exhange-popup-wrap .content {
  font-size: 14px;
  margin: 12px 0;
  padding-left: 25px;
}
::v-deep .exhange-popup .ivu-modal .ivu-modal-content .ivu-modal-body .exhange-popup-wrap .content .store-num {
  color: #E67D00;
  padding: 0 2px;
}
::v-deep .exhange-popup .ivu-modal .ivu-modal-content .ivu-modal-body .exhange-popup-wrap .btn-wrap {
  display: flex;
  justify-content: flex-end;
}
::v-deep .exhange-popup .ivu-modal .ivu-modal-content .ivu-modal-footer {
  text-align: center;
  font-size: 16px;
  color: #ce7720;
  cursor: pointer;
}
::v-deep .exhange-popup .ivu-modal .ivu-modal-content .ivu-modal-footer span {
  display: inline-block;
}
