.tag {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 133.333% */
}
.tag .dot {
  width: 8px;
  height: 8px;
  top: 50%;
  border-radius: 50%;
}
.tag-green {
  color: #034D00;
  background-color: rgba(77, 172, 57, 0.1);
}
.tag-blue {
  color: #2438BC;
  background-color: rgba(56, 83, 226, 0.1);
}
.tag-orange {
  color: #CE7720;
  background: #FFF8E8;
  border: #CE7720 1px solid;
}
.tag-red {
  color: #D4380D;
  background: #FFF2E8;
  border: #FF7A45 1px solid;
}
.review {
  position: relative;
  margin-right: 10px;
  padding-left: 16px;
  font-size: 16px;
}
.review::before {
  content: '';
  position: absolute;
  left: 0;
  width: 8px;
  height: 8px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
}
.review-success {
  color: #1F7C15;
}
.review-success::before {
  background-color: #4DAC39;
}
.review-waiting {
  color: #2438BC;
}
.review-waiting::before {
  background-color: #3853E2;
}
.review-refuse {
  color: #CB272D;
}
.review-refuse::before {
  background-color: #CB272D;
}
