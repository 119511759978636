.right-content {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  background: #fff;
}
:deep(tbody .ivu-table-cell) {
  color: var(--font_black-1, rgba(0, 0, 0, 0.9));
  /* regular/12 */
  font-family: "PingFang SC";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 166.667% */
}
:deep(thead .ivu-table-cell) {
  color: var(--font_black-1, rgba(0, 0, 0, 0.9));
  /* regular/12 */
  font-family: "PingFang SC";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 166.667% */
}
:deep(.tag) {
  padding: 0px 4px;
  align-items: center;
  gap: 4px;
  justify-content: center;
}
:deep(.un_open_auth) {
  border-radius: 4px;
  border: 1px solid var(--error-3, #FBACA3);
  background: var(--error-1, #FFECE8);
  color: var(--error-6, #F53F3F);
  /* regular/12 */
  font-family: "PingFang SC";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 166.667% */
}
:deep(.un_auth) {
  border-radius: 4px;
  border: 1px solid var(--brand-3, #FDDA9F);
  background: var(--brand-1, #FFF8E8);
  color: var(--brand-7, #E67D00);
  /* regular/12 */
  font-family: "PingFang SC";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 166.667% */
}
:deep(.auth) {
  border-radius: 4px;
  border: 1px solid var(--success-3, #AADE97);
  background: var(--success-1, #F0FFE8);
  color: var(--success-6, #4DAC39);
  /* regular/12 */
  font-family: "PingFang SC";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 166.667% */
}
:deep(.expired) {
  overflow: hidden;
  color: var(--error-6, #F53F3F);
  text-overflow: ellipsis;
  /* medium/12 */
  font-family: "PingFang SC";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 166.667% */
  padding-left: 8px;
}
