.v-poster {
  width: 1200px;
  display: flex !important;
  flex-direction: row;
}
.v-poster > div {
  flex: 1;
  margin-left: 16px;
}
.v-poster > div:first-child {
  margin-left: 0;
}
.v-poster .img {
  width: 100%;
  border-radius: 8px;
}
