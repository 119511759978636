
.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  ::v-deep .ivu-page {
    .ivu-page-item {
      border: 1px solid #E7E7E7;
      border-radius: 4px;
      margin: 0 4px;
      &:hover{
        background: #eee;
        a {
          color: rgba(0, 0, 0, 0.9);
        }
      } 
    }


    .ivu-page-item-active {
      a {
        color: rgba(0, 0, 0, 0.9);
      }
      background: #eee;
    }
    .ivu-page-prev, .ivu-page-next {
      background: transparent;
      &:hover{
        background: #eee;
        border: 1px solid #E7E7E7;
        a {
          color: rgba(0, 0, 0, 0.9);
        }
      } 
    }
    .ivu-page-options-elevator input{
      text-align: center;
    }
    .ivu-page-options-elevator input:focus{
      border: 1px solid #A6A6A6;
      box-shadow: 0 0px 0px #A6A6A6;
    }
    .ivu-page-options-elevator input:hover{
      border: 1px solid #A6A6A6;
      box-shadow: 0 0px 0px #A6A6A6;
    }
    .ivu-select-selection .ivu-select-selection:hover{
      border: 1px solid #A6A6A6;
      box-shadow: 0 0px 0px #A6A6A6;
      color: rgba(0, 0, 0, 0.9);
    }
    .ivu-icon{
      color: rgba(0, 0, 0, 0.9);
    }
  }

  .theme-color {
    color: rgba(0, 0, 0, 0.9);
  }
  .ml8 {
    margin-left: 8px;
  }
}
