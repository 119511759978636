.events {
  position: relative;
  max-width: 100%;
  max-height: 100%;
  width: 100%;
}
.events .event-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 392px;
  height: 392px;
}
.events .coupon {
  background: url("/img/Event/coupon.png") no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.events .coupon_list {
  width: 48px;
  height: 52px;
  position: absolute;
  right: 8px;
  top: 8px;
}
.events .coupon_list .text {
  margin-top: 2.5px;
  color: #E90028;
  font-family: "PingFang SC";
  font-size: 7px;
  font-style: normal;
  font-weight: 500;
  line-height: 5px;
  /* 71.429% */
}
.events .coupon_list .content-num {
  height: 24px;
  color: #FFFDFC;
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 600;
  margin-top: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.events .coupon_list .num {
  color: #FFFDFC;
  font-family: "PingFang SC";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 100% */
}
.events .coupon_list .unit {
  color: #FFFDFC;
  font-family: "PingFang SC";
  font-size: 10px !important;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.events .coupon_detail {
  width: 60px;
  height: 66px;
  position: absolute;
  right: 16px;
  top: 16px;
}
.events .coupon_detail .text {
  margin-top: 3.5px;
  color: #E90028;
  font-family: "PingFang SC";
  font-size: 7px;
  font-style: normal;
  font-weight: 500;
  line-height: 5px;
  /* 71.429% */
}
.events .coupon_detail .content-num {
  height: 30px;
  color: #FFFDFC;
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 600;
  margin-top: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.events .coupon_detail .num {
  color: #FFFDFC;
  font-family: "PingFang SC";
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 100% */
}
.events .coupon_detail .unit {
  color: #FFFDFC;
  font-family: "PingFang SC";
  font-size: 12px !important;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
