
::v-deep .frozen-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  .ivu-modal {
    top: 0;
    .ivu-modal-content {
      .ivu-modal-body {
        .frozen-content {
          text-align: center;
          .title {
            font-size: 16px;
            font-weight: 500;
            color: #000;
          }
          .content {
            font-size: 16px;
            margin-top: 10px;
          }
        }
      }
      .ivu-modal-footer {
        text-align: center;
        font-size: 16px;
        color: #ce7720;
        cursor: pointer;
        span {
          display: inline-block;
        }
      }
    }
  }
}
