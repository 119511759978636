
.upload-content {
  display: inline-flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 10px;
  .upload {
    .ivu-upload-drag {
      border: 1px solid #dcdee2 !important;
    }
    .upload-icon {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 118px;
      height: 118px;
      color: rgba(0, 0, 0, 0.6);
      font-size: 12px;
      &-loading {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }
    }
  }
  .upload-list {
    display: flex;
    // gap: 20px;
    gap: 15px;
    flex-wrap: wrap;
    &-item {
      width: 120px;
      height: 120px;
      text-align: center;
      line-height: 120px;
      border: 1px solid #dcdee2;
      padding: 6px;
      border-radius: 4px;
      overflow: hidden;
      background: #fff;
      position: relative;
      // box-shadow: 0 1px 1px rgba(0, 0, 0, .2);
      box-sizing: border-box;
      img {
        width: 100%;
        height: 100%;
      }
      .upload-list-cover {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.6);
        opacity: 0;
        transition: opacity 0.5s ease;
        i {
          color: #fff;
          font-size: 20px;
          cursor: pointer;
          margin: 0 2px;
        }
      }
      &:hover .upload-list-cover {
        opacity: 1;
        cursor: pointer;
      }
    }
  }
}
