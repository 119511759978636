.contact-modal {
  min-height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
}
.contact-item {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
}
.contact-item-title,
.contact-item-content {
  color: rgba(0, 0, 0, 0.9);
  font-size: 14px;
}
.contact-item-content {
  padding: 0 8px;
}
.contact-item-btn {
  color: #3853e2;
  font-size: 14px;
  cursor: pointer;
}
