.root {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15%;
}
.root img {
  display: block;
  width: 216px;
  height: 216px;
  margin: 164px auto 0 auto;
}
