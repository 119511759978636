.four-product-wrap ::v-deep .product-item {
  border-left: 0.5px solid #eee;
}
.four-product-wrap ::v-deep .goods_container {
  width: 234px;
  padding-top: 24px;
  border-radius: 0px;
  border-right: 1px solid #EEEEEE;
}
.four-product-wrap ::v-deep .goods_container:first-child {
  border-radius: 8px 0 0 8px;
}
.four-product-wrap ::v-deep .goods_container:last-child {
  border-right: 0;
  border-radius: 0 8px 8px 0;
}
.four-product-wrap ::v-deep .goods_container .show_name,
.four-product-wrap ::v-deep .goods_container .show_org {
  width: 196px;
}
.four-product-wrap ::v-deep .goods_container .item_img {
  width: 146px;
  height: 146px;
}
.four-product-wrap ::v-deep .tag_ite {
  margin-left: 0 !important;
}
.five-product-wrap ::v-deep .goods_container {
  width: 188px;
  height: 242px;
  padding-top: 24px;
  border-radius: 0px;
  border-right: 1px solid #EEEEEE;
}
.five-product-wrap ::v-deep .goods_container:last-child {
  border-right: 0;
  border-radius: 0 8px 8px 0;
}
.five-product-wrap ::v-deep .goods_container .item_img {
  width: 100px;
  height: 100px;
}
.five-product-wrap ::v-deep .goods_container .purchase-wrap .content {
  width: 100%;
}
.five-product-wrap ::v-deep .tag_ite {
  margin-left: 0 !important;
}
.five-product-wrap ::v-deep .term_of_validity {
  left: unset;
  right: 0;
}
.v-category-card .five-product-wrap ::v-deep .purchase-wrap .content {
  width: 100%;
}
.v-category-card .five-product-wrap ::v-deep .purchase-wrap .content .title {
  width: 100%;
  white-space: unset;
}
.v-category-card .five-product-wrap ::v-deep .purchase-wrap .content .buy-num {
  flex-wrap: wrap;
}
.eight-product-wrap ::v-deep > *:nth-child(4n) {
  border-right: 0 !important;
}
.eight-product-wrap ::v-deep > *:nth-child(n + 5) {
  border-bottom: 0 !important;
}
.eight-product-wrap ::v-deep > *:nth-child(5n),
.eight-product-wrap ::v-deep > *:nth-child(0n+1) {
  border-left: 1px solid #EEEEEE;
}
.eight-product-wrap ::v-deep .goods_container {
  width: 234px;
  height: 270px;
  padding-top: 24px;
  border-radius: 0 !important;
  border-right: 1px solid #EEEEEE;
  border-bottom: 1px solid #EEEEEE;
}
.eight-product-wrap ::v-deep .goods_container .item_img {
  width: 100px;
  height: 100px;
}
.eight-product-wrap ::v-deep .tag_ite {
  margin-left: 0 !important;
}
.six-product-wrap ::v-deep > *:nth-child(2) {
  margin-left: 600px;
}
.six-product-wrap ::v-deep > *:nth-child(n + 5) {
  border-bottom: 0 !important;
}
.six-product-wrap ::v-deep .goods_container {
  width: 300px;
  height: 324px;
  padding-top: 24px;
  border-radius: 0 !important;
  border-right: 1px solid #EEEEEE;
  border-bottom: 1px solid #EEEEEE;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.six-product-wrap ::v-deep .goods_container .item_img {
  width: 160px;
  height: 160px;
}
.six-product-wrap ::v-deep .purchase-wrap .content {
  width: 100%;
}
.six-product-wrap ::v-deep .right-content {
  width: 100% !important;
}
.six-product-wrap ::v-deep .right-content .title {
  width: 100% !important;
}
.six-product-wrap ::v-deep .tag_ite {
  margin-left: 0 !important;
}
.six-product-wrap ::v-deep .prd_control {
  left: 0;
  margin-left: 0;
}
.six-product ::v-deep .six-img {
  height: 316px !important;
}
.v-many-product ::v-deep .goods_container {
  width: 228px;
  height: 272px;
  padding-top: 24px;
  border-radius: 8px;
  border-right: 1px solid transparent;
}
.v-many-product ::v-deep .goods_container:nth-child( 5n ) {
  margin-right: 0;
}
.v-many-product ::v-deep .goods_container .item_img {
  width: 100px;
  height: 100px;
}
.v-many-product ::v-deep .tag_ite {
  margin-left: 0 !important;
}
.v-product-card ::v-deep .goods_container,
.v-market ::v-deep .goods_container {
  width: 205px;
  height: 272px;
  padding-top: 24px;
  border-radius: 0;
  border-right: 1px solid #EEEEEE;
}
.v-product-card ::v-deep .goods_container:nth-child( 5n ),
.v-market ::v-deep .goods_container:nth-child( 5n ) {
  margin-right: 0;
}
.v-product-card ::v-deep .goods_container .item_img,
.v-market ::v-deep .goods_container .item_img {
  width: 109px;
  height: 109px;
}
.v-product-card ::v-deep .goods_container .content,
.v-market ::v-deep .goods_container .content {
  width: unset;
}
.v-product-card ::v-deep .goods_container .confirm-btn .btn,
.v-market ::v-deep .goods_container .confirm-btn .btn {
  width: 72px;
  height: 24px;
  line-height: 24px;
  font-size: 8px;
}
.v-product-card ::v-deep .goods_container .input,
.v-market ::v-deep .goods_container .input {
  width: 40px !important;
  margin: 0 4px !important;
}
.v-product-card ::v-deep .goods_container .minus,
.v-market ::v-deep .goods_container .minus,
.v-product-card ::v-deep .goods_container .plus,
.v-market ::v-deep .goods_container .plus {
  width: 14px !important;
  height: 14px !important;
}
.v-product-card ::v-deep .goods_container .title,
.v-market ::v-deep .goods_container .title {
  width: 100% !important;
}
.v-product-card ::v-deep .tag_ite,
.v-market ::v-deep .tag_ite {
  margin-left: 0 !important;
}
.v-hot-product-recommend {
  width: 1200px;
}
.card-list {
  display: flex;
  gap: 8px;
}
.card {
  flex: 1;
  padding: 16px 12px 12px 12px;
  border-radius: 8px;
}
.card-bg1 {
  background: url('~@/assets/images/home/hot-product1.png') no-repeat;
  background-size: cover;
}
.card-bg2 {
  background: url('~@/assets/images/home/hot-product2.png') no-repeat;
  background-size: cover;
}
.card-bg3 {
  background: url('~@/assets/images/home/hot-product3.png') no-repeat;
  background-size: cover;
}
.card-wrap {
  height: 337px;
  padding: 0 12px;
  border-radius: 8px 8px 0 0;
  background-color: #fff;
  overflow: hidden;
}
.card-wrap .product-item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 0;
  cursor: pointer;
}
.card-wrap .product-item:nth-child(1),
.card-wrap .product-item:nth-child(2) {
  border-bottom: 0.5px solid #e7e7e7;
}
.card-wrap .product-item .img {
  width: 88px;
  height: 88px;
}
.card-wrap .product-item .content {
  display: flex;
  flex-direction: column;
}
.card-wrap .product-item .activity-price {
  color: #CB272D;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
}
.card-wrap .product-item .activity-unit {
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-right: 2px;
}
.card-wrap .product-item .sell-price {
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-decoration: line-through;
}
.card-wrap .product-item .product-name {
  color: rgba(0, 0, 0, 0.9);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 8px;
}
.card-wrap .product-item .platform-tag {
  height: 18px;
  width: auto;
  margin-right: 4px;
  vertical-align: middle;
  margin-top: -3px;
}
.no-product {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 337px;
}
.no-product img {
  width: 120px;
  height: 136px;
}
.no-product p {
  margin-top: 6px;
  color: var(--font_black-2, rgba(0, 0, 0, 0.6));
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}
.head-wrap1 {
  color: #6A4A1B;
}
.head-wrap2 {
  color: #4A4453;
}
.head-wrap3 {
  color: #334524;
}
.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 12px;
  margin-right: 12px;
  margin-bottom: 4px;
}
.head .title {
  width: 80%;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
}
.head .more {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
}
.second-title {
  display: inline-block;
  border-radius: 0px 12px 12px 12px;
  padding: 0px 8px;
  margin-left: 12px;
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}
.second-bg1 {
  background: #edd2a9;
}
.second-bg2 {
  background: #decaf9;
}
.second-bg3 {
  background: #afe6de;
}
