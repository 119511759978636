.search-buy-btn {
  width: 112px;
  height: 40px;
  border-radius: 4px;
  background-color: #ffffff;
  position: relative;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #EEE;
  margin-left: 12px;
}
.search-buy-btn .search-buy-icon {
  width: 16px;
  height: 16px;
}
.search-buy-btn .search-buy-text {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);
  font-weight: 400;
  margin-left: 8px;
}
.search-buy-btn:hover {
  border: 1px solid #fab357;
}
.search-buy-btn:hover .search-buy-text {
  color: #e67d00;
}
.cart-num {
  position: absolute;
}
.cart-num {
  width: 28px;
  height: 15px;
  line-height: 15px;
  top: -10px;
  right: 6px;
  text-align: center;
  background-size: 100% 100%;
}
.cart-num .num {
  font-size: 12px;
  color: #ffffff;
}
.cart-num.indivdual {
  width: 16px;
  height: 16px;
  background-image: url(../assets/images/common/car-num-indivdual.png);
}
.cart-num.ten {
  background-image: url(../assets/images/common/car-num-ten.png);
  width: 24px;
  height: 16px;
}
.cart-num.hundred {
  width: 32px;
  height: 16px;
  background-image: url(../assets/images/common/car-num-hundred.png);
}
.msg-num {
  width: 16px;
  height: 16px;
  text-align: center;
  background-size: 100% 100%;
  background: #f53f3f;
  border-radius: 50%;
  font-size: 9.6px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
}
.msg-num.ten {
  width: 26px;
  height: 16px;
  border-radius: 8px;
  padding: 0 4px;
}
::v-deep .ivu-poptip-popper {
  min-width: 112px;
  padding: 0 4px;
}
.search-msg-box .search-msg-item {
  width: 104px;
  height: 32px;
  padding: 0px 12px 0px 12px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
}
.search-msg-box .search-msg-item:hover {
  color: #e67d00;
  background: #fff8e8;
}
.search-msg-box.active .search-msg-item {
  justify-content: flex-start;
}
