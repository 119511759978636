
.no-data-view {
  width:100%;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .img {
    width:216px;
  }
  .text {
    margin-top:-22px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.6);
  }
}
