.center {
  width: 1200px;
  margin: 0 auto;
  text-align: left;
  color: rgba(0, 0, 0, 0.9);
  cursor: default;
}
.center .content {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  gap: 12px;
}
.center .content-left {
  width: 200px;
  height: 100%;
  padding: 18px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 8px;
}
.center .content-left .menu-item {
  padding: 6px 15px;
  color: #000;
  font-size: 16px;
}
.center .content-left .menu-item.bold {
  margin-top: 14px;
  font-weight: 700;
}
.center .content-left .menu-item.gray {
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
}
.center .content-left .menu-item.current {
  color: #ce7720;
  font-weight: 500;
}
.center .content-right {
  flex: 1;
  width: 980px;
}
.center .content-right .box {
  margin-bottom: 12px;
  padding: 16px 24px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--font_white-0, #FFF);
  background-color: #fff;
}
.center .content-right .box-content {
  display: flex;
  gap: 20px;
  padding-top: 0px;
}
.center .content-right .box-content-step {
  width: 170px;
  border-right: 1px dashed #dcdcdc;
}
.center .content-right .caption {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
  color: var(--font_black-1, rgba(0, 0, 0, 0.9));
  /* medium/14 */
  font-family: "PingFang SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 157.143% */
}
.center .content-right .caption-right {
  color: var(--font_black-2, rgba(0, 0, 0, 0.6));
  /* regular/14 */
  font-family: "PingFang SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
}
.center .content-right .caption-right:hover {
  color: #e67d00 !important;
  /* regular/14 */
  font-family: "PingFang SC" !important;
  font-size: 14px!important;
  font-style: normal!important;
  font-weight: 400!important;
  line-height: 22px!important;
}
.center .content-right .grid {
  display: flex;
  align-items: center;
  gap: 15px;
  line-height: 22px;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
  box-sizing: border-box;
}
.center .content-right .grid-hover .grid-item {
  transition: background-color 0.5s ease-in;
}
.center .content-right .grid-hover .grid-item:hover {
  background-color: #f5f5f5;
  border-radius: 4px;
  cursor: pointer;
}
.center .content-right .grid-item {
  position: relative;
  flex: 1;
  padding: 10px;
  background-color: #fff;
}
.center .content-right .grid-item .count {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 26px;
  font-size: 20px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
}
.center .content-right .grid-item.merchat {
  text-align: left;
}
.center .content-right .grid-item.merchat .count {
  justify-content: flex-start;
  line-height: 40px;
  font-size: 24px;
}
.center .margin-bottom-20 {
  margin-bottom: 10px;
}
.last {
  color: rgba(0, 0, 0, 0.9);
}
.iconfonts {
  display: inline-block;
}
.iconfonts::before {
  content: url('../../../assets/images/common/gery-right-arrow.png');
  display: inline-block;
  position: relative;
  top: 1px;
}
.can-click {
  cursor: pointer;
  color: var(--font_black-1, rgba(0, 0, 0, 0.9));
  text-align: center;
  /* regular/14 */
  font-family: PingFang SC;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
}
.black_1 {
  color: var(--font_black-2, rgba(0, 0, 0, 0.6));
  text-align: center;
  /* regular/14 */
  font-family: PingFang SC;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
}
