
.user {
  height: 406px;
  // margin-bottom: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  // align-items: center;
  border-radius: 8px;
  position: relative;
}

.no-related {
  height: 140px;
  text-align: center;
  &-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.9);
  }
  &-desc {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.4);
  }
  &-btn {
    display: inline-block;
    width: 200px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #fff;
    margin-top: 12px;
    padding: 8px 10px;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    background: var(--3, linear-gradient(332deg, #ff5c17 17.15%, #ffae35 82.37%));
  }
}

.custom-name {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.9);
  user-select: none;
  cursor: pointer;
  min-height: 24px;
  max-height: 48px;
  .type {
    border-radius: 4px;
    font-weight: 400;
    line-height: 10px;
    padding: 4px 4px 3px;
    margin-right: 4px;
    display: inline-block;
    box-sizing: border-box;
    border: 1px solid;
    font-size: 10px;
    box-sizing: border-box;
    vertical-align: text-top;
  }
  .state-icon {
    width: 58px;
    height: 18px;
    margin-right: 4px;
    vertical-align: text-top;
    margin-top: 2px;
  }
}

.address {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.6);
}

.orders {
  display: flex;
  // justify-content: space-between;
  margin-top: 4px;

  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    & p:nth-child(1) {
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      color: rgba(0, 0, 0, 0.9);
    }

    & p:nth-child(2) {
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      color: rgba(0, 0, 0, 0.9);
    }

    // &:nth-child(1) p:nth-child(1) {
    //   color: #f99d33;
    // }

    // &:nth-child(2) p:nth-child(1) {
    //   color: #3853e2;
    // }

    // &:nth-child(3) p:nth-child(1) {
    //   color: #1f7c15;
    // }
  }
}

.item {
  width: 73px;
  height: 52px;
  padding: 4px 0;
  border-radius: 4px;

  &:hover {
    background: #f5f5f5;
  }
}

.collect-business {
  display: flex;
  // background: #FAFAFA;
  margin-top: 4px;
  width: max-content;

  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    cursor: pointer;

    & p:nth-child(1){
      height: 24px;
      img {
        width: 24px;
        height: 24px;
      }
    }

    & p:nth-child(2) {
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      color: rgba(0, 0, 0, 0.9);
    }
  }
}

.change-custom {
  left: -412px;
  top: 78px;
}

.svg-icon.address {
  width: 12px;
  height: 12px;
  margin-bottom: 1px;
}

::v-deep .ivu-tooltip {
  height: 22px;
}

.coupon-list {
  position: absolute;
  bottom: 16px;
  width: 228px;
}

.user {
  ::v-deep .ivu-avatar {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  ::v-deep .ivu-avatar > img {
    vertical-align: middle;
  }
}
