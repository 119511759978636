.root {
  width: 100%;
  background-color: #fff;
  margin-bottom: 16px;
}
.root .content {
  width: 1200px;
  padding: 12px 0;
  height: 100%;
  margin: 0 auto;
  position: relative;
}
.root .content .tabs {
  width: auto;
  height: 22px;
  display: flex;
}
.root .content .tabs .tabs-list {
  cursor: pointer;
  display: inline-block;
  height: 22px;
  position: relative;
  display: flex;
}
.root .content .tabs .tabs-list .tabs-item {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.9);
  margin-right: 40px;
  align-items: center;
}
.root .content .tabs .tabs-list .tabs-item:hover {
  color: #ce7720;
  font-weight: bold;
}
.root .content .tabs .tabs-list .icon-direction {
  transform: rotate(90deg);
  margin: 0 8px 0 -4px;
}
.root .content .tabs .tabs-list img {
  max-width: 100%;
  max-height: 24px;
  margin: 0 8px !important;
  padding: 0 !important;
}
.root .content .tabs .selectde-tab {
  color: #ce7720 !important;
  font-weight: bold;
}
