::v-deep .exhange-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}
::v-deep .exhange-modal .ivu-modal {
  top: 0;
}
::v-deep .exhange-modal .ivu-modal .ivu-modal-content .ivu-modal-body {
  padding: 0 !important;
}
::v-deep .exhange-modal .ivu-modal .ivu-modal-content .ivu-modal-body .exhange-modal-wrap {
  padding-top: 20px;
}
::v-deep .exhange-modal .ivu-modal .ivu-modal-content .ivu-modal-body .exhange-modal-wrap .padding-lr {
  padding-left: 24px;
  padding-right: 24px;
}
::v-deep .exhange-modal .ivu-modal .ivu-modal-content .ivu-modal-body .exhange-modal-wrap .title-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
::v-deep .exhange-modal .ivu-modal .ivu-modal-content .ivu-modal-body .exhange-modal-wrap .title-box .title-text {
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
}
::v-deep .exhange-modal .ivu-modal .ivu-modal-content .ivu-modal-body .exhange-modal-wrap .org-name {
  font-size: 18px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  margin-bottom: 16px;
}
::v-deep .exhange-modal .ivu-modal .ivu-modal-content .ivu-modal-body .exhange-modal-wrap .label {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
}
::v-deep .exhange-modal .ivu-modal .ivu-modal-content .ivu-modal-body .exhange-modal-wrap .pics-box {
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  max-height: 402px;
  overflow: auto;
  margin-bottom: 24px;
}
::v-deep .exhange-modal .ivu-modal .ivu-modal-content .ivu-modal-body .exhange-modal-wrap .pics-box .pic-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 160px;
  padding: 20px 8px 16px 8px;
  border-radius: 4px;
  border: 1px solid #e7e7e7;
}
::v-deep .exhange-modal .ivu-modal .ivu-modal-content .ivu-modal-body .exhange-modal-wrap .pics-box .pic-item img {
  width: 120px;
  height: 120px;
  margin-bottom: 8px;
  background-color: #eee;
}
::v-deep .exhange-modal .ivu-modal .ivu-modal-content .ivu-modal-body .exhange-modal-wrap .pics-box .swiper-container {
  width: 100%;
}
::v-deep .exhange-modal .ivu-modal .ivu-modal-content .ivu-modal-body .exhange-modal-wrap .platform-wrap {
  display: flex;
  align-items: center;
  margin: 16px 0;
}
::v-deep .exhange-modal .ivu-modal .ivu-modal-content .ivu-modal-body .exhange-modal-wrap .platform-wrap .ivu-radio-wrapper {
  display: inline-block !important;
  margin-right: 16px;
}
::v-deep .exhange-modal .ivu-modal .ivu-modal-content .ivu-modal-body .exhange-modal-wrap .platform-wrap .ivu-radio-wrapper .ivu-radio {
  margin-right: 0;
}
::v-deep .exhange-modal .ivu-modal .ivu-modal-content .ivu-modal-body .exhange-modal-wrap .btn-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FAFAFA;
  padding: 12px 24px;
  border-radius: 0px 0px 8px 8px;
}
::v-deep .exhange-modal .ivu-modal .ivu-modal-content .ivu-modal-body .exhange-modal-wrap .agree {
  color: rgba(0, 0, 0, 0.4);
  font-size: 12px;
}
::v-deep .exhange-modal .ivu-modal .ivu-modal-content .ivu-modal-body .exhange-modal-wrap .agree .ivu-checkbox-inner {
  border-radius: 4px;
}
::v-deep .exhange-modal .ivu-modal .ivu-modal-content .ivu-modal-body .exhange-modal-wrap .agree .ivu-checkbox-wrapper {
  font-size: inherit;
}
::v-deep .exhange-modal .ivu-modal .ivu-modal-content .ivu-modal-body .exhange-modal-wrap .agree .ivu-checkbox-wrapper .ivu-checkbox {
  margin-right: 4px;
}
::v-deep .exhange-modal .ivu-modal .ivu-modal-content .ivu-modal-body .exhange-modal-wrap .agree .agree-content {
  color: #ce7720;
  line-height: 20px;
  cursor: pointer;
}
::v-deep .exhange-modal .ivu-modal .ivu-modal-content .ivu-modal-body .exhange-modal-wrap .btn-box {
  display: flex;
  justify-content: flex-end;
}
::v-deep .exhange-modal .ivu-modal .ivu-modal-content .ivu-modal-body .exhange-modal-wrap .btn-box .agree-btn {
  margin-left: 12px;
}
::v-deep .exhange-modal .ivu-modal .ivu-modal-content .ivu-modal-body .exhange-modal-wrap .btn-box .agree-btn-disabled {
  color: #fff;
  background-color: #FDDA9F;
}
::v-deep .exhange-modal .ivu-modal .ivu-modal-content .ivu-modal-footer {
  text-align: center;
  font-size: 16px;
  color: #ce7720;
  cursor: pointer;
}
::v-deep .exhange-modal .ivu-modal .ivu-modal-content .ivu-modal-footer span {
  display: inline-block;
}
