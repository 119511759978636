.platform-tag {
  height: 16px;
  width: auto;
  margin-right: 4px;
}
.platform-tag-outer {
  display: inline-block;
  vertical-align: middle;
  height: 16px;
}
