
.right-content {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;

  border-radius: 8px;
  background: #fff;
}

:deep(tbody .ivu-table-cell) {
  color: var(--font_black-1, rgba(0, 0, 0, 0.9));

  /* regular/12 */
  font-family: 'PingFang SC';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
}

:deep(thead .ivu-table-cell) {
  color: var(--font_black-1, rgba(0, 0, 0, 0.9));

  /* regular/12 */
  font-family: 'PingFang SC';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
}

:deep(.tag) {
  padding: 0px 4px;
  align-items: center;
  gap: 4px;
  justify-content: center;
}

:deep(.un_open_auth) {
  border-radius: 4px;
  border: 1px solid var(--error-3, #fbaca3);
  background: var(--error-1, #ffece8);

  color: var(--error-6, #f53f3f);

  /* regular/12 */
  font-family: 'PingFang SC';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
}

:deep(.un_auth) {
  border-radius: 4px;
  border: 1px solid var(--brand-3, #fdda9f);
  background: var(--brand-1, #fff8e8);

  color: var(--brand-7, #e67d00);

  /* regular/12 */
  font-family: 'PingFang SC';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
}

:deep(.auth) {
  border-radius: 4px;
  border: 1px solid var(--success-3, #aade97);
  background: var(--success-1, #f0ffe8);

  color: var(--success-6, #4dac39);

  /* regular/12 */
  font-family: 'PingFang SC';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
}

:deep(.expired) {
  overflow: hidden;
  color: var(--error-6, #f53f3f);
  text-overflow: ellipsis;

  /* medium/12 */
  font-family: 'PingFang SC';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 166.667% */
  padding-left: 10px;
}

:deep(.ivu-tabs-bar) {
  margin-bottom: 0px;
}

:deep(.column-info) {
  display: flex;
  flex-direction: column;
  padding: 12px 0px;

  span {
    color: var(--font_black-1, rgba(0, 0, 0, 0.9));
    /* regular/12 */
    font-family: 'PingFang SC';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 166.667% */

    overflow: hidden;

    text-overflow: ellipsis;
  }

  .grey-info {
    color: var(--font_black-2, rgba(0, 0, 0, 0.6));
    white-space: nowrap; /* 禁止文本换行 */
    overflow: hidden;    /* 隐藏溢出内容 */
    text-overflow: ellipsis; /* 使用省略号表示溢出内容 */
  }
}

:deep(.oper-info) {
  color: #3853e2 !important;

  /* regular/12 */
  font-family: 'PingFang SC';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
}

