
//$grayTxt: rgba(0, 0, 0, 0.6);
.cmsFileDetail-wrapper ~ .user-footer-wrapper {
  background: #F5F5F5;
}
.user-footer-wrapper {
  padding: 32px 0;
  background: #fff;
  .row-type {
    text-align: center;
    max-width: 85%;
    margin: 0 auto;
    .item {
      cursor: pointer;
    }
    span {
      font-size: 12px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.4);
    }
    .line {
      margin: 0 10px;
    }
  }
}
