.v-together-group {
  width: 1200px;
  height: 374px;
  background-image: url('~@/assets/images/home/pc-together-group.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 0 42px;
  padding-bottom: 12px;
}
.v-together-group .ttitle {
  color: #FFF;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: block;
  margin: 12px auto;
  text-align: center;
}
.v-together-group .list {
  display: flex;
  margin-top: 18px;
}
.v-together-group .item {
  width: 204px;
  height: 284px;
  border-radius: 8px;
  border: 2px solid #F76560;
  background: #FFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-left: 24px;
}
.v-together-group .item:first-child {
  margin-left: 0;
}
.v-together-group .item .img {
  width: 140px;
  height: 140px;
  margin-top: 8px;
  margin-bottom: 18px;
}
.v-together-group .item .pieces-num {
  background-image: url('~@/assets/images/home/pc-pieces-num.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 152px;
  height: 45px;
  color: #FFEFB7;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  padding-top: 15px;
  position: absolute;
  left: 18px;
  top: 125px;
}
.v-together-group .item .activity-price {
  color: #CB272D;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
}
.v-together-group .item .activity-unit {
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-right: 2px;
}
.v-together-group .item .sell-price {
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-decoration: line-through;
}
.v-together-group .item .product-name {
  color: rgba(0, 0, 0, 0.9);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  height: 40px;
}
.v-together-group .item .btn {
  width: 98px;
  height: 36px;
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  border-radius: 8px;
  border: 1px solid #CB272D;
  background: #F53F3F;
  margin-top: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2px;
  cursor: pointer;
}
.v-together-group .item .btn:hover {
  color: #FFF59E;
}
.v-together-group .item .btn .icon {
  margin-left: 4px;
}
.v-together-group .item .content {
  width: 172px;
}
.v-together-group .more {
  border-radius: 4px;
  background: #FA3343;
  width: 48px;
  height: 24px;
  position: absolute;
  right: 10px;
  top: 20px;
  color: #FFF;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
::v-deep .root {
  margin-top: 0;
}
.platform-tag {
  height: 18px;
  width: auto;
  margin-right: 4px;
  vertical-align: middle;
  margin-top: -3px;
}
