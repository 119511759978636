
.list {
  padding-top: 10px;
  &-item {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    font-size: 14px;
    .label {
      width: 88px;
      display: inline-block;
      padding-right: 16px;
      color: rgba(0, 0, 0, 0.4);
    }

    .value{
      flex: 1;
    }

    .hint{
      display: flex;
      color: var(--gray-2, #EEE);
      gap: 2px;

      /* regular/14 */
      font-family: PingFang SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */

    a{
      color: var(--brand-6, #F99D33);
      /* regular/14 */
      font-family: PingFang SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      }
    }

    ::v-deep .ivu-poptip-inner{
      background-color:rgba(0, 0, 0, 0.90)
    }

    ::v-deep .ivu-poptip-popper[x-placement^="top"] .ivu-poptip-arrow:after{
      border-top-color:rgba(0, 0, 0, 0.90)
    }
  }

  &-item:last-child {
    display: flex;
    align-items: center;
    margin-bottom: 0px;
    font-size: 14px;
  }
}
.caption-right:hover{
  color: #3853E2;
  cursor: pointer;
}

.caption-right{
  color: var(--font_black-3, rgba(0, 0, 0, 0.40));
  /* regular/12 */
  font-family: PingFang SC;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

