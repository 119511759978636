.detail .item {
  display: flex;
  margin-bottom: 16px;
}
.detail .item.center {
  align-items: center;
}
.detail .item .label {
  width: 100px;
  text-align: right;
  color: rgba(0, 0, 0, 0.9);
}
.detail .item .value {
  flex: 1;
  padding-left: 8px;
  color: rgba(0, 0, 0, 0.9);
}
.detail .item .goods {
  display: flex;
}
.detail .item .goods .goods-img {
  width: 80px;
  height: 80px;
  border: 1px solid #f1f1f1;
  border-radius: 4px;
}
.detail .item .goods .goods-info {
  padding-left: 10px;
}
.detail .item .goods .goods-info p {
  margin: 0;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);
}
.detail .item .goods .goods-info p.name {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
.detail .item .goods .goods-info p.sub-name {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  padding-top: 4px;
}
.detail .car-text {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);
  padding: 12px 0;
}
.detail .car-text span {
  padding: 0 6px;
}
.detail .car-text .rest {
  color: #cb272d;
}
.detail :deep(.ivu-input-number-input) {
  text-align: center !important;
}
