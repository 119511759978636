.cms-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100000;
}
.cms-dialog ._shade {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #000;
  opacity: 0.6;
  z-index: 110000;
}
.cms-dialog .box {
  width: 548px;
  background-size: 100% auto;
  height: 358px;
  background-color: #fff;
  border-radius: 8px;
  position: absolute;
  background-repeat: no-repeat;
  z-index: 110001;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 24px;
  box-sizing: border-box;
}
.cms-dialog .box .header {
  height: 22px;
  display: flex;
}
.cms-dialog .box .header .title {
  font-size: 18px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  text-align: center;
}
.cms-dialog .box .header .close {
  margin: 0 0 0 auto;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.cms-dialog .box .region {
  box-sizing: border-box;
  display: flex;
  height: 284px;
  flex-direction: column;
}
.cms-dialog .box .region .content {
  font-size: 14px;
  line-height: 48px;
  color: rgba(0, 0, 0, 0.6);
  flex: 1;
  white-space: pre-wrap;
  word-break: break-all;
  overflow: hidden auto;
}
.cms-dialog .box .btns {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 36px;
  box-sizing: border-box;
}
.cms-dialog .box .btns .btn {
  line-height: 36px;
  text-align: center;
  border-radius: 4px;
  font-size: 14px;
  width: 92px;
  cursor: pointer;
}
.cms-dialog .box .btns .btn:nth-child(1) {
  color: #ffffff;
  background-color: #f99d33;
}
.cms-dialog .box .btns .btn:nth-child(2) {
  color: rgba(0, 0, 0, 0.4);
  background-color: #eeeeee;
  margin-left: 16px;
}
.cms-dialog .box .zone {
  position: absolute;
}
