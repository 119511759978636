.batch-order-wrap {
  margin: auto;
  width: 1200px;
}
.batch-order-wrap .batch-order-content {
  margin-top: 24px;
  min-height: calc(100vh - 182px);
}
.batch-order-wrap .batch-order-content .next-step {
  margin-top: 16px;
  height: 36px;
  padding: 8px 16px;
  box-sizing: border-box;
  border-radius: 4px;
  background: var(--brand-6, #F99D33);
  color: var(--font-white-0, #FFF);
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  border: none;
}
.batch-order-wrap .batch-order-content .next-step:hover {
  cursor: pointer;
  box-shadow: none;
}
.batch-order-wrap .batch-order-content .unable {
  background: var(--brand-3, #FDDA9F);
}
