.home-helper-wrapper {
  background-repeat: no-repeat;
}
.home-helper-wrapper .home-helper-wrapper-content {
  padding: 32px 0 16px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.home-helper-wrapper .home-helper-wrapper-content .guide-item {
  width: 90px;
  margin-right: 48px;
}
.home-helper-wrapper .home-helper-wrapper-content .guide-item h4 {
  margin: 0 0 16px 0;
}
.home-helper-wrapper .home-helper-wrapper-content .guide-item > * {
  margin-top: 12px;
}
.home-helper-wrapper .home-helper-wrapper-content .guide-item span {
  cursor: pointer;
}
.home-helper-wrapper .home-helper-wrapper-content .app-code {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 160px;
  background: #FFFFFF;
  border-radius: 8px;
}
.home-helper-wrapper .home-helper-wrapper-content .app-code img {
  width: 100%;
}
.home-helper-wrapper .home-helper-wrapper-content .app-code div {
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.9);
  text-align: center;
  margin-top: -18px;
}
.title {
  color: rgba(0, 0, 0, 0.9);
}
.desc {
  color: #383838;
}
