
::v-deep .exhange-popup {
  top: 91px;
  right: -801px;
  .ivu-modal {
    .ivu-modal-content {
      .ivu-modal-body {
        padding: 20px 24px;
        .exhange-popup-wrap {
          .title-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .title-text {
              margin-left: 8px;
              font-size: 16px;
              font-weight: 500;
              color: rgba(0, 0, 0, 0.90);
            }
          }
          .content {
            font-size: 14px;
            margin: 12px 0;
            padding-left: 25px;
            .store-num {
              color: #E67D00;
              padding: 0 2px;
            }
          }
          .btn-wrap {
            display: flex;
            justify-content: flex-end;
          }
        }
      }
      .ivu-modal-footer {
        text-align: center;
        font-size: 16px;
        color: #ce7720;
        cursor: pointer;
        span {
          display: inline-block;
        }
      }
    }
  }
}
