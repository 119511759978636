.affiliated {
  width: 1200px;
  margin: 0 auto;
}
.affiliated-content {
  margin-bottom: 20px;
  padding: 24px;
  text-align: left;
  color: rgba(0, 0, 0, 0.9);
  cursor: default;
  box-sizing: border-box;
  background-color: #fff;
}
::v-deep .cust_bor .ivu-input {
  border-radius: 4px !important;
}
::v-deep .modalTable {
  width: 100%;
}
::v-deep .modalTable .ivu-table-wrapper-with-border {
  border-radius: 8px 8px 0px 0px;
}
::v-deep .modalTable .ivu-table-cell {
  overflow: hidden;
  color: var(--font_black-1, rgba(0, 0, 0, 0.9));
  text-overflow: ellipsis;
  white-space: nowrap;
  /* regular/12 */
  font-family: "PingFang SC";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 166.667% */
}
