
.cms-config-empty {
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .cms-config-wrap {
    width: 216px;
    height: 216px;
    position: relative;
  }

  img {
    width: 216px;
    height: 216px;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.6);
    position: absolute;
    bottom: -2px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.tab-box {
  padding: 20px 20px 0 20px;
}

.w-page {
  background: #fff;
  // overflow: scroll;
  padding-bottom: 20px;
  position: relative;
  width: 1200px;
  margin: 12px auto;
  :deep(.ivu-tabs-bar){
    margin-bottom: 0;
  }

  .content-wrapper {
    padding:24px;

    // height: calc(100% - 85px);
    // overflow: auto;
    ::v-deep .ivu-select {
      margin-bottom: 10px;
      width: 75px;

      .ivu-select-selection {
        border: none;
      }
    }

    .items {
      display: flex;
      flex-wrap: wrap;
      grid-gap: 16px 16px;
      justify-content: flex-start;
      grid-template-columns: auto auto;
      margin-top: 12px;
    }

    .coupon-btns {

      .btn {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        border-radius: 8px;
        margin-left: 20px;
      }

      button:first-child {
        margin-left: 0;
      }

      .btn-default {
        color: rgba(75, 75, 75, 0.70);
        background-color: #eee;
        border: none;
      }

      .btn-selected {
        color: #CE7720;
        background: #FFF8E8;
        border: 1.5px solid #F99D33;
        font-weight: 500;
      }

      .link-mycoupon {
        margin-left: 40px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        border: none;
        display: inline-block;

        img {
          width: 20px;
          height: 20px;
          display: inline-block;
          vertical-align: middle;
        }

        a {
          color: rgba(0, 0, 0, 0.90);
          margin-left: 4px;
          display: inline-block;
          vertical-align: middle;

          .right-arrow {
            width: 16px;
            height: 16px;

          }
        }

      }
    }
  }

  // .page-wrapper {
  //   position: sticky  ;
  //   bottom: 0;
  //   z-index: 100;
  //   height: 32px;
  // }
}

.content-wrapper {
  ::v-deep .ivu-select-item {
    color: rgba(0, 0, 0, .4);
  }

  ::v-deep .ivu-select-item-selected {
    color: #000;
  }

  ::v-deep .ivu-select-selected-value {
    color: #000;
    font-weight: 500;
  }

  ::v-deep .ivu-select-visible .ivu-select-selection {
    box-shadow: none;
  }
  :deep(.coupon-item){
    width:calc((100% - 32px)/3);
  }
  .org-info{
    display: flex;
    .org-logo{
      display: block;
      width: 24px;
      height: 24px;
      border:1px solid #e7e7e7;
      border-radius: 4px;
    }
    .org-name{
      color: rgba(0, 0, 0, 0.90);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      margin-left: 8px;
    }
  }
}
.main-index{
  background: #eee;
}
