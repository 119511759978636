.top-menu-wrapper {
  position: sticky;
  top: 0;
  width: 100%;
  height: 36px;
  line-height: 36px;
  background: #fafafa;
  z-index: 1000;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
}
.top-menu-wrapper .top-menu-wrapper_content {
  display: flex;
  justify-content: space-between;
  width: 1200px;
  margin: 0 auto;
}
.top-menu-wrapper .top-menu-wrapper_content .left {
  display: flex;
  align-items: center;
}
.top-menu-wrapper .top-menu-wrapper_content .left .location {
  color: rgba(0, 0, 0, 0.6);
  margin-right: 16px;
}
.top-menu-wrapper .top-menu-wrapper_content .left .location-icon {
  margin-right: 4px;
}
.top-menu-wrapper .top-menu-wrapper_content .left > span {
  line-height: 36px;
  height: 36px;
}
.top-menu-wrapper .top-menu-wrapper_content .left .top-shop-name {
  display: inline-block;
  color: rgba(0, 0, 0, 0.6);
  position: relative;
  max-width: 540px;
  white-space: nowrap;
  /* 禁止文本换行 */
  overflow: hidden;
  /* 隐藏超出范围的内容 */
  text-overflow: ellipsis;
  /* 使用省略号 */
}
.top-menu-wrapper .top-menu-wrapper_content .left .welcome {
  color: #f99d33;
  margin-right: 16px;
}
.top-menu-wrapper .top-menu-wrapper_content .left .quit {
  color: rgba(0, 0, 0, 0.25);
}
.top-menu-wrapper .top-menu-wrapper_content .left .quit:hover {
  cursor: pointer;
  color: #ce7720;
}
.top-menu-wrapper .top-menu-wrapper_content .right {
  display: flex;
  align-items: center;
}
.top-menu-wrapper .top-menu-wrapper_content .right .item {
  padding: 0 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.top-menu-wrapper .top-menu-wrapper_content .right .item a {
  color: rgba(0, 0, 0, 0.6);
}
.top-menu-wrapper .top-menu-wrapper_content .right .item:hover {
  color: #ce7720;
}
.top-menu-wrapper .top-menu-wrapper_content .right .item img {
  margin-right: 2px;
}
.top-menu-wrapper .smartphone-icon,
.top-menu-wrapper .smartphone-icon-hover {
  width: 12px;
  height: 12px;
  margin-right: 4px;
}
.top-menu-wrapper .smartphone-icon-hover {
  display: none;
}
::v-deep .ivu-radio-wrapper {
  margin-right: 0;
}
.shop-name {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 999;
}
.shop-name:hover .list {
  display: block;
}
.shop-name .address-wrapper {
  background-color: #fff;
  box-shadow: 0 0 30px 1px rgba(0, 0, 0, 0.2);
  position: absolute;
  left: 402px;
  top: 0;
}
.shop-name .list {
  border-radius: 8px;
  padding-bottom: 8px;
  display: block;
  position: absolute;
  width: 404px;
  background-color: #fff;
  box-shadow: 0 0 30px 1px rgba(0, 0, 0, 0.2);
  z-index: 999;
  top: 40px;
  left: 243px;
}
.shop-name .list .address-content {
  position: absolute;
  max-height: 400px;
  overflow-y: auto;
  box-shadow: 0 0 30px 1px rgba(0, 0, 0, 0.2);
  /*          left: calc(100%);*/
  top: 4px;
  z-index: 9999;
  background-color: #fff;
}
.shop-name .list .address-content .address-fixation {
  max-height: 350px;
  overflow-y: scroll;
}
.shop-name .list .address-content .address-box .address-box-loading {
  text-align: center;
  width: 200px;
  height: 50px;
  margin: 0 auto;
  position: relative;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.9);
}
.shop-name .list .address-content .address-input {
  width: 364px;
  padding: 8px 24px 8px 24px;
}
.shop-name .list .address-content .address-content-item {
  display: flex;
  padding: 8px 24px;
  margin-bottom: 5px;
  word-wrap: break-word;
  white-space: pre-wrap !important;
}
.shop-name .list .address-content .address-content-item ::v-deep .ivu-radio {
  position: relative;
}
.shop-name .list .address-content .address-content-item:hover {
  background: #f5f5f5;
}
.shop-name .list .address-content .address-content-item .address {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.9);
}
.shop-name .list .address-content .address-content-item .phone {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.6);
}
.shop-name .list .item-content {
  max-height: 400px;
  overflow-y: auto;
}
.shop-name .list .item-content ::v-deep .ivu-radio-group {
  width: 100%;
  word-wrap: break-word;
}
.shop-name .list .title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.shop-name .list .title .ico {
  cursor: pointer;
}
.shop-name .list .list-item {
  padding: 8px 24px;
  display: flex;
}
.shop-name .list .list-item:hover {
  background: #f5f5f5;
}
.shop-name .list .list-item .content-box {
  flex: 1;
  word-wrap: break-word;
  max-width: calc(100% - 44px);
  white-space: pre-wrap !important;
}
.shop-name .list .list-item .content-box > div {
  max-width: 100%;
  word-break: break-word;
}
.shop-name .list .list-item .custome-name {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.9);
}
.shop-name .list .list-item .custome-addr {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.6);
}
.shop-name .list .list-item .change-address {
  position: relative;
  cursor: pointer;
  max-height: 50px;
  overflow-y: auto;
  color: #3853e2;
}
.shop-name .list .list-item .change-address .address-content {
  position: absolute;
  top: 4px;
  z-index: 9999;
  background-color: #fff;
}
.shop-name .list .list-item .change-address .address-content .address-content-item {
  display: flex;
}
.demo-spin-col .circular {
  width: 25px;
  height: 25px;
}
.demo-spin-icon-load {
  animation: ani-demo-spin 1s linear infinite;
}
@keyframes ani-demo-spin {
  from {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.demo-spin-col {
  height: 100px;
  position: relative;
  border: 1px solid #eee;
}
.menu-copy {
  margin-left: 8px;
}
::v-deep .ivu-dropdown .ivu-select-dropdown {
  width: auto;
}
::v-deep .ivu-dropdown-item {
  display: flex;
  align-items: center;
}
::v-deep .ivu-dropdown-menu {
  padding: 0 4px;
}
::v-deep .ivu-dropdown-menu .ivu-dropdown-item:hover {
  color: #e67d00;
  background: #fff8e8;
}
::v-deep .ivu-poptip-popper {
  min-width: 116px;
  top: 28px !important;
}
::v-deep .ivu-poptip-popper.download-poptip {
  min-width: 100px;
}
.service-box .service-item {
  width: 106px;
  height: 32px;
  padding: 0px 12px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  position: relative;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.9);
}
.service-box .service-item:hover {
  background: #fff8e8;
  color: #e67d00;
}
.service-box .service-contact {
  position: absolute;
  bottom: 7px;
  left: 117px;
  height: 40px;
  padding: 0 12px;
  border-radius: 0px 4px 4px 0px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.9);
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
}
.service-box .service-contact .copy-text {
  font-size: 12px;
  color: #2438bc;
  margin-left: 8px;
}
.download-box .download-item {
  width: 90px;
  height: 32px;
  padding: 0px 12px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  position: relative;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.9);
}
.download-box .download-item:hover {
  background: #fff8e8;
  color: #e67d00;
}
.smartphone,
.smartphone-hover {
  width: 12px !important;
  height: 12px !important;
}
.smartphone-hover {
  display: none;
}
.phone-item:hover {
  color: #e67d00;
}
.phone-item:hover .smartphone {
  display: none;
}
.phone-item:hover .smartphone-hover {
  display: inline-block;
}
.code-container {
  width: 256px;
  height: 150px;
  background-color: #fff;
  z-index: 999;
  top: 80px;
  right: 8px;
  display: flex;
  padding: 12px 16px;
  border-radius: 8px;
  box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.08);
}
.code-container .code-content {
  flex: 1;
  text-align: center;
}
.code-container .code-content .code-title {
  color: rgba(0, 0, 0, 0.9);
  font-family: "PingFang SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
.code-container img {
  width: 100px;
  height: 100px;
}
