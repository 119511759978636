.home-content {
  margin: 0 auto 12px;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  padding: 0 15px 0px 15px;
  overflow: hidden;
  position: relative;
}
.content-right {
  width: 236px;
  height: max-content;
}
.cms-config {
  padding-bottom: 16px;
  position: relative;
  overflow: hidden;
  clear: both;
  font-size: 0;
}
.cms-config-item {
  display: block;
  vertical-align: middle;
  min-width: 1200px;
  margin: 12px 0 0 0;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.cms-config-item:first-child {
  margin-top: 0;
}
.cms-config-item.no-top {
  margin-top: 0;
}
.cms-config-item.no-bottom {
  margin-bottom: 0;
}
.cms-config-empty {
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.cms-config-empty .cms-config-wrap {
  width: 216px;
  height: 216px;
  position: relative;
}
.cms-config-empty img {
  width: 216px;
  height: 216px;
}
.cms-config-empty p {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.6);
  position: absolute;
  bottom: -2px;
  left: 50%;
  transform: translateX(-50%);
}
.v-lift-nav-ref {
  position: sticky;
  bottom: 0;
  right: 20px;
}
