.sign-content {
  width: 1200px;
  margin: auto;
}
.sign-list-content {
  background-color: #fff;
  margin-top: 12px;
  padding: 24px;
}
.sign-list-content .company-title {
  color: rgba(0, 0, 0, 0.9);
  /* medium/16 */
  font-family: 'PingFang SC';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  margin-bottom: 4px;
}
.sign-list-content .company-total {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sign-list-content .company-total .c-t-l {
  display: flex;
  align-items: center;
}
.sign-list-content .company-total .c-t-l span {
  margin-right: 8px;
  color: rgba(0, 0, 0, 0.9);
  /* regular/14 */
  font-family: 'PingFang SC';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
.sign-list-content .company-total .c-t-l .red {
  color: var(--error-7, #cb272d);
  /* medium/18 */
  font-family: 'PingFang SC';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  /* 144.444% */
}
.sign-list-content .company-total .c-t-r {
  color: rgba(0, 0, 0, 0.6);
  /* regular/14 */
  font-family: 'PingFang SC';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
}
.sign-list-content .company-total .c-t-r span {
  margin-left: 24px;
}
.sign-list-content .sign-list-content-bottom {
  margin-top: 24px;
  display: flex;
}
.sign-list-content .sign-list-content-bottom .s-l-c-b-l {
  position: relative;
  width: 360px;
  height: 510px;
  border: 1px solid var(--gray-3, #eee);
  margin-right: 24px;
}
.sign-list-content .sign-list-content-bottom .s-l-c-b-l .full-screen {
  position: absolute;
  right: 16px;
  top: 70px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.6);
  width: 82px;
  height: 28px;
  display: flex;
  padding: 0px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  cursor: pointer;
}
.sign-list-content .sign-list-content-bottom .s-l-c-b-l .full-screen span {
  color: var(--fontcolor-white-0, #fff);
  font-family: 'PingFang SC';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  /* 116.667% */
  white-space: nowrap;
}
.sign-list-content .sign-list-content-bottom .s-l-c-b-l .full-screen img {
  width: 16px;
  height: 16px;
}
.sign-list-content .sign-list-content-bottom .s-l-c-b-r {
  flex: 1;
}
.sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal {
  margin-bottom: 24px;
}
.sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .title {
  color: rgba(0, 0, 0, 0.9);
  /* medium/16 */
  font-family: 'PingFang SC';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  margin-bottom: 4px;
}
.sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .tip {
  color: rgba(0, 0, 0, 0.6);
  /* regular/12 */
  font-family: 'PingFang SC';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 166.667% */
  margin-bottom: 16px;
}
.sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods {
  margin-top: 16px;
}
.sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-header {
  display: flex;
}
.sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-header .col {
  display: flex;
  height: 40px;
  padding: 0px 8px;
  align-items: center;
  background: var(--gray-1, #fafafa);
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.9);
  /* regular/12 */
  font-family: 'PingFang SC';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 166.667% */
}
.sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-header .col span.red {
  color: #cb272d;
}
.sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-header .col.col-1 {
  width: 268px;
}
.sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-header .col.col-2 {
  width: 112px;
}
.sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-header .col.col-3 {
  width: 112px;
}
.sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-header .col.col-4 {
  flex: 1;
}
.sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-header .col.col-5 {
  width: 48px;
}
.sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-body {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid var(--gray-3, #eee);
}
.sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-body .col {
  height: 80px;
  padding: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.9);
}
.sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-body .col.col-1 {
  width: 268px;
}
.sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-body .col.col-1 div {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-body .col.col-1 div.good-name {
  color: rgba(0, 0, 0, 0.9);
  font-family: 'PingFang SC';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 166.667% */
}
.sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-body .col.col-1 div.good-company,
.sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-body .col.col-1 div.good-code {
  color: rgba(0, 0, 0, 0.6);
  /* regular/12 */
  font-family: 'PingFang SC';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}
.sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-body .col.col-2 {
  width: 112px;
}
.sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-body .col.col-2 .abnormal-num {
  width: 96px;
}
.sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-body .col.col-3 {
  width: 112px;
}
.sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-body .col.col-3 .abnormal-num {
  width: 76px;
}
.sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-body .col.col-3 .unit {
  color: rgba(0, 0, 0, 0.9);
  /* regular/12 */
  font-family: 'PingFang SC';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 166.667% */
  margin-left: 8px;
}
.sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-body .col.col-4 {
  flex: 1;
}
.sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-body .col.col-4 .abnormal-num {
  width: 100%;
}
.sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-body .col.col-5 {
  width: 48px;
}
.sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-body .col.col-5 .del-btn {
  color: var(--hblue-6, #3853e2);
  /* regular/12 */
  font-family: 'PingFang SC';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 166.667% */
  cursor: pointer;
}
.sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-body .abnormal-type {
  width: 112px;
}
.sign-list-content .sign-list-content-bottom .s-l-c-b-r .abnormal .abnormal-goods .abnormal-goods-body .abnormal-type .type-desc {
  -webkit-line-clamp: 3;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  word-break: break-all;
  -webkit-box-orient: vertical;
  cursor: pointer;
}
.sign-list-content .sign-list-content-bottom .s-l-c-b-r .certificate .title {
  color: rgba(0, 0, 0, 0.9);
  /* medium/16 */
  font-family: 'PingFang SC';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  margin-bottom: 4px;
}
.content-imgs {
  margin-top: 16px;
  gap: 16px;
  display: flex;
  width: 768px;
  flex-wrap: wrap;
}
.content-imgs img {
  width: 120px;
  height: 120px;
}
