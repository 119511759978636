.cmsFileDetail-wrapper ~ .user-footer-wrapper {
  background: #F5F5F5;
}
.user-footer-wrapper {
  padding: 32px 0;
  background: #fff;
}
.user-footer-wrapper .row-type {
  text-align: center;
  max-width: 85%;
  margin: 0 auto;
}
.user-footer-wrapper .row-type .item {
  cursor: pointer;
}
.user-footer-wrapper .row-type span {
  font-size: 12px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.4);
}
.user-footer-wrapper .row-type .line {
  margin: 0 10px;
}
