
.notice-bar {
    overflow: hidden;
    position: relative;
    p {
        position: absolute;
        font-size: 16px;
        padding: 6px 7px;
        height: 40px;
        line-height: 28px;
        opacity: 0;
        animation-delay: 0s;
        &.start {
            opacity: 1;
        }
    }
}
.animate__animated {
    animation-duration: 1s;
    animation-fill-mode: both;
}
.animate__fadeInUp {
    animation-name: fadeInUp;
}
@keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}
@keyframes fadeOutUp {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
}
.animate__fadeOutUp {
    -webkit-animation-name: fadeOutUp;
    animation-name: fadeOutUp;
}
