
:deep(.group-buy-input) {
  height: 36px;
  width: 116px;
  box-shadow: none;
  border-color: #dcdcdc;

  &:hover {
    border-color: #dcdcdc !important;
    outline: none;
  }

  .ivu-input-number-input {
    height: 36px;
  }

  .ivu-input-number-input-wrap {
    height: 36px;
  }

  .ivu-input-number-controls-outside-btn {
    height: 36px;
    line-height: 34px;
    width: 28px;
    background: #f5f5f5;

    i {
      font-size: 20px;
      line-height: 36px;
      color: #4b4b4b;

      &:before,
      &:after {
        font-size: 20px !important;
        font-weight: bold;
      }
    }

    &:hover i {
      color: #f99d33;
    }
  }

  .ivu-input-number-input {
    text-align: center;
  }
}

.outer {
  .btn {
    margin-left: 8px;
    border: 0;
    width: 72px;
    height: 36px;
    cursor: pointer;
    background: url('~@/assets/productCards/group-buy-btn.png') no-repeat;
    background-size: 72px 36px;
    border-radius: 4px;

    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    color: #fff;
    line-height: 36px;
    padding-right: 6px;
    text-align: right;

    &.disabled {
      background: url('~@/assets/productCards/buy-btn-disabled.png') no-repeat;
      background-size: 72px 36px;
    }
  }
}

.actions {
  margin-top: 8px;
  display: flex;
}

.progress {
  padding: 7px 0;
  display: flex;
  align-items: center;

  .progress-line {
    height: 6px;
    flex-grow: 1;
    background: #fff2e8;
    border-radius: 40px;
  }

  .progress-bar {
    height: 100%;
    border-radius: 30px;
    background: linear-gradient(90deg, #fa541c 70%, #fea278 100%);
    max-width: 100%;
  }

  .reach-num {
    color: rgba(0, 0, 0, 0.6);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    padding-right: 8px;
  }

  .rate {
    margin-left: 8px;
    color: #d4380d;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
}

.price {
  overflow: hidden;
  height: 28px;
}

.activity-price {
  display: inline-block;
  height: 28px;
  color: #cb272d;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
}

.origin-price {
  display: inline-block;
  height: 28px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  text-decoration-line: line-through;
  margin-left: 8px;
}

.title {
  line-height: 20px;
  max-height: 40px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.prefix {
  position: relative;
  margin-right: 5px;
  padding-right: 5px;

  &::after {
    content: '';
    position: absolute;
    height: 14px;
    width: 1px;
    background: #1a1a1a;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}

.group-buy-info {
  margin-top: 2px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  display: flex;
  align-items: center;
  .group-buy-info-text {
    max-width: 160px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.start-buy {
  position: relative;
  padding-left: 5px;
  margin-left: 5px;

  &:before {
    content: '';
    position: absolute;
    height: 12px;
    width: 1px;
    background: rgba(0, 0, 0, 0.6);
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}

.confirm-btn {
  color: #fafafa;
  width: 72px;
  height: 36px;
  background: #f7ba1e;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-weight: 500;
  margin-left: 6px;
}

.enter-shop {
  color: rgba(0, 0, 0, 0.4);
  white-space: nowrap;
  line-height: 16px;
  font-size: 12px;
  margin-left: auto;
  transition: 0.3s ease;
  &:hover {
    color: #e67d00;
  }
}
em {
  font-style: normal;
}
.prefix {
  color: var(--error-7, #cb272d);
  margin-right: 4px;

  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.step-price-wrap {
  display: flex;
  align-items: center;
  .cny {
    color: var(--error-7, #cb272d);
    font-family: 'PingFang SC';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 128.571% */
  }
  .int {
    color: var(--error-7, #cb272d);
    font-family: 'Arial';
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
  .float {
    font-family: 'Arial';
    color: #cb272d;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  }
  .activity-price .no-price {
    font-size: 16px;
  }
  .through-price {
    display: inline-block;
    vertical-align: middle;
    height: 28px;
    text-decoration-line: line-through;
    text-decoration-color: #999;
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    margin-top: 7px;
    margin-left: 8px;
  }
}
.step-wrap {
  color: var(--font_black-2, rgba(0, 0, 0, 0.6));
  font-family: 'PingFang SC';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  margin:6px 0;
  .step-red {
    color: var(--error-7, #CB272D);
  }
}
