.center {
  width: 1200px;
  color: rgba(0, 0, 0, 0.9);
}
.black_2 a {
  color: rgba(0, 0, 0, 0.6) !important;
}
.black_2 span {
  color: rgba(0, 0, 0, 0.6) !important;
}
.black_1 a {
  color: rgba(0, 0, 0, 0.9) !important;
}
.black_1 span {
  color: rgba(0, 0, 0, 0.9) !important;
}
