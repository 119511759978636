:deep(.vertical-center-modal) {
  display: flex;
  align-items: center;
  justify-content: center;
}
:deep(.vertical-center-modal) .ivu-modal {
  top: 0;
}
:deep(.ivu-modal-header) {
  border: none;
}
:deep(.ivu-modal-header) .ivu-modal-header-inner {
  color: var(--font-black-1, rgba(0, 0, 0, 0.9));
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  height: 26px;
}
:deep(.ivu-modal-close) {
  right: 24px;
  top: 8px;
}
:deep(.ivu-modal-close) .ivu-icon-ios-close {
  font-size: 24px;
}
:deep(.ivu-modal-body) {
  padding: 0 16px;
}
:deep(.ivu-modal-footer) {
  border: none;
  padding: 20px 24px;
}
:deep(.ivu-modal-footer) .ivu-btn-text {
  height: 36px;
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid var(--gray-4, #DCDCDC);
  box-sizing: border-box;
  color: var(--font-black-1, rgba(0, 0, 0, 0.9));
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}
:deep(.ivu-modal-footer) .ivu-btn-primary {
  height: 36px;
  padding: 8px 16px;
  border-radius: 4px;
  background: var(--brand-6, #F99D33);
  border: none;
  color: var(--font-white-0, #FFF);
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}
:deep(.ivu-table-header) {
  border-radius: 8px 8px 0px 0px;
  border: 1px solid var(--gray-2, #EEE);
  background: var(--gray-1, #FAFAFA);
}
:deep(.ivu-table-header) th {
  border: none;
  background: none;
  padding: 4px;
  border-right: 1px solid #eee;
}
:deep(.ivu-table-header) th:last-child {
  border-right: none;
}
:deep(.ivu-table-header) th:first-child {
  padding-left: 10px;
}
:deep(.ivu-table-header) th:first-child .ivu-table-cell {
  text-align: center;
}
:deep(.ivu-table-header) .ivu-table-cell {
  padding: 0;
  color: var(--font-black-2, rgba(0, 0, 0, 0.9));
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}
:deep(table) {
  width: 1074px;
  border-spacing: 16px 0;
  border-collapse: collapse;
}
:deep(.ivu-table-body) {
  border-radius: 0px 0px 8px 8px;
  border: 1px solid var(--gray-2, #EEE);
  border-top: 0;
}
:deep(.ivu-table-tbody) tr {
  border-bottom: 1px solid var(--gray-2, #EEE);
}
:deep(.ivu-table-tbody) tr:last-child {
  border-bottom: none;
}
:deep(.ivu-table-tbody) td {
  border-bottom: none;
  padding: 4px;
  vertical-align: top;
  border-right: 1px solid #eee;
}
:deep(.ivu-table-tbody) td:last-child {
  border-right: none;
}
:deep(.ivu-table-tbody) td:first-child {
  padding-left: 10px;
}
:deep(.ivu-table-tbody) .ivu-table-row-hover td {
  background: #FFF8F0 !important;
}
:deep(.ivu-table-tbody) .ivu-table-cell {
  padding: 0;
  color: var(--font-black-2, rgba(0, 0, 0, 0.9));
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}
:deep(.ivu-table):before {
  display: none;
}
