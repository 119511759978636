.four-product-wrap ::v-deep .product-item {
  border-left: 0.5px solid #eee;
}
.four-product-wrap ::v-deep .goods_container {
  width: 234px;
  padding-top: 24px;
  border-radius: 0px;
  border-right: 1px solid #EEEEEE;
}
.four-product-wrap ::v-deep .goods_container:first-child {
  border-radius: 8px 0 0 8px;
}
.four-product-wrap ::v-deep .goods_container:last-child {
  border-right: 0;
  border-radius: 0 8px 8px 0;
}
.four-product-wrap ::v-deep .goods_container .show_name,
.four-product-wrap ::v-deep .goods_container .show_org {
  width: 196px;
}
.four-product-wrap ::v-deep .goods_container .item_img {
  width: 146px;
  height: 146px;
}
.four-product-wrap ::v-deep .tag_ite {
  margin-left: 0 !important;
}
.five-product-wrap ::v-deep .goods_container {
  width: 188px;
  height: 242px;
  padding-top: 24px;
  border-radius: 0px;
  border-right: 1px solid #EEEEEE;
}
.five-product-wrap ::v-deep .goods_container:last-child {
  border-right: 0;
  border-radius: 0 8px 8px 0;
}
.five-product-wrap ::v-deep .goods_container .item_img {
  width: 100px;
  height: 100px;
}
.five-product-wrap ::v-deep .goods_container .purchase-wrap .content {
  width: 100%;
}
.five-product-wrap ::v-deep .tag_ite {
  margin-left: 0 !important;
}
.five-product-wrap ::v-deep .term_of_validity {
  left: unset;
  right: 0;
}
.v-category-card .five-product-wrap ::v-deep .purchase-wrap .content {
  width: 100%;
}
.v-category-card .five-product-wrap ::v-deep .purchase-wrap .content .title {
  width: 100%;
  white-space: unset;
}
.v-category-card .five-product-wrap ::v-deep .purchase-wrap .content .buy-num {
  flex-wrap: wrap;
}
.eight-product-wrap ::v-deep > *:nth-child(4n) {
  border-right: 0 !important;
}
.eight-product-wrap ::v-deep > *:nth-child(n + 5) {
  border-bottom: 0 !important;
}
.eight-product-wrap ::v-deep > *:nth-child(5n),
.eight-product-wrap ::v-deep > *:nth-child(0n+1) {
  border-left: 1px solid #EEEEEE;
}
.eight-product-wrap ::v-deep .goods_container {
  width: 234px;
  height: 270px;
  padding-top: 24px;
  border-radius: 0 !important;
  border-right: 1px solid #EEEEEE;
  border-bottom: 1px solid #EEEEEE;
}
.eight-product-wrap ::v-deep .goods_container .item_img {
  width: 100px;
  height: 100px;
}
.eight-product-wrap ::v-deep .tag_ite {
  margin-left: 0 !important;
}
.six-product-wrap ::v-deep > *:nth-child(2) {
  margin-left: 600px;
}
.six-product-wrap ::v-deep > *:nth-child(n + 5) {
  border-bottom: 0 !important;
}
.six-product-wrap ::v-deep .goods_container {
  width: 300px;
  height: 324px;
  padding-top: 24px;
  border-radius: 0 !important;
  border-right: 1px solid #EEEEEE;
  border-bottom: 1px solid #EEEEEE;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.six-product-wrap ::v-deep .goods_container .item_img {
  width: 160px;
  height: 160px;
}
.six-product-wrap ::v-deep .purchase-wrap .content {
  width: 100%;
}
.six-product-wrap ::v-deep .right-content {
  width: 100% !important;
}
.six-product-wrap ::v-deep .right-content .title {
  width: 100% !important;
}
.six-product-wrap ::v-deep .tag_ite {
  margin-left: 0 !important;
}
.six-product-wrap ::v-deep .prd_control {
  left: 0;
  margin-left: 0;
}
.six-product ::v-deep .six-img {
  height: 316px !important;
}
.v-many-product ::v-deep .goods_container {
  width: 228px;
  height: 272px;
  padding-top: 24px;
  border-radius: 8px;
  border-right: 1px solid transparent;
}
.v-many-product ::v-deep .goods_container:nth-child( 5n ) {
  margin-right: 0;
}
.v-many-product ::v-deep .goods_container .item_img {
  width: 100px;
  height: 100px;
}
.v-many-product ::v-deep .tag_ite {
  margin-left: 0 !important;
}
.v-product-card ::v-deep .goods_container,
.v-market ::v-deep .goods_container {
  width: 205px;
  height: 272px;
  padding-top: 24px;
  border-radius: 0;
  border-right: 1px solid #EEEEEE;
}
.v-product-card ::v-deep .goods_container:nth-child( 5n ),
.v-market ::v-deep .goods_container:nth-child( 5n ) {
  margin-right: 0;
}
.v-product-card ::v-deep .goods_container .item_img,
.v-market ::v-deep .goods_container .item_img {
  width: 109px;
  height: 109px;
}
.v-product-card ::v-deep .goods_container .content,
.v-market ::v-deep .goods_container .content {
  width: unset;
}
.v-product-card ::v-deep .goods_container .confirm-btn .btn,
.v-market ::v-deep .goods_container .confirm-btn .btn {
  width: 72px;
  height: 24px;
  line-height: 24px;
  font-size: 8px;
}
.v-product-card ::v-deep .goods_container .input,
.v-market ::v-deep .goods_container .input {
  width: 40px !important;
  margin: 0 4px !important;
}
.v-product-card ::v-deep .goods_container .minus,
.v-market ::v-deep .goods_container .minus,
.v-product-card ::v-deep .goods_container .plus,
.v-market ::v-deep .goods_container .plus {
  width: 14px !important;
  height: 14px !important;
}
.v-product-card ::v-deep .goods_container .title,
.v-market ::v-deep .goods_container .title {
  width: 100% !important;
}
.v-product-card ::v-deep .tag_ite,
.v-market ::v-deep .tag_ite {
  margin-left: 0 !important;
}
.v-many-product {
  width: 1200px;
}
.row-list {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}
.row-list .list_item:nth-child(-n + 5) {
  margin-top: 0px;
}
.head {
  display: flex;
  align-items: center;
}
.head .icon-title {
  margin-bottom: 16px;
  height: 32px;
}
.head .more {
  margin-left: auto;
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  color: #181818;
  cursor: pointer;
  width: 60px;
  margin-bottom: 24px;
}
::v-deep .root {
  margin-top: 0;
}
