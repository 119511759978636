
.center {
  width: 1200px;
  color: rgba(0, 0, 0, 0.9);

}
.wrap{
  line-height: 22px; 
  padding-bottom: 24px; 
  font-size: 12px;
}
.black_2 {
  a{
    color: rgba(0, 0, 0, 0.60) !important;
  }
  span{
    color: rgba(0, 0, 0, 0.60) !important;
  }
  
}
.black_1 {
  a{
    color: rgba(0, 0, 0, 0.90) !important;
  }
  span{
    color: rgba(0, 0, 0, 0.90) !important;
  }
  
}
