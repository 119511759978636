
:deep(.ivu-modal-header) {
    border-bottom: none;
    padding: 20px 16px 0;
}

:deep(.ivu-modal-body) {
    padding: 24px;
}

:deep(.ivu-modal-footer) {
    border-top: none;

    .tip {
        position: absolute;
        left: 16px;
        bottom: 16px;
        color: rgba(0, 0, 0, 0.60);
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;

        .download-icon {
            width: 20px;
            height: 20px;
        }

        .down-load {
            color: var(--hbrand-6, #3853E2);

            &:hover {
                cursor: pointer;
            }
        }

        .export {
            margin-left: 10px;
        }
    }

    .ivu-btn-primary[disabled] {
        background: var(--brand-3, #FDDA9F);
        color: #fff;
        border-color: #fdda9f;
    }

}

