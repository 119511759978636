
@import url('../../product.less');
.v-category-card {
  width: 1200px;
}

.four-product-wrap {
  height: 324px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  /deep/ .prd_control{
    height: 56px;
  }
  .four-product-cover {
    width: 288px;
    .four-product {
      width: 100%;
      height: 100%;
    }
  }
  .four-product-right {
    flex: 1;
  }
}

.five-product-wrap {
  height: 320px;
  display: flex;
  /deep/ .prd_control{
    height: 56px;
  }
  .five-product-cover {
    width: 210px;
    .five-product {
      width: 100%;
      height: 100%;
    }
  }
  .five-product-right {
    border-radius: 8px;
    flex: 1;
    .five-item {
      overflow: hidden;
      box-sizing: border-box;
      //border-right: 0.5px solid #eee;
      width: 200px;
    }
  }
}
// .five-product-wrap {
//   height: 320px;
//   display: flex;
//   /deep/ .prd_control{
//     height: 56px;
//   }
//   .five-product-cover {
//     border: 1px solid red;
//     width: 288px;
//     .four-product {
//       width: 100%;
//       height: 100%;
//     }
//   }
//   .five-product-right {
//     flex: 1;
//     border: 1px solid red;
//   }
// }
// .five-product-wrap {
//   display: flex;
//   .product-item {
//     border-left:0.5px solid #eee;
//   }
// }
// .five-product {
//   width: 210px;
//   height: auto;
// }

.swiper-img {
  width: 24px;
  height: 32px;
  position: absolute;
  top: 45%;
  z-index: 999;
}
.four-img-right {
  left: 288px;
}
.four-img-left {
  right: 0;
}
.five-img-right{
  left: 210px;
}
.five-img-left {
  right: 0;
}

.eight-product-wrap {
  display: flex;
  .eight-img-wrap {
    display: flex;
    flex-direction: column;
    .eight-img {
      width: 245px;
      height: 80px;
    }
  }
  .eight-prudoct-wrap {
    display: flex;
    flex-wrap: wrap;
  }
}

:deep(.five-item){
  .list-card{
    width: 198px;
    height: 292px;
    padding: 12px;
    border-radius: 0;
    //&:nth-child(n+1){
    //  border-left:0.5px solid #eee;
    //}
    //&:last-child{
    //  border-right: 0;
    //}
    //.title{
    //  height: 20px;
    //  span{
    //    display: block;
    //    white-space: nowrap;
    //    overflow: hidden;
    //    text-overflow: ellipsis;
    //  }
    //}
  }
  .cover-box,.cover-mask{
    width: 110px!important;
    height: 110px!important;
  }
  .cover-mask{
    top: 12px;
  }
  .price-area-box{
    margin-top: 0;
  }
}
:deep(.four-product-wrap){
  .list-card{
    //width: 228px;
    height: 324px;
    border-radius: 0;
    border-left: 0.5px solid #e7e7e7;
  }
  .cover-box,.cover-mask{
    width: 148px;
    height: 148px;
  }
}
