
.search-bar {
  background-color: #fff;
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 3;
  // position: sticky;
  // z-index: 3;
  // top: 36px
  &.box-shadow {
    box-shadow: 4px 0px 20px 0px rgba(0, 0, 0, 0.08);
  }
}
  .notice {
    height: 100%;
    // overflow: hidden;
    :deep(.ivu-tabs-bar){
      margin-bottom: 0;
    }
    .info_item {
      display: flex;
      align-items: center;
      padding: 16px 0;
      border-style: solid;
      border-color: #E7E7E7;
      border-width: 0 0 0.5px 0;
      cursor: pointer;
      &:last-child {
        border-bottom-width: 0;
        // padding-bottom: 0;
      }
      &:hover {
        background: #FAFAFA;
      }
      .title {
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.9);
      }
      .content {
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.9);
        flex: 1;
      }
      .time {
        font-size: 12px;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.4);
        margin-left: auto;
      }
    }
  }
  .notice-content {
    width: 1200px;
    margin: 0 auto 0;
    background: #FFFFFF;
    padding: 0px 32px;
    box-sizing: border-box;
    border-radius: 0 0 8px 8px;
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 300px;
  }
  .search-warp {
    width: 1200px;
    margin: 16px auto 0;
    position: relative;
    padding-top: 14px;
    background: #FFFFFF;
    border-radius: 8px 8px 0 0;
    :deep(.ivu-tabs-nav-scroll){
      padding: 0 20px;
    }
  }
  .search {
    // float: right;
    margin-left: auto;
    position: absolute;
    right: 24px;
    top: 11px;
  }
  .pagination {
    width: 1200px;
    margin: 32px auto 0;
  }
  .breadcrumb {
    background: #eee;
    padding: 4px 0;
    .breadcrumb-warp {
      width: 1200px;
      margin: 0px auto 0;
      display: flex;
    }
    .text {
      margin-right: 32px;
      color: rgba(0, 0, 0, 0.6);
      font-size: 14px;
    }
    :deep(.ivu-breadcrumb .ivu-breadcrumb-item-separator){
      color: #A6A6A6;
    }
  }
  .empty {
    width: 216px;
    height: 238px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
