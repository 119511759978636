

::v-deep .ivu-modal-content {
  display: flex;
  border-radius: 8px;
  background: var(--font_white-0, #FFF);
}

::v-deep .ivu-modal-header {
 height: 0px;
 padding: 0px;
 margin: 0px;
}

::v-deep .ivu-modal-footer {
 height: 0px;
 padding: 0px;
 margin: 0px;
}

::v-deep .ivu-modal-body{
  display: flex;
  padding: 20px 24px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  width: 100%;
  gap: 16px;

  .title {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;

    img {
      width: 16px;
      height: 16px;
    }

    span {
      color: var(--fontcolor-black-1, rgba(0, 0, 0, 0.90));
      /* medium/16 */
      font-family: "PingFang SC";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
    }

    .close-icon {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .img{
        width: 16px;
        height: 16px;
        cursor: pointer;
      }
    }
  }

  .content {
    color: var(--font_black-1, rgba(0, 0, 0, 0.90));
    /* regular/14 */
    font-family: "PingFang SC";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    margin-left: 23px;
  }

  .bottom {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    gap: 12px;
  }
}

