.menu {
  position: relative;
}
.left-menu {
  width: 260px;
  height: 406px;
  border-radius: 8px;
  overflow: hidden;
}
.left-list {
  background: #FFFFFF;
}
.left-title {
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  padding: 0 16px;
}
.icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
.all-product-icon {
  margin-right: 11px;
}
.left-list {
  overflow: hidden;
  padding: 24px 18px;
}
.left-list-item {
  padding: 0px 0px 10px;
  height: 74px;
}
.left-list-item.hover {
  background: #F5F5F5;
}
.item-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.9);
  cursor: pointer;
  display: flex;
  align-items: center;
}
.item-title:hover {
  color: #CE7720;
}
.item-title .icon-box {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}
.two-level {
  padding-left: 32px;
  height: 40px;
  padding-top: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.two-level .two-level-name {
  box-sizing: content-box;
  height: 22px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.6);
  padding-right: 16px;
  cursor: pointer;
}
.two-level .two-level-name:hover {
  color: #CE7720;
}
.business {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.business .title {
  height: 46px;
  position: relative;
}
.business .title img {
  width: 100%;
  height: 100%;
}
.business .img-list {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px 12px;
  box-sizing: border-box;
  flex: 1;
}
.business .img-list img {
  width: 100%;
  height: 60px;
  border-bottom: 0.5px solid #E7E7E7;
  cursor: pointer;
}
.business .img-list img:last-child {
  border: none;
}
.cate-icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}
