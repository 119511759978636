:deep(.ivu-date-picker) {
  width: 100%;
}
:deep(.time-content) {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  align-items: center;
  gap: 12px;
  flex: 1;
}
:deep(.time-content) .check {
  width: 48px;
}
:deep(.time-content) .input {
  flex: 1;
}
:deep(.time-content) .icon {
  font-size: 20px;
}
