
.information {
  width: 1200px;
  margin: 0 auto;
  &-content {
    margin-bottom: 20px;
    //padding: 24px 218px;
    padding: 24px;
    text-align: left;
    color: rgba(0, 0, 0, 0.9);
    cursor: default;
    box-sizing: border-box;
    background-color: #fff;
  }
}
