
.menu {
  position: relative;
}
.left-menu {
  width: 260px;
  height: 406px;
  border-radius: 8px;
  overflow: hidden;
}
.left-list {
  background: #FFFFFF;
}
.left-title {
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  padding:0 16px;
}
.icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
.all-product-icon {
  margin-right: 11px;
}
.left-list {
  overflow: hidden;
  padding: 24px 18px;
}
.left-list-item {
  padding:0px 0px 10px;
  height: 74px;
  &.hover {
    background: #F5F5F5;
  }
}
.item-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.9);
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    color: #CE7720;
  }
  .icon-box{
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
}
.two-level {
  padding-left: 32px;
  height: 40px;
  padding-top: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  // padding-top: 4px;
  .two-level-name {
    box-sizing: content-box;
    //display: inline-flex;;
    height: 22px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    //padding-right: 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.6);
    padding-right: 16px;
    cursor: pointer;
    &:hover {
      color: #CE7720;
    }
  }
}
.business {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .title {
    height: 46px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .img-list {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px 12px;
    box-sizing: border-box;
    flex: 1;
    img {
      width: 100%;
      height: 60px;
      border-bottom: 0.5px solid #E7E7E7;
      cursor: pointer;
      &:last-child {
        border: none;
      }
    }

  }
}

.cate-icon{
  width: 24px;
  height: 24px;
  margin-right: 8px;
}
