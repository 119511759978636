

.merchat{
  color: var(--font_black-1, rgba(0, 0, 0, 0.90));
  font-family: "PingFang SC";
  font-size: 20px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 32px !important; /* 160% */
}
.info{


  .box{

    .caption{
      color: var(--font_black-1, rgba(0, 0, 0, 0.90));
      /* medium/14 */
      font-family: "PingFang SC";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px; /* 157.143% */

      .caption-right{
        color: var(--font_black-2, rgba(0, 0, 0, 0.60));
        /* regular/14 */
        font-family: "PingFang SC";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
      }
    }
    .grid{
      .item-box{
        display: flex;
        align-items: center;
        flex-direction: column;
      }

      .text-14{
        color: var(--font_black-1, rgba(0, 0, 0, 0.90));
        /* regular/14 */
        font-family: "PingFang SC";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
      }
    }
  }
}
.grid-item{
  &>img{
    width: 26px;
    height: 26px;
  }
}

.grid-hover{
  padding: 2px !important;
  &:hover {
    background-color: #fafafa !important;
    border-radius: 4px !important;
  }
}
.center .content-right .bottom img{
  border-radius: 50%;
  transition: background-color 0.5s ease-in;
}
.center .reBtn{
  display: flex;
  padding: 4px 16px;
  align-items: center;
  gap: 2px;

  border-radius: 4px;
  border: 1px solid var(--gray-2, #EEE);
  background: var(--gray-1, #FAFAFA);

  color: var(--hbrand-6, #3853E2);
  /* regular/14 */
  font-family: PingFang SC;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}
.center .reBtn-68{
  width: 68px;
  height: 22px;
  padding: 0 8px;
  font-size: 12px;

  border-radius: 4px;
  border: 1px solid var(--gray-4, #DCDCDC);
  background: var(--gray-1, #FAFAFA);

  color: var(--font_black-1, rgba(0, 0, 0, 0.90));

  /* regular/12 */
  font-family: PingFang SC;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
}

.center .add-btn{
  background: var(--gray-1, #FAFAFA);
  align-items: center;
  display: flex;
  padding-top: 2px;

  display: flex;
  height: 36px;
  padding: 8px 16px;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  border: 1px solid var(--gray-4, #DCDCDC);
  background: var(--gray-1, #FAFAFA);

  color: var(--font_black-1, rgba(0, 0, 0, 0.90));
  /* regular/14 */
  font-family: PingFang SC;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.grid-item-order {
  padding: 0 !important;
  cursor: pointer;
  transition: all .35s ease-in-out;
  position: relative;
  &:hover {
    background-color: #fafafa !important;
    border-radius: 4px !important;
  }
  .order-line {
    width: 15px;
    height: 52px;
    position: absolute;
    top: 0;
    left: -10px;
    border-radius: none !important;
  }
}
