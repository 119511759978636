.matching-shipping ::v-deep .vertical-center-modal .ivu-modal {
  width: 688px !important;
}
.matching-shipping ::v-deep .vertical-center-modal .ivu-modal .ivu-modal-content .ivu-modal-header {
  border-bottom: none;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.9);
  padding: 16px 32px 4px;
}
.matching-shipping ::v-deep .vertical-center-modal .ivu-modal .ivu-modal-content .ivu-modal-close {
  top: 16px;
  right: 32px;
}
.matching-shipping ::v-deep .vertical-center-modal .ivu-modal .ivu-modal-content .ivu-modal-body {
  padding: 8px 32px 16px;
}
.matching-shipping ::v-deep .vertical-center-modal .ivu-modal .ivu-modal-content .ivu-modal-body .not-full .text {
  font-size: 16px;
  line-height: 24px;
  color: #000;
  margin-bottom: 12px;
}
.matching-shipping ::v-deep .vertical-center-modal .ivu-modal .ivu-modal-content .ivu-modal-body .not-full .content-item {
  max-height: 300px;
  overflow-y: scroll;
}
.matching-shipping ::v-deep .vertical-center-modal .ivu-modal .ivu-modal-content .ivu-modal-body .not-full .content-item::-webkit-scrollbar {
  display: none;
}
.matching-shipping ::v-deep .vertical-center-modal .ivu-modal .ivu-modal-content .ivu-modal-body .not-full .business-box {
  margin-bottom: 16px;
}
.matching-shipping ::v-deep .vertical-center-modal .ivu-modal .ivu-modal-content .ivu-modal-body .not-full .business-box:last-child {
  margin-bottom: 0;
}
.matching-shipping ::v-deep .vertical-center-modal .ivu-modal .ivu-modal-content .ivu-modal-body .not-full .business-box .name {
  font-size: 18px;
  line-height: 26px;
  color: rgba(0, 0, 0, 0.9);
  padding: 16px 26px 18px 26px;
  box-sizing: border-box;
  background-color: #f5f5f5;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.matching-shipping ::v-deep .vertical-center-modal .ivu-modal .ivu-modal-content .ivu-modal-body .not-full .business-box .info {
  padding: 10px 26px 20px 26px;
  box-sizing: border-box;
  border: 1px solid #eee;
  border-top: none;
  border-radius: 0px 0px 8px 8px;
}
.matching-shipping ::v-deep .vertical-center-modal .ivu-modal .ivu-modal-content .ivu-modal-body .not-full .business-box .info-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.matching-shipping ::v-deep .vertical-center-modal .ivu-modal .ivu-modal-content .ivu-modal-body .not-full .business-box .info-content .left {
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.6);
}
.matching-shipping ::v-deep .vertical-center-modal .ivu-modal .ivu-modal-content .ivu-modal-body .not-full .business-box .info-content .left span {
  color: #cb272d;
}
.matching-shipping ::v-deep .vertical-center-modal .ivu-modal .ivu-modal-content .ivu-modal-body .not-full .business-box .info-content .right {
  font-size: 16px;
  line-height: 24px;
  color: #CB272D;
  cursor: pointer;
}
.matching-shipping ::v-deep .vertical-center-modal .ivu-modal .ivu-modal-content .ivu-modal-body .not-full .business-box .tag {
  display: flex;
  align-items: center;
}
.matching-shipping ::v-deep .vertical-center-modal .ivu-modal .ivu-modal-content .ivu-modal-body .not-full .business-box .tag .typeOne {
  border: 0.5px solid #3853E2;
  background-color: #F1F5FE;
  color: #3853E2;
  font-size: 14px;
  line-height: 22px;
  border-radius: 4px;
  padding: 2px 4px;
  margin-right: 10px;
  margin-top: 10px;
}
.matching-shipping ::v-deep .vertical-center-modal .ivu-modal .ivu-modal-content .ivu-modal-body .not-full .business-box .tag .typeTwo {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.4);
  font-size: 14px;
  line-height: 22px;
  border-radius: 4px;
  padding: 2px 4px;
  margin-top: 10px;
}
.matching-shipping ::v-deep .vertical-center-modal .ivu-modal .ivu-modal-content .ivu-modal-body .not-full .business-box .freight_voucher {
  margin-top: 12px;
  display: flex;
  align-items: flex-start;
}
.matching-shipping ::v-deep .vertical-center-modal .ivu-modal .ivu-modal-content .ivu-modal-body .not-full .business-box .freight_voucher .freight_mark {
  padding: 0 6px;
  margin-right: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFEDF1;
  border: 0.5px solid #FF4D77;
  border-radius: 4px;
}
.matching-shipping ::v-deep .vertical-center-modal .ivu-modal .ivu-modal-content .ivu-modal-body .not-full .business-box .freight_voucher .freight_mark .freight_txt {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #E72A57;
}
.matching-shipping ::v-deep .vertical-center-modal .ivu-modal .ivu-modal-content .ivu-modal-body .not-full .business-box .freight_voucher .freight_wrap {
  display: flex;
  flex: 1;
}
.matching-shipping ::v-deep .vertical-center-modal .ivu-modal .ivu-modal-content .ivu-modal-body .not-full .business-box .freight_voucher .freight_wrap p {
  width: 536px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}
.matching-shipping ::v-deep .vertical-center-modal .ivu-modal .ivu-modal-content .ivu-modal-body .not-full .business-box .freight_voucher .freight_wrap p .freight_tip {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.6);
}
.matching-shipping ::v-deep .vertical-center-modal .ivu-modal .ivu-modal-content .ivu-modal-body .modal-footer {
  display: flex;
  justify-content: flex-end;
  padding-top: 24px;
}
.matching-shipping ::v-deep .vertical-center-modal .ivu-modal .ivu-modal-content .ivu-modal-body .modal-footer span {
  padding: 5px 16px;
  border-radius: 3px;
  font-size: 14px;
  line-height: 22px;
  margin-left: 8px;
  cursor: pointer;
}
.matching-shipping ::v-deep .vertical-center-modal .ivu-modal .ivu-modal-content .ivu-modal-body .modal-footer .back-car {
  background-color: #e7e7e7;
  color: rgba(0, 0, 0, 0.9);
}
.matching-shipping ::v-deep .vertical-center-modal .ivu-modal .ivu-modal-content .ivu-modal-body .modal-footer .continue {
  background-color: #f99d33;
  color: rgba(255, 255, 255, 0.9);
}
