.bottom-bar {
  width: 100%;
  display: flex;
  justify-content: center;
  position: static;
  bottom: 0;
  left: 0;
  z-index: 100;
}
.bottom-bar .bottom-bar-content {
  margin: 0 auto;
  width: 1200px;
  height: 68px;
  padding: 16px 0px 16px 16px;
  box-sizing: border-box;
  background: var(--font-white-0, #FFF);
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px -16px 20px -10px rgba(0, 0, 0, 0.04);
}
.bottom-bar .bottom-bar-content .select-del ::v-deep .ivu-checkbox-wrapper {
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.9);
}
.bottom-bar .bottom-bar-content .select-del ::v-deep .ivu-checkbox-wrapper .ivu-checkbox {
  margin-right: 8px;
}
.bottom-bar .bottom-bar-content .select-del .del {
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.9);
  margin-left: 16px;
  cursor: pointer;
}
.bottom-bar .bottom-bar-content .select-del .del:hover {
  color: #F76560;
}
.bottom-bar .bottom-bar-content .select-del .del:active {
  color: #CB272D;
}
.bottom-bar .bottom-bar-content .right {
  display: flex;
  align-items: center;
}
.bottom-bar .bottom-bar-content .right .sub-total {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.bottom-bar .bottom-bar-content .right .sub-total .num {
  display: flex;
  align-items: center;
}
.bottom-bar .bottom-bar-content .right .sub-total .num .num-wrap {
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400 !important;
}
.bottom-bar .bottom-bar-content .right .sub-total .num .num-wrap i {
  font-style: normal;
  color: #ce7720;
  font-weight: 500 !important;
}
.bottom-bar .bottom-bar-content .right .sub-total .num .price-wrap {
  margin-left: 11px;
}
.bottom-bar .bottom-bar-content .right .sub-total .num .price-wrap .num-price,
.bottom-bar .bottom-bar-content .right .sub-total .num .price-wrap .num-price > span {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.9);
  font-family: "PingFang SC";
}
.bottom-bar .bottom-bar-content .right .sub-total .num .mr-left {
  margin-left: 5px;
}
.bottom-bar .bottom-bar-content .right .sub-total .num .mr-lf-8 {
  margin-left: 8px;
}
.bottom-bar .bottom-bar-content .right .sub-total .num .font-style-r {
  font-weight: 600 !important;
}
.bottom-bar .bottom-bar-content .right .sub-total .sum {
  font-size: 12px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 2px;
}
.bottom-bar .bottom-bar-content .right .sub-total .sum span {
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.9);
  font-weight: 500;
  margin-left: 8px;
  margin-right: 8px;
}
.bottom-bar .bottom-bar-content .right .sub-total .sum span:last-child {
  margin-right: 0px;
}
.bottom-bar .bottom-bar-content .right .gray-line {
  width: 1px;
  height: 52px;
  background-color: #e7e7e7;
  margin: 0 24px;
}
.bottom-bar .bottom-bar-content .right .total {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.bottom-bar .bottom-bar-content .right .total .amount-price {
  display: flex;
  align-items: center;
}
.bottom-bar .bottom-bar-content .right .total .amount-price .amount {
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.9);
  font-weight: 400;
}
.bottom-bar .bottom-bar-content .right .total .amount-price .price {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #cb272d;
}
.bottom-bar .bottom-bar-content .right .total .detailed .activity-discountAmount {
  font-family: "PingFang SC";
  font-size: 12px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.9);
  font-weight: 400 !important;
  font-style: normal;
  margin-right: 16px;
}
.bottom-bar .bottom-bar-content .right .total .detailed .activity-discountAmount .activity-num {
  color: var(--font_black-1, rgba(0, 0, 0, 0.9));
  font-weight: 600 !important;
  font-style: normal;
}
.bottom-bar .bottom-bar-content .right .total .detailed .detail-text {
  cursor: pointer;
  font-size: 12px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.9);
  margin-right: 2px;
}
.bottom-bar .bottom-bar-content .right .total .detailed ::v-deep .ivu-poptip .ivu-poptip-body {
  width: 528px;
  padding: 16px;
  max-height: 392px;
  overflow-y: scroll;
  box-sizing: border-box;
}
.bottom-bar .bottom-bar-content .right .total .detailed ::v-deep .ivu-poptip .ivu-poptip-body::-webkit-scrollbar {
  display: none;
}
.bottom-bar .bottom-bar-content .right .total .detailed ::v-deep .ivu-poptip .ivu-poptip-body .ivu-poptip-body-content .detail-content .item {
  margin-bottom: 16px;
}
.bottom-bar .bottom-bar-content .right .total .detailed ::v-deep .ivu-poptip .ivu-poptip-body .ivu-poptip-body-content .detail-content .item:last-child {
  margin-bottom: 0;
}
.bottom-bar .bottom-bar-content .right .total .detailed ::v-deep .ivu-poptip .ivu-poptip-body .ivu-poptip-body-content .detail-content .item .company-name {
  padding: 8px 26px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.9);
  background: #f5f5f5;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.bottom-bar .bottom-bar-content .right .total .detailed ::v-deep .ivu-poptip .ivu-poptip-body .ivu-poptip-body-content .detail-content .item .info {
  border: 1px solid #f5f5f5;
  border-top: none;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 0px 20px 12px;
}
.bottom-bar .bottom-bar-content .right .total .detailed ::v-deep .ivu-poptip .ivu-poptip-body .ivu-poptip-body-content .detail-content .item .info .row {
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
}
.bottom-bar .bottom-bar-content .right .total .detailed ::v-deep .ivu-poptip .ivu-poptip-body .ivu-poptip-body-content .detail-content .item .info .row:first-child {
  padding-top: 12px;
  margin-top: 0;
}
.bottom-bar .bottom-bar-content .right .total .detailed ::v-deep .ivu-poptip .ivu-poptip-body .ivu-poptip-body-content .detail-content .item .info .row .detail-price {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
}
.bottom-bar .bottom-bar-content .right .total .detailed ::v-deep .ivu-poptip .ivu-poptip-body .ivu-poptip-body-content .detail-content .item .info .row .info-icon {
  transition: all 0.35s ease-in-out;
}
.bottom-bar .bottom-bar-content .right .total .detailed ::v-deep .ivu-poptip .ivu-poptip-body .ivu-poptip-body-content .detail-content .item .info .row.active .info-icon {
  transform: rotate(-180deg);
}
.bottom-bar .bottom-bar-content .right .total .detailed ::v-deep .ivu-poptip .ivu-poptip-body .ivu-poptip-body-content .detail-content .item .info .info-box {
  height: 0;
  background-color: #fafafa;
  padding: 0px 20px;
  margin: 3px 0;
  border-radius: 4px;
  overflow: hidden;
}
.bottom-bar .bottom-bar-content .right .total .detailed ::v-deep .ivu-poptip .ivu-poptip-body .ivu-poptip-body-content .detail-content .item .info .info-box .info-box-row {
  font-size: 12px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: space-between;
}
.bottom-bar .bottom-bar-content .right .total .detailed ::v-deep .ivu-poptip .ivu-poptip-body .ivu-poptip-body-content .detail-content .item .info .info-box .info-box-row .info-box-coupon {
  color: rgba(0, 0, 0, 0.6);
  display: inline-block;
}
.bottom-bar .bottom-bar-content .right .total .detailed ::v-deep .ivu-poptip .ivu-poptip-body .ivu-poptip-body-content .detail-content .item .info .info-box .info-box-row .info-box-goods {
  width: 320px;
  overflow: hidden;
  margin-left: 3px;
}
.bottom-bar .bottom-bar-content .right .total .detailed ::v-deep .ivu-poptip .ivu-poptip-body .ivu-poptip-body-content .detail-content .item .info .info-box.active {
  height: auto;
  padding: 5px 20px;
  overflow: auto;
}
.bottom-bar .bottom-bar-content .right .total .detailed ::v-deep .ivu-poptip .ivu-poptip-body .ivu-poptip-body-content .detail-content .item .info .detail-amount {
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
}
.bottom-bar .bottom-bar-content .right .total .detailed ::v-deep .ivu-poptip .ivu-poptip-body .ivu-poptip-body-content .detail-content .item .info .detail-amount .left {
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.6);
}
.bottom-bar .bottom-bar-content .right .total .detailed ::v-deep .ivu-poptip .ivu-poptip-body .ivu-poptip-body-content .detail-content .item .info .detail-amount .left span {
  color: #ce7720;
  margin: 0 4px;
}
.bottom-bar .bottom-bar-content .right .total .detailed ::v-deep .ivu-poptip .ivu-poptip-body .ivu-poptip-body-content .detail-content .item .info .detail-amount .right {
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.9);
}
.bottom-bar .bottom-bar-content .right .total .detailed ::v-deep .ivu-poptip .ivu-poptip-body .ivu-poptip-body-content .detail-content .item .info .detail-amount .right span {
  color: #d4380d;
  font-weight: 500;
}
.bottom-bar .bottom-bar-content .right .foot {
  background-color: #f99d33;
  color: var(--font-white-0, #FFF);
  font-family: PingFang SC;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
  margin-left: 24px;
  width: 136px;
  line-height: 68px;
  text-align: center;
}
.bottom-bar .bottom-bar-content .right .foot:hover {
  background-color: #FAB357;
}
.bottom-bar .bottom-bar-content .right .foot:active {
  background-color: #E67D00;
}
.noData {
  position: fixed !important;
}
.bottom-btn-disabled {
  background-color: #fdda9f !important;
}
.disabledBtn {
  background-color: #fdda9f !important;
}
.disabledBtn::after {
  border: none;
}
::v-deep .ivu-poptip-inner {
  border-radius: 8px;
}
::v-deep .ivu-checkbox:hover .ivu-checkbox-inner {
  /* 在这里设置你想要的悬停颜色 */
  border-color: #F99D33;
}
