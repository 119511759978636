.user {
  height: 406px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 8px;
  position: relative;
}
.no-related {
  height: 140px;
  text-align: center;
}
.no-related-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.9);
}
.no-related-desc {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.4);
}
.no-related-btn {
  display: inline-block;
  width: 200px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #fff;
  margin-top: 12px;
  padding: 8px 10px;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background: var(--3, linear-gradient(332deg, #ff5c17 17.15%, #ffae35 82.37%));
}
.custom-name {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.9);
  user-select: none;
  cursor: pointer;
  min-height: 24px;
  max-height: 48px;
}
.custom-name .type {
  border-radius: 4px;
  font-weight: 400;
  line-height: 10px;
  padding: 4px 4px 3px;
  margin-right: 4px;
  display: inline-block;
  border: 1px solid;
  font-size: 10px;
  box-sizing: border-box;
  vertical-align: text-top;
}
.custom-name .state-icon {
  width: 58px;
  height: 18px;
  margin-right: 4px;
  vertical-align: text-top;
  margin-top: 2px;
}
.address {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.6);
}
.orders {
  display: flex;
  margin-top: 4px;
}
.orders .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.orders .item p:nth-child(1) {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: rgba(0, 0, 0, 0.9);
}
.orders .item p:nth-child(2) {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: rgba(0, 0, 0, 0.9);
}
.item {
  width: 73px;
  height: 52px;
  padding: 4px 0;
  border-radius: 4px;
}
.item:hover {
  background: #f5f5f5;
}
.collect-business {
  display: flex;
  margin-top: 4px;
  width: max-content;
}
.collect-business .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
}
.collect-business .item p:nth-child(1) {
  height: 24px;
}
.collect-business .item p:nth-child(1) img {
  width: 24px;
  height: 24px;
}
.collect-business .item p:nth-child(2) {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: rgba(0, 0, 0, 0.9);
}
.change-custom {
  left: -412px;
  top: 78px;
}
.svg-icon.address {
  width: 12px;
  height: 12px;
  margin-bottom: 1px;
}
::v-deep .ivu-tooltip {
  height: 22px;
}
.coupon-list {
  position: absolute;
  bottom: 16px;
  width: 228px;
}
.user ::v-deep .ivu-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
}
.user ::v-deep .ivu-avatar > img {
  vertical-align: middle;
}
