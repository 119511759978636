
.home-notice-wrap {
  background: #ffffff;
  // border-radius: 8px;
  overflow: hidden;
  margin-top: 8px;
  ::v-deep .ivu-tabs-bar {
    margin-bottom: 0px;
  }
  ::v-deep .ivu-tabs-nav {
    .ivu-tabs-tab {
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      padding: 2px 0;
      color: rgba(0, 0, 0, 0.9);
      &:hover{
        color: #e67d00;
      }
    }
    .ivu-tabs-tab-active {
      color: #e67d00;
      font-weight: 500;
    }
  }

  .notic-icon {
    cursor: pointer;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.6);
    &:hover{
      color: #e67d00;
      .ivu-icon{
          color: #e67d00 !important;
        }
    }
  }

  .ivu-icon {
    margin-top: -2px;
    color: rgba(0, 0, 0, 0.6);
  }

  .list {
    height: 96px;
    padding-top: 6px;
    .item {
      margin: 2px 0;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.9);
      cursor: pointer;

      &:hover {
        color: #e67d00;
      }
    }
    .empty-notice {
      height: inherit;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.40);
      line-height: 20px;
      text-align: center;
    }
  }
}
