
.demo-carousel {
  height: 200px;
  line-height: 200px;
  text-align: center;
  color: #fff;
  font-size: 20px;
  background: #506b9e;
}
.certification {
  &-description {
    width: 744px; height: 46px; margin: 20px auto; line-height: 22px; padding: 12px 24px; box-sizing: border-box; font-size: 14px; color: rgba(0, 0, 0, .6); background-color:#F5F5F5;
    span {
      color: #A1151E; font-weight: 700;
    }
  }
}
.card-list {
  width: 960px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding-top: 20px;
  &-item {
    display: flex;
    gap: 12px;
    width: 360px;
    padding: 12px;
    border: #eee 1px solid;
    border-radius: 4px;
    &-img {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 110px;
      height: 110px;
      padding: 6px;
      border: #eee 1px solid;
      border-radius: 4px;
      box-sizing: border-box;
      .preview {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .4);
        border-radius: 4px;
        opacity: 0;
        transition: opacity .5s ease;
      }
      &:hover {
        .preview {
          opacity: 1;
        }
      }
      img {
        width: 100%;
      }
    }
    &-content {
      line-height: 20px;
      padding-left: 10px;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.6);
      .name {
        position: relative;
        line-height: 30px;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.9);
        &.required {
          &::before {
            content: '*';
            position: absolute;
            left: -10px;
            top: 50%;
            transform: translateY(-50%);
            color: #CB272D;
          }
        }
      }
    }
  }
}
