
:deep(.abnormal-goods-select-modal) {
  // 弹框垂直居中
  display: flex;
  align-items: center;
  justify-content: center;

  .ivu-modal {
    // 弹框垂直居中
    top: 0;

    width: 886px !important;
    .ivu-modal-content {
      border-radius: 8px;
      .ivu-modal-header {
        padding: 14px 16px 0px;
        border: none;
        .ivu-modal-header-inner {
          color: rgba(0, 0, 0, 0.9);
          /* medium/16 */
          font-family: 'PingFang SC';
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px; /* 150% */
        }
      }
      .ivu-modal-body {
        .ivu-input-wrapper {
          width: 320px;
          .ivu-input {
            padding: 5px 8px;
            &::placeholder {
              overflow: hidden;
              color: rgba(0, 0, 0, 0.4);
              text-overflow: ellipsis;
              white-space: nowrap;
              /* Body/Medium */
              font-family: 'PingFang SC';
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px; /* 157.143% */
            }
          }
        }

        .ivu-table-wrapper {
          margin: 12px 0;
          border-bottom: 1px solid transparent;
          .ivu-table {
            .ivu-table-cell {
              padding: 0;
            }
            tr th,
            tr td {
              height: 36px;
              padding: 0 8px;
            }
            .ivu-table-header {
              color: rgba(0, 0, 0, 0.9);
              text-align: center;
              /* regular/12 */
              font-family: 'PingFang SC';
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 36px; /* 166.667% */
            }
            .ivu-table-tbody {
              color: rgba(0, 0, 0, 0.9);
              /* regular/12 */
              font-family: 'PingFang SC';
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px; /* 166.667% */
            }
          }
        }

        .footer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 28px;
          .footer-left {
            color: rgba(0, 0, 0, 0.6);
            text-align: center;
            /* Body/Small */
            font-family: 'PingFang SC';
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 166.667% */
            .red {
              color: var(--brand-7, #e67d00);
            }
          }
          .footer-page {
            .ivu-page-item {
              min-width: 28px;
              width: 28px;
              height: 28px;
              border-radius: 4px;
              border: 1px solid var(--gray-3, #e7e7e7);
              color: rgba(0, 0, 0, 0.6);
              text-align: center;
              font-family: 'PingFang SC';
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 28px; /* 166.667% */
            }
            .ivu-page-prev,
            .ivu-page-next,
            .ivu-page-item-jump-prev,
            .ivu-page-item-jump-next {
              min-width: 28px;
              width: 28px;
              height: 28px;
              line-height: 28px; /* 166.667% */
            }
          }
        }
      }
      .ivu-modal-footer {
        border: none;
        padding: 0px 16px 14px;
      }
    }
  }
}
