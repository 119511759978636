
:deep(.ivu-modal-header){
  border-bottom: none;
  padding: 20px 16px 0;
}
:deep(.ivu-modal-footer){
  border-top: none;
  .tip { 
    position: absolute;
    left: 16px;
    bottom: 16px;
    color: rgba(0, 0, 0, 0.60);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    .download-icon {
      width: 20px;
      height: 20px;
    }
    .down-load {
      color: var(--hbrand-6, #3853E2);

      &:hover {
        cursor: pointer;
      }
    }

    .export {
      margin-left: 10px;
    }
  }
  .ivu-btn-primary[disabled] {
    background: var(--brand-3, #FDDA9F);
    color: #fff;
    border-color: #fdda9f;
  }

}
.upload-section {
  .fail-content {
    height: 32px;
    background: rgba(255, 236, 232, 1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px;
    border-radius: 4px;
    svg {
      width: 16px;
      height: 16px;
    }
    a {
      color: rgba(56, 83, 226, 1);
    }
    .export {
      color: rgba(203, 39, 45, 1);
    }
  }
  .plan-name-input {
    display: flex;
    align-items: center;
    margin-top: 16px;
    .ivu-input-wrapper {
      margin-left: 16px;
      width: 410px;
    }
  }
  .ivu-upload {
    margin-top: 16px;
    background: rgba(250, 250, 250, 1) !important
  }
  
  .upload-btn {
    padding: 32px;
    height: 160px;
    &:hover {
      cursor: pointer;
    }
    .upload-icon {
      width: 40px;
      height: 40px;
    }
    .upload-text {
      color: var(--font-black-1, rgba(0, 0, 0, 0.60));
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      display: block;
    }
  }

  .upload-list {
    .file-item {
      margin-top: 12px;
      // width: 324px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .file-name {
        color: var(--font-black-2, rgba(0, 0, 0, 0.60));
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
      }

      .del-icon {
        width: 14px;
        height: 14px;
      }

      &:hover {
        background: var(--gray-15, #F5F5F5);
      }
    }
  }
}
