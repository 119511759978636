.default {
  width: 100%;
  text-align: center;
  border: none;
}
.default img {
  width: 216px;
  height: 216px;
  object-fit: contain;
}
.product-list {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}
.product-list > div {
  border: 1px solid #EEE;
  border-radius: 8px;
}
.list {
  position: relative;
}
.list .buy-count {
  position: absolute;
  left: 0;
  top: 180px;
  width: 100%;
  height: 24px;
  text-align: center;
  line-height: 24px;
  background: rgba(238, 238, 238, 0.8);
}
.list .buy-count .text {
  color: #F76560;
}
.collection-price {
  margin-top: 6px;
  font-size: 12px;
  line-height: 20px;
  height: 20px;
  transform-origin: left top;
}
.collection-price .text {
  margin-right: 4px;
}
.collection-price .price {
  color: #349425;
}
.collection-price .price-no-change {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.4);
}
:deep(.product-list) .list-card {
  width: 223px;
}
:deep(.product-list) .cover-max,
:deep(.product-list) .cover-box {
  width: 194px;
  height: 194px;
}
