
.tags-row {
  white-space: nowrap;
  overflow: hidden;
  background: linear-gradient(270deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
  position: relative;
  font-size: 0;
  min-height: 18px;
}
.tag-item {
  display: inline-block;
  font-size: 12px;
  color: #f53f3f;
  border-radius: 4px;
  padding: 0 3.5px;
  border: 0.5px solid #f53f3f;
  margin-right: 4px;
  line-height: 17px;
  //line-height: 1;
  height: 18px;
  box-sizing: border-box;
}
.tag-mask {
  position: absolute;
  height: 19px;
  width: auto;
  right: 0;
  top: 0;
}
.activity-item {
  display: inline-block;
  font-size: 12px;
  color: #f53f3f;
  border-radius: 4px;
  padding: 0 3.5px;
  border: 0.5px solid #f53f3f;
  margin-right: 4px;
  line-height: 17px;
  height: 18px;
  background: rgba(245, 63, 63, 0.05);
}
.nearly-time {
  display: inline-block;
  font-size: 12px;
  color: #f53f3f;
  background: url('~@/assets/images/notic/nearly-time-bg.png') no-repeat;
  background-size: cover;
  width: 60px;
  height: 18px;
  line-height: 18px;
  margin-right: 4px;
  padding-left: 21px;
  //   background-size:auto 18px;
}

.group-buy-tag {
  color: #f53f3f;
  font-size: 12px;
  height: 18px;
  box-sizing: border-box;
  padding-left: 22px;
  position: relative;
  border: 0.5px solid #fb6654;
  border-radius: 4px;
  display: inline-flex;
  padding-right: 3px;
  line-height: 17px;
  overflow: hidden;
  margin-right: 4px;
  .split {
    height: 12px;
    width: 0.5px;
    margin: 0 4px;
    background: #f53f3f;
    display: inline-block;
  }
}
.group-buy-icon {
  height: 18px;
  width: auto;
  position: absolute;
  top: 0;
  left: -1px;
}
.group-buy-inner {
  display: flex;
  align-items: center;
}

.coupon-mark {
  border: 0.5px solid #ff4c77;
  box-sizing: border-box;
  border-radius: 4px;
  color: #e72a57;
  text-align: center;
  background: #ffeef2;
  height: 18px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
  span {
    font-size: 12px;
    line-height: 14px;
  }
}
// 润滑算标签
.time-special {
  padding-left: 21px;
  padding-right: 3px;
  border: 0.5px solid;
  height: 18px;
  border-radius: 4px;
  font-size: 12px;
  line-height: 18px;
  margin-right: 4px;
  display: inline-flex;
  &.green {
    border-color: #4dac39;
    color: #086453;
    background: #f6fbf5 url('~@/assets/images/notic/time-special-green.png') no-repeat left center;
    background-size: auto 18px;
  }
  &.orange {
    border-color: #fc9c57;
    color: #a63d11;
    background: #fefaf5 url('~@/assets/images/notic/time-special-orange.png') no-repeat left center;
    background-size: auto 18px;
  }
}
.fixed-price {
  display: inline-block;
  width: 44px;
  height: 18px;
  margin-right: 4px;
  line-height: 18px;
  vertical-align: top;
}
.label {
  display: inline-block;
  //vertical-align: middle;
  height: 18px;
  padding: 0 4px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 0.5px solid rgba(160, 112, 60, 0.8);
  margin-right: 4px;

  color: #a0703c;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
