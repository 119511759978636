:deep(.vertical-center-modal) {
  display: flex;
  align-items: center;
  justify-content: center;
}
:deep(.vertical-center-modal) .ivu-modal {
  top: 0;
}
:deep(.ivu-modal-header) {
  padding: 24px;
  border: none;
}
:deep(.ivu-modal-header) .ivu-modal-header-inner {
  color: var(--font-black-1, rgba(0, 0, 0, 0.9));
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  height: 26px;
}
:deep(.ivu-modal-close) {
  right: 24px;
  top: 8px;
}
:deep(.ivu-modal-close) .ivu-icon-ios-close {
  font-size: 24px;
}
:deep(.ivu-modal-body) {
  padding: 48px 16px 16px;
}
:deep(.ivu-modal-footer) {
  border: none;
  padding: 24px 32px;
}
:deep(.ivu-modal-footer) .check {
  position: absolute;
  left: 33px;
  bottom: 20px;
  font-size: 16px;
}
:deep(.ivu-table-header) {
  border-radius: 8px 8px 0px 0px;
  border: 1px solid var(--gray-2, #EEE);
  background: var(--gray-1, #FAFAFA);
}
:deep(.ivu-table-header) th {
  border: none;
  background: none;
  padding: 0 8px;
  height: 36px;
  border-right: 1px solid #eee;
}
:deep(.ivu-table-header) th:last-child {
  border-right: none;
}
:deep(.ivu-table-header) .ivu-table-cell {
  padding: 0;
  color: var(--font-black-2, rgba(0, 0, 0, 0.9));
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}
:deep(table) {
  width: 1066px !important;
  border-spacing: 16px 0;
  border-collapse: collapse;
}
:deep(.ivu-table-body) {
  border-radius: 0px 0px 8px 8px;
  border: 1px solid var(--gray-2, #EEE);
  border-top: 0;
  max-height: calc(100vh - 300px);
  overflow-y: scroll;
}
:deep(.ivu-table-tbody) tr {
  border-bottom: 1px solid var(--gray-2, #EEE);
}
:deep(.ivu-table-tbody) tr:last-child {
  border-bottom: none;
}
:deep(.ivu-table-tbody) td {
  border-bottom: none;
  padding: 4px 8px;
  vertical-align: top;
  vertical-align: middle;
  border-right: 1px solid #eee;
}
:deep(.ivu-table-tbody) td:last-child {
  border-right: none;
}
:deep(.ivu-table-tbody) .ivu-table-row-hover td {
  background: rgba(249, 157, 51, 0.2) !important;
}
:deep(.ivu-table-tbody) .ivu-table-cell {
  padding: 0;
  color: var(--font-black-2, rgba(0, 0, 0, 0.9));
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}
:deep(.ivu-table):before {
  display: none;
}
.good-info p {
  width: 205px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
:deep(.front-display-label-box) {
  overflow: hidden;
  white-space: nowrap;
}
:deep(.front-display-label-box) .front-display-label {
  color: #a0703c;
}
:deep(.front-display-label-box) .front-display-label-split {
  margin: 0 4px;
  font-size: 10px;
  color: #a0703c;
  border-right: 1px solid rgba(160, 112, 60, 0.3);
}
:deep(.ivu-tooltip) {
  display: flex;
}
:deep(.ivu-table-tip) {
  height: 238px;
  padding: 12px 16px;
  box-sizing: border-box;
  border-radius: 0px 0px 8px 8px;
  border: 1px solid var(--gray-2, #EEE);
  border-top: none;
}
:deep(.ivu-table-tip) td {
  margin: 0 auto;
  display: block;
  width: 216px !important;
  height: 216px;
  background: url(../../../../assets/center/noData.png) 0 0 no-repeat;
  background-size: 100% 100%;
  border: none;
}
:deep(.ivu-table-tip) td span {
  display: none;
}
.img-box {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img-box .good-img {
  width: 64px;
  height: 64px;
}
.pagination {
  margin-top: 10px;
}
.near-date1 {
  text-align: left;
}
.near-date-empty {
  text-align: left;
}
.expiration-warn {
  color: #E67D00;
}
