
.search-bar {
  background-color: #fff;
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 3;
  // position: sticky;
  // z-index: 3;
  // top: 36px
  &.box-shadow {
    box-shadow: 4px 0px 20px 0px rgba(0, 0, 0, 0.08);
  }
}
  .licence {
    height: 100%;
    // overflow: hidden;
    .info_item {
      display: flex;
      align-items: center;
      padding: 16px 0;
      border-style: solid;
      border-color: #E7E7E7;
      border-width: 0 0 1px 0;
      cursor: pointer;
      &:last-child {
        border-bottom-width: 0;
        padding-bottom: 0;
      }
      &:first-child {
        padding-top: 0;
      }
      .title {
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.9);
      }
      .content {
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.9);
        flex: 1;
      }
      .time {
        font-size: 12px;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.4);
        margin-left: auto;
      }
    }
  }
  .notice-content {
    width: 1200px;
    margin: 16px auto 0;
    background: #FFFFFF;
    padding: 16px 32px;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
  }
  .search-warp {
    width: 1200px;
    margin: 40px auto 0;
    display: flex;
    justify-content: flex-end;
  }
  .search {
    // float: right;
    margin-left: auto;
  }
  .pagination {
    width: 1200px;
    margin: 32px auto 0;
  }
  .breadcrumb {
    background: #eee;
    padding: 4px 0;
    .breadcrumb-warp {
      width: 1200px;
      margin: 0px auto 0;
      display: flex;
      align-items: center;
    }
    .text {
      margin-right: 32px;
      color: rgba(0, 0, 0, 0.6);
      font-size: 14px;
    }
  }
:deep(.ivu-breadcrumb-item-separator) {
    width: 24px;
    height: 24px;
    padding: 6px 8px;
  }
