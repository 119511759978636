
.v-many-text {
  width: 1200px;
  padding: 24px 32px;
  background: #FFFFFF;
  border-radius: 8px;
  .text {
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.6);
    white-space: pre-line;
  }
  .more {
    margin-top: 16px;
    display: flex;
    justify-content: center;
    height: 16px;  
    color: rgba(0,0,0,.6);
    user-select: none;
    cursor: pointer;
  }
}
