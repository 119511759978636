.all-product-class {
  width: 1200px;
  height: 40px;
  margin: 0 auto;
  padding-top: 4px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  display: flex;
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  position: relative;
}
.left {
  width: 274px;
}
.right {
  margin-left: 12px;
  flex: 1;
}
::v-deep .root {
  height: 100% !important;
  margin-bottom: 0px !important;
}
::v-deep .root .tabs-list *:nth-child(2) {
  display: none;
}
::v-deep .content {
  width: auto !important;
  padding-top: 0px !important;
}
::v-deep .content .tabs .tabs-list .selectde-tab {
  font-size: 16px !important;
  color: rgba(0, 0, 0, 0.9) !important;
  font-weight: 500 !important;
  position: relative;
}
::v-deep .content .tabs .tabs-list .selectde-tab::before {
  content: ' ';
  position: absolute;
  bottom: -13px;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  width: 70%;
  height: 4px;
  border-radius: 10px;
  background-color: #f99d33;
}
::v-deep .content .tabs .tabs-item {
  font-weight: 400 !important;
}
.layout-grid-icon {
  margin-right: 8px;
}
.ios-arrow-forward-cion {
  transform: rotate(90deg);
  margin-left: 4px;
}
.modal {
  width: 826px;
  overflow-y: auto;
  background: #ffffff;
  border-radius: 8px;
  position: absolute;
  z-index: 4;
  left: 0;
  top: 56px;
  box-shadow: 5px 5px 10px -4px rgba(0, 0, 0, 0.12), 5px -5px 10px -4px rgba(0, 0, 0, 0.12);
  display: flex;
  min-width: 0;
}
.modal .first {
  width: 150px;
  height: 424px;
  padding: 4px;
  border-radius: 4px;
  border: 1px solid var(#eee);
  background: var(#fff);
  box-shadow: 0px 16px 20px -10px rgba(0, 0, 0, 0.04), 0px 32px 48px 4px rgba(0, 0, 0, 0.02), 0px 12px 60px 10px rgba(0, 0, 0, 0.03);
  overflow-y: auto;
}
.modal .first .first-item {
  padding: 8px;
  box-sizing: border-box;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modal .first .first-item:hover,
.modal .first .first-item.hover {
  background-color: #f5f5f5;
}
.modal .first .first-item:hover .first-name-cion,
.modal .first .first-item.hover .first-name-cion {
  display: block;
}
.modal .first .first-name {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.9);
}
.modal .first .first-name:hover {
  color: #ce7720;
}
.modal .first .first-name-cion {
  display: none;
}
.modal .modal-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.9);
  height: 56px;
  padding: 0 16px;
  display: flex;
  align-items: center;
}
.modal .modal-warp {
  padding: 8px 24px;
  flex: 1;
  height: 424px;
  overflow-y: auto;
}
.modal .two-name {
  width: 82px;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.9);
  cursor: pointer;
}
.modal .two-name:hover {
  color: #ce7720;
}
.modal .two-item {
  padding: 16px 0 10px;
  display: flex;
  border-style: solid;
  border-color: #e7e7e7;
  border-width: 0 0 1px 0;
}
.modal .two-item:last-child {
  border-bottom-width: 0;
}
.modal .three-warp {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}
.modal .three-item {
  margin: 0 16px 6px 16px;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.6);
}
.modal .three-item:hover {
  color: #ce7720;
}
