.car {
  background-color: #f5f5f5;
}
.car .car-content {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}
.car .car-content .car-box {
  width: 1200px;
}
.total-address {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.total-address .total {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: flex-end;
  margin-right: 9px;
}
.total-address .total .total-big {
  font-size: 16px;
  line-height: 24px;
}
.total-address .total .total-num {
  font-size: 18px;
  line-height: 26px;
}
.total-address .address {
  display: flex;
  align-items: center;
}
.total-address .address img {
  width: 13px;
  height: 15px;
  margin-right: 10px;
}
.total-address .address span {
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.9);
}
::v-deep .auth-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}
::v-deep .auth-modal .ivu-modal {
  top: 0;
}
::v-deep .auth-modal .ivu-modal .ivu-modal-content .ivu-modal-body .tip-content {
  text-align: center;
}
::v-deep .auth-modal .ivu-modal .ivu-modal-content .ivu-modal-body .tip-content .title {
  font-size: 16px;
  font-weight: 500;
  color: #000;
}
::v-deep .auth-modal .ivu-modal .ivu-modal-content .ivu-modal-body .tip-content .content {
  font-size: 16px;
  margin-top: 10px;
}
::v-deep .auth-modal .ivu-modal .ivu-modal-content .ivu-modal-footer {
  font-size: 16px;
  cursor: pointer;
  text-align: center;
}
::v-deep .auth-modal .ivu-modal .ivu-modal-content .ivu-modal-footer .left-btn {
  border: none;
}
::v-deep .auth-modal .ivu-modal .ivu-modal-content .ivu-modal-footer .right-btn {
  border: none;
  background: #f99d33;
  color: #FFFFFF;
}
::v-deep .auth-modal .ivu-modal .ivu-modal-content .ivu-modal-footer .single .right-btn {
  width: 260px;
}
