.act_time {
  display: flex;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #1F7C15;
}
.time_num {
  display: flex;
}
.time_num li {
  padding: 0;
  margin: 0;
  list-style: none;
}
.count-time {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.count-time .activity-txt {
  font-weight: 400;
  font-size: 20rpx;
  line-height: 28rpx;
  color: #FA541C;
  margin-right: 8rpx;
}
.count-time .time-section .time {
  display: flex;
  align-items: center;
}
.count-time .time-section .time__custom {
  padding: 5rpx 2rpx 0 2rpx;
  height: 24rpx;
  background: #FFFFFF;
  border: 1rpx solid #FFBB96;
  border-radius: 4rpx;
  display: flex;
  justify-content: center;
  align-items: center;
}
.count-time .time-section .time__custom__item {
  font-weight: 500;
  font-size: 20rpx;
  line-height: 20rpx;
  color: #FA541C;
}
.count-time .time-section .time__doc {
  width: 10rpx;
  height: 36rpx;
  text-align: center;
  font-weight: 400;
  font-size: 20rpx;
  line-height: 36rpx;
  color: #FA541C;
}
.green_statr {
  color: #A35511 !important;
}
