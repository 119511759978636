
.v-title {
  width: 1200px;
  height: 72px;
  padding: 24px 32px;
  background: #FFFFFF;
  border-radius: 8px;
  font-size: 0;
  cursor: pointer;
  // display: flex;
  // align-items: center;
  // position: relative;
  img {
    width: 24px;
    height: 24px;
    margin-right: 12px;
    display: inline-block;
    vertical-align: middle;
    // position: absolute;
    // left: 32px;
  }
  .text {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    display: inline-block;
    vertical-align: middle;
    color: rgba(0, 0, 0, 0.9);    
    padding-top: 3px;
    box-sizing: border-box;
  }
}

