.status_bar {
  height: var(--status-bar-height);
  width: 100%;
  background: #fff;
}
.more-list {
  position: relative;
  min-height: 100%;
  width: 1200px;
  margin: auto;
}
.more-list .content-title {
  display: flex;
  justify-content: space-between;
  margin: 0 auto 12px auto;
}
.more-list .content-title .title {
  font-size: 24px;
  line-height: 32px;
  color: #000000;
  font-weight: 500;
}
.more-list .content-title .content-arrow-icon {
  margin-left: 8px;
  width: 23px;
  height: 19px;
}
.list_item:nth-child(5n) {
  margin-right: 0;
}
.product-list {
  position: relative;
  width: 1200px;
  margin: 0 auto;
  display: flex;
  gap: 12px;
  justify-content: flex-start;
  flex-wrap: wrap;
  min-height: 400px;
}
.loading-outer {
  min-height: calc(100vh - 300px);
}
::v-deep .u-tag__text {
  font-size: 20rpx !important;
}
::v-deep .u-tag {
  height: 30rpx !important;
}
::v-deep .acti_tag .line-clamp-1 {
  max-width: 400rpx;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.lock_record {
  width: 216px;
  height: 238px;
  margin: 70px auto;
}
.lock_record img {
  width: 100%;
  height: 100%;
}
.pagination-more {
  margin: 20px auto;
}
