
:deep(.ivu-date-picker){
  width: 100%;
}
:deep(.time-content){
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  align-items: center;
  gap: 12px;
  flex: 1;

  .check{
    width: 48px;
  }

  .input{
    flex: 1;
  }

  .icon{
    font-size: 20px;
  }
}
