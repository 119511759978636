
@import url('../../product.less');
.v-hot-product-recommend {
  width: 1200px;
}
.card-list {
  display: flex;
  gap: 8px;
}
.card {
  flex: 1;
  padding: 16px 12px 12px 12px;
  border-radius: 8px;
}
.card-bg1 {
  background: url('~@/assets/images/home/hot-product1.png') no-repeat;
  background-size: cover;
}
.card-bg2 {
  background: url('~@/assets/images/home/hot-product2.png') no-repeat;
  background-size: cover;
}
.card-bg3 {
  background: url('~@/assets/images/home/hot-product3.png') no-repeat;
  background-size: cover;
}
.card-wrap {
  height: 337px;
  padding: 0 12px;
  border-radius: 8px 8px 0 0;
  background-color:#fff ;
  overflow: hidden;
  .product-item {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px 0;
    cursor: pointer;
    &:nth-child(1),&:nth-child(2) {
      border-bottom: 0.5px solid #e7e7e7;
    }
    .img {
      width: 88px;
      height: 88px;
    }
    .content {
      display: flex;
      flex-direction: column;
    }
    .activity-price {
      color: #CB272D;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px;
    }

    .activity-unit {
      color: rgba(0, 0, 0, 0.60);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin-right: 2px;
    }

    .sell-price {
      color: rgba(0, 0, 0, 0.60);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      text-decoration: line-through;
    }

    .product-name {
      color: rgba(0, 0, 0, 0.90);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 8px;
    }
    .platform-tag{
      height: 18px;
      width: auto;
      margin-right: 4px;
      vertical-align: middle;
      margin-top: -3px;
    }
  }
}
.no-product {
  img{
    width: 120px;
    height: 136px;
  }
  p{
    margin-top: 6px;
    color: var(--font_black-2, rgba(0, 0, 0, 0.60));
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  height: 337px;
}

.head-wrap1{
  color:#6A4A1B
}
.head-wrap2{
  color:#4A4453
}
.head-wrap3{
  color:#334524
}
.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 12px;
  margin-right: 12px;
  margin-bottom: 4px;
  .title {
    width:80%;
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
  }
  .more {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    cursor: pointer;
  }
}
.second-title {
  display: inline-block;
  border-radius: 0px 12px 12px 12px;
  padding: 0px 8px;
  margin-left: 12px;
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}
.second-bg1 {
  background: #edd2a9;
}
.second-bg2 {
  background: #decaf9;
}
.second-bg3 {
  background: #afe6de;
}

