
@import url('../../product.less');
.v-floor {
  width: 1200px;
}
.eight-product-wrap {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  .product-item{
    border-left:0.5px solid #eee;
    &:nth-child(1),&:nth-child(5){
      border:0;
    }
    &:nth-child(n+4){
      border-top:0.5px solid #eee;
    }
  }
}
.eight-product {
  display: flex;
  border-radius: 8px;
  overflow: hidden;
  .eight-img {
    cursor: pointer;
    width: 288px;
    height: 648px;
  }
}
.six-product-wrap {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  border-radius: 8px;
  overflow: hidden;
}
.six-product {
  position: relative;
  .six-img {
    cursor: pointer;
    position: absolute;
    left: 300px;
    width: 600px;
    //height: 374px !important;
    }
  .product-item{
    border-left:0.5px solid #eee;
    &:nth-child(1),&:nth-child(2){
      border:0;
    }
    &:nth-child(n+3){
      border-top:0.5px solid #eee;
    }
    &:nth-child(3){
      border-left:0;
    }
  }
}

:deep(.six-product) {
  .list-card{
    width: 300px;
    height: 316px;
    padding: 16px;
    border-radius: 0;
    border:0;
    &:nth-child(n+3){
      border-top:0.5px solid #eee;
      border-right:0.5px solid #eee;
    }
    &:last-child{
      border-right: 0;
    }
    .title{
      height: 20px;
      display: flex;
      align-items: center;
      span{
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .cover-box,.cover-mask{
    width: 155px!important;
    height: 155px!important;
  }
  .price-area-box{
    margin-top: 0;
  }
}

:deep(.eight-product-wrap){
  .list-card{
    width: 228px;
    height: 324px;
    border-radius: 0;
    border-left:0.5px solid #e7e7e7;
    &:nth-child(n+5) {
      border-top:0.5px solid #e7e7e7;
    }
  }
  .cover-mask,.cover-box{
    width: 148px;
    height: 148px;
  }
}
