
@import url('../../product.less');
.v-many-product {
  width: 1200px;
  // margin: 0 auto;
}
.row-list {
  display: flex;
  flex-wrap: wrap;
  gap:12px;
  .list_item {
    //margin-right: 15px;
    //margin-top: 24px;
    &:nth-child(-n + 5) {
      margin-top: 0px;
    }
  }
}
.head {
  display: flex;
  align-items: center;
  .icon-title{
    margin-bottom: 16px;
    height: 32px;
  }
  .more {
    margin-left: auto;
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 24px;
    color: #181818;
    cursor: pointer;
    width: 60px;
    margin-bottom: 24px;
  }
}
::v-deep .root {
  margin-top: 0;
}
