
.sign-content {
  width: 1200px;
  margin: auto;
}
.sign-list-content {
      background-color: #fff;
      margin-top: 12px;
      padding: 24px;
      .company-title {
        color: rgba(0, 0, 0, 0.9);
        /* medium/16 */
        font-family: 'PingFang SC';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */
        margin-bottom: 4px;
      }
      .company-total {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .c-t-l {
          display: flex;
          align-items: center;
          span {
            margin-right: 8px;
            color: rgba(0, 0, 0, 0.9);
            /* regular/14 */
            font-family: 'PingFang SC';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
          }
          .red {
            color: var(--error-7, #cb272d);
            /* medium/18 */
            font-family: 'PingFang SC';
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 26px; /* 144.444% */
          }
        }
        .c-t-r {
          color: rgba(0, 0, 0, 0.6);
          /* regular/14 */
          font-family: 'PingFang SC';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px; /* 157.143% */
          span {            
            margin-left: 24px;
          }
        }
      }
      .sign-list-content-bottom {
        margin-top: 24px;
        display: flex;
        .s-l-c-b-l {
          position: relative;
          width: 360px;
          height: 510px;
          border: 1px solid var(--gray-3, #eee);
          margin-right: 24px;
          .full-screen {
            position: absolute;
            right: 16px;
            top: 70px;
            border-radius: 4px;
            background: rgba(0, 0, 0, 0.6);
            width: 82px;
            height: 28px;
            display: flex;
            padding: 0px 8px;
            justify-content: center;
            align-items: center;
            gap: 4px;
            cursor: pointer;
            span {
              color: var(--fontcolor-white-0, #fff);
              font-family: 'PingFang SC';
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 14px; /* 116.667% */
              white-space: nowrap;
            }
            img {
              width: 16px;
              height: 16px;
            }
          }
        }
        .s-l-c-b-r {
          flex: 1;
          .abnormal {
            margin-bottom: 24px;
            .title {
              color: rgba(0, 0, 0, 0.9);
              /* medium/16 */
              font-family: 'PingFang SC';
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px; /* 150% */
              margin-bottom: 4px;
            }
            .tip {
              color: rgba(0, 0, 0, 0.6);
              /* regular/12 */
              font-family: 'PingFang SC';
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px; /* 166.667% */
              margin-bottom: 16px;
            }
            .abnormal-goods {
              margin-top: 16px;
              .abnormal-goods-header {
                display: flex;
                .col {
                  display: flex;
                  height: 40px;
                  padding: 0px 8px;
                  align-items: center;
                  background: var(--gray-1, #fafafa);
                  box-sizing: border-box;
                  color: rgba(0, 0, 0, 0.9);
                  /* regular/12 */
                  font-family: 'PingFang SC';
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px; /* 166.667% */
                  span.red {
                    color: #cb272d;
                  }
                  &.col-1 {
                    width: 268px;
                  }
                  &.col-2 {
                    width: 112px;
                  }
                  &.col-3 {
                    width: 112px;
                  }
                  &.col-4 {
                    flex: 1;
                  }
                  &.col-5 {
                    width: 48px;
                  }
                }
              }
              .abnormal-goods-body {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                border-bottom: 1px solid var(--gray-3, #eee);
                .col {
                  height: 80px;
                  padding: 8px;
                  box-sizing: border-box;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  font-size: 12px;
                  color: rgba(0, 0, 0, 0.9);
                  &.col-1 {
                    width: 268px;
                    div {
                      width: 100%;
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                      &.good-name {
                        color: rgba(0, 0, 0, 0.9);
                        font-family: 'PingFang SC';
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 20px; /* 166.667% */
                      }
                      &.good-company,
                      &.good-code {
                        color: rgba(0, 0, 0, 0.6);
                        /* regular/12 */
                        font-family: 'PingFang SC';
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                      }
                    }
                  }
                  &.col-2 {
                    width: 112px;
                    .abnormal-num {
                      width: 96px;
                    }
                  }
                  &.col-3 {
                    width: 112px;
                    .abnormal-num {
                      width: 76px;                       
                    }
                    .unit {
                      color: rgba(0, 0, 0, 0.9);
                      /* regular/12 */
                      font-family: 'PingFang SC';
                      font-size: 12px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 20px; /* 166.667% */
                      margin-left: 8px;
                    }
                  }
                  &.col-4 {
                    flex: 1;
                    .abnormal-num {
                      width: 100%;
                    }
                  }
                  &.col-5 {
                    width: 48px;
                    .del-btn {
                      color: var(--hblue-6, #3853e2);
                      /* regular/12 */
                      font-family: 'PingFang SC';
                      font-size: 12px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 20px; /* 166.667% */
                      cursor: pointer;
                    }
                  }
                }
                .abnormal-type {
                  width: 112px;
                  .type-desc {
                    -webkit-line-clamp: 3;
                    white-space: normal;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    word-break: break-all;
                    -webkit-box-orient: vertical;
                    cursor: pointer;
                  }
                }
              }
            }
          }
          .certificate {
            .title {
              color: rgba(0, 0, 0, 0.9);
              /* medium/16 */
              font-family: 'PingFang SC';
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px; /* 150% */
              margin-bottom: 4px;
            }
          }
        }
      }
    }
    .content-imgs {
      margin-top: 16px;
      gap: 16px;
      display: flex;
      width: 768px;
      flex-wrap: wrap;
      img {
        width: 120px;
        height: 120px;     
      }
    }
