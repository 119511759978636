
.information {
  .ivu-steps-title {
    color: rgba(0, 0, 0, 0.4) !important;
  }
  .ivu-steps-title {
    margin-top: 3px;
  }
  .ivu-steps-status-process .ivu-steps-title {
    color: #181818 !important;
  }
}
