

.coupon-item-content {
  height: 110px;
  display: flex;
  position: relative;
  z-index: 2;
  width: 100%;

  .info {
    width: calc(100% - 120px);
    padding: 12px 16px;
    border: 1px solid #FFECE8;
    border-right: 0;
    border-radius: 8px 0 0 8px;
    position: relative;

    .coupon-name {
      overflow: hidden;
      color: var(--font_black-1, rgba(0, 0, 0, 0.90));
      text-overflow: ellipsis;

      /* medium/14 */
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      white-space: nowrap;
    }

    .coupon-desc {
      color: var(--font_black-2, rgba(0, 0, 0, 0.60));
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin-top: 2px;
    }

    .coupon-footer {
      display: flex;
      position: absolute;
      left: 12px;
      bottom: 12px;
      align-items: center;

      .coupon-type {
        padding: 0 4px;
        border-radius: 4px;
        background: #FFF4F1;
        color: #F53F3F;
        font-size: 10px;
        line-height: 18px;
        font-style: normal;
        font-weight: 400;
        height: 17px;
        display: flex;
        justify-content: center;
        overflow: hidden;

        &.postage {
          color: #2438BC;
          background: #F1F4FD;
        }

        &.commodity {
          color: #C56000;
          background: #FFF8E8;
        }
      }

      .coupon-time {
        color: rgba(0, 0, 0, 0.60);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin-left: 6px;
      }

      .coupon-trigger {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: .3s ease;

        &.open {
          transform: rotate(180deg);
        }
      }
    }
  }

  .action {
    width: 120px;
    position: relative;
    border-radius: 0 8px 8px 0;
    overflow: hidden;
  }

  .action-bg {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
  }

  .action-bg-disabled {
    display: none;
  }

  .action-bg-selected {
    display: none;
  }

  .action-info {
    width: 100%;
    height: 100%;
    z-index: 4;
    position: relative;
    padding-left: 8px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .action-num {
    color: #F53F3F;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .unit {
      color: #F53F3F;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
    }
  }

  .action-condition {
    color: #F53F3F;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-top: 4px;
  }

  .action-btn {
    width: 72px;
    margin: 4px auto 0;
    border-radius: 20px;
    background: #F53F3F;
    color: #fff;
    line-height: 24px;
    font-size: 12px;
    transition: .3s ease;
    cursor: pointer;

    &:hover {
      background: #F76560;
    }
  }

  .action-mark {
    width: 42px;
    height: 42px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 3;

    img {
      display: block;
      width: 42px;
      height: 42px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
  }
}

.coupon-item.disabled-coupon {
  .coupon-item-content {
    .info {
      border-color: #E7E7E7;
    }

    .action-bg {
      display: none;
    }

    .action-bg-disabled {
      display: block;
    }

    .action-num {
      color: rgba(0, 0, 0, 0.6);

      .unit {
        color: rgba(0, 0, 0, 0.6);
      }
    }

    .action-condition {
      color: rgba(0, 0, 0, 0.6);
    }

    .coupon-type {
      color: rgba(0, 0, 0, 0.6) !important;
      background: #F5F5F5 !important;
    }
  }

  .coupon-detail,.coupon-detail-line {
    border-color: #E7E7E7;
  }
}

.coupon-item {
  position: relative;

  .coupon-detail {
    padding: 12px;
    border-radius: 0 0 8px 8px;
    overflow: hidden;
    line-height: 20px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    position: absolute;
    top: 110px;
    left: 0;
    background: #fff;
    width: 100%;
    border: 0.5px solid #FDCDC5;
    border-top: 0;
    z-index: 3;

    /* shadow/3 */
    box-shadow: 0px 12px 60px 10px rgba(0, 0, 0, 0.03), 0px 32px 48px 4px rgba(0, 0, 0, 0.02), 0px 16px 20px -10px rgba(0, 0, 0, 0.04);

    .coupon-detail-item {
      position: relative;
      padding-left: 10px;

      &:before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        content: '';
        display: block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: #CACACA;
      }
    }
  }
  .coupon-detail-line{
    width: 100%;
    border: 0.5px solid #FDCDC5;
    border-top: 0;
    z-index: 2;
    height: 20px;
    position: absolute;
    bottom: -10px;
    border-bottom: 0;
  }

  &.selected {
    .coupon-item-content {
      .info {
        border: 1px solid #F53F3F;
        border-right: 0;
      }
    }

    .action-bg-selected {
      display: block;
    }
  }

  &.cart{
    margin-top: 10px;
  }
}
.selected-line{
  width: 2px;
  background: #fff;
  height: 103px;
  z-index: 9;
  position: absolute;
  top: 3px;
  left: 0;
}
