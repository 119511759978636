
.tag {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */

  .dot {
    width: 8px;
    height: 8px;
    top: 50%;
    border-radius: 50%;
  }
  &-green {
    color: #034D00;
    background-color: rgba(77, 172, 57, 0.1);
  }
  &-blue {
    color: #2438BC;
    background-color: rgba(56, 83, 226, 0.1);
  }
  &-orange {
    color: #CE7720;
    background: #FFF8E8;
    border: #CE7720 1px solid;
  }
  &-red {
    color: #D4380D;
    background: #FFF2E8;
    border: #FF7A45 1px solid;
  }
}
.review {
  position: relative;
  margin-right: 10px;
  padding-left: 16px;
  font-size: 16px;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    width: 8px;
    height: 8px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
  }
  &-success {
    color: #1F7C15;
    &::before {
      background-color: #4DAC39;
    }
  }
  &-waiting {
    color: #2438BC;
    &::before {
      background-color: #3853E2;
    }
  }
  &-refuse {
    color: #CB272D;
    &::before {
      background-color: #CB272D;
    }
  }
}
