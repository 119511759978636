
.good-content-box{
  ::v-deep span.ivu-radio + * {
    width:414px;
    display:block;
    word-break: break-all !important;
    line-height: auto !important;
    white-space: wrap !important;
  }
  ::v-deep .ivu-radio-group-vertical .ivu-radio-wrapper{
    display:flex;
    height:auto !important;
    line-height:auto !important;
  }
  ::v-deep .ivu-radio-inner {
    margin-top:7px;
  }
  ::v-deep .ivu-poptip-body{
    max-height: 300px;
    overflow-y: scroll;
    &::-webkit-scrollbar{
      display:none;
    }
  }

  .good-content-right {
    width: 735px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
}

::v-deep .ivu-input-number-controls-outside{
  width:112px;
  height:24px;
  padding:0 24px;
  .ivu-input-number-controls-outside-btn{
    width:24px;
    height:24px;
    line-height: 22px;
  }
  .ivu-input-number-input-wrap{
    height:24px;
    line-height: 24px;
    .ivu-input-number-input{
      height:24px;
    }
  }
}
::v-deep .ivu-checkbox-wrapper {
  margin-right: 0;
  .ivu-checkbox {
    margin-right: 16px;
  }
  .ivu-checkbox-checked {
    .ivu-checkbox-inner {
      background-color: #f99d33;
    }
  }
}
.header-all {
  display: flex;
  font-size: 12px;
  line-height: 40px;
  color: rgba(0, 0, 0, 0.6);
  padding: 0 32px 0 16px;
  box-sizing: border-box;
  background-color: #fafafa;
  border-radius: 8px;
  height: 40px;
  ::v-deep .ivu-checkbox-wrapper {
    font-size: 12px;
    line-height: 40px;
  }
  .handle-all-other {
    flex: 1;
    display: flex;
    justify-content: space-between;
    .good-info {
      width: 360px;
      text-align: center;
    }
    .header-all-content {
      width: calc(100% - 360px);
      display: flex;
      justify-content: space-between;
    }
  }
}
.campany {
  margin-top:16px;
  .header {
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: rgba(0, 0, 0, 0.9);
    background-color: #fafafa;
    padding: 0 16px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    position:relative;
    .coupon-modal{
      position:absolute;
      top:42px;
      left:512px;
      width: 432px;
      max-height: 394px;
      overflow-y:scroll;
      padding:24px;
      border-radius: 8px;
      border: 0.5px solid var(--error-6, #F53F3F);
      background: #FFF;
      box-shadow: 0px 16px 20px -10px rgba(0, 0, 0, 0.04), 0px 32px 48px 4px rgba(0, 0, 0, 0.02), 0px 12px 60px 10px rgba(0, 0, 0, 0.03);
      box-sizing:border-box;
      z-index:999;
      &::-webkit-scrollbar {
        display: none;
      }
      .coupon-item{
        margin-top:16px;
        &:first-child{
          margin-top:0;
        }
      }
    }
    .last-coupon-modal{
      top:auto;
      bottom:42px;
    }
    .ivu-checkbox-wrapper {
      font-size: 12px;
      line-height: 20px;
    }
    .left-info{
      display: flex;
      justify-content: space-between;
      align-items: center;
      ::v-deep{
        .ivu-checkbox-wrapper{
          align-items: center;
        }
      }
      ::v-deep {
        .ivu-tooltip {
          display: flex;
          align-items: center;
        }
      }
      .store-name{
        width: 200px;
        cursor:pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: rgba(0,0,0,.6);
        margin-right:32px;
        &:hover{
          color: var(--brand-7, #CE7720);
        }
      }
      .header-coupon-box {
        display: -webkit-inline-box;
        max-width: 320px;
        overflow: hidden;
        .header-coupon-item {
          display: flex;
          padding: 1px 8px;
          align-items: center;
          color: var(--error-7, #CB272D);
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          background-color: #FEECEC;
          margin-right: 4px;
          border-radius: 4px;
          &.display-none {
            display: none;
          }
        }
      }
      .header-coupon-more {
        margin-left: 8px;
        span {
          color: rgba(0, 0, 0, 0.9);
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
        }
        .ivu-icon {
          transform: rotate(90deg);
        }
      }
    }
    .right-info {
      display: flex;
      align-items: center;
      .get-coupon{
        width: 66px;
        height: 22px;
        padding:0 8px;
        box-sizing:border-box;
        background-image: url("~@/assets/images/car/coupon_bg.png");
        background-size:100% 100%;
        margin-right: 32px;
        .wrap{
          display: flex;
          align-items:center;
          height:100%;
          padding: 0 1px 0 5px;
          box-sizing:border-box;
          border-left:0.5px dashed #CB272D;
          border-right:0.5px dashed #CB272D;
        }
        .txt{
          margin-right:2px;
          color: var(--error-7, #CB272D);
          font-size: 12px;
          font-weight: 500;
          line-height: 20px;
        }
        .arrow{
          width:16px;
          height:16px;
        }
      }
      span {
        display: flex;
        align-items: center;
        font-size: 12px;
        line-height: 20px;
      }
      .tips-text {
        margin-right: 32px;
      }
      .open-close {
        color: rgba(0, 0, 0, 0.60);
        &:hover{
          color:#3853E2;
        }
      }
      .cursor {
        cursor: pointer;
      }
      .tips-wrap{
        display: flex;
        align-items: center;
        margin-right: 6px;
        .tips-text{
          margin-right:0;
        }
        .poor-amount{
          margin-left:8px;
          margin-right:8px;
          .money{
            color: var(--error-6, #F53F3F);
          }
        }
      }
    }
  }
  .goods {
    background-color: #fff;
    .item {
      padding: 0px 16px;
      box-sizing: border-box;
      border-bottom: 1px dashed #eeeeee;
      .rule-info {
        display: flex;
        align-items: flex-start;
        padding-left: 38px;
        padding-top: 12px;
        .tag {
          margin-top:4px;
          padding: 1px 4px 0;
          box-sizing: border-box;
          border: 0.5px solid var(--error-6, #F53F3F);
          color: #F53F3F;
          font-weight: 500;
          background:#FEF5F5;
          border-radius: 4px;
          font-size: 8.5px;
          line-height: 16px;
          margin-right: 8px;
        }
        .text {
          max-width: 1020px;
          font-size: 14px;
          line-height: 26px;
          color: rgba(0, 0, 0, 0.9);
          margin-right: 8px;
        }
        .collect-money {
          margin-top:2px;
          font-size: 14px;
          line-height: 22px;
          color: #d4380d;
          cursor: pointer;
        }
      }
      .good-content,
      .replacement {
        .good-content-detail {
          display: flex;
          align-items: center;
          padding: 21px 0 12px 0px;
          .good-content-box {
            flex: 1;
            display: flex;
            justify-content: space-between;
            padding-right: 16px;
            align-items: flex-start;
            .good-img-text {
              width: 384px;
              display: flex;
              justify-content: flex-start;
              .good-img-box {
                width: 80px;
                height: 80px;
                box-sizing: border-box;
                border: 1px solid rgba(238, 238, 238, 1);
                border-radius: 4px;
                position: relative;
                .img-box {
                  width: 100%;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  cursor: pointer;
                }
                .exchange-tip {
                  position: absolute;
                  top: 4px;
                  left: 4px;
                  padding: 0 4px;
                  box-sizing: border-box;
                  color: var(--purple-6, #722ED1);
                  text-align: center;
                  font-size: 8.5px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 16px;
                  background: #F8F5FD;
                  border: 0.5px solid #722ED1;
                  border-radius: 4.57px;
                }
                .nearterm-tip{
                  position: absolute;
                  top: 4px;
                  left: 4px;
                  padding: 1px 6px 0;
                  box-sizing: border-box;
                  font-weight: 500;
                  font-size: 8.5px;
                  line-height: 16px;
                  color: #F53F3F;
                  background: #FEF5F5;
                  border: 0.5px solid #F53F3F;
                  border-radius: 4px;
                }
                img {
                  width: 64px;
                  height: 64px;
                }
                .img-box{
                  position: relative;
                  cursor: pointer;
                  .prescription-img{
                    position: absolute;
                    left:0;
                    top:0;
                    width: 64px;
                    height: 64px;
                  }
                }
              }
              .text-info {
                margin-left:16px;
                width: 280px;
                display: flex;
                flex-direction: column;
                ::v-deep {
                  .ivu-tooltip{

                  }
                  .ivu-tooltip-rel {
                    font-weight: 500;
                    font-size: 14px;
                    color: rgba(0, 0, 0, 0.9);
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    display: block;
                  }
                }
                .text-productName-wrap{
                    width: 100%;
                  }
                .date {
                  font-size: 12px;
                  line-height: 20px;
                  color: rgba(0, 0, 0, 0.6);
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  &.active {
                    color: rgba(230, 125, 0, 1);
                  }
                  .active{
                    color: rgba(230, 125, 0, 1);
                  }
                  .alert-circle {
                    width: 16px;
                    height: 16px;
                    margin-top: -2px;
                  }
                }
                .good-name{
                  font-size: 14px;
                  line-height: 22px;
                  cursor:pointer;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 2;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: normal;
                  color: rgba(0, 0, 0, 0.9);
                  font-weight: 500;
                  &:hover{
                    color:#CE7720;
                  }
                }
              }
            }
            .exchange-good-text{
              width: 336px;
              .good-img-box{
                width:74px;
                height:74px;
                padding:8px;
                box-sizing: border-box;
                border-radius: 3.2px;
                border: 1px solid var(--gray-2, #EEE);
                background: var(--font-white-0, #FFF);
                cursor: pointer;
                img {
                  width: 56px;
                  height: 56px;
                }
                .img-box{
                  position: relative;
                  cursor: pointer;
                  .prescription-img{
                    position: absolute;
                    left:0;
                    top:0;
                    width: 56px;
                    height: 56px;
                  }
                }
              }
            }
            .goods-pack {
              width: 100px;
              font-size: 12px;
              line-height: 20px;
              color: rgba(0, 0, 0, 0.9);
              text-align: center;
              display: flex;
              flex-direction: column;
            }
            .price {
              width: 200px;
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              justify-content: center;
              .activty-img {
                width: 100%;
                text-align: right;
                img {
                  width: 38px;
                  height: 21px;
                }
              }
              .show-price {
                font-size: 12px;
                line-height: 20px;
                color: rgba(0, 0, 0, 0.9);
              }
              .lineation-price {
                font-size: 12px;
                line-height: 20px;
                text-decoration-line: line-through;
                color: rgba(0, 0, 0, 0.4);
                margin-top: 4px;
              }
              .estimate-price {
                font-size: 14px;
                line-height: 22px;
                font-weight: 500;
                color: #CB272D;
                margin-top: 4px;
                .received-price-label {
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px;
                }
              }
              ::v-deep .ivu-poptip {
                .ivu-poptip-rel {
                  cursor: pointer;
                  font-size: 12px;
                  line-height: 20px;
                  color: rgba(0, 0, 0, 0.6);
                  &:hover{
                    color:#3853E2;
                  }
                }
              }
              .seat{
                height:24px;
              }
            }
            .stepper-box {
              width: 112px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              .input-wrap{
                position: relative;
              }
              ::v-deep .ivu-input-number {
                .ivu-input-number-input-wrap > .ivu-input-number-input {
                  padding:0;
                  font-size: 12px;
                  line-height: 20px;
                  text-align: center;
                }
              }
              span {
                display: inline-block;
                height: 20px;
                font-size: 12px;
                line-height: 20px;
                color: rgba(0, 0, 0, 0.9);
              }
              .num {
                color: var(--error-7, #CB272D);
                font-size: 12px;
                line-height: 20px;
                margin-top: 4px;
              }
            }
            .amount {
              width: 60px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-end;
              span {
                display: inline-block;
                height: 24px;
                font-size: 14px;
                line-height: 22px;
                font-weight: 500;
                color: rgba(0, 0, 0, 0.9);
              }
            }
            .handle {
              width: 80px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-end;
              ::v-deep .ivu-poptip {
                .ivu-poptip-rel {
                  font-size: 12px;
                  line-height: 20px;
                  color: rgba(0, 0, 0, 0.9);
                  cursor: pointer;
                  &:hover {
                    color: #3853E2;
                  }
                }
                .ivu-poptip-popper
                  > .ivu-poptip-content
                  > .ivu-poptip-inner
                  > .ivu-poptip-body
                  > .ivu-poptip-body-content {
                  font-size: 14px;
                  line-height: 22px;
                  color: rgba(0, 0, 0, 0.9);
                  .del-good {
                    color: rgba(0, 0, 0, 0.60);
                    cursor: pointer;
                    &:hover{
                      color: #3853e2;
                    }
                  }
                }
              }
              span {
                display: inline-block;
                height: 24px;
                margin-top: 4px;
                &:first-child {
                  cursor: pointer;
                  :hover {
                    color: #d4380d;
                  }
                }
              }
            }
          }
          .gift-content-box{
            padding-left:64px;
            justify-content: flex-start;
            .good-img-text{
              justify-content: flex-start;
              align-items: flex-start;
              .text-info{
                margin-left:16px;
              }
            }
            .stepper-box{
              margin-left: 180px;
            }
          }
        }
        .about-end {
          // padding-bottom: 16px;
          // padding-left: 40px;
          .act_time{
            ::v-deep .time_num li{
              border:none;
              height:auto;
            }
          }
        }
      }
      .replacement {
        padding-left: 32px;
        padding-bottom: 14px;
        .good-content-detail {
          background-color: rgba(241, 245, 254, 1);
          padding-left: 16px;
          .good-content-box > .price {
            span {
              margin-top: 4px;
              &:first-child {
                margin-top: 0px;
              }
            }
          }
        }
      }
      .good-content-make {
        width: 100%;
        display: flex;
        align-items: center;
        margin: 12px 0px;
        .good-make-box {
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding: 12px 16px 12px 16px;
          box-sizing: border-box;
          background-color: #f1f5fe;
          border-radius: 4px;
          .goods-make {
            width: 366px;
            display: flex;
            .goods-item {
              display: flex;
              flex-direction: column;
              margin-right: 8px;
              .new-price {
                font-size: 14px;
                line-height: 22px;
                color: rgba(0, 0, 0, 0.9);
                margin-top: 4px;
                &.active {
                  color: #cb272d;
                }
              }
              .old-price {
                font-size: 12px;
                line-height: 24px;
                color: rgba(0, 0, 0, 0.4);
                text-decoration-line: line-through;
              }
              .actual-price {
                font-size: 14px;
                font-weight: 500;
                line-height: 22px;
                color: #cb272d;
                .actual-price-label {
                  color: #cb272d;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px;
                }
              }
              .goods-item-img {
                width: 82px;
                height: 82px;
                padding:8px;
                box-sizing: border-box;
                border: 1px solid var(--gray-2, #EEE);
                position: relative;
                display: flex;
                align-items: center;
                cursor: pointer;
                img {
                  width: 64px;
                  height: 64px;
                }
                .goods-img-box{
                  position:relative;
                  .prescription-img{
                    position: absolute;
                    left:0;
                    top:0;
                    width: 64px;
                    height: 64px;
                  }
                }
                .img-box{
                  width: 20px;
                  height: 20px;
                  display:flex;
                  align-items: center;
                  justify-content: center;
                  cursor: pointer;
                  &:hover{
                    background:#E7E7E7;
                    border-radius: 4px;
                  }
                }
                .more-make {
                  width: 12px;
                  height: 12px;
                }
                .num {
                  position: absolute;
                  bottom: 1px;
                  right: 1px;
                  padding: 0px 4px;
                  background-color: rgba(0, 0, 0, 0.6);
                  border-radius: 4px;
                  font-size: 10px;
                  line-height: 18px;
                  color: #fff;
                }
              }
            }
            .more-img{
              .goods-item-img{
                border:none;
              }
            }
          }
          .price-make {
            width: 128px;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: center;
            span {
              display: inline-block;
              height: 24px;
              font-size: 16px;
              line-height: 24px;
              color: rgba(0, 0, 0, 0.9);
              margin-top: 4px;
            }
          }
          .step-box-make {
            width: 112px;
            padding-top: 4px;
            .input-wrap{
              position: relative;
            }
            ::v-deep .ivu-input-number {
              .ivu-input-number-input-wrap > .ivu-input-number-input {
                font-size: 12px;
                line-height: 24px;
                text-align: center;
              }
            }
            span {
              display: inline-block;
              height: 24px;
              font-size: 16px;
              line-height: 24px;
              color: rgba(0, 0, 0, 0.9);
              margin-top: 4px;
            }
          }
          .amount-make {
            width: 60px;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            .new-price {
              font-size: 14px;
              font-weight: 500;
              line-height: 22px;
              color: rgba(0, 0, 0, 0.9);
            }
            .old-price {
              font-size: 12px;
              line-height: 20px;
              color: rgba(0, 0, 0, 0.6);
              text-decoration-line: line-through;
              margin-top: 4px;
            }
            span {
              display: inline-block;
              height: 20px;
              font-size: 12px;
              line-height: 20px;
              color: rgba(0, 0, 0, 0.9);
            }
          }
          .handle-make {
            width: 80px;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: flex-start;
            ::v-deep .ivu-poptip {
              .ivu-poptip-rel {
                font-size: 12px;
                line-height: 20px;
                color: rgba(0, 0, 0, 0.9);
                cursor: pointer;
                &:hover {
                  color: #d4380d;
                }
              }
              .ivu-poptip-popper
                > .ivu-poptip-content
                > .ivu-poptip-inner
                > .ivu-poptip-body
                > .ivu-poptip-body-content {
                font-size: 14px;
                line-height: 22px;
                color: rgba(0, 0, 0, 0.9);
                .del-good {
                  color: #3853e2;
                  cursor: pointer;
                }
              }
            }
            span {
              display: inline-block;
              height: 24px;
              font-size: 16px;
              line-height: 24px;
              color: rgba(0, 0, 0, 0.9);
              margin-top: 4px;
            }
          }
        }
      }
      .good-content-gift {
        display: flex;
        flex-wrap: wrap;
        background-color: rgba(248, 250, 255, 1);
        .good-content-container {
          width: 100%;
          background-color: #ffffff;
        }
        
        .good-content-detail {
          display: flex;
          align-items: center;
          padding: 21px 0 12px 0px;
          .good-content-box {
            flex: 1;
            display: flex;
            justify-content: space-between;
            padding-right: 16px;
            align-items: flex-start;
            .good-img-text {
              width: 384px;
              display: flex;
              justify-content: flex-start;
              .good-img-box {
                width: 80px;
                height: 80px;
                box-sizing: border-box;
                border: 1px solid rgba(238, 238, 238, 1);
                border-radius: 4px;
                position: relative;
                background-color: #fff;
                .img-box {
                  width: 100%;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  cursor: pointer;
                }
                .exchange-tip {
                  position: absolute;
                  top: 2px;
                  left: 2px;
                  padding: 0 4px;
                  box-sizing: border-box;
                  color: var(--purple-6, #722ED1);
                  text-align: center;
                  font-size: 8.5px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 16px;
                  background: #F8F5FD;
                  border: 0.5px solid #722ED1;
                  border-radius: 4px;
                }
                .nearterm-tip{
                  position: absolute;
                  top: 4px;
                  left: 4px;
                  padding: 1px 6px 0;
                  box-sizing: border-box;
                  font-weight: 500;
                  font-size: 8.5px;
                  line-height: 16px;
                  color: #F53F3F;
                  background: #FEF5F5;
                  border: 0.5px solid #F53F3F;
                  border-radius: 4px;
                }
                img {
                  width: 64px;
                  height: 64px;
                }
                .img-box{
                  cursor: pointer;
                  position: relative;
                  .prescription-img{
                    position: absolute;
                    left:0;
                    top:0;
                    width: 64px;
                    height: 64px;
                  }
                }
              }
              .text-info {
                margin-left:16px;
                width: 280px;
                display: flex;
                flex-direction: column;
                ::v-deep {
                  .ivu-tooltip{

                  }
                  .ivu-tooltip-rel {
                    font-weight: 500;
                    font-size: 14px;
                    color: rgba(0, 0, 0, 0.9);
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    display: block;
                  }
                }
                .text-productName-wrap{
                    width: 100%;
                  }
                .date {
                  font-size: 12px;
                  line-height: 20px;
                  color: rgba(0, 0, 0, 0.6);
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  &.active {
                    color: rgba(230, 125, 0, 1);
                  }
                  .active{
                    color: rgba(230, 125, 0, 1);
                  }
                  .alert-circle {
                    width: 16px;
                    height: 16px;
                    margin-top: -2px;
                  }
                }
                .good-name{
                  font-size: 14px;
                  line-height: 22px;
                  cursor:pointer;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 2;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: normal;
                  color: rgba(0, 0, 0, 0.9);
                  font-weight: 500;
                  &:hover{
                    color:#CE7720;
                  }
                }
              }
            }
            .exchange-good-text{
              width: 336px;
              .good-img-box{
                width:74px;
                height:74px;
                padding:8px;
                box-sizing: border-box;
                border-radius: 3.2px;
                border: 1px solid var(--gray-2, #EEE);
                background: var(--font-white-0, #FFF);
                cursor: pointer;
                img {
                  width: 56px;
                  height: 56px;
                }
                .img-box{
                  cursor: pointer;
                  position: relative;
                  .prescription-img{
                    position: absolute;
                    left:0;
                    top:0;
                    width: 56px;
                    height: 56px;
                  }
                }
              }
            }
            .goods-pack {
              width: 100px;
              font-size: 12px;
              line-height: 20px;
              color: rgba(0, 0, 0, 0.9);
              text-align: center;
              display: flex;
              flex-direction: column;
            }
            .price {
              width: 200px;
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              justify-content: center;
              .activty-img {
                width: 100%;
                text-align: right;
                img {
                  width: 38px;
                  height: 21px;
                }
              }
              .show-price {
                font-size: 12px;
                line-height: 20px;
                color: rgba(0, 0, 0, 0.9);
              }
              .lineation-price {
                font-size: 12px;
                line-height: 20px;
                text-decoration-line: line-through;
                color: rgba(0, 0, 0, 0.4);
                margin-top: 4px;
              }
              .estimate-price {
                font-size: 14px;
                line-height: 22px;
                font-weight: 500;
                color: #CB272D;
                margin-top: 4px;
                .received-price-label {
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px;
                }
              }
              ::v-deep .ivu-poptip {
                .ivu-poptip-rel {
                  cursor: pointer;
                  font-size: 12px;
                  line-height: 20px;
                  color: rgba(0, 0, 0, 0.6);
                  &:hover{
                    color:#3853E2;
                  }
                }
              }
              .seat{
                height:24px;
              }
            }
            .stepper-box {
              width: 112px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              .input-wrap{
                position: relative;
              }
              ::v-deep .ivu-input-number {
                .ivu-input-number-input-wrap > .ivu-input-number-input {
                  padding:0;
                  font-size: 12px;
                  line-height: 20px;
                  text-align: center;
                }
              }
              span {
                display: inline-block;
                height: 20px;
                font-size: 12px;
                line-height: 20px;
                color: rgba(0, 0, 0, 0.9);
              }
              .num {
                color: var(--error-7, #CB272D);
                font-size: 12px;
                line-height: 20px;
                margin-top: 4px;
              }
            }
            .amount {
              width: 60px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-end;
              span {
                display: inline-block;
                height: 24px;
                font-size: 14px;
                line-height: 22px;
                font-weight: 500;
                color: rgba(0, 0, 0, 0.9);
              }
            }
            .handle {
              width: 80px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-end;
              ::v-deep .ivu-poptip {
                .ivu-poptip-rel {
                  font-size: 12px;
                  line-height: 20px;
                  color: rgba(0, 0, 0, 0.9);
                  cursor: pointer;
                  &:hover {
                    color: #3853E2;
                  }
                }
                .ivu-poptip-popper
                  > .ivu-poptip-content
                  > .ivu-poptip-inner
                  > .ivu-poptip-body
                  > .ivu-poptip-body-content {
                  font-size: 14px;
                  line-height: 22px;
                  color: rgba(0, 0, 0, 0.9);
                  .del-good {
                    color: rgba(0, 0, 0, 0.60);
                    cursor: pointer;
                    &:hover{
                      color: #3853e2;
                    }
                  }
                }
              }
              span {
                display: inline-block;
                height: 24px;
                margin-top: 4px;
                &:first-child {
                  cursor: pointer;
                  :hover {
                    color: #d4380d;
                  }
                }
              }
            }
          }
          .gift-content-box{
            padding-left: 8px;
            padding-right: 0;
            justify-content: flex-start;
            
            .good-img-text{
              justify-content: flex-start;
              align-items: flex-start;
              .text-info{
                margin-left:16px;
              }
            }
            .stepper-box{
              margin-left: 180px;
            }
            .gift-good-text {
              width: 80px;
              flex-wrap: wrap;
            }
          }
        }
        .container-gift {
          margin-left: 0;
          &:first-child {
            margin-left: 40px;
          }
          .good-content-detail {
            padding: 12px 0;
          }
          .gift-good-num {
            width: 18px;
            height: 18px;
            padding: 0px 4px;
            border-radius: 4px;
            background-color: rgba(0, 0, 0, 0.6);
            font-size: 10px;
            font-weight: 400;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ffffff;
            position: absolute;
            right: 0;
            bottom: 0;
          }

          .gift-good-name {
            height: 22px;
            margin-top: 4px;
            overflow: hidden;
          }
        }
      }
    }
  }
  .subtotal {
    width: 100%;
    background-color: #fff;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 12px 16px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    .sub-num {
      font-size: 14px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.6);
      margin-right: 24px;
      span {
        color: #ce7720;
        margin: 0 4px;
      }
    }
    .good-amount {
      font-size: 14px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.6);
      margin-right: 24px;
      span {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.9);
        margin-left: 8px;
      }
    }
    .sub-amount {
      font-size: 14px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.9);
      span {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #cb272d;
        margin-left: 8px;
      }
    }
  }
}
.lose-good {
  margin-top:16px;
  width: 100%;
  .header {
    height:auto !important;
    padding: 8px 28px 8px 50px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fafafa;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    font-size: 14px;
    .num {
      font-size: 14px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.9);
    }
    .clear {
      font-size: 12px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.60);
      cursor: pointer;
      &:hover{
        color:#3853E2;
      }
    }
  }
  .goods {
    padding: 12px 32px 12px 50px;
    box-sizing: border-box;
    background-color: #fff;
    border-bottom: 1px dashed #eeeeee;
    &:last-child {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
    .good-content-box {
      flex: 1;
      display: flex;
      justify-content: space-between;
      .good-img-text {
        width: 396px;
        display: flex;
        justify-content: space-between;
        .good-img-box {
          width: 82px;
          height: 82px;
          padding:8px;
          box-sizing: border-box;
          border: 1px solid var(--gray-2, #EEE);
          border-radius: 4px;
          position: relative;
          cursor: pointer;
          .lose-cause {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 62px;
            height: 62px;
            box-sizing: border-box;
            background-color: rgba(0, 0, 0, 0.4);
            color: #fff;
            font-size: 14px;
            border-radius: 50%;
            padding: 0 10px;
          }
          img {
            width: 64px;
            height: 64px;
          }
          .img-box{
            position: relative;
            cursor: pointer;
            opacity: 0.5;
            .prescription-img{
              position: absolute;
              left:0;
              top:0;
              width: 64px;
              height: 64px;
            }
          }
        }
        .text-info {
          width: 280px;
          display: flex;
          flex-direction: column;
          ::v-deep .ivu-tooltip {
            width: 100%;
            height: 22px;
            .ivu-tooltip-rel {
              font-weight: 400;
              font-size: 14px;
              line-height: 22px;
              color: rgba(0, 0, 0, 0.4);
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              display: block;
            }
          }
          .date {
            font-size: 12px;
            line-height: 20px;
            color: rgba(0, 0, 0, 0.4);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .lose-state {
            font-size: 12px;
            line-height: 20px;
            color: #CB272D;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
      .handle {
        width: 80px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        margin-top: 10px;
        ::v-deep .ivu-poptip {
          .ivu-poptip-rel {
            font-size: 12px;
            line-height: 20px;
            color: rgba(0, 0, 0, 0.9);
            cursor: pointer;
            &:hover {
              color: #d4380d;
            }
          }
          .ivu-poptip-popper
            > .ivu-poptip-content
            > .ivu-poptip-inner
            > .ivu-poptip-body
            > .ivu-poptip-body-content {
            font-size: 14px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.9);
            .del-good {
              cursor: pointer;
              color: rgba(0, 0, 0, 0.60);
              &:hover{
                color:#3853E2;
              }
            }
          }
        }
        span {
          display: inline-block;
          height: 24px;
          margin-top: 4px;
          &:first-child {
            cursor: pointer;
            :hover {
              color: #d4380d;
            }
          }
        }
      }
    }
  }
}
.good-tag {
  border: 1px solid red;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  position: relative;
}
.tag-item {
  display: inline-block;
  font-size: 12px;
  color: #F53F3F;
  border-radius: 4px;
  padding: 0 3.5px;
  border: 0.5px solid #F53F3F;
  margin-right: 4px;
  line-height: 18px;
  height: 18px;
  box-sizing: border-box;
}
::v-deep .ivu-poptip {
  .ivu-poptip-body {
    width: 406px;
    padding: 16px;
    max-height: 392px;
    overflow-y: scroll;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
.cart-coupon-title {
  height: 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.4);
  img {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
}
.cart-coupon-item {
  width: 374px;
  margin: 0 auto;
  padding: 12px 0;
  .coupon-item-left {
    .coupon-item-type {
      width: 38px;
      height: 17px;
      padding: 2px 4px;
      border-radius: 4px;
      background-color: #FFF4F1;
      margin-right: 4px;
      margin-top: 2px;
      span {
        font-size: 10px !important;
        font-weight: 400 !important;
        color: #F53F3F !important;
      }
    }
    .coupon-item-content{
      font-size: 12px;
      font-weight: 400;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.9);
      .coupon-item-money{
        position: relative;
        margin-left: 0;
        padding-left: 0;
        &.red {
          color: rgba(245, 63, 63, 1);
        }
      }
      &.active {
        display: flex;
        .coupon-item-money {
          margin-left: 4px;
          padding-left: 5px;
          &::before {
            content: "";
            width: 1px;
            height: 12px;
            background-color: rgba(238, 238, 238, 1);
            position: absolute;
            top: 5px;
            left: 0;
          }
          &.red {
            color: rgba(245, 63, 63, 1);
            &::before {
              content: "";
              width: 1px;
              height: 12px;
              background-color: rgba(253, 205, 197, 1);
              position: absolute;
              top: 5px;
              left: 0;
            }
          }
        }
      }
    }
  }
  .coupon-item-right {
    display: flex;
    width: 52px;
    height: 22px;
    padding: 0 8px;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    font-size: 12px;
    font-weight: 400;
    background-color: #F53F3F;
    color: #ffffff;
  }
  .cart-coupon-swiper {
    width: 100%;
    height: 56px;
    margin-top: 12px;
  }
}
.label-box {
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  &::after {
    content: "";
    display: block;
    width: 17px;
    height: 18px;
    position: absolute;
    top: 2px;
    right: 0;
    background: linear-gradient(270deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
  }
  .activity-time {
    display: inline-block;;
  }
  .label-item {
    display: inline-block;
    //vertical-align: middle;
    height: 18px;
    padding: 0 4px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: 0.5px solid rgba(160, 112, 60, 0.80);
    margin-right: 4px;

    color: #A0703C;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
}

.exchange-good-text {
  .label-box {
    &::after {
      content: "";
      display: none;
    }
  }
}
:deep(.get-coupon){
  cursor: pointer;
  .coupon-item{
    margin-top: 10px;
    margin-right: 0;
    &:first-child{
      margin-top: 0;
    }
  }
  .ivu-poptip-body{
    max-height: 502px;
    overflow-y: auto;
  }
  .ivu-poptip-body-content{
    &::-webkit-scrollbar-track {
      background-color: #fff;
      border-radius: 8px;
    }

    /* 为滚动条滑块设置样式 */
    &::-webkit-scrollbar-thumb {
      background-color:#E7E7E7;
      border-radius: 8px;
    }
  }
  .coupon-wrap{
    max-height: 470px;
    overflow-y: auto;
    width: 100%;

    /* 或者设置滚动条的样式 */
    &::-webkit-scrollbar {
      display: none;
    }

  }
}

.swiper-slide-cart {
  border: 1px solid #eeeeee;
  border-radius: 4px;
  width: 56px;
  height: 56px;
  position: relative;
  .swiper-slide-img {
    width: 45px;
    height: 45px;
  }
  .swiper-slide-select {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 0;
    right: 0;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .swiper-slide-num {
    min-width: 24px;
    height: 18px;
    padding: 0px 4px;
    border-radius: 4px;
    font-size: 10px;
    font-weight: 400;
    text-align: left;
    color: #ffffff;
    position: absolute;
    bottom: 2px;
    left: 2px;
    background-color: rgba(0, 0, 0, 0.6);
    span {
      color: #ffffff !important;
    }
  }
}

.banner-button-prev, .banner-button-next {
  position: absolute;
  top: 50%;
  width: 20px;
  height: 20px;
  z-index: 10;
  cursor: pointer;
  background-size: 27px 44px;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 50%;
  transform: translateY(-50%);
  &.swiper-button-disabled {
    display: none;
  }
}
.banner-button-prev {
  left: 0px;
  right: auto;
  padding-right: 2px;
}
.banner-button-next {
  right: 0px;
  left: auto;
  padding-left: 2px;
}

