.v-lift-wrapper {
  width: 58px;
  position: sticky;
  right: 20px;
  top: 44px;
  float: right;
  z-index: 1;
  overflow: hidden;
}
.v-lift-wrapper .coupon {
  width: 86px;
  height: 86px;
  position: relative;
  right: 12px;
}
.v-lift-nav {
  background: #FFFFFF;
  border-radius: 8px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 8;
}
.v-lift-nav > a {
  padding: 12px 17px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  border-bottom: 1px solid #EEEEEE;
  color: rgba(0, 0, 0, 0.9);
}
.v-lift-nav > a:hover {
  color: #3853E2;
}
.v-lift-nav .svg-icon {
  width: 8px;
  height: 8px;
}
.v-lift-nav .top {
  padding: 12px 0;
  cursor: pointer;
}
.v-lift-nav .top-text {
  margin: 8px  0 0 0;
  color: #CE7720;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}
.coupon-icon {
  width: 100%;
  cursor: pointer;
}
