
.w-bg-white {
    background-color: #FFF;
}
.w-bg-error-1 {
    background-color: #FFECE8;;
}
.w-bg-error-7 {
    background-color: #CB272D;;
}
.w-bg-gray-1 {
    background-color: #FAFAFA;
}
.w-bg-gray-2 {
    background-color: #EEEEEE;
}
.w-border-radius-4 {
    border-radius: 4px;
}
.w-border-radius-8 {
    border-radius: 8px;
}
.w-border-base {
    border: 1px solid #EEEEEE;
}
.w-border-right-base {
    border-right: 1px solid #EEEEEE;
}
.w-border-bottom-base {
    border-bottom: 1px solid #EEEEEE;
}
.w-border-gray-3{
    border: 1px solid #E7E7E7;
}
.w-font-color-white {
    color: #fff;
}
.w-font-color-black-1 {
    color: rgba(0,0,0,0.9);
}
.w-font-color-black-2 {
    color: rgba(0,0,0,0.6);
}
.w-font-color-black-3 {
    color: rgba(0,0,0,0.4);
}
.w-font-color-brand-7 {
    color: #CE7720;
}
.w-font-color-success-7 {
    color: #349425;
}
.w-font-color-error-6 {
    color: #F53F3F;
}
.w-font-color-error-7 {
    color: rgba(203, 39, 45, 1);
}
.w-font-color-error-8 {
    color: rgba(161, 21, 30, 1);
}
.w-font-size-8 {
    font-size: 12px;
    transform: scale(0.667);
    text-indent: -8px;
}
.w-font-size-10 {
    font-size: 12px;
    transform: scale(0.833);
    text-indent: -12px;
}
.w-font-size-12 {
    font-size: 12px;
    height: 20px;
    line-height: 20px;
}

.w-font-size-14 {
    font-size: 14px;
}
.w-font-size-16{
    font-size: 16px;
}
.w-font-size-18 {
    font-size: 18px;
}
.w-font-size-32 {
    font-size: 32px;
}
.w-font-weight-400 {
    font-weight: 400;
}
.w-font-weight-500 {
    font-weight: 500;
}
.w-padding-24 {
    padding: 24px;
}
.w-padding-16 {
    padding: 16px;
}
.w-pb-12{
    padding-bottom: 12px;
}
.w-padding-12 {
    padding: 12px;
}
.w-padding-x-24 {
    padding-left: 24px;
    padding-right: 24px;
}
.w-padding-y-24 {
    padding-top: 24px;
    padding-bottom: 24px;
}
.w-padding-32 {
    padding: 32px;
}
.w-text-align-center {
    text-align: center;
}

.w-flex {
    display: flex;
}
.w-flex-column {
    flex-direction: column;
}
.w-flex-justify-between {
    justify-content: space-between;
}
.w-flex-justify-end {
    justify-content: flex-end;
}
.w-flex-justify-center {
    justify-content: center;
}
.w-flex-align-center {
    align-items: center;
}
.w-flex-align-end {
    align-items: flex-end;
}

.w-text-button-primary {
    font-size: 14px;
    color: rgba(56, 83, 226, 1);
    cursor: pointer;
}
.w-text-button-info {
    font-size: 14px;
    color:rgba(0, 0, 0, 0.9);
    cursor: pointer;
}
.w-text-button-info:hover {
    color: rgba(56, 83, 226, 1);
}
.text-12{
    font-size: 12px;
}
.text-14{
    font-size: 14px;
}
.pb-8{
    padding-bottom: 8px;
}
.mb-12{
    margin-bottom: 12px;
}
.line-clamp-1 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
  .line-clamp-2 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  .line-clamp-3 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }
.ivu-tabs{
    color:rgba(0,0,0,0.6);
}
.ivu-tabs-nav .ivu-tabs-tab-active{
    color:rgba(0,0,0,0.9);
    font-weight: 600;
}
.ivu-tabs-nav .ivu-tabs-tab:hover{
    color:#CE7720;
}

.ivu-table-default::before {
    display: none;
}

.ivu-table-header th {
    border-bottom: none !important;
}
.ivu-page.mini .ivu-page-options-elevator input {
    text-align: center;
}

.ivu-table-row-hover {
    td {
      background: #FFF8E8 !important;
    }
}

.ivu-breadcrumb{
    font-size: 14px;
}
.mt-12{
    margin-top: 12px;
}
.ml-12 {
    margin-left: 12px;
}

.fixed_price {
    width: 44px;
    height: auto;
}

.cursor-pointer {
    cursor: pointer;
}