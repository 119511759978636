
//$grayTxt: rgba(0, 0, 0, 0.6);
.user-tool-wrapper {
  padding: 24px 0 16px 0;
  background: #FFFFFF;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.03);
  &__content {
    display: flex;
    justify-content: space-between;
    height: 48px;
    .left {
      .logo {
        width: 195px;
        height: 48px;
        margin-right: 40px;
        margin-top: 5px;
      }
      .slot-wrapper {
        position: relative;
        top: -15px;
        color: rgba(0, 0, 0, 0.60);
        font-size: 16px;
        font-weight: 500;
      }
    }
    .right {
      color: rgba(0, 0, 0, 0.60);
      line-height: 64px;
      font-size: 16px;
    }
    .to-login-class {
      color: #ff9900;
    }
  }
}
